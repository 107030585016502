import React from 'react'

function MoreScreen () {
    const whatsAppLink = (phoneNumber) => {
        return `https://wa.me/${phoneNumber}`;
      };  
        return (
            <div id='new-screens-div'>
            
            </div>
        );
}
 
export default MoreScreen;