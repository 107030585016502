import React , {useState, useEffect, useRef} from 'react'
import Category from '../components/Category';
import ElectronicsImg from '../assets/electronics.avif';
import FurnitureImg from '../assets/furniture.jpg';
import autoParts from '../assets/auto-parts.jpg';
import musicInstruments from '../assets/music-instruments.jpeg';
import CustomNavbar from '../components/CustomNavbar';
import { initializeApp } from 'firebase/app';
import { getFirestore, collection, getDocs, onSnapshot, query, where, updateDoc, limit, doc, getDoc, serverTimestamp, arrayUnion , addDoc, orderBy} from 'firebase/firestore';
import Product from '../components/Product';
import Button from '@mui/material/Button';
import { Select, MenuItem } from '@mui/material';
import { Dropdown, DropdownMenu, DropdownToggle } from 'react-bootstrap';
import { Divider } from '@mui/material';
import { PiGavelFill } from "react-icons/pi";
import { MdOutlineWatchLater } from "react-icons/md";
import { AiOutlineHeart } from "react-icons/ai";
import { IoIosWarning } from "react-icons/io";
import {useNavigate, useLocation, useSearchParams, useParams, Link} from "react-router-dom";
import Countdown from 'react-countdown';
import ImageGallery from "react-image-gallery";
import '../../node_modules/react-image-gallery/styles/css/image-gallery.css';
import { AiFillStar } from "react-icons/ai";
import { BiSolidPurchaseTagAlt } from "react-icons/bi";
import f from '../assets/furniture.jpg';
import { getAuth, signInAnonymously, onAuthStateChanged } from "firebase/auth";
import Comment from '../components/Comment';
import { MdVerifiedUser } from "react-icons/md";
import { FaLocationDot } from "react-icons/fa6";
import { Oval } from 'react-loader-spinner'
import { db, auth, storage } from '../config/firebase'

function UserScreen() {
  const [Listing, setListing] = useState([]);
  const [gal, setgal] = useState([]);
  const [similarProducts, setSimilarProducts] = useState([]);
  const [similarCategory, setSimilarCategory] = useState([]);
  const [similarSubCategory, setSimilarSubCategory] = useState([]);
  const [productCategory, setProductCategory] = useState("")
  const [productSubCategory, setProductSubCategory] = useState("")
  const [productPrice, setProductPrice] = useState(0)
  const [productStorageSize, setProductStorageSize] = useState("")
  const [productScreenSize, setProductScreenSize] = useState("")
  const [productMemorySize, setProductMemorySize] = useState("")
  const [productInkKind, setProductInkKind] = useState("")
  const [filteredListing, setFilteredListing] = useState([]);
  const [filteredCategory, setFilteredCategory] = useState("");
  const [qu, setQu] = useState(query(collection(db, "Posts")));
  const [isLoading, setIsLoading] = useState(true);
  const [hasExpired, setExpired] = useState(false);
  const [acceptsOffers, setAcceptsOffers] = useState(false);
  const [commentsLoading, setCommentsLoading] = useState(false);
  const [currentBid, setCurrentBid] = useState(0);
  const [myBid, setMyBid] = useState(0);
  const [comment, setComment] = useState("");
  const [comments, setComments] = useState([]);
  const [postComments, setPostComments] = useState([]);
  const [userId, setUserId] = useState("");
  const [myUserId, setMyUserId] = useState("");
  const [addCommentLoading, setAddCommentLoading] = useState(false);

  const [city, setCity] = useState("")

  const [isVerified, setIsVerified] = useState(false)
  const [userExist, setUserExist] = useState(false)

  const [sellerId, setSellerId] = useState("");


  const [userName, setUserName] = useState("");
  const [profileImgUrl, setProfileImgUrl] = useState("");
  const [userRating, setUserRating] = useState(0);
  const [numRaters, setNumRaters] = useState(0);


  const navigate = useNavigate();

  const myBidRef = useRef();
  const commentRef = useRef();
  
  
  const [isDeleted, setisDeleted] = useState(false);
  const [isSold, setisSold] = useState(false);

  const [bidders, setBidders] = useState([]);

  const [isCustomer, setIsCustomer] = useState(false);
  
  const {id} = useParams();
  
  const q = query(collection(db, "Users"), where("id", "==", parseInt(id)));
  
  const postRef = doc(db, "Posts", `post${id}`);

  const fetchComments = async () => {
    try {
      // const postRef = doc(db, 'Users', id);
      const postDoc = await getDoc(postRef);

      if (postDoc.exists()) {
        const postData = postDoc.data();
        const commentsData = postData.commentsOnTheUser || [];

        if(commentsData.length > 0){
          // Fetch user data for each comment
        const commentsWithUserData = await Promise.all(
          commentsData.map(async (comment) => {
            const userRef = doc(db, 'Users', comment.userId);
            const userDoc = await getDoc(userRef);
            const userData = userDoc.exists() ? userDoc.data() : null;

            return {
              ...comment,
              user: userData,
            };
          })
        );

        setComments(commentsWithUserData);
        }
      } else {
        console.error('Post not found');
      }
    } catch (error) {
      console.error('Error fetching comments:', error);
    }
  };
  
  
  const fetchCommentsNew = async () => {
    try {
      const userQuery = query(collection(db, 'Users'), where('id', '==', parseInt(id)));
      const userSnapshot = await getDocs(userQuery);
      const userDoc = userSnapshot.docs[0];
      if (userDoc) {

        const commentsRef = collection(db, "Users" , userDoc.id, 'commentsOnTheUser');
        const commentsSnapshot = await getDocs(query(commentsRef, where('isDeleted', '==', false), orderBy('timestamp', 'asc')));
        const comments = [];

        for (const commentDoc of commentsSnapshot.docs) {
          const commentData = commentDoc.data();
  
          const userQuery2 = query(collection(db, 'Users'), where('id', '==', commentData.userId));
          const userSnapshot2 = await getDocs(userQuery2);
          const userDoc2 = userSnapshot2.docs[0]

          if (userDoc2) {
            const userData = userDoc2.data();

            comments.push({
              commentId: commentDoc.id,
              userId: commentData.userId,
              comment: commentData.comment,
              timestamp: commentData.timestamp,
              isDeleted: commentData.isDeleted,
              isUpdated: commentData.isUpdated,
              user: {
                fullName: userData.fullName,
                profileImgUrl: userData.profileImgUrl,
                // Add other user properties you need
              },
            });
          }
        }
  
        setComments(comments);
      }
    } catch (error) {
      console.error('Error fetching comments:', error);
    }
    setCommentsLoading(false);
  };
  
    
  useEffect(() => {
    const fetchMyId = async () =>{
      if(auth.currentUser !== null){
        const snapshot = await getDoc(doc(db, "Users", auth.currentUser.uid))
        setMyUserId(snapshot.data()['id']);
      }
    }
    const fetchData = async () => {
      try {
        const querySnapshot = await getDocs(q);
        if (!querySnapshot.empty) {
          setUserExist(true)
          querySnapshot.forEach((doc) => {
            const data = doc.data();
              setProfileImgUrl(data.profileImgUrl)
              setUserName(data.fullName)
              setUserRating(data.rating)
              setNumRaters(data.numRaters)
              setIsVerified(data.isVerified)
              setCity(data.city)
              setUserId(data.id)
          });

          fetchCommentsNew();
          const listener = onSnapshot(query(collection(db, "Posts"), where('seller.sellerId', "==", parseInt(id))), (querySnapshot) => {
            if (!querySnapshot.empty) {
              const posts = [];
              querySnapshot.forEach((doc) => {
                if(!doc.data()['isDeleted'] && !doc.data()['isSold']){
                  if (doc.data().expiryDate !== "") {
                    if (Date.now() / 1000 - doc.data().expiryDate.seconds < 0) {
                      posts.push(doc.data());
                    }
                  } else {
                    posts.push(doc.data());
                  }
                }
              });
              
              setListing(posts);
            } else {
              setListing([]);
            }
          }, (err) => {
            console.error('Error fetching posts:', err);
          });
        } else {
          setIsLoading(false)
          // return (<h2 style={{marginTop: "60px"}}>there is no user </h2>)
          setUserExist(false);
        }



        const myPostsHeighestBidders = [];
        
        const snapshot = await getDocs(query(collection(db, "Posts"), where("seller.sellerId", "==", parseInt(id))))


        if(!snapshot.empty){
            snapshot.forEach((doc) => {
                const data = doc.data();
                if(data.isSold){
                    myPostsHeighestBidders.push(data.heighestBidder)
                }
            });
        }

        if(auth.currentUser != null){
            if( myPostsHeighestBidders.includes(auth.currentUser.uid)){
                setIsCustomer(true)
            }
            else{
                setIsCustomer(false);
            }
        }
        else{
            setIsCustomer(false)
        }
  
        setIsLoading(false);
        
      } catch (err) {
        // Handle error
      }
    };
  
    fetchMyId();
    const unsubscribe = onSnapshot(q, fetchData);
  
    return () => {
      // Cleanup
      unsubscribe();
    };
  }, [id]);
  

    // id, isDeleted, currentBid, similarProducts, similarCategory, Object.keys(bidders).length

    const addCommentToUser = async () => {
      try {
        if (auth.currentUser !== null) {
          const myRef = doc(db, `Users/${auth.currentUser.uid}`)
          const docSnap = await getDoc(myRef)

          const userQuery = query(collection(db, 'Users'), where('id', '==', parseInt(id)));
          const userSnapshot = await getDocs(userQuery);
    
          if (!userSnapshot.empty) {
            const userDoc = userSnapshot.docs[0]; // Assuming there is only one matching document
    
            // Add the comment document directly to the user's comment collection
            await addDoc(collection(userDoc.ref, 'commentsOnTheUser'), {
              userId: docSnap.data()['id'],
              comment: commentRef.current.value,
              timestamp: serverTimestamp(),
              isDeleted: false,
              isUpdated: false,
            });


            // Add the comment document directly to the user's comment collection
            await addDoc(collection(myRef, 'commentsOnUsers'), {
              userId: docSnap.data()['id'],
              comment: commentRef.current.value,
              timestamp: serverTimestamp(),
              isDeleted: false,
              isUpdated: false,
            });


    
            // Refetch comments after adding a new comment
            // fetchComments(`user${id}`);
            fetchCommentsNew();
          } else {
            console.error('User document not found');
          }
        } else {
          alert('You need to sign in to add a comment.');
        }
      } catch (error) {
        console.error('Error adding comment:', error);
      }
    
      // Clear the comment input field
      commentRef.current.value = '';
    };

    const handleAddComment = async () => {
      try {
        if (auth.currentUser !== null) {
          const postRef = doc(db, 'Users', parseInt(id));
          const postDoc = await getDoc(postRef);
  
          if (postDoc.exists()) {
            const updatedComments = arrayUnion({
              userId: auth.currentUser.uid,
              comment: commentRef.current.value,
              timestamp: new Date().toISOString(),
              isDeleted: false,
              isUpdated: false
            });
  
            // Update the Firestore document with the new comments array
            await updateDoc(postRef, {
              commentsOnTheUser: updatedComments,
            });
  
            // Refetch comments after adding a new comment
            // fetchComments();


          const userRef = doc(db, 'Users', auth.currentUser.uid);
          const userDoc = await getDoc(userRef);
  
          if (userDoc.exists()) {
            const commentsOnUsers = arrayUnion({
              userId: parseInt(id),
              comment: commentRef.current.value,
              timestamp: new Date().toISOString(),
              isDeleted: false,
              isUpdated: false
            });
  
            // Update the Firestore document with the new comments array
            await updateDoc(userRef, {
              commentsOnUsers: commentsOnUsers,
            });
          } else {
            console.error('Post not found');
          }
          } else {
            console.error('Post not found');
          }
        } else {
          alert('You need to sign in to add a comment.');
        }
      } catch (error) {
        console.error('Error adding comment:', error);
      }

      commentRef.current.value = ""
    };


    // const getComments = async () => {
    //   const commentElements = await Promise.all(
    //     Object.entries(postComments).map(async (c) => {
    //       const docRef = doc(db, "Users", c[0]);
    //       const docu = await getDoc(docRef);
    //       // Your code logic here using docu
          
    //       // Return the JSX element you want to render
    //       return <>
    //       <Comment 
    //       comment={c[1]}
    //       userImg={docu.data()['profileImgUrl']}
    //       userRating={docu.data()['rating']}
    //       numRaters={docu.data()['numRaters']}
    //       userName={docu.data()['fullName']}
    //       />
    //       </>
    //     })
    //     );
    //     setComments(commentElements); // Set the comments in the state
    //     setIsLoading(false)
    //   };
      
    //   getComments();



    if(isLoading){
      return       <div style={{
        width: "100vw",
        height: "70vh",
        marginTop: "55px",
        display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

      }}>
        <Oval
              height={"50px"}
              width={"50px"}
              color={"dodgerblue"}
              wrapperClass=""
              visible={true}
              wrapperStyle={{display: "block"}}
              ariaLabel='oval-loading'
              secondaryColor="#ccc"
              strokeWidth={1}
              strokeWidthSecondary={1}
            />
      </div>
    }


    if(!userExist){
      return <div style={{marginTop: "60px", height: "80vh"}}>
<h2>user doesnt exist</h2>
      </div>
    }

  return ( 
    
    <>
    <div style={{
      marginTop: "10px",
      marginBottom: "55px",
      }}
      >

<div style={{ display: "flex", direction: "rtl", width: "fit-content", marginLeft: "auto", marginRight: "auto", textAlign: "center" }}>
  <div>
    <img width={200} height={200} style={{ borderRadius: "1000px", objectFit: "cover", marginLeft: "auto", marginRight: "auto" }} src={profileImgUrl} />

    <div style={{ display: "flex", flexDirection: "column", alignItems: "center", marginTop: "8px" }}>
      <div style={{ display: "flex", alignItems: "center" }}>
        <h4 style={{ margin: "0", marginLeft: "4px" }}>{userName}</h4>
        {isVerified && <MdVerifiedUser size={25}  style={{marginLeft: "4px"}} color='dodgerblue'/>}
      </div>
      <div style={{display: "flex"}}>
        <AiFillStar size={18} style={{marginTop: "3px"}} color='gold' />
<p style={{marginBottom: "0px"}}>{userRating}</p>
<p style={{marginBottom: "0px", marginRight: "4px"}}>({numRaters})</p>
      </div>
      
      {
        city != "" &&
        <div style={{direction: "rtl", display: "flex"}}>
<FaLocationDot color='red'/>
<p style={{marginRight: "2px", marginBottom: "0px", color:"black"}}>{city}</p>
      </div>
      }

{isCustomer && auth.currentUser !== null && auth.currentUser.uid !== parseInt(id) && (
  <button
id='my-button'
style={{
  marginTop: "8px",
  width: "60%",
   padding: "4px 8px"
}}
  >
    تقييم البائع
  </button>
)}


    </div>          
  </div>
</div>

        
<div id='product-details-comment-section'
style={{marginTop: "64px"}}
>



{
  comments.length > 0 ? (
    <>
      <h3 style={{marginBottom: "16px"}}>التعليقات</h3>
      {comments.map((comment) => (
        <Comment 
          key={comment.timestamp}  // Make sure to add a unique key
          comment={comment.comment} 
          userRating={comment.user.rating}
          numRaters={comment.user.numRaters}
          userImg={comment.user.profileImgUrl}
          userName={comment.user.fullName}
          commentRef="User"
          link={`/user/${comment.user.id}`}
          timestamp={comment.timestamp}
        />
      ))}
    </>
  ) : (
    <p style={{fontSize: "18px", marginRight: "8px"}}>لا توجد تعليقات حتى الآن</p>
  )
}

{auth.currentUser != null && myUserId !== id && (
  <>
    <textarea
      required={false}
      ref={commentRef}
      id='input-comment'
      style={{marginRight: "8px"}}
      minLength={100}
      onChange={(event) => {
        setComment(event.target.value);
      }}
      placeholder="أضف تعليقًا أو سؤالًا"
    />

    <button
      id='add-comment-button'
      disabled={auth.currentUser == null}
      onClick={addCommentToUser}
    >
         {   
     addCommentLoading ?    
     <div style={{
    alignItems: 'center',
    width: "100%",
    height: "100%",
    justifyContent: 'center',

      }}>
        <Oval
              height={"20px"}
              width={"20px"}
              color={"white"}
              visible={true}
              wrapperStyle={{display: "block"}}
              ariaLabel='oval-loading'
              secondaryColor="#ccc"
              strokeWidth={1}
              strokeWidthSecondary={1}
              />
              </div>
               : "اضافة تعليق"
              }
    </button>
  </>
)}


  {auth.currentUser == null && (
  <p style={{ marginTop: "8px" }}>
    <Link 
    style={{
      marginRight: "8px"
    }} to="/sign_in">أضف تعليق</Link>
  </p>
)}




{Listing.length > 0 ? (
  <>
    <h3 style={{ marginTop: "64px" }}>منتجات البائع</h3>
    <div style={{display:"grid", gap: "4px", width:"100%", height: "fit-content", gridTemplateColumns: window.innerWidth > 600 ? "repeat(auto-fill, minmax(20%, 1fr))" : "repeat(auto-fill, minmax(40%, 1fr))", alignItems: "center", textAlign:"center"}}>

    {Listing.map((item) => (
      <div key={item.id}
      
      >
        
        <Product
          postId={item.id}
          expiryDate={`${item.expiryDate.seconds}`}
          link={`/product/${item.id}`}
          numBidders={item.numBidders}
          currentBid={item.currentBid}
          imgs={item.imgs[0]}
          condition={item.condition}
          acceptsOffers={item.acceptsOffers}
          productPrice={item.productPrice}
          isSold={item.isSold}
          startingPrice={item.startingPrice}
          productDescription={item.productDescription}
          productSeller={item.seller}
          productName={item.productName}
          />
                <Divider
          style={{ borderBottomWidth: 1, width: "100%" }}
          color="#ccc"
          />
      </div>
    ))}
    </div>
  </>
) : (
  <p style={{marginBottom: "85px"}}>هذا البائع ليس لديه منتجات</p>
)}



</div>
    </div>
    </>
   );
  }

export default UserScreen;