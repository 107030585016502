import React, { useEffect, useState } from 'react'
import e from '../assets/electronics.avif';
import f from '../assets/furniture.jpg';
import { MdOutlineWatchLater } from "react-icons/md";
import { PiGavelFill } from "react-icons/pi";
import { AiFillStar } from "react-icons/ai";
import { Divider } from '@mui/material';
import { initializeApp } from 'firebase/app';
import {Link , useParams, useNavigate, createSearchParams, useSearchParams} from 'react-router-dom';
import Countdown from 'react-countdown';
import { getStorage, ref, listAll, getDownloadURL,  } from "firebase/storage";
import { getFirestore, collection, getDocs, onSnapshot, where, Timestamp, Firestore, limit, doc, query , updateDoc, serverTimestamp } from 'firebase/firestore';
import placeholder from '../assets/no-image.jpg';
import { IoMdCheckmarkCircle } from "react-icons/io";
import { MdDangerous } from "react-icons/md";
import { FaLocationDot } from "react-icons/fa6";
import {storage} from '../config/firebase';
import { BsStopwatchFill } from "react-icons/bs";
  
  function Product({postId, imgs , isSold, isBidder, productName, heighestBidder, acceptsOffers ,productDescription, productPrice, startingPrice,productSeller, expiryDate, condition, currentBid ,numBidders, numBids, link, sellerCity}) {
    //   const postRef = doc(db, 'Posts', `post${postId}`);

      const [downloadURL, setDownloadURL] = useState(null);

      useEffect(() => {

        const fetchImageURL = async () => {
            try {
              const url = await getDownloadURL(ref(storage, `Posts/post${postId}/1`));
              setDownloadURL(url);
            } catch (error) {
              console.error('Error fetching download URL:', error);
            }
          };
      
        //   fetchImageURL();
      }, [])
      
    function formatAccountingArabic(number) {
        // Check if the input is a valid number
        if (typeof number !== 'number' || isNaN(number)) {
          return 'Invalid input';
        }
      
        // Use toLocaleString to format the number with commas and two decimal places
        const formattedNumber = number.toLocaleString('en', {
          minimumFractionDigits: number > 100 ? 0 : 2,
          maximumFractionDigits: number > 100 ? 0 : 2,
        });
      
        // Add the Arabic currency symbol manually
        const arabicFormattedNumber = `${formattedNumber} ر.س`;
      
        return arabicFormattedNumber;
    }
    
    return (
        <>
        <div style={{display: "flex"}}>
        <a href={link}>
            <div className='product-div'>
                <img src={imgs} className='product-img skeleton' />
                <p id='product-name-div'>{productName}</p>
                <div style={{ display: "flex", direction: "rtl", justifyContent: "space-between", alignItems: "center"}}>
  {productPrice > 0 && !acceptsOffers && (
    <p style={{ direction: "rtl", color: "#00C6B7", marginBottom: "4px" }}>{formatAccountingArabic(productPrice)}</p>
  )}

  {acceptsOffers && (
    <>
    <p style={{marginBottom: "0px", color: "#00C6B7"}}>{formatAccountingArabic(currentBid)}</p>
        </>
  )}
</div>
  {acceptsOffers && (
    <div style={{display: "flex", direction: "rtl", alignItems: "center", marginBottom: "2px", marginTop: "2px"}}>
      <div style={{display:"flex", justifyContent: "space-between"}}>
        <PiGavelFill
    style={{color: "#00C6B7", marginTop:"4px"}}
    />
    <p style={{marginBottom: "0px", marginRight: "4px", color: "#F00057"}}>{numBidders}</p>
    </div>

    <div style={{display: "flex",  marginRight:"auto"}}>

    <Countdown
    
    className='product-countdown-class'
    date={expiryDate * 1000}
    >
      <p style={{ marginBottom: "0px", color: "#F00057" }}>expired</p>
    </Countdown>
    <BsStopwatchFill color='#F00057' style={{marginRight: "4px", marginTop:"2px"}}/>
        </div>
    </div>
  )}
  <div style={{display: "flex", direction: "rtl"}}>

            <p style={{marginBottom: "4px", marginTop: "0px", color: "#00C6B7"}}>الحالة: </p>
            <p style={{marginBottom: "0px", color: "#F00057", marginRight: "4px"}}>{condition}</p>
  </div>
                <button style={{width: "100%", height: "40px    ", backgroundColor: "#00C6B7", borderRadius: "8px", color: "white", padding: "4px", outline: "none", border: "none",marginBottom: "4px"}}>رؤية التفاصيل</button>
            </div>
        </a>
        
        </div>


        
        </>
    );
}
 
export default Product;