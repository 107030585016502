import React , {useState, useEffect} from 'react'
import Category from '../components/Category';
import ElectronicsImg from '../assets/electronics.avif';
import FurnitureImg from '../assets/furniture.jpg';
import autoParts from '../assets/auto-parts.jpg';
import musicInstruments from '../assets/music-instruments.jpeg';
import CustomNavbar from '../components/CustomNavbar';
import { initializeApp } from 'firebase/app';
import { getFirestore, collection, getDocs, onSnapshot,deleteDoc, doc, query, where, updateDoc, limit, serverTimestamp , getDoc} from 'firebase/firestore';
import Product from '../components/Product';
import Button from '@mui/material/Button';
import { Select, MenuItem } from '@mui/material';
import { Dropdown, DropdownMenu, DropdownToggle } from 'react-bootstrap';
// Follow this pattern to import other Firebase services
// import { } from 'firebase/<service>';
import { Divider } from '@mui/material';
import { PiGavelFill } from "react-icons/pi";
import { MdOutlineWatchLater } from "react-icons/md";
import { AiOutlineHeart } from "react-icons/ai";
import { IoIosWarning } from "react-icons/io";
import {useNavigate, useLocation, useSearchParams, useParams, Link} from "react-router-dom";
import Countdown from 'react-countdown';
import ImageGallery from "react-image-gallery";
import '../../node_modules/react-image-gallery/styles/css/image-gallery.css';
import { AiFillStar } from "react-icons/ai";
import { BiSolidPurchaseTagAlt } from "react-icons/bi";
import f from '../assets/furniture.jpg';
import { useRef } from 'react';
import { Oval } from 'react-loader-spinner'
import { db, auth, storage } from '../config/firebase'
import { listAll, ref, getDownloadURL, uploadBytes, deleteObject  } from 'firebase/storage';

function MyPostDetailsScreen() {
  const [Listing, setListing] = useState([]);
  const [gal, setGal] = useState([]);
  const [tempGal, setTempGal] = useState([]);
  const [similarProducts, setSimilarProducts] = useState([]);
  const [similarCategory, setSimilarCategory] = useState([]);
  const [productCondition, setProductCondition] = useState("");
  const [productName, setProductName] = useState("");
  const [productDescription, setProductDescription] = useState("");
  const [productPrice, setProductPrice] = useState();
  const [filteredListing, setFilteredListing] = useState([]);
  const [filteredCategory, setFilteredCategory] = useState("");
  const [qu, setQu] = useState(query(collection(db, "Posts")));
  const [isLoading, setLoading] = useState(true);
  const [hasExpired, setExpired] = useState(false);
  const [acceptsOffers, setAcceptsOffers] = useState(false);
  const [postId, setPostId] = useState("");
  const [images, setImages] = useState([]);
  const [blobImgs, setBlobImgs] = useState([]);
  const [hasUpdatedImages, setHasUpdatedImages] = useState(false);
  const [productIsSold, setProductIsSold] = useState(false);
  const [doesntExist, setDoesntExist] = useState(false);
  const [originalImgs, setOriginalImgs] = useState([]);
  const [downloadURLs, setDownloadURLs] = useState([]);
  const [bidders, setBidders] = useState(0);
  const location = useLocation();

  const navigate = useNavigate();


  const {id} = useParams();

  const productNameRef = useRef();
  const productDescriptionRef = useRef();
  const productPriceRef = useRef();
  const startingPriceRef = useRef();
  const productImagesRef = useRef();
  

  const [productImagesHasError, setProductImageHasError] = useState(false);
  const [productNameHasError, setProductNameHasError] = useState(false);
  const [productDescriptionHasError, setProductDescriptionHasError] = useState(false);
  const [productPriceHasError, setProductPriceHasError] = useState(false);
  const [productExpiryDateHasError, setProductExpiryDateHasError] = useState(false);
  const [productStartingPriceHasError, setProductStartingPriceHasError] = useState(false);
  const [productConditionHasError, setProductConditionHasError] = useState(false);
  const [productCategoryHasError, setProductCategoryHasError] = useState(false);
  const [bidIncrementHasError, setBidIncrementHasError] = useState(false);
  const [startingPrice, setStartingPrice] = useState(10);
  const [bidIncrements, setBidIncrements] = useState(10);
  const [searchParams] = useSearchParams();
  
  const [imageUrls, setImageUrls] = useState([]);

  useEffect(() => {

    const gal = [];
    const fetchImages = async () => {
      try {
        const folderRef = ref(storage, `Posts/post${id}`);
        // List all items in the folder
        listAll(folderRef)
          .then((result) => {
            // result.items is an array of references to each image in the "images" folder
            const promises = result.items.map((itemRef) => {
              // Get download URL for each image
              return getDownloadURL(itemRef);
            });

            // Resolve all promises
            return Promise.all(promises);
          })
          .then((urls) => {
            // Set the array of image URLs
            urls.forEach((url)=>{
                gal.push({
                  original: url,
                  thumbnail: url,
                  originalWidth: '500px',
                  originalClass: 'image-gallery-class',
                  bulletClass: 'bulletClass',
                });
            })
            setGal([...gal]);
          })
          .catch((error) => {
            console.error('Error fetching images:', error);
          });
      } catch (error) {
        console.error('Error fetching images:', error);
      }
    };

    const fetchData = async () => {
      try {
        const userRef = doc(db, `Users/${auth.currentUser.uid}`);
        const docSnap = await getDoc(userRef);

        const docRef = query(
          collection(db, 'Posts'),
          where('id', '==', id),
          where('seller.sellerId', '==', docSnap.data()['id'])
        );

        const unsubscribe = onSnapshot(docRef, (querySnapshot) => {
          if (!querySnapshot.empty) {
            const cities = [];
            const imgs = [];

            querySnapshot.forEach((doc) => {
              const data = doc.data();
              setProductName(data['productName']);
              setProductDescription(data['productDescription']);
              setAcceptsOffers(data['acceptsOffers']);
              setProductCondition(data['condition']);
              // setOriginalImgs(data['imgs']);
              setPostId(doc.id);
              setProductPrice(data['productPrice']);
              setProductIsSold(data['isSold']);
              setBidders(data['bidders']);

              cities.push(data);

              // const imgKeys = Object.keys(data['imgs']);
              // for (let i = 0; i < imgKeys.length; i++) {
              //   const imgKey = imgKeys[i];
              //   imgs.push(data['imgs'][imgKey]);
              //   gal.push({
              //     original: data['imgs'][imgKey],
              //     thumbnail: data['imgs'][imgKey],
              //     originalWidth: '500px',
              //     originalClass: 'image-gallery-class',
              //     bulletClass: 'bulletClass',
              //   });
              // }
            });

            setListing(cities);
            setGal(gal);
          } else {
            setLoading(false);
            setDoesntExist(true);
            // Update state variables to indicate no records found
          }
        });
      } catch (err) {
        // Handle error
        console.error('Error fetching data:', err);
      }
    };

    const unsubscribeAuthStateChanged = auth.onAuthStateChanged(async (user) => {
      if (user != null) {
        await fetchData();
        setLoading(false);
      } else {
        // Set loading to false if the user is null
        setLoading(false);
      }
    });

    const uploadImagesToStorageAndFirestore = async (imagesArray, newId) => {
      const urls = {};
      const uploadPromises = imagesArray.map(async (image, index) => {
        const storageRef = ref(storage, `Posts/post${newId}/${index + 1}`);
        await uploadBytes(storageRef, image);
        // const downloadURL = await getDownloadURL(storageRef);
        // Object.assign(urls, {
        //   [`${index}`]: downloadURL
        // })

        // return urls;
      });
    
      try {
        await Promise.all(uploadPromises);
        // const urls = Object.assign({}, ...urlsArray);
        // return urls;
      } catch (error) {
        console.error('Error uploading images:', error);
        throw error;
      }
    };

if(images.length > 0){

  images.forEach(image => {
    tempGal.push({
        original: URL.createObjectURL(image),
        thumbnail: URL.createObjectURL(image),
        originalClass: 'image-gallery-class',
        bulletClass: 'bulletClass',
      })
    });
}
fetchImages();

    return () => {
      // Cleanup
      unsubscribeAuthStateChanged();
    };
  }, [images, imageUrls]);
  
  function formatAccountingArabic(number) {
    // Check if the input is a valid number
    if (typeof number !== 'number' || isNaN(number)) {
      return 'Invalid input';
    }
  
    // Use toLocaleString to format the number with commas and two decimal places
    const formattedNumber = number.toLocaleString('en', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  
    // Add the Arabic currency symbol manually
    const arabicFormattedNumber = `${formattedNumber} ر.س`;
  
    return arabicFormattedNumber;
}



const uploadImagesToStorageAndFirestore = async (imagesArray, id) => {
  // const urls = {};
  // const uploadPromises = imagesArray.map(async (image, index) => {
  //   const storageRef = ref(storage, `Posts/post${id}/${index + 1}`);
  //   await uploadBytes(storageRef, image);
  //   // const downloadURL = await getDownloadURL(storageRef);
  //   // Object.assign(urls, {
  //   //   [`${index}`]: downloadURL
  //   // })

  //   // return urls;
  // });

  const folderRef = ref(storage, `Posts/${postId}`); // Replace 'images' with your folder name

  // Delete existing files in the folder
  const existingFiles = await listAll(folderRef);
  await Promise.all(existingFiles.items.map((fileRef) => deleteObject(fileRef)));


  // const uploadPromises = imagesArray.map(async (image, index) => {
  //   const storageRef = ref(storage, folderRef);
  //   await uploadBytes(storageRef, image);
  //   // const downloadURL = await getDownloadURL(storageRef);
  //   // Object.assign(urls, {
  //   //   [`${index}`]: downloadURL
  //   // })

  //   // return urls;
  // });

          const urls = {};
          const uploadPromises = imagesArray.map(async (image, index) => {
            try {
              const storageRef = ref(storage, `Posts/${postId}/${index + 1}`);
              await uploadBytes(storageRef, image);
              const downloadURL = await getDownloadURL(storageRef);
              urls[index] = downloadURL;
            } catch (error) {
              console.error(`Error uploading image ${index + 1}:`, error);
              throw error;
            }
          });



  try {
    await Promise.all(uploadPromises);
    // const urls = Object.assign({}, ...urlsArray);
    // return urls;
  } catch (error) {
    console.error('Error uploading images:', error);
    throw error;
  }
  
}

if(doesntExist){
  return <p style={{marginTop: "60px"}}>لا يوجد منتج لديك بهذا الرقم</p>

}
    if(isLoading){
      return       <div style={{
        width: "100vw",
        height: "80vh",
        marginTop: "55px",
        display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

      }}>
        <Oval
              height={"50px"}
              width={"50px"}
              color={"dodgerblue"}
              wrapperClass=""
              visible={true}
              wrapperStyle={{display: "block"}}
              ariaLabel='oval-loading'
              secondaryColor="#ccc"
              strokeWidth={1}
              strokeWidthSecondary={1}
            />
      </div>
    }

    // if(acceptsOffers && hasExpired ){
    //   return <h1 style={{marginTop:"60px", marginBottom: "100px"}}>this post has expired</h1>
    // }


  return ( 
    <>
    <div style={{
      marginTop: "55px",
      marginBottom:"55px"
      }}
      key={1}
      >
      {
        Listing.map  ((item) => (
        <div
          id='product-details-and-img'
            key={item.id}
            >
          <div
          id='product-details-div'
          key={item.id}
          >
            <div
            id='post-details-img'
            key={1}
            >

              <ImageGallery 
              showBullets={true}
              showNav={false}
              showPlayButton={false}
              showFullscreenButton={false}
              isRTL={true}
              infinite={true}
              items={images.length == 0 ? gal : tempGal} />

            {/* <div style={{ display: "flex", flexWrap: "wrap" }}>
              {imageUrls.map((image, index) => (
                <div key={index} style={{height: "fit-content", padding: "4px" }}>
                  <img
                    src={image}
                    alt={`Image ${index}`}
                    style={{ width: "100%", height: "200px", objectFit: "scale-down" }}
                  />
                </div>
              ))}
            </div> */}


<input ref={productImagesRef} hidden onChange={(value)=>{
                    setImages([...value.target.files]);
                    setHasUpdatedImages(true);
                      }} type='file' multiple style={{display: "none"}} placeholder='صور المنتج' />
            </div>
            
                <div
                style={{
                  margin: "16px 32px",
                }}
                >
                  <div style={{ justifyContent: "center", alignItems: "center", textAlign: "center", marginBottom: "24px"}}>

                  {(!productIsSold && Object.keys(bidders).length === 0) && (
                    <button onClick={()=>{
                      productImagesRef.current.click();
                    }} id='my-button-2' style={{ width: "55%" }}>
                      اعادة اختيار صور المنتج
                    </button>
                  )}


                  {
                    hasUpdatedImages && 
                    <button id='my-button-2' style={{ width: "55%", marginTop:"8px" }}
                    onClick={()=>{
                      setHasUpdatedImages(false);
                      productImagesRef.current.value = "";
                      setTempGal([])
                      setImages([])}
                    }
                    >
                      اعادة الصور
                    </button>
                  }

                  </div>
                    {
                      productIsSold || Object.keys(bidders).length > 0 ? 
                      <h2
                      id='product-details-desc'

                      style={{color:"black", fontSize: "18px"}}
                      >{item.productName}</h2> 
                      : 
                  <input placeholder='اسم المنتج' onChange={(value)=>{
                    setProductName(value.target.value)
                  }} style={{display: "block", width: "80%",borderRadius: "8px", border: "none", direction: "rtl", height: "45px", paddingRight: "8px", border: "1px solid #f0f0f0"}} type='text' ref={productNameRef} defaultValue={item.productName}/>
                  }

                    { 
                    productIsSold || Object.keys(bidders).length > 0 ? 
                    <h4
                    id='product-details-desc'
                    style={{fontSize: "16px"}}
                    >{item.productDescription}</h4>  
                    :
                <textarea  style={{marginRight: "0px", border: "1px solid #f0f0f0" , width: "80%" }} ref={productDescriptionRef} id={ 'text-area'} minLength={100} defaultValue={productDescription} onChange={(value)=> {
                  // setProductDescriptionHasError(false);
                  setProductDescription(value.target.value)}}  placeholder='وصف المنتج' />
                  }

                <div 
                  style={{
                    display: "flex",
                    marginTop: "20px"
                  }}
                  >

                  <div
                  style={{
                    marginRight: "8px"
                  }}
                  >
                  <div
                  style={{
                    display: "flex"
                  }}
                  >

                    </div>
                  </div>
                </div>

                <div
                style={{
                  display: "flex",
                  alignItems: "center"
                }}
                >
                <p style={{marginBottom: "0px", fontSize: "16px"}} id='product-details-condition1'>الحالة:</p>
                {  productIsSold || Object.keys(bidders).length > 0 ? 

                   <p style={{marginBottom: "0px", fontSize: "20px"}} id='product-details-condition2'>{item.condition}</p>
                   : 
                <select defaultValue={item.condition}  style={{display: "block", backgroundColor: "white", width: "50%",borderRadius: "8px", border: "none", direction: "rtl", height: "40px", marginTop: "8px" , marginRight: "8px" , paddingRight: "24px", border: "1px solid #f0f0f0"}} onChange={(value)=>{
              setProductCondition(value.target.value);
            }}>
                <option disabled hidden>حالة المنتج</option>
                <option value="جديد">جديد</option>
                <option value="مستعمل">مستعمل</option>
                <option value="ممتازة">ممتازة</option>
                <option value="جيدة جدا">جيدة جدا</option>
                <option value="جيدة">جيدة</option>
                <option value="مقبولة">مقبولة</option>
            </select>
                }
                </div>
                { acceptsOffers &&
                  <>
                <div
                id='product-details-icon-with-data'
                >
                <MdOutlineWatchLater
                id='product-details-icon'

                style={{marginBottom: "0px", color: "gray"}}
                />
                <p style={{marginTop:"1px"}}>:</p>
                <Countdown
                className='countdown-class'
                  date={item.expiryDate.seconds * 1000}
                  onComplete={()=>{setExpired(true)}}
                  >
                    <p style={{marginRight: "4px", marginTop:"1px"}}>expired</p>
                  </Countdown>
                </div>
                <div
                id='product-details-icon-with-data'
                style={{marginTop: "0px"}}
                >
                <PiGavelFill
                style={{marginTop:"0px"}}
                id='product-details-icon'
                />
                <p>:</p>
                <p
                id='product-details-numbidders'
                >{Object.keys(item.bidders).length}</p>
                </div>
                </>
              }
                                  
                                  {acceptsOffers && (
  <div style={{ display: "flex", alignItems: "center" }}>
    <p style={{ whiteSpace: "nowrap", fontSize: "16px", marginBottom: "0" }}>
      مبلغ بدء المزاد:
    </p>
    {Object.keys(item.bidders).length > 0 || productIsSold ? (
      <p style={{ marginRight: "4px", marginBottom: "0px", fontSize: "17px" }}>
        {formatAccountingArabic(item.startingPrice)}
      </p>
    ) : (
      <input
        style={{
          display: "block",
          width: "42%",
          borderRadius: "8px",
          border: "none",
          direction: "rtl",
          height: "40px",
          paddingRight: "24px",
          border: "1px solid #f0f0f0",
        }}
        type="number"
        ref={startingPriceRef}
        defaultValue={item.startingPrice}
      />
    )}
  </div>
)}




                <div
                id='product-details-icon-with-data'
                >
                  <div style={{width: "100%"}}>

                  {item.productPrice > 0 && acceptsOffers ?
                  <div
                  style={{
                    display: "flex"
                  }}
                  >
                  <p style={{marginTop:"4px", fontSize:"16px"}}>سعر الشراء الفوري:</p>  
                  {
                    productIsSold || Object.keys(bidders).length > 0 ? 
                    <p style={{fontSize:"17px", marginTop: "4px", marginRight: "2px"}}>
                      {formatAccountingArabic(item.productPrice)}
                    </p>
                    : 
                  <input style={{display: "block", width: "42%", marginRight: "8px" ,borderRadius: "8px", border: "none", direction: "rtl", height: "40px", paddingRight: "24px", border: "1px solid #f0f0f0"}} type='text' ref={productPriceRef} defaultValue={item.productPrice}/>
                  }
                  </div>
                  : ""
                  }
                  <div style={{
                    display: "flex",
                  }}>
                
                { acceptsOffers ? <p style={{ whiteSpace:"nowrap", fontSize:"16px"}}>السعر الحالي:</p> : <p style={{marginTop: "4px", whiteSpace:"nowrap", fontSize:"16px"}}>السعر:</p>}
                
                {acceptsOffers ? 

                <p
                style={{fontSize:"17px", marginBottom: "4px"}}
                id='product-details-currentBid'
                >{formatAccountingArabic(item.currentBid)}</p> 

                : 
                <div>

                {
                  productIsSold ? 
                   <p
                  id='product-details-currentBid'
                >{formatAccountingArabic(item.productPrice)}</p> 
                : 
                <input placeholder='سعر المنتج' onChange={(e)=>{
                  setProductPrice(e.target.value)
                }} style={{display: "block", width: "100%",borderRadius: "8px", border: "none", direction: "rtl", height: "40px", paddingRight: "24px", border: "1px solid #f0f0f0", marginRight: "4px"}} type='text' ref={productPriceRef} defaultValue={item.productPrice}/>
              }
                </div>
                }
                </div>
                {
                  ! productIsSold && Object.keys(bidders).length == 0 ?
                  <>
                  

                <div
                style={{
                  marginBottom: "65px",
                  width: "100%",
                  marginLeft: "32px",
                  display: "flex",
                  flexDirection: "column",
                  marginTop: "24px",
                }}
                >
                    <button id='my-button-2'  style={{
                      marginTop: "8px", 
                      backgroundColor: "teal"
                    }} onClick={async ()=>{
              // if(!acceptsOffers){
              //   if(images.length < 3 || 
              //     productName.length < 10 || 
              //     productDescription.length < 50 || 
              //     typeof parseFloat(productPrice) !== 'number' || isNaN(parseFloat(productPrice)) ||
              //     productCondition == ""
              //     ){
              //       if(images.length < 3){
              //         setProductImageHasError(true);
              //       }
              //       if(productName.length < 10){
              //         setProductNameHasError(true)
              //       }
              //       if(productDescription.length < 50){
              //         setProductDescriptionHasError(true)
              //       }
              //       if(productPrice == ""){
              //         setProductPriceHasError(true)
              //       }
              //       if(typeof parseFloat(productPrice) !== 'number'){
              //         setProductPriceHasError(true)
              //       }
              //       if(isNaN(parseFloat(productPrice))){
              //         setProductPriceHasError(true)
              //       }
              //       if(productCondition == ""){
              //         setProductConditionHasError(true)
              //       }
              //       return;
              //     }
              // }
              // else{
              //   if(images.length < 3 || 
              //     productName.length < 10 || 
              //     productDescription.length < 50 || 
              //     typeof parseFloat(productPrice) !== 'number' || isNaN(parseFloat(productPrice)) ||
              //     productCondition == "" || 
              //     startingPrice == "" ||
              //     parseFloat(startingPrice) < 10 ||
              //     typeof parseFloat(startingPrice) !== 'number' || isNaN(parseFloat(startingPrice)) ||
              //     ((parseFloat(productPrice) > 0) && (parseInt(startingPrice) >= parseFloat(productPrice))) ||
              //     parseFloat(bidIncrements) < 10
              //     ){
              //       if(images.length < 3){
              //         setProductImageHasError(true);
              //       }
              //       if(productName.length < 10){
              //         setProductNameHasError(true)
              //       }
              //       if(productDescription.length < 50){
              //         setProductDescriptionHasError(true)
              //       }
              //       if(productPrice == ""){
              //         setProductPriceHasError(true)
              //       }
              //       if(typeof parseFloat(productPrice) !== 'number'){
              //         setProductPriceHasError(true)
              //       }
              //       if(isNaN(parseFloat(productPrice))){
              //         setProductPriceHasError(true)
              //       }
              //       if(productCondition == ""){
              //         setProductConditionHasError(true)
              //       }

              //       if(startingPrice == "")
              //         setProductStartingPriceHasError(true)
              //       if(startingPrice < 10)
              //         setProductStartingPriceHasError(true)
              //       if(typeof parseInt(startingPrice) !== 'number' || isNaN(parseInt(startingPrice)))
              //       setProductStartingPriceHasError(true)
              //       if((parseFloat(productPrice) > 0) && parseFloat(startingPrice) >= parseFloat(productPrice)){
              //         setProductPriceHasError(true)
              //         setProductStartingPriceHasError(true)
              //       }
              //       if(parseFloat(bidIncrements) < 10){
              //         setBidIncrementHasError(true)
              //       }
              //       if(isNaN(parseFloat(bidIncrements))){
              //         setBidIncrementHasError(true)
              //       }
      
              //       return;
              //     }
              // }
              
                      setLoading(true);

                      // for(var i = 0; i < 10 ; i ++){
                      //     // Create a reference to the file to delete
                      //     const desertRef = ref(storage, `Posts/post${id}/${i}`);
                      //     // Delete the file
                      //     await deleteObject(desertRef).catch((error) => {
                      //       // Uh-oh, an error occurred!
                      //       console.log(error)
                      //     });
                      //   }

                      if(hasUpdatedImages){
                        await uploadImagesToStorageAndFirestore(images, postId).then(()=>{
                          setHasUpdatedImages(false)
                        })
                      }

                      const washingtonRef = doc(db, "Posts", postId);

                      try {
                        await updateDoc(washingtonRef, {
                          condition: productCondition,
                          productName: productName,
                          productDescription: productDescription,
                          isUpdated: true,
                          updatedDate: serverTimestamp(),
                          productPrice: parseFloat(productPrice),

                        });
                        console.log("Update successful");
                      } catch (error) {
                        console.error("Error updating document:", error.message);
                      }
                      
                      setLoading(false);
                      // navigate("/my_posts")
                    }}  >تحديث البيانات</button>


                    <button id='my-button-2' style={{
                      // backgroundColor: "#ff4942",
                    backgroundColor: "crimson",

                    marginTop: "8px"}} onClick={()=>{
                    setHasUpdatedImages(false);
                    productImagesRef.current.value = "";
                    setTempGal([])
                    setImages([])}
                    }>حذف جميع التغييرات</button>


                    <button id='my-button-2' style={{
                      // backgroundColor: "#ff4942",
                      backgroundColor: "crimson",
                      marginTop: "8px"
                    }} onClick={async ()=>{
                      setLoading(true);
                      const washingtonRef = doc(db, "Posts", postId);
                      // await deleteDoc(washingtonRef);
                      await updateDoc(washingtonRef, {
                        isDeleted: true
                      });
                      setLoading(false);
                      navigate("/my_account")
                    }}  >حذف المنتج</button>
                    </div>
                    </>
                    :
                    <>
                    {
                      productIsSold &&
                      <h3 style={{color: "green", marginTop:"16px"}}>تم بيع هذا المنتج</h3>
                    }
                    {
                      Object.keys(bidders).length > 0 &&
                      <h3 style={{color: "green", marginTop:"16px"}}>لا يمكنك تعديل منتج تمت المزايدة عليه</h3>
                    }
                    </>
                  }
                </div>
                </div>
          </div>
        </div>


        </div>
        ))
      }
    </div>
    </>
   );
  }

export default MyPostDetailsScreen;