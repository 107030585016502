import React , {useState, useEffect, useRef} from 'react'
import { getFirestore, collection, query } from 'firebase/firestore';
import {useNavigate, useSearchParams} from "react-router-dom";
import '../../node_modules/react-image-gallery/styles/css/image-gallery.css';
import { Oval } from 'react-loader-spinner'
import watch from '../assets/watches.png'
import sbhah from '../assets/sbhah.png'
import laptopBanner from '../assets/laptop-banner.jpg'
import perfumesBanner from '../assets/perfumes-banner.jpg'
import pen from '../assets/pen2.png'
import wallet from '../assets/wallets.png'
import emailjs from 'emailjs-com';
import axios from 'axios';
import ImageGallery from "react-image-gallery";


function LandingScreen() {
    const [isLoading, setIsLoading] = useState(true);
    const [ searchParams, setSearchParams ] = useSearchParams();
    const navigate = useNavigate();

    const [phoneNumber, setPhoneNumber] = useState('');
    const [message, setMessage] = useState('');
    
    useEffect(() => {
      setIsLoading(false)
    }, []);


    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'instant'
    });

    if(isLoading){
      return       <div style={{
        width: "100vw",
        height: "80vh",
        marginTop: "55px",
        display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

      }}>
        <Oval
              height={"50px"}
              width={"50px"}
              color={"dodgerblue"}
              wrapperClass=""
              visible={true}
              wrapperStyle={{display: "block"}}
              ariaLabel='oval-loading'
              secondaryColor="#ccc"
              strokeWidth={1}
              strokeWidthSecondary={1}
            />
      </div>
    }
    const sendEmail = async () => {
        const url = 'https://example.com'; // Replace with your actual URL
        const templateParams = {
          to_email: "ybaghlaf@hotmail.com",
          to_name : "يوسف",
          website_url: url, // Add the URL to the template parameters
          // other template parameters if needed
        };
    
        try {
          const response = await emailjs.send("service_8rvibeb",
          "template_6gtnb4i",
          templateParams,
          "mPwQnQEP1--hqeKcI",
          "zDU_ue_gZ7PQcfiwa"
          );
    
          console.log('Email sent successfully!', response);
        } catch (error) {
          console.error('Error sending email:', error);
        }
      };




    //   const cropperRef = useRef<ReactCropperElement>(null);
    //   const onCrop = () => {
    //     const cropper = cropperRef.current?.cropper;
    //     console.log(cropper.getCroppedCanvas().toDataURL());
    //   };


    const handleSendOtp = async () => {
      try {
        const response = await axios.post('https://cors-anywhere.herokuapp.com/https://us-central1-mazad-782fd.cloudfunctions.net/sendOTP', {
          to: "+966541236990",
        });
  
        console.log(response.data);
        // Handle success or error responses
      } catch (error) {
        console.error('Error sending OTP:', error);
      }
    };
    
  return ( 
    <div id='landing-screen-div'
      >
       {/* <Cropper
      src="https://raw.githubusercontent.com/roadmanfong/react-cropper/master/example/img/child.jpg"
      style={{ height: 400, width: "100%" }}
      // Cropper.js options
      initialAspectRatio={16 / 9}
      guides={false}
      crop={onCrop}
      ref={cropperRef}
    /> */}


         {/* <div>

          <button onClick={handleSendOtp}>Send Email</button>
        </div> */}



              <ImageGallery 
              showBullets={true}
              showNav={false}
              showPlayButton={false}
              showFullscreenButton={false}
              isRTL={false}
              infinite={true}
              autoPlay
              slideInterval={4000}
              showThumbnails={false}
              
              onClick={(e)=> {
                if(e.target.src.includes("perfumes-banner")){
                    console.log("navigate to perfumes")
                }
                else{
                    console.log("navigate to laptops")
                }
              }}
              items={[
                {
                original: perfumesBanner,
                thumbnail: perfumesBanner,
                originalClass: 'image-gallery-class',
                bulletClass: 'bulletClass',
              },
                {
                original: laptopBanner,
                thumbnail: laptopBanner,
                originalClass: 'image-gallery-class',
                bulletClass: 'bulletClass',
              },
              ]} />



        <div id='explore-categories' style={{width: "100%"}}>
            <p style={{marginBottom: "16px", fontSize: "18px", fontWeight: "bold"}} >التصنيفات</p>
        </div>

            {/* <div style={{textAlign: "center", marginRight: "8px"}}>
                <Link to='/electronics' style={{textDecoration: "none", color: "black"}}>
            <img height={80} width={80} src={ElectronicsImg} style={{borderRadius: "1000px", objectFit: "cover"}} />
                    <p>الكترونيات</p>
                </Link>
            </div> */}
            {/* <div style={{textAlign: "center", marginRight: "8px"}}>
            <img height={80} width={80} src={FurnitureImg} style={{borderRadius: "1000px", objectFit: "cover"}} />
                    <p>اثاث</p>
            </div> */}

            <div style={{display: "flex"}}>
            <div style={{textAlign: "center", cursor: "pointer", width: "fit-content", 
            marginRight: "auto",
            marginLeft: "auto"    
            }}
                onClick={
                    () => {
                        searchParams.set('category', 'ساعات');
                        navigate(`/products?${searchParams.toString()}`)
                }}
            >
            <img id='landing-screen-img-size' style={{borderRadius: "1000px", objectFit: "scale-down", padding: "18px", backgroundColor: "#f7f7f7"}}
            src={watch}  />
                    <p style={{marginTop: "8px", fontSize: "18px"}}>ساعات</p>
            </div>

            <div style={{textAlign: "center",cursor: "pointer", width: "fit-content", 
        marginRight: "auto",
        marginLeft: "auto"    }}
                onClick={
                    () => {
                        searchParams.set('category', 'سبح');
                        navigate(`/products?${searchParams.toString()}`)
                }}
            >
                <img id='landing-screen-img-size' style={{borderRadius: "1000px", backgroundColor:"#f7f7f7",}} src={sbhah} />
                <p style={{marginTop: "8px", fontSize: "18px"}}>سبح</p>
            </div>
            </div>


                <div style={{display: "flex", marginTop: "52px"}}>
                <div style={{textAlign: "center",cursor: "pointer", width: "fit-content", 
        marginRight: "auto",
        marginLeft: "auto"    }}
                onClick={
                    () => {
                        searchParams.set('category', 'اقلام');
                        navigate(`/products?${searchParams.toString()}`)
                }}
            >
                <img id='landing-screen-img-size' style={{borderRadius: "1000px", objectFit: "scale-down", padding: "8px", backgroundColor:"#f7f7f7"}} src={pen} />
                <p style={{marginTop: "8px", fontSize: "18px"}}>أقلام</p>
            </div>

            <div style={{textAlign: "center", cursor: "pointer", width: "fit-content", 
        marginRight: "auto",
        marginLeft: "auto"    }}
                onClick={
                    () => {
                        searchParams.set('category', 'ابواك');
                        navigate(`/products?${searchParams.toString()}`)
                }}
            >
                <img id='landing-screen-img-size' style={{borderRadius: "1000px", objectFit: "scale-down", backgroundColor:"#f7f7f7"}} src={wallet} />
                <p style={{marginTop: "8px", fontSize: "18px"}}>أبواك</p>
            </div>

            

            {/* <div style={{textAlign: "center", marginRight: "8px"}}>
            <img height={80} width={80} src={carParts} style={{borderRadius: "1000px", objectFit: "cover"}} />
                    <p>قطع غيار</p>
            </div> */}
        </div>


        {/* products with > 10 bidders */}
        {/* <h2 style={{marginBottom: "16px", marginRight: "8px", marginTop: "16px"}} >صفقات لا تفوّت!</h2> */}
        {/* <div id='main-div'  style={{display: "flex", overflow: "scroll"}}>

            <div style={{textAlign: "center", marginRight: "8px"}}>
            <img height={80} width={80} src={ElectronicsImg} style={{borderRadius: "1000px", objectFit: "cover"}} />
                    <p>الكترونيات</p>
            </div>
            <div style={{textAlign: "center", marginRight: "8px"}}>
            <img height={80} width={80} src={FurnitureImg} style={{borderRadius: "1000px", objectFit: "cover"}} />
                    <p>اثاث</p>
            </div>
            <div style={{textAlign: "center", marginRight: "8px"}}>
            <img height={80} width={80} src={watch} style={{borderRadius: "1000px", objectFit: "scale-down"}} />
                    <p>ساعات</p>
            </div>
            <div style={{textAlign: "center", marginRight: "8px"}}>
            <img height={80} width={80} src={sbhah} style={{borderRadius: "1000px", objectFit: "scale-down"}} />
                    <p>سبح</p>
            </div>
            <div style={{textAlign: "center", marginRight: "8px"}}>
            <img height={80} width={80} src={carParts} style={{borderRadius: "1000px", objectFit: "cover"}} />
                    <p>قطع غيار</p>
            </div>
        </div> */}

        {/* products with > 10 views */}
        {/* <h2 style={{marginBottom: "16px", marginRight: "8px", marginTop: "16px"}} >منتجات رائجة</h2> */}


        {/* visited categories with count */}
        {/* <h2 style={{marginBottom: "16px"}} >منتجات تهمك</h2>
        <div style={{display: "flex", overflow: "scroll"}}>

            <div style={{textAlign: "center"}}>
            <img height={80} width={80} src={ElectronicsImg} style={{borderRadius: "1000px", objectFit: "cover"}} />
                    <p>الكترونيات</p>
            </div>
            <div style={{textAlign: "center", marginRight: "8px"}}>
            <img height={80} width={80} src={FurnitureImg} style={{borderRadius: "1000px", objectFit: "cover"}} />
                    <p>اثاث</p>
            </div>
            <div style={{textAlign: "center", marginRight: "8px"}}>
            <img height={80} width={80} src={watch} style={{borderRadius: "1000px", objectFit: "scale-down"}} />
                    <p>ساعات</p>
            </div>
            <div style={{textAlign: "center", marginRight: "8px"}}>
            <img height={80} width={80} src={sbhah} style={{borderRadius: "1000px", objectFit: "scale-down"}} />
                    <p>سبح</p>
            </div>
            <div style={{textAlign: "center", marginRight: "8px"}}>
            <img height={80} width={80} src={carParts} style={{borderRadius: "1000px", objectFit: "cover"}} />
                    <p>قطع غيار</p>
            </div>
        </div> */}
    </div>
   );
  }

export default LandingScreen;