import React from 'react'
import { Link } from 'react-router-dom';
import { IoLogoWhatsapp } from "react-icons/io5";

function ContactUs () {
    const whatsAppLink = (phoneNumber) => {
        return `https://wa.me/${phoneNumber}`;
      };  
        return (
            <div id='new-screens-div'>
                <h4>اتصل بنا</h4>

<p>نحن هنا لخدمتكم. إذا كان لديكم أي استفسارات أو ملاحظات، يمكنكم الاتصال بنا عبر البريد الإلكتروني: <a href="mailto:ybaghlaf@hotmail.com">ybaghlaf@hotmail.com</a></p>
            
            <h5>أو</h5>
            <p>عن طريق الواتساب:</p>
            <div style={{marginTop: "16px",direction: "rtl", alignItems: "center", marginBottom: "16px", justifyContent: "center",
              marginRight: "auto", marginLeft: "auto", textAlign: "center"
          
          }}>
              <Link to={whatsAppLink("966541236990")} style={{textDecoration: "none"}}>
              <div style={{display: "flex", cursor:"pointer"}}>
              <IoLogoWhatsapp style={{color:"black", marginTop: "2px"}} />
              <p style={{direction: "ltr", fontSize: "14px", marginRight: "4px", marginBottom: "0px", color:"black" }}>+966541236990</p>
              </div>
              </Link>
            </div>
            </div>
        );
}
 
export default ContactUs;