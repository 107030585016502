import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { GoHomeFill, GoReport } from "react-icons/go";
import { BsListCheck, BsFillPersonFill } from "react-icons/bs";
import { BsPersonCircle } from "react-icons/bs";
import { BiSupport } from "react-icons/bi";
import { FiSearch } from "react-icons/fi";
import { GiHamburgerMenu } from "react-icons/gi";
import { AiOutlineClose } from "react-icons/ai";
import { IoMdAddCircleOutline, IoMdSettings } from "react-icons/io";
import Dropdown from 'react-bootstrap/Dropdown';
import image from '../assets/logo.jpg';
import { TextField, Button, Divider } from "@mui/material"; 
import { Link, NavLink } from 'react-router-dom';
import { DropdownDivider } from 'react-bootstrap';
import Drawer from '@mui/material/Drawer';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import React, {useState, useRef} from 'react';
import ReactDom from 'react-dom'; 
import Category from './Category';
import HomeScreen from '../screens/ProductsScreen';
import { RxHamburgerMenu } from "react-icons/rx";
import { useSearchParams , useNavigate} from 'react-router-dom';
import furnitureImg from '../assets/furniture.jpg'
import logo2 from '../assets/logo2.png'
import { BiSolidWatch } from "react-icons/bi";
import sebhah from '../assets/sebhah.png'
import { BsFillPenFill } from "react-icons/bs";
import { BiSolidWalletAlt } from "react-icons/bi";
import { LiaPenAltSolid } from "react-icons/lia";
import { IoWalletOutline } from "react-icons/io5";
import { PiWatch } from "react-icons/pi";
import { BsThreeDotsVertical } from "react-icons/bs";
import logo from '../assets/logo2.png'
import payment from '../assets/payment_methods.png'
import { IoLogoWhatsapp } from "react-icons/io5";

function Footer() {
 const [openDrawer, setOpenDrawer] = useState(false);
 const [openSearch, setOpenSearch] = useState(false);
 const [searchParams, setSearchParams] = useSearchParams();
 const navigate = useNavigate();

 const searchRef = useRef();
 const [searchTerm, setSearchTerm] = useState("");

 const formRef = useRef();

 function handleKeyDown(event) {
  if (event.key === 'Enter') {
    setSearchParams(`?${searchParams}`);
    if(searchRef.current.value.trim().length == 0){
      navigate("/");
      searchRef.current.value = "";
    }
    else{
      navigate(`?${searchParams}`)
    }
 }
}
const whatsAppLink = (phoneNumber) => {
  return `https://wa.me/${phoneNumber}`;
};  


  return (
   <div style={{backgroundColor: "#fbfbfb"}}>
     <div id="footer-div">
            <div style={{display: "flex", width: "100%", flexDirection: "column"}}>
            <div style={{width: "100%", display: "flex", flexDirection: "column", textAlign: "center", marginBottom: "16px"}}>
                <img src={logo} height={70} width={70} style={{marginRight: "auto", marginLeft: "auto"}} />
                <p style={{marginBottom : "8px", fontSize: "14px", marginTop:"8px" }}>فرص جديدة في كل لحظة</p>
              </div>
              <div style={{ width: "100%", textAlign: "center"}}>
                <p style={{marginBottom : "8px", fontSize: "18px", fontWeight: "bold"}}>روابط مهمة</p>
                <Link to="about_us" style={{textDecoration: "none"}}><p style={{marginBottom : "8px", fontSize: "14px", cursor:"pointer", color: "black" }}>من نحن</p></Link>
                <Link to="user_policy" style={{textDecoration: "none"}}><p style={{marginBottom : "8px", fontSize: "14px", cursor:"pointer", color: "black" }}>سياسة الاستخدام</p></Link>
                {/* <Link to="return_policy" style={{textDecoration: "none"}}><p style={{marginBottom : "8px", fontSize: "14px", cursor:"pointer", color: "black", marginBottom: "32px" }}>سياسة الاستبدال و الاسترجاع</p></Link> */}
              </div>

              <div style={{width: "100%", textAlign: "center", marginBottom: "0px", marginTop:"16px"}}>
                <p style={{fontWeight: "bold", fontSize: "18px"}}>سهلناها عليك ووفرنا طرق الدفع التالية</p>
                <img src={payment} height={50} style={{width: "100%", objectFit: "scale-down"}}/>
              </div>
            </div>
            <div style={{marginTop: "16px",direction: "rtl", alignItems: "center", marginBottom: "16px", justifyContent: "center",
              marginRight: "auto", marginLeft: "auto", textAlign: "center"
          
          }}>
            <p style={{marginBottom: "4px"}}>تواصل معنا</p>
              <Link to={whatsAppLink("966541236990")} style={{textDecoration: "none"}}>
              <div style={{display: "flex", cursor:"pointer"}}>
              <IoLogoWhatsapp style={{color:"black", marginTop: "2px"}} />
              <p style={{direction: "ltr", fontSize: "14px", marginRight: "4px", marginBottom: "0px", color:"black" }}>+966541236990</p>
              </div>
              </Link>
            </div>
          </div>
   </div>
  );
}

export default Footer;