import React, {useEffect, useRef} from 'react'
import CustomNavbar from '../components/CustomNavbar';
import Product from '../components/Product';
import { useState } from 'react';
import { getAuth, RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import { initializeApp } from 'firebase/app';
import { getFirestore, collection, getDocs, onSnapshot, query, where,getDoc, updateDoc, limit,getCountFromServer ,doc, setDoc, Timestamp, serverTimestamp } from 'firebase/firestore';
import { getStorage, ref as storagePath, uploadBytes, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { useNavigate } from 'react-router-dom';
import { Oval } from 'react-loader-spinner'
import { useLocation } from 'react-router-dom'
import { Link } from 'react-router-dom';
import { db, auth, storage } from '../config/firebase'
import { useReadOTP } from "react-read-otp";


function SignInOrSignUpScreen ({prompt}) {
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [imgUrl, setImgUrl] = useState("");
    const [blobUrl, setBlobUrl] = useState("");
    const countryCode = "+966"
    const [phoneNumber, setPhoneNumber] = useState("");
    const [email, setEmail] = useState("");
    const [OTP, setOTP] = useState("");
    const [hasSelectedProfilePicture, setHasSelectedProfilePicture] = useState(false);
    const [profilePictureName, setProfilePictureName] = useState("");
    const [otpSent , setOtpSent] = useState(false);
    const navigate = useNavigate();
    const [isLoading, setLoading] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");
    const [userExists, setUserExists] = useState(false);

    // const location = useLocation()
    // const { from } = location.state

    const phoneNumberRef = useRef();

    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'instant',
    });

    const generateRecaptcha = () => {
      window.recaptchaVerifier = new RecaptchaVerifier(auth, 'recaptcha-container', {
        size: 'invisible',
        callback: (response) => {
          // reCAPTCHA solved, allow signInWithPhoneNumber.
        },
      });
    };

    const requestOTP = async () => {
      setErrorMsg("")
      if(isNaN(parseInt(phoneNumber))){
        setErrorMsg("يرجى ادخال قيمة صحيحة. يسمح بالأرقام الانجليزية فقط 0-9")
        return;
      }


      try {
        if (phoneNumber.length === 9 && ! isNaN(parseInt(phoneNumber))) {
          const userQuery = query(collection(db, 'Users'), where('phoneNumber', '==', `${countryCode}${phoneNumber}`));
          const querySnapshot = await getDocs(userQuery);
          setOtpSent(true);
          generateRecaptcha();
    
          const appVerifier = window.recaptchaVerifier;
          signInWithPhoneNumber(auth, `${countryCode}${phoneNumber}`, appVerifier)
            .then((confirmationResult) => {
              window.confirmationResult = confirmationResult;
            })
            .catch((error) => {
              setErrorMsg(' حدث خطأ أثناء إرسال رمز التحقق, الرجاء تحديث الصفحة و المحاولة مجدداً ');
            });

          if (!querySnapshot.empty) {
            setUserExists(true);
          } else {
            setUserExists(false);
          }
        } else {
          setErrorMsg('الرجاء إدخال رقم هاتف صحيح');
        }
      } catch (error) {
        console.error('Error requesting OTP:', error);
        setErrorMsg('حدث خطأ أثناء إرسال الرمز' + error );
      }
    };


if(isLoading){
      return       <div style={{
        width: "100vw",
        height: "80vh",
        marginTop: "55px",
        display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

      }}>
        <Oval
              height={"50px"}
              width={"50px"}
              color={"dodgerblue"}
              wrapperClass=""
              visible={true}
              wrapperStyle={{display: "block"}}
              ariaLabel='oval-loading'
              secondaryColor="#ccc"
              strokeWidth={1}
              strokeWidthSecondary={1}
            />
      </div>
    }



    const verifyOTP = () => {
      setLoading(true);
    
      try {
        const confirmationResult = window.confirmationResult;
    
        confirmationResult
          .confirm(OTP)
          .then(async (result) => {
            // Handle successful OTP verification
            if(!userExists){
              const coll = collection(db, "Posts");
              const snapshot = await getCountFromServer(coll);
              var newId = ++snapshot.data().count;

              const docData = {
                firstName: "",
                lastName: "",
                fullName: `مستخدم جديد`,
                email: email,
                phoneNumber: `+966${phoneNumber}`,
                dob: "",
                profileImgUrl: "https://firebasestorage.googleapis.com/v0/b/men-ela-3cd15.appspot.com/o/Placeholders%2FProfile%20Picture%20Placeholder%2Fprofile-picture-placeholder.jpg?alt=media&token=6a9c95d9-042b-4faa-afd8-d4e4524e07fe",
                id: parseInt(newId),
                sex: "",
                balance: 0,
                feeBalance: 0,
                rating: 0,
                numRaters: 0,
                comments: {},
                isVerified: false,
                city: "",
                joinedDate: serverTimestamp()
                };
                setDoc(doc(db, "Users", `${auth.currentUser.uid}`), docData);
            }
            setLoading(false); // Set loading to false in the success case
            navigate('/'); // Redirect to the desired location upon successful verification
          })
          .catch((error) => {
            // Handle OTP verification error
            console.error('Error confirming OTP:', error);
            setErrorMsg('خطأ في تأكيد الرمز');
          })
          .finally(() => {
            setLoading(false); // Ensure loading is set to false even if there's an error
          });
      } catch (error) {
        // Handle other errors
        console.error('Error during OTP verification:', error);
        setErrorMsg('حدث خطأ أثناء تأكيد الرمز');
        setLoading(false); // Set loading to false in case of other errors
      }
    };

        return (
            <div style={{
                backgroundColor:"#f0f0f0",
                width: "100vw",
                height: "100vh",
                alignItems: "center",
                justifyContent: "center",
                textAlign: "center",
                display: "flex"
            }}>

                <div style={{
                    backgroundColor : "white",
                    width: "80%",
                    borderRadius: "20px",
                    marginTop: "-20%",
                    boxShadow: "0px 1px 0px 0px #ccc",
                    padding: "32px 16px"
                }}>
                  
                    {prompt == "" ? <p>"الرجاء التسجيل او تسجيل الدخول لرؤية هذه الصفحة" </p> : <p>{prompt}</p>}

                    <div style={{
                    display: "flex",
                    margin: "8px",
                    marginTop:"16px",
                    }}>
                    <div style={{
                        textAlign: "center",
                        display: "flex",
                        justifyContent: "center",
                    }}>

                    <p style={{
                    marginTop: "auto",
                    marginBottom: "auto",
                    marginRight: "4px"
                        }}>+966</p>
                    </div>
                        <input type='tel' ref={phoneNumberRef} onChange={(value)=>{setPhoneNumber(value.target.value)}} style={{display: "block", width: "100%",borderRadius: "4px", border: "none", height: "40px", direction: "rtl", paddingRight: "8px", border: "1px solid #f0f0f0"}} placeholder='رقم الجوال'/>
                    </div>
                        <p style={{color:"red", display: errorMsg != "" ? "block" : "none"}}>{errorMsg}</p>
                <button id='my-button' style={{marginTop: "16px", fontSize: "14px", width: "50%"}} onClick={requestOTP}>إرسال رمز التحقق</button>
                    <div
                    style={{
                    justifyContent: "center",
                    display: otpSent ? "flex" : "none",
                    flexDirection: "column",
                    alignItems: "center",
                    }}
                    >
                        <input

                        autoFocus={OTP !== ''}  // Set autoFocus based on the OTP value
                        style={{width: "30%",borderRadius: "8px", border: "none", textAlign:"center" , height: "40px", marginTop: "32px" , direction: "rtl", paddingRight: "4px", paddingLeft:"4px" , border: "1px solid #f0f0f0"}}
                        onChange={
                          // (event) => setOTP(event.target.value)
                          e => setOTP(e.target.value)
                        }
                        value={OTP}
                        placeholder='الرمز'
                        />
                        <p style={{color: "gray"}}>قد يستغرق وصول الرمز دقيقة واحدة</p>
                        <button id='my-button' style={{width:"50%"}} onClick={verifyOTP} >التحقق من الرمز</button>
                    </div>
                    <p style={{marginBottom:"0px", marginTop:"8px", fontSize: "12px"}}>إذا كنت مستخدمًا جديدًا وأنشأت حسابًا، فإنك توافق على <Link to="/user_policy">سياسة الاستخدام و الخصوصية</Link></p>
                </div>
                <div style={{display: "none", visibility:"hidden" , justifyContent: "center", alignItems: "center", marginBottom: "50px"}} id='recaptcha-container'></div>
            </div>
        );
}
 
export default SignInOrSignUpScreen;