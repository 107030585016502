import React , {useState, useEffect, useRef} from 'react'
import Category from '../components/Category';
import ElectronicsImg from '../assets/electronics.avif';
import FurnitureImg from '../assets/furniture.jpg';
import autoParts from '../assets/auto-parts.jpg';
import musicInstruments from '../assets/music-instruments.jpeg';
import CustomNavbar from '../components/CustomNavbar';
import { initializeApp } from 'firebase/app';
import { getFirestore, collection, getDocs, onSnapshot, query, where, updateDoc, limit, doc, getDoc, serverTimestamp, arrayUnion, FieldValue, increment, addDoc, orderBy } from 'firebase/firestore';
import Product from '../components/Product';
import Button from '@mui/material/Button';
import { Select, MenuItem } from '@mui/material';
import { Dropdown, DropdownMenu, DropdownToggle } from 'react-bootstrap';
import { Divider } from '@mui/material';
import { PiGavelFill } from "react-icons/pi";
import { MdOutlineWatchLater } from "react-icons/md";
import { AiOutlineHeart } from "react-icons/ai";
import { IoIosWarning } from "react-icons/io";
import {useNavigate, useLocation, useSearchParams, useParams, Link} from "react-router-dom";
import Countdown from 'react-countdown';
import ImageGallery from "react-image-gallery";
import '../../node_modules/react-image-gallery/styles/css/image-gallery.css';
import { AiFillStar } from "react-icons/ai";
import { BiSolidPurchaseTagAlt } from "react-icons/bi";
import f from '../assets/furniture.jpg';
import { getAuth, signInAnonymously, onAuthStateChanged } from "firebase/auth";
import Comment from '../components/Comment';
import { IoMdCheckmarkCircle } from "react-icons/io";
import { MdDangerous } from "react-icons/md";
import { FaLocationDot } from "react-icons/fa6";
import { Oval } from 'react-loader-spinner'
import { FaMinus } from "react-icons/fa";
import { FaPlus } from "react-icons/fa";
import { formatDistanceToNow } from 'date-fns';
import arLocale from 'date-fns/locale/ar';
import { GoPencil } from "react-icons/go";
import { db, auth, storage } from '../config/firebase'
import emailjs from 'emailjs-com';
import { listAll, ref, getDownloadURL, uploadBytes, deleteObject  } from 'firebase/storage';

function PostDetailsScreen() {

  const profitPercentage = 0.10;

  const [Listing, setListing] = useState([]);
  const [gal, setgal] = useState([]);
  const [similarProducts, setSimilarProducts] = useState([]);
  const [similarCategory, setSimilarCategory] = useState([]);
  const [similarSubCategory, setSimilarSubCategory] = useState([]);
  const [productCategory, setProductCategory] = useState("")
  const [productSubCategory, setProductSubCategory] = useState("")
  const [productPrice, setProductPrice] = useState(0)
  const [numBids, setNumBids] = useState(0)
  const [productStartingPrice, setProductStartingPrice] = useState(10)
  const [bidIncrements, setBidIncrements] = useState(10)
  const [productStorageSize, setProductStorageSize] = useState("")
  const [productScreenSize, setProductScreenSize] = useState("")
  const [productMemorySize, setProductMemorySize] = useState("")
  const [productInkKind, setProductInkKind] = useState("")
  const [lastBidDate, setLastBidDate] = useState("");
  const [filteredListing, setFilteredListing] = useState([]);
  const [filteredCategory, setFilteredCategory] = useState("");
  const [qu, setQu] = useState(query(collection(db, "Posts")));
  const [isLoading, setIsLoading] = useState(true);
  const [hasExpired, setExpired] = useState(false);
  const [imageLoading, setimageLoading] = useState(true);
  const [productName, setProductName] = useState("")
  const [isReceived, setIsReceived] = useState(false);
  const [commentsLoading, setCommentsLoading] = useState(true);
  const [acceptsOffers, setAcceptsOffers] = useState(false);
  const [isUpdated, setIsUpdated] = useState(false);
  const [lastUpdatedDate, setLastUpdatedDate] = useState("");
  const [currentBid, setCurrentBid] = useState(0);
  const [myBid, setMyBid] = useState(0);
  const [comment, setComment] = useState("");
  const [comments, setComments] = useState([]);
  const [postComments, setPostComments] = useState([]);
  const [heighestBidder, setHeighestBidder] = useState("");
  const [sellerEmail, setSellerEmail] = useState("");
  const [newBid, setNewBid] = useState(0);
  const [addCommentLoading, setAddCommentLoading] = useState(false);

  const [sellerId, setSellerId] = useState("");

  const navigate = useNavigate();

  const myBidRef = useRef();
  const commentRef = useRef();
  
  
  const [isDeleted, setisDeleted] = useState(false);
  const [isSold, setisSold] = useState(false);

  const [bidders, setBidders] = useState([]);
  const [keywords, setKeywords] = useState([]);


  const [productExists, setProductExists] = useState(false)
  
  const {id} = useParams();
  
  const q = query(collection(db, "Posts"), where("id", "==", id));
  
  const postRef = doc(db, "Posts", `post${id}`);



  const fetchComments = async (postId) => {
    setCommentsLoading(true)
    try {
      const postRef = doc(db, 'Posts', postId);
      
      const commentsRef = collection(postRef, 'comments');
  
      const commentsSnapshot = await getDocs(query(commentsRef, where('isDeleted', '==', false), orderBy("timestamp", "asc")));
      const comments = [];
      
      for (const commentDoc of commentsSnapshot.docs) {
        const commentData = commentDoc.data();

        const userQuery2 = query(collection(db, 'Users'), where('id', '==', commentData.userId));
        const userSnapshot2 = await getDocs(userQuery2);
        const userDoc2 = userSnapshot2.docs[0]

        if (userDoc2) {
          const userData = userDoc2.data();
          comments.push({
            commentId: commentDoc.id,
            userId: commentData.userId,
            comment: commentData.comment,
            timestamp: commentData.timestamp,
            isDeleted: commentData.isDeleted,
            isUpdated: commentData.isUpdated,
            user: {
              fullName: userData.fullName,
              profileImgUrl: userData.profileImgUrl,
              // Add other user properties you need
            },
          });
        }
      }
  
      // Set the comments state or perform other actions
      setComments(comments);
    } catch (error) {
      console.error('Error fetching comments:', error);
    }
    setCommentsLoading(false);
  };
  
  // Call the fetchCommentsWithUserData function with the postId
  // You might call this when the component mounts or when needed
  
  
  // Call the fetchComments function with the postId
  // You might call this when the component mounts or when needed
  
  // const fetchComments = async () => {
  //   try {
  //     const postRef = doc(db, 'Posts', `post${id}`);
  //     const postDoc = await getDoc(postRef);

  //     if (postDoc.exists()) {
  //       const postData = postDoc.data();
  //       const commentsData = postData.comments || [];

  //       // Fetch user data for each comment
  //       const commentsWithUserData = await Promise.all(
  //         commentsData.map(async (comment) => {
  //           const userRef = doc(db, 'Users', comment.userId);
  //           const userDoc = await getDoc(userRef);
  //           const userData = userDoc.exists() ? userDoc.data() : null;

  //           return {
  //             ...comment,
  //             user: userData,
  //           };
  //         })
  //       );

  //       setComments(commentsWithUserData);
  //     } else {
  //       console.error('Post not found');
  //     }
  //   } catch (error) {
  //     console.error('Error fetching comments:', error);
  //   }
  // };

  useEffect(() => {
    const fetchImages = async () => {
      try {
        const folderRef = ref(storage, `Posts/post${id}`);
        // List all items in the folder
        const result = await listAll(folderRef);
    
        // result.items is an array of references to each image in the folder
        const urls = await Promise.all(
          result.items.map(async (itemRef) => {
            // Get download URL for each image
            return await getDownloadURL(itemRef);
          })
        );
    
        // Set the array of image URLs
        const updatedGal = urls.map((url) => ({
          original: url,
          thumbnail: url,
          originalWidth: '500px',
          originalClass: 'image-gallery-class',
          bulletClass: 'bulletClass',
        }));
    
        setgal(updatedGal);
      } catch (error) {
        console.error('Error fetching images:', error);
      }
      setimageLoading(false)
    };
    
    const fetchData = (querySnapshot) => {
      const cities = [];
      const imgs = [];
      const gal = [];
      const kwds = [];

        // Check if the querySnapshot is empty
  if (querySnapshot.empty) {
    // Handle the case when no documents are found
    console.log("No documents found");
    setProductExists(false)
    setIsLoading(false);
    return;
  }
  setProductExists(true)
  
      querySnapshot.forEach((doc) => {
        const data = doc.data();
  
        if (!data.isDeleted || !data.isSold) {
          // Extracting data and setting state
          const {
            subCategory,
            acceptsOffers,
            category,
            productStorageSize,
            productScreenSize,
            productMemorySize,
            productInkKind,
            currentBid,
            productPrice,
            seller,
            bidders,
            isSold,
            comments,
            heighestBidder,
            startingPrice,
            expiryDate,
            imgs: productImgs,
            keywords,
            isReceived,
            bidIncrements,
            lastBidDate,
            numBids,
            isUpdated,
            updatedDate,
            productName
          } = data;
  
          setProductName(productName)
          setProductSubCategory(subCategory);
          setAcceptsOffers(acceptsOffers);
          setProductCategory(category);
          setProductStorageSize(productStorageSize);
          setProductScreenSize(productScreenSize);
          setProductMemorySize(productMemorySize);
          setProductInkKind(productInkKind);
          setCurrentBid(currentBid);
          setNumBids(numBids);
          setProductPrice(productPrice);
          setSellerId(seller.sellerId);
          setSellerEmail(seller.sellerEmail);
          setBidIncrements(parseInt(bidIncrements))
          setBidders(bidders);
          setLastBidDate(lastBidDate);
          setisSold(isSold);
          setPostComments(comments);  
          setIsUpdated(isUpdated)
          setLastUpdatedDate(updatedDate)
          setHeighestBidder(heighestBidder);
          setProductStartingPrice(startingPrice);
          setMyBid(auth.currentUser !== null ? bidders[auth.currentUser.uid] : 0);
          setKeywords(keywords);
          setIsReceived(isReceived);
          setNewBid(parseInt(currentBid) == 0 ? parseInt(startingPrice) : parseInt(bidIncrements + currentBid))
  
          for (const keyword of keywords) {
            kwds.push(keyword);
          }
  
          // Pushing data to arrays
          cities.push(data);
          
          for(var i = 0 ; i < Object.keys(productImgs).length ; i++){
            imgs.push(productImgs[i]);
            gal.push({
              original: productImgs[i],
              thumbnail: productImgs[i],
              originalWidth: "500px",
              originalClass: "image-gallery-class",
              bulletClass: "bulletClass",
            });
          }

        } else {
          // Handling sold or deleted products
          data.isSold ? setisSold(true) : setisSold(false);
        }
      });
  
      // Setting state with the collected data
      setListing(cities);
      setgal(gal);
      // Fetch additional data or perform other tasks

      // if(comments.length === 0 ){
        fetchComments(`post${id}`);
      // }
  
      // Fetch similar products
      const additionalQuery = query(collection(db, "Posts"), where("id", "!=", id), where("category", "==", productCategory), where("keywords", "array-contains", kwds));
      const unsubscribeAdditional = onSnapshot(additionalQuery, (additionalSnapshot) => {
        const simi = [];
        additionalSnapshot.forEach((doc) => {
          const data = doc.data();
          if (!data.isDeleted) {
            simi.push(data);
          }
        });
        setSimilarProducts(simi);
      });
  
      // Ensure that setLoading(false) is always called
      // fetchImages();
      setIsLoading(false);
      // Clean up the listeners when the component unmounts
      return () => {
        unsubscribe();
        unsubscribeAdditional();
      };
    };
  
    // Set up the snapshot listener
    const unsubscribe = onSnapshot(q, fetchData);
  
    // Clean up the listener when the component unmounts
    return () => {
      unsubscribe();
    };
  }, [currentBid, Object.keys(bidders).length, id, heighestBidder]);
  
    // id, isDeleted, currentBid, similarProducts, similarCategory, Object.keys(bidders).length
    
    const handleScrollToTop = () => {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'instant'
      });
    }

    const addComment = async (postId, commentText) => {
      try {
        const userDocRef = doc(db, 'Users', auth.currentUser.uid);

        // Fetch the user document data
        const userDocSnapshot = await getDoc(userDocRef);
        
        // Check if the document exists before accessing the 'id' field
        if (userDocSnapshot.exists()) {
          const userId = userDocSnapshot.data().id;
        
          // Reference to the post document
          const postRef = doc(db, 'Posts', postId);
        
          // Add the comment document directly to the post's comments collection
          const commentDocRef = await addDoc(collection(postRef, 'comments'), {
            userId: userId,
            comment: commentText,
            timestamp: serverTimestamp(),
            isDeleted: false,
            isUpdated: false,
          });
        
          // Add the comment document directly to the user's comment collection
          await addDoc(collection(userDocRef, 'commentsOnPosts'), {
            userId: userId,
            comment: commentText,
            timestamp: serverTimestamp(),
            isDeleted: false,
            isUpdated: false,
            postId: commentDocRef.id, // Add postId to link comment with post
          });
        } else {
          console.error('User document not found');
        }
    
        // Refetch comments after adding a new comment
        fetchComments(`post${postId}`);
      } catch (error) {
        console.error('Error adding comment:', error);
      }
      
      commentRef.current.value = '';
    };
    
    
    const sendCommentEmail = async () => {
      const url = `https://men-ela.com/product/${id}`; // Replace with your actual URL
      const templateParams = {
        to_email: sellerEmail,
        // to_name : "يوسف",
        website_url: url, // Add the URL to the template parameters
        // other template parameters if needed
      };
  
      try {
        const response = await emailjs.send("service_60fpize",
        "template_6gtnb4i",
        templateParams,
        "mPwQnQEP1--hqeKcI",
        "zDU_ue_gZ7PQcfiwa"
        );
  
        console.log('Email sent successfully!', response);
      } catch (error) {
        console.error('Error sending email:', error);
      }
    };


    const sendPurchasedEmail = async () => {
      const url = `https://men-ela.com/my_products/${id}`; // Replace with your actual URL
      const templateParams = {
        to_email: sellerEmail,
        // to_name : "يوسف",
        product_name: productName,
        product_url: url, // Add the URL to the template parameters
        // other template parameters if needed
      };
  
      try {
        const response = await emailjs.send("service_60fpize",
        "template_7f2zp0p",
        templateParams,
        "mPwQnQEP1--hqeKcI",
        "zDU_ue_gZ7PQcfiwa"
        );
  
        console.log('Email sent successfully!', response);
      } catch (error) {
        console.error('Error sending email:', error);
      }
    };


    const handleAddComment = async () => {
      try {
        if (auth.currentUser !== null) {
          const postRef = doc(db, 'Posts', `post${id}`);
          const postDoc = await getDoc(postRef);
  
          if (postDoc.exists()) {
            const updatedComments = arrayUnion({
              userId: auth.currentUser.uid,
              comment: commentRef.current.value,
              timestamp: new Date().toISOString(),
              isDeleted: false,
              isUpdated: false
            });
  
            // Update the Firestore document with the new comments array
            await updateDoc(postRef, {
              comments: updatedComments,
            });
  
            // Refetch comments after adding a new comment
            fetchComments(`post${id}`);


          const userRef = doc(db, 'Users', auth.currentUser.uid);
          const userDoc = await getDoc(userRef);
  
          if (userDoc.exists()) {
            const commentsOnPosts = arrayUnion({
              postId: id,
              comment: commentRef.current.value,
              timestamp: new Date().toISOString(),
              isDeleted: false,
              isUpdated: false
            });
  
            // Update the Firestore document with the new comments array
            await updateDoc(userRef, {
              commentsOnPosts: commentsOnPosts,
            });
          } else {
            console.error('Post not found');
          }
          } else {
            console.error('Post not found');
          }
        } else {
          alert('You need to sign in to add a comment.');
        }
      } catch (error) {
        console.error('Error adding comment:', error);
      }

      commentRef.current.value = ""
    };

    if(isLoading){
      return       <div style={{
        width: "100vw",
        height: "80vh",
        marginTop: "55px",
        display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

      }}>
        <Oval
              height={"50px"}
              width={"50px"}
              color={"dodgerblue"}
              wrapperClass=""
              visible={true}
              wrapperStyle={{display: "block"}}
              ariaLabel='oval-loading'
              secondaryColor="#ccc"
              strokeWidth={1}
              strokeWidthSecondary={1}
            />
      </div>
    }
    
if(auth.currentUser !== null){
  if(acceptsOffers && hasExpired && heighestBidder != auth.currentUser.uid ){
    return <h1 style={{marginTop:"60px"}}>this post has expired</h1>
  }
}
else{
  if(acceptsOffers && hasExpired){
    return <h1 style={{marginTop:"60px"}}>this post has expired</h1>
  }
}

if(!productExists){
  return <h1 style={{marginTop: "60px"}}>product doesnt exist</h1>
}

    if(isDeleted){
      return <h1 style={{marginTop: "60px"}}>the post was deleted</h1>
    }
    if( auth.currentUser != null && heighestBidder != auth.currentUser.uid && isSold){
      return <h1 style={{marginTop: "60px"}}>the product was sold</h1>
    }

    function formatAccountingArabic(number) {
      // Check if the input is a valid number
      if (typeof number !== 'number' || isNaN(number)) {
        return 'Invalid input';
      }
    
      // Use toLocaleString to format the number with commas and two decimal places
      const formattedNumber = number.toLocaleString('en', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    
      // Add the Arabic currency symbol manually
      const arabicFormattedNumber = `${formattedNumber} ر.س`;
    
      return arabicFormattedNumber;
  }

  return ( 
    <>
    <div style={{
      marginTop: "55px",
      backgroundColor: "#fcfcfc",
      }}
      >
      {
        Listing.map  ((item) => (
        <div
          id='product-details-and-img'
            key={item.id}
            >
          <div
          id='product-details-div'
          >
            <div
            id='post-details-img'
            >
              {/* {
                imageLoading &&
<div style={{
        width: "100vw",
        height: "20vh",
        marginTop: "55px",
        display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
      }}>
        <Oval
              height={"50px"}
              width={"50px"}
              color={"dodgerblue"}
              wrapperClass=""
              visible={true}
              wrapperStyle={{display: "block"}}
              ariaLabel='oval-loading'
              secondaryColor="#ccc"
              strokeWidth={1}
              strokeWidthSecondary={1}
            />
      </div>
              } */}
                <ImageGallery 
                showBullets={true}
                showNav={false}
                showPlayButton={false}
                showFullscreenButton={true}
                isRTL={true}
                infinite={false}
                items={gal} />
            </div>
                <div
                style={{
                  margin: "0px 32px",
                  width: "40vw"
                }}
                >
                      <h2
                      id='product-details-desc'

                      style={{color:"black", fontSize: "18px"}}
                      >{item.productName}</h2> 
<h4
                    id='product-details-desc'
                    style={{fontSize: "16px"}}
                    >{item.productDescription}</h4> 

                {
                  isUpdated &&
                  <div style={{display: "flex", width: "fit-content", alignItems: "center"}}>
                  <GoPencil style={{color: "gray"}} />
                  <p style={{marginRight: "4px", marginBottom: "0px", whiteSpace: "nowrap", color: "gray"}}>تم تحديث المنتج {formatDistanceToNow(lastUpdatedDate.toDate(), { addSuffix: true, locale: arLocale })}</p>
                  </div>
                }

                <div 
                  style={{
                    display: "flex",
                    marginTop: "20px",
                  }}
                  >
                 <Link to={`/user/${item.seller['sellerId']}`} style={{display: "contents", color: "black", textDecoration: "none"}}>
            <div style={{display: "flex", width: "100%", paddingLeft: "32px"}}>
                <img src={item.seller['sellerImg']} width={60} height={60} style={{borderRadius: "1000px", objectFit: "cover"}}/>
                <div>
                    <p style={{marginRight: "8px", marginBottom: "0px", width: "50vw"}}>{item.seller['sellerName']}</p>
                    <div style={{display: "flex",alignItems: "center"}}>
                    <AiFillStar size={14} color='gold' style={{marginBottom:"2px", marginRight:"4px"}}/>
                         <p style={{marginBottom:"0px", marginRight:"2px", fontSize: "14px"}}>{item.seller['sellerRating']}</p>
                         <p style={{marginBottom:"0px"   , marginRight:"2px", fontSize: "14px"}}>({item.seller['sellerNumRaters']})</p>
                    </div>
                    {
                      item.seller['sellerCity'] != "" &&
                                        <div style={{direction: "rtl", display: "flex"}}>
                                        <FaLocationDot size={14} color='red' style={{marginRight: "4px", marginTop: "4px"}} />
                                        <p style={{marginRight: "2px"}}>{item.seller['sellerCity']}</p>
                                        </div>
                    }

                    
                </div>
            </div>
            </Link>
                </div>

                  <div
                  >
                  {
                    productSubCategory == 'جوال' ? 
                    <div style={{marginTop: "16px", display: "flex"}}>
                    <h4 style={{marginLeft: "8px" , whiteSpace:"nowrap"}}>سعة التخزين: </h4>
                    <h3>{productStorageSize}</h3>
                    </div>
                    : ""
                  }
                  {
                    productSubCategory == 'لابتوب' ? 
                    <>
                    <div style={{marginTop: "16px", display: "flex"}}>
                    <h4 style={{marginLeft: "8px", whiteSpace:"nowrap"}}>سعة الذاكرة: </h4>
                    <h3 style={{whiteSpace:"nowrap"}}>{productMemorySize}</h3>
                    </div>
                    <div style={{marginTop: "4px", display: "flex"}}>
                    <h4 style={{marginLeft: "8px", whiteSpace:"nowrap"}}>حجم الشاشة : </h4>
                    <h3 style={{whiteSpace:"nowrap"}}>{productScreenSize}</h3>
                    </div>
                    </>
                    : ""
                  }
                  {
                    productSubCategory == 'شاشة' ? 
                    <div style={{marginTop: "4px", display: "flex"}}>
                    <h4 style={{marginLeft: "8px"}}>حجم الشاشة : </h4>
                    <h3>{productScreenSize}</h3>
                    </div>
                    : ""
                  }
                  </div>

                <div
                style={{
                  display: "flex",
                  marginTop: "8px"
                }}
                >
                <p style={{marginBottom: "0px", fontSize: "16px"}}>الحالة:</p>
                <p style={{marginBottom: "0px", fontSize: "16px", fontWeight: "bold", marginRight: "4px"}}>{item.condition}</p>
                </div>
                { acceptsOffers &&
                  <>
                <div
                id='product-details-icon-with-data'
                >
                <MdOutlineWatchLater
                  id='product-details-icon'
                  style={{marginBottom: "0px", color: "gray"}}
                />
                <p style={{marginBottom: "0px"}}>:</p>
                  {
                    item.expiryDate !== null &&
                    <Countdown
                    className='countdown-class'
                    date={item.expiryDate.seconds * 1000}
                    onComplete={async ()=>{
                        setExpired(true)
                        // let losingBidders = [];
                        // for(var i = 0 ; i < Object.keys(bidders).filter((userId) => userId !== heighestBidder).length ; i ++){
                        //   losingBidders.push(Object.keys(bidders).filter((userId) => userId !== heighestBidder)[i])
                        // }

                        // for(var i = 0 ; i < losingBidders.length; i ++)
                        // {
                        //   const postRef = doc(db, "Users", losingBidders[i]);
                        //   const docSnap = await getDoc(db, "Users", losingBidders[i])
                        //   var fb = parseFloat(docSnap.data()['feeBalance'])
                        //   fb += Math.round(parseFloat(productStartingPrice * 0.05))
                        //   await updateDoc(postRef, {
                        //     feeBalance: parseFloat(fb)
                        //   });
                        // }

                    }}
                    >
                    </Countdown>
                  }
                </div>
                <div
                id='product-details-icon-with-data'
                >
                <PiGavelFill
                id='product-details-icon'
                />
                <p>:</p>
                <p
                id='product-details-numbidders'
                >{Object.keys(item.bidders).length}</p>
                </div>
                </>
              }
                <div
                id='product-details-icon-with-data'
                >
                  <div
                  style={{
                  }}
                  >

                  {acceptsOffers ?
                  <>
                  <div
                  style={{
                    display: "flex",
                     alignItems: "center"
                  }}
                  >
                  
                  <p style={{ whiteSpace: "nowrap", fontSize: "16px", marginBottom: "0px" }}>
      مبلغ بدء المزاد:
    </p>
    <p style={{ marginRight: "4px", marginBottom: "0px", fontSize: "17px", fontWeight: "bold" }}>
        {formatAccountingArabic(item.startingPrice)}
      </p>
                  {/* <h4 style={{marginRight: "4px"}}>ر.س</h4> */}
                  </div>
                  <div
                  style={{
                    display: "flex",
                    marginBottom: "8px"
                  }}
                  >
                  
                  <p style={{ whiteSpace: "nowrap", fontSize: "16px", marginBottom: "0px" }} >مضاعفات المزايدة:</p>  
                  <p style={{ marginRight: "4px", marginBottom: "0px", fontSize: "17px", fontWeight: "bold" }}>{formatAccountingArabic(item.bidIncrements)}</p>
                  {/* <h4 style={{marginRight: "4px"}}>ر.س</h4> */}
                  </div>
                    </>
                  : ""
                  }
                  {item.productPrice > 0 && acceptsOffers ?
                  
                  <div
                  style={{
                    display: "flex",
                    marginBottom: "8px"
                  }}
                  >
                  
                  <p style={{ whiteSpace: "nowrap", fontSize: "16px", marginBottom: "0px" }} >سعر الشراء الفوري:</p>  
                  <p style={{ marginRight: "4px", marginBottom: "0px", fontSize: "17px", fontWeight: "bold" }}>{formatAccountingArabic(item.productPrice)}</p>
                  {/* <h4 style={{marginRight: "4px"}}>ر.س</h4> */}
                  </div>
                  : ""
                  }
                  <div style={{
                    display: "flex"
                  }}>
                
                { acceptsOffers ? <p style={{ whiteSpace: "nowrap", fontSize: "16px", marginBottom: "0px" }} >السعر الحالي:</p> : <p style={{ whiteSpace: "nowrap", fontSize: "16px", marginBottom: "0px" }} >السعر:</p>}
                {acceptsOffers ? 
                <>
                <div style={{
  display: "flex",
  alignItems: "center",
}}>
  <p id='product-details-currentBid'
  style={{ marginRight: "4px", marginBottom: "16px", fontSize: "17px", fontWeight: "bold", whiteSpace: "nowrap", }}
  >
    
    {formatAccountingArabic(item.currentBid)}
  </p> 
{
  lastBidDate &&
  <>
    {acceptsOffers && Object.keys(bidders).length > 0 && (
    <h6 style={{ color: "black", margin: "4px 8px 0px 0px" }}>
  (آخر مزايدة كانت {formatDistanceToNow(lastBidDate.toDate(), { addSuffix: true, locale: arLocale })})
</h6>

  )}</>
}
</div>

                </>
                : 
                <div
                style={{
                  display: "flex"
                }}
                >
                <p
                id='product-details-currentBid'
                style={{
                  marginRight: "4px", marginBottom: "0px", fontSize: "17px", fontWeight: "bold"
                }}
                >{formatAccountingArabic(item.productPrice)}</p>
                </div>
                }
                </div>
                {
                  acceptsOffers &&
                  <p id='bidding-disclaimer'>يجب دفع رسوم دخول المزاد بنسبة ٥٪ من قيمة بدء المزاد. عند اكمال عملية شراء المنتج بمبلغ المزايدة، يُخصم مبلغ العربون الذي دُفع مُسبقًا من الإجمالي. في حال عدم تحقيق أعلى مزايدة، يتم استرداد المبلغ. إذا كنت قد قمت بأعلى مزايدة ولم تكمل عملية الشراء، فإن المبلغ المدفوع كعربون لن يُسترد.</p>
                }
                </div>
                </div>

                {
                  auth.currentUser != null && acceptsOffers && Object.keys(bidders).includes(auth.currentUser.uid) ? (
                    <>
                      {heighestBidder === auth.currentUser.uid ? (
                        <div style={{ display: "flex", width: "100vw", alignItems: "center" }}>
                          <IoMdCheckmarkCircle size={22} style={{ color: "green" }} />
                          <p style={{ marginBottom: "0px", marginRight: "6px", whiteSpace: "nowrap" }}>أنت صاحب أعلى مزايدة</p>
                        </div>
                      ) : (
                        myBid > 0 &&
                        <div style={{ display: "flex", width: "100vw", alignItems: "center" }}>
                          <MdDangerous size={22} style={{ color: "orange" }} />
                          <p style={{ marginBottom: "0px", marginRight: "6px", whiteSpace: "nowrap" }}>قام شخص ما بالمزايدة عليك</p>
                        </div>
                      )}
                    </>
                  ) : null
                }

                
                <div
                id='product-details-buttons-div'
                >

                  {/* {item.productPrice > 0 && !hasExpired && !isSold &&
                  // here
                  // hide after expire
                  // ! hasExpired && heighestBidder === auth.currentUser.uid &&
                  
                  <Button 
                  id='product-details-button'
                  // disabled={auth.currentUser!= null ? sellerId == auth.currentUser.uid : false}
                  onClick={async ()=>{
                    if(auth.currentUser != null){
                      setExpired(true)
                        await updateDoc(postRef, {
                          heighestBidder: auth.currentUser.uid,
                          currentBid: productPrice,
                          isSold: true,
                          expiryDate: serverTimestamp(),
                          soldDate: serverTimestamp()
                        });

                      // update seller balance
                    // const sellerRef = doc(db, "Users", sellerId);
                    // if(sellerId != ""){
                    //   const sellerData = await getDoc(sellerRef);
                    //   console.log(sellerData.data().balance); 
  
                    //   const newBalance = sellerData.data().balance += productPrice
  
                    //   await updateDoc(sellerRef, {
                    //     balance: newBalance
                    //   }).then(()=>{
                    //     navigate("/")
                    //   })
                    // }
                    }
                    else{
                      alert('You need to sign up to buy a product.')
                    }
                  }}
                  variant="contained">الشراء الان<BiSolidPurchaseTagAlt size={20} style={{marginRight:"4px"}}/></Button>
                  } */}
{
  acceptsOffers ?
    productPrice > 0  ? 
      auth.currentUser != null && Object.keys(bidders).includes(auth.currentUser.uid) ? 
        !hasExpired && 
        <Button 
        id='product-details-button'
        disabled={auth.currentUser!= null ? sellerId == auth.currentUser.uid : false}
        onClick={async ()=>{
          if(auth.currentUser != null){
            setExpired(true)
              await updateDoc(postRef, {
                heighestBidder: auth.currentUser.uid,
                currentBid: productPrice,
                isSold: true,
                expiryDate: serverTimestamp(),
                soldDate: serverTimestamp()
              });
          }
          else{
            alert('You need to sign up to buy a product.')
          }
        }}
        variant="contained"><BiSolidPurchaseTagAlt size={18} style={{marginLeft:"4px"}}/>الشراء الفوري {formatAccountingArabic(productPrice - productStartingPrice * 0.05)}</Button>
        : !hasExpired &&
        <Button 
        id='product-details-button'
        // disabled={auth.currentUser!= null ? item.seller['sellerId'] == auth.currentUser.uid : false}
        onClick={async ()=>{
          navigate('checkout')
          // if(auth.currentUser != null){
          //   setExpired(true)
          //     await updateDoc(postRef, {
          //       heighestBidder: auth.currentUser.uid,
          //       currentBid: productPrice,
          //       isSold: true,
          //       expiryDate: serverTimestamp(),
          //       soldDate: serverTimestamp()
          //     });
          //     // sendPurchasedEmail()
          // }
          // else{
          //   alert('You need to sign up to buy a product.')
          // }
        }}
        variant="contained">{productPrice} الشراء الان<BiSolidPurchaseTagAlt size={20} style={{marginRight:"4px"}}/></Button>
        : ""
        :  !isSold &&       
        <Button 
        id='product-details-button'
        // disabled={auth.currentUser!= null ? item.seller['sellerId'] == auth.currentUser.uid : false}
        onClick={async ()=>{
          navigate("checkout")
          // if(auth.currentUser != null){
          //   setExpired(true)
          //     await updateDoc(postRef, {
          //       heighestBidder: auth.currentUser.uid,
          //       currentBid: productPrice,
          //       isSold: true,
          //       expiryDate: serverTimestamp(),
          //       soldDate: serverTimestamp()
          //     });
          //     // sendPurchasedEmail();
          // }
          // else{
          //   alert('You need to sign up to buy a product.')
          // }
        }}
        variant="contained"><BiSolidPurchaseTagAlt size={18} style={{marginLeft:"4px"}}/>الشراء الان {formatAccountingArabic(productPrice)}</Button>
}

{
  acceptsOffers && (
    auth.currentUser != null &&
    Object.keys(bidders).includes(auth.currentUser.uid) ? (
      !hasExpired && (
        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          {/* <input
            ref={myBidRef}
            placeholder="مبلغ المزايدة"
            type="tel"
            style={{
              padding: "4px",
              fontSize: "14px",
              width: "45%",
              height: "45px",
              paddingRight: "8px",
              marginBottom: "auto",
              borderRadius: 1000,
              border: "1px solid #ccc",
              direction: "rtl",
            }}
          /> */}
          <button id='my-button'
          onClick={()=>{
            if(newBid - bidIncrements > currentBid){
              setNewBid(newBid - bidIncrements)
            }
            else return;
          }}
          style={{
            width: "20%",
            borderRadius: "8px",
            height: "40px",
            backgroundColor: "#F00057"
          }}
          ><FaMinus size={12} /></button>


          <p style={{
            marginBottom: "0px",
            marginRight: "8px",
            marginLeft: "8px",
            whiteSpace: "nowrap"
          }}>{formatAccountingArabic(newBid)}</p>


          <button id='my-button'
          onClick={()=>{
            if(productPrice > 0 && newBid + bidIncrements > productPrice){
              setNewBid(productPrice)
              return;
            }
            setNewBid(newBid + bidIncrements)
          }}
          style={{
            borderRadius: "8px",
            width: "20%",
            height: "40px",
            backgroundColor: "#00C6B7"
          }}
          ><FaPlus size={12} /></button>
          <Button
          
            onClick={async () => {
              const bidValue = parseFloat(newBid);

              if(isNaN(parseInt(bidValue)) || typeof parseInt(bidValue) !== 'number') return;

              if (bidValue <= currentBid || bidValue < productStartingPrice) {
                return;
              }


              if (bidValue >= productPrice && productPrice !== 0) {
                console.log("bid reached buy now price");
                setCurrentBid(productPrice)
                Object.assign(bidders, {
                  [`${auth.currentUser.uid}`]: parseFloat(productPrice),
                });

                const postRef = doc(db, "Posts", `post${id}`);
                await updateDoc(postRef, {
                  bidders: bidders,
                  heighestBidder: auth.currentUser.uid,
                  currentBid: parseFloat(productPrice),
                  lastBidDate: serverTimestamp(),
                  expiryDate: serverTimestamp()
                });

                // const sellerRef = doc(db, "Users", sellerId);

                // if (sellerId !== "") {
                //   const sellerData = await getDoc(sellerRef);
                //   const newBalance = sellerData.data().balance += productPrice;

                //   await updateDoc(sellerRef, {
                //     balance: newBalance,
                //   });

                //   navigate("/");
                // }
              } else {
                Object.assign(bidders, {
                  [`${auth.currentUser.uid}`]: bidValue,
                });

                const postRef = doc(db, "Posts", `post${id}`);
                await updateDoc(postRef, {
                  bidders: bidders,
                  currentBid: bidValue,
                  heighestBidder: auth.currentUser.uid,
                  lastBidDate: serverTimestamp()
                });
              }
            }}
            
            id="my-button"

            style={{ width: "42%", height: "40px", borderRadius: "6px" , marginRight: "8px", backgroundColor: "#00C6B7"}}
            variant="contained"
          >
            المزايدة
          </Button>
        </div>
      )
    ) : ! hasExpired && (
      <Button
      disabled={auth.currentUser!= null ? item.seller['sellerId'] == auth.currentUser.uid : false}
        id="product-details-button"
        onClick={async () => {
          if (auth.currentUser !== null) {
            navigate(`checkout`)
          } else {
            alert("You need to sign up to place a bid.");
          }
        }}
        variant="contained"
      >
        قم بالمزايدة
        <PiGavelFill size={20} style={{ marginRight: "4px" }} />
      </Button>
    )
  )
}

{
  acceptsOffers ? (
    hasExpired && heighestBidder === auth.currentUser.uid && (
      <>
        {!isSold ? (
          <Button
            id='product-details-button'
            style={{ color: "white" }}
            onClick={async () => {
              navigate("checkout");
            }}
          >
            شراء المنتج بمبلغ {currentBid - productStartingPrice * 0.05} ريال
          </Button>
        ) : !isReceived ? (
          <>
            <h4>تم شراء المنتج! عند استلام المنتج، يرجى تأكيد استلامه:</h4>
            <Button
              id='product-details-button'
              onClick={async () => {
                await updateDoc(postRef, {
                  isReceived: true,
                  receivedDate: serverTimestamp(),
                });

                // Update seller balance
                const sellerRef = doc(db, "Users", sellerId);
                if (sellerId !== "") {
                  const sellerData = await getDoc(sellerRef);

                  let newBalance;
                  if (acceptsOffers) {
                    newBalance = sellerData.data().balance + currentBid;
                  } else {
                    newBalance = sellerData.data().balance + productPrice;
                  }

                  await updateDoc(sellerRef, {
                    balance: newBalance,
                  }).then(() => {
                    navigate("/");
                  });
                }
              }}
            >
              تأكيد استلام المنتج
            </Button>
          </>
        ) : (
          <h4>تم استلام المنتج!</h4>
        )}
      </>
    )
  ) : (
    isSold && (
      !isReceived ? (
        <>
          <h4>تم شراء المنتج! عند استلام المنتج، يرجى تأكيد استلامه:</h4>
          <Button
            id='product-details-button'
            onClick={async () => {
              await updateDoc(postRef, {
                isReceived: true,
                receivedDate: serverTimestamp(),
              });

              // Update seller balance

              const sellerRef = collection(db, "Users");
              const quer = query(sellerRef, where("id", "==", parseFloat(sellerId)));

const querySnapshot = await getDocs(quer);

if (!querySnapshot.empty) {
  const userDoc = querySnapshot.docs[0];
  const currentBalance = userDoc.data().balance;

  let newBalance;

  if (acceptsOffers) {
    newBalance = currentBalance + currentBid;
  } else {
    newBalance = currentBalance + productPrice;
  }

  // Update the document with the new balance
  await updateDoc(doc(db, "Users", userDoc.id), {
    balance: newBalance - (newBalance * profitPercentage),
  }).then(() => {
    navigate("/");
  });
} else {
  // Handle the case where the user with the specified ID is not found
  console.error("User not found");
}
            }}
          >
            تأكيد استلام المنتج
          </Button>
        </>
      ) : (
        <h4>تم استلام المنتج!</h4>
      )
    )
  )
}




                  {/* <Button 
                  id='product-details-button'
                  // disabled={auth.currentUser  != null ? sellerId == auth.currentUser.uid : false}
                  variant="contained">اضافة الى الامنيات<AiOutlineHeart size={20} style={{marginRight:"4px",}}/></Button> */}
                  {/* <Button 
                  id='product-details-button'
                  // disabled={auth.currentUser != null ? sellerId == auth.currentUser.uid : false}
                  style={{backgroundColor: "#ff4942"}}
                  variant="contained">الابلاغ عن المنشور<IoIosWarning size={20} style={{marginRight:"4px",}}/></Button> */}
                </div>
                </div>
          </div>
        </div>
        ))
      }
    </div>

<div id='product-details-comment-section'>



    {
     ! commentsLoading ?
    comments.length > 0 ? (
      <>
        <h3 style={{ marginBottom: "16px" }}>التعليقات</h3>
        {comments.map((comment) => (
          <Comment
            key={comment.timestamp} // Make sure to add a unique key
            comment={comment.comment}
            userRating={comment.user.rating}
            numRaters={comment.user.numRaters}
            userImg={comment.user.profileImgUrl}
            commentId={comment.commentId}
            commentRef="Post"
            isUpdated={comment.isUpdated}
            postId={`post${id}`}
            userName={comment.user.fullName}
            timestamp={comment.timestamp}
            userId={comment.userId}
            link={`/user/${comment.user.id}`}
          />
        ))}
      </>
    ) : (
      <p style={{fontSize: "18px", marginRight: "8px"}}>لا توجد تعليقات حتى الآن</p>
    )
    : 
    <div style={{
      marginTop: "55px",
      display: 'flex',
      width: "fit-content",
      marginRight: "8px",
  alignItems: 'flex-start',
  justifyContent: 'flex-start',

    }}>
      <Oval
            height={"30px"}
            width={"30px"}
            color={"dodgerblue"}
            wrapperClass=""
            visible={true}
            wrapperStyle={{display: "block"}}
            ariaLabel='oval-loading'
            secondaryColor="#ccc"
            strokeWidth={1}
            strokeWidthSecondary={1}
          />
    </div>
    }

{!hasExpired && auth.currentUser != null && (
  <>
    <textarea
      required={false}
      ref={commentRef}
      id='input-comment'
      style={{marginRight:"8px"}}
      minLength={100}
      onChange={(event) => {
        setComment(event.target.value);
      }}
      placeholder="أضف تعليقًا أو سؤالًا"
    />

    <button
      id= {addCommentLoading ? 'add-comment-button-disabled' : 'add-comment-button'}
      disabled={addCommentLoading}
      onClick={()=>{
         if(comment.length < 1) return;
         
        setAddCommentLoading(true);
        addComment(`post${id}`, comment).then(()=>{
          setAddCommentLoading(false);
          setComment("");
          // if(sellerEmail != "" && sellerId != myId){
          //   // sendCommentEmail()
          // }
        })}}
    >
    {   
     addCommentLoading ?    
     <div style={{
    alignItems: 'center',
    width: "100%",
    height: "100%",
    justifyContent: 'center',

      }}>
        <Oval
              height={"20px"}
              width={"20px"}
              color={"white"}
              visible={true}
              wrapperStyle={{display: "block"}}
              ariaLabel='oval-loading'
              secondaryColor="#ccc"
              strokeWidth={1}
              strokeWidthSecondary={1}
              />
              </div>
               : "اضافة تعليق"
              }
    </button>
  </>
)}

{!hasExpired && auth.currentUser == null && (
  <p style={{ marginTop: "8px" }}>
    <Link 
    style={{
      marginRight: "8px"
    }} to="/sign_in">أضف تعليق</Link>
  </p>
)}

</div>


    <div
            id='more-products-div'
            >

              <Divider sx={{height: "2px"}}/>
              <br/>
                <div
                style={{
                  direction: "rtl",
                }}
                >
               {similarProducts.length > 0 ? (
  <>
    <p style={{fontSize: "18px", marginRight: "16px"}}>منتجات مشابهة</p>
    {similarProducts.map((item2) => (
      <div style={{ padding: "0px 8px", marginRight: "8px" }} key={item2.id} onClick={handleScrollToTop}>
        <Product
          postId={item2.id}
          expiryDate={`${item2.expiryDate.seconds}`}
          link={`/product/${item2.id}`}
          numBidders={item2.numBidders}
          currentBid={item2.currentBid}
          productPrice={item2.productPrice}
          imgs={item2.imgs[0]}
          condition={item2.condition}
          productDescription={item2.productDescription}
          productName={item2.productName}
          productSeller={item2.seller}
          acceptsOffers={item2.acceptsOffers}
          heighestBidder={item2.heighestBidder}
          isBidder={item2.isBidder}
          isSold={item2.isSold}
          startingPrice={item2.startingPrice}
          numBids={item2.numBids}

        />
        <Divider sx={{ height: "2px" }} />
      </div>
    ))}
  </>
) : null}


<br/>
                <div
                style={{padding: "0px 8px"}}
                >

{similarCategory.length > 0 && (
  <>
    <h2>منتجات تهمك</h2>
    {similarCategory.map((item3) => (
      <div key={item3.key}>
        <Product 
          postId={item3.id}
          expiryDate={`${item3.expiryDate.seconds}`}
          link={`/product/${item3.id}`}
          numBidders={item3.numBidders}
          currentBid={item3.currentBid}
          productPrice={item3.productPrice}
          imgs={item3.imgs[0]}
          condition={item3.condition}
          productDescription={item3.productDescription}
          productName={item3.productName}
          productSeller={item3.seller}
          acceptsOffers={item3.acceptsOffers}
          heighestBidder={item3.heighestBidder}
          isBidder={item3.isBidder}
          isSold={item3.isSold}
          startingPrice={item3.startingPrice}
          numBids={item3.numBids}
        />
        <Divider sx={{ height: "2px" }} />
      </div>
    ))}
  </>
)}

          </div>
          </div>
        </div>
    </>
   );
  }

export default PostDetailsScreen;