// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";

// Your web app's Firebase configuration
const firebaseConfig = {
  // production
  apiKey: "AIzaSyBZLYvXZ-xWVh9Qfq1Fpn3q8Kh56Y5aQLg",
  authDomain: "men-ela-3cd15.firebaseapp.com",
  projectId: "men-ela-3cd15",
  storageBucket: "men-ela-3cd15.appspot.com",
  messagingSenderId: "573543417636",
  appId: "1:573543417636:web:671320f89a842d53ba95b1",
  measurementId: "G-Z34J62GK6Z"

  // testing
  // apiKey: "AIzaSyA2AaC4uMmL17Qki73a2YwniHqpSdaaHcQ",
  // authDomain: "mazad-782fd.firebaseapp.com",
  // projectId: "mazad-782fd",
  // storageBucket: "mazad-782fd.appspot.com",
  // messagingSenderId: "192544614851",
  // appId: "1:192544614851:web:ed301703189d8b0fe67576",
  // measurementId: "G-75MCKC4M70"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);
const storage = getStorage(app);

// Export firestore, auth, and storage
// It will be imported into your React app whenever it is needed
export { db, auth, storage };
