  import React , {useState, useEffect, useRef} from 'react'
  import Category from '../components/Category';
  import ElectronicsImg from '../assets/electronics.avif';
  import FurnitureImg from '../assets/furniture.jpg';
  import autoParts from '../assets/auto-parts.jpg';
  import musicInstruments from '../assets/music-instruments.jpeg';
  import CustomNavbar from '../components/CustomNavbar';
  import { initializeApp } from 'firebase/app';
  import { getFirestore, collection, getDocs, onSnapshot, where, Timestamp, Firestore, limit, doc, limitToLast, getDoc, orderBy, and } from 'firebase/firestore';
  import Product from '../components/Product';
  import Button from '@mui/material/Button';
  import { Select, MenuItem, List } from '@mui/material';
  // Follow this pattern to import other Firebase services
  // import { } from 'firebase/<service>';
  import { Divider } from '@mui/material';
  import { DropdownDivider } from 'react-bootstrap';
  import Dropdown from 'react-dropdown';
  import InfiniteScroll from 'react-infinite-scroll-component'; 
  import { getDatabase, ref, onValue, set, limitToFirst, orderByChild, query, startAfter, child, update, equalTo } from "firebase/database";
  import { getAuth, signInWithPhoneNumber } from "firebase/auth";
  import { useLocation, useSearchParams, useNavigate } from 'react-router-dom';
  import { FaCheck } from "react-icons/fa6";
  import { Oval } from 'react-loader-spinner'
  import { FaChevronDown } from "react-icons/fa";
  import { BiChevronDown } from "react-icons/bi";
  import { BiChevronUp } from "react-icons/bi"; 
import { db, auth, storage } from '../config/firebase'
import { FiFilter } from "react-icons/fi";
import { MdFilterListAlt } from "react-icons/md";
import { PiGavelFill } from "react-icons/pi";  
import { BsStopwatchFill } from "react-icons/bs";
import Countdown from 'react-countdown';

function HomeScreen (){
    const [Listing, setListing] = useState([]);
    const [initialListing, setInitialListing] = useState([]);
    const [moreListings, setMoreListings] = useState([]);
    const [filteredListing, setFilteredListing] = useState([]);
    const [filteredCategory, setFilteredCategory] = useState("");
    const [qu, setQu] = useState(query(collection(db, "Posts")));
    const[lastDoc, setLastDoc] = useState("");
    const listInnerRef = useRef();
    const [hasMore, setHasMore] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [displayMenu, setDisplayMenu] = useState(false);

    const [conditionSelected, setConditionSelected] = useState("");
    const [orderSelected, setOrderSelected] = useState("");
    const [orderSelectedName, setOrderSelectedName] = useState("");
    const [orderSelectedFun, setOrderSelectedFun] = useState("");
    const [filterSelected, setFilterSelected] = useState("");
    const [productKindSelected, setProductKindSelected] = useState("");
    const [postKind, setPostKind] = useState("");
    
    
    const [lastKey, setLastKey] = useState("");
    const [lastValue, setLastValue] = useState("");
    
    const [ searchParams, setSearchParams ] = useSearchParams();

    const [showFilters, setShowFilters] = useState(false);

    const [sortedNewestToOldest, setSortedNewestToOldest] = useState(false);
    const [sortedOldestToNewest, setSortedOldestToNewest] = useState(false);
    const [sortedByProductPriceHeighestToLowest, setSortedByProductPriceHeighestToLowest] = useState(false);
    const [sortedByProductPriceLowestToHeighest, setSortedByProductPriceLowestToHeighest] = useState(false);



    const [hasOrderSelected, setHasOrderSelected] = useState(false);
    const [orderBySelected, setOrderByName] = useState("");
    const navigate = useNavigate();
    const [numProducts, setNumProducts] = useState("");

    const res = searchParams.get("search")
    const categorySearch = searchParams.get("category")
    const subCategorySearch = searchParams.get("subCategory")


    useEffect(() => {
      // Check if categorySearch is not null before updating the query
      if (categorySearch !== null) {
        setQu(query(collection(db, "Posts"), where("category", "==", categorySearch)));
      }
      else if(subCategorySearch !== null){
        setQu(query(collection(db, "Posts"), where("subCategory", "==", subCategorySearch)));
      }
    }, [categorySearch]);

    useEffect(() => {
      const listener = onSnapshot(qu, (querySnapshot) => {
        if (!querySnapshot.empty) {
          const arr = [];
    
          querySnapshot.forEach((doc) => {
            if (!res) {
              if (!doc.data().isDeleted && !doc.data().isSold) {
                if (doc.data().expiryDate !== "") {
                  if (Date.now() / 1000 - doc.data().expiryDate.seconds < 0) {
                    arr.push(doc.data());
                  }
                } else {
                  arr.push(doc.data());
                }
              }
            } else {
              if (!doc.data().isDeleted) {
                const searchTerm = res.split(" ");
                const keywords = doc.data().keywords;
    
                const hasAllElems = searchTerm.every(elem => keywords.includes(elem));
    
                if (hasAllElems) {
                  if(!doc.data()['isDeleted'] && !doc.data()['isSold']){
                    if (doc.data().expiryDate !== "") {
                      if (Date.now() / 1000 - doc.data().expiryDate.seconds < 0) {
                        arr.push(doc.data());
                      }
                    } else {
                      arr.push(doc.data());
                    }
                  }
                }
              }
            }
          });
    
          let sortedArr;
          switch (orderSelectedName) {
            case "الاحدث فالأقدم":
              sortedArr = arr.sort((a, b) => b.createdAt - a.createdAt);
              break;
              case "الأقدم فالأحدث":
              sortedArr = arr.sort((a, b) => a.createdAt - b.createdAt);
              break;
              case "سعر الشراء تنازليا":
              sortedArr = arr.sort((a, b) => b.productPrice - a.productPrice);
              break;
              case "سعر الشراء تصاعديا":
              sortedArr = arr.sort((a, b) => a.productPrice - b.productPrice).filter(post => post.acceptsOffers === false);
              break;
              case "سعر المزايدة تصاعديا":
              sortedArr = arr.sort((a, b) => a.currentBid - b.currentBid).filter(post => post.acceptsOffers === true);
              break;
              case "سعر المزايدة تنازليا":
              sortedArr = arr.sort((a, b) => b.currentBid - a.currentBid);
              break;
              default:
              sortedArr = arr.sort((a, b) => b.createdAt - a.createdAt);
              break;
          }
    
          setListing(sortedArr);
          setInitialListing(sortedArr);
    
          const productsFound = sortedArr.length;
          if(productsFound === 0){
            setNumProducts("لم يتم العثور على منتجات");
          }
          else if (productsFound === 1) {
            setNumProducts("تم العثور على منتج واحد");
          } else if (productsFound === 2) {
            setNumProducts("تم العثور على منتجين");
          } else if (productsFound > 0 && productsFound <= 10) {
            setNumProducts(`تم العثور على ${productsFound} منتجات`);
          } else if (productsFound > 10) {
            setNumProducts(`تم العثور على ${productsFound} منتج`);
          }

        } else {
          setNumProducts("لم يتم العثور على منتجات");
          setListing([]);
          setInitialListing([]);
        }
        setIsLoading(false);
      });
    
      return () => listener();
    }, [qu, res, orderSelectedName]);

      
      const fetchMore = () => {
      //   console.log("more")
      //   onSnapshot(query(collection(db, "Posts"), startAfter(lastDoc),  limit(3)), (querySnapshot) => {
      //     if (!querySnapshot.empty) {
      //       const cities = [];
      //       // console.log(Listing[0]['id']);
      //       querySnapshot.forEach((doc) => {
      //         // console.log(doc.data().id);
      //         // if(Date.now() / 1000 - doc.data()['expiryDate'].seconds < 0){  
      //           cities.push(doc.data());
      //           // }
      //         });
          
      //         setListing((Listing)=> [...Listing, ...cities]);
      //         // setListing(cities);
      //         setLastDoc(querySnapshot.docs[querySnapshot.docs.length - 1]);
      //       } else{
      //         setHasMore(false);
      //       } //whatever you choose to do when there are no records;
      //     },
      //     (err) => {
      //   //whatever you want to do in case of an error
      // }
      // );
    }

    // if(Listing.length > 0){
    //   console.log(Listing[0].productName = "hhh")
    // }

    const toggleShowFilters = () => {
      setShowFilters(!showFilters);
    };

    if(isLoading){
      return       <div style={{
        width: "100vw",
        height: "80vh",
        marginTop: "55px",
        display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

      }}>
        <Oval
              height={"50px"}
              width={"50px"}
              color={"dodgerblue"}
              wrapperClass=""
              visible={true}
              wrapperStyle={{display: "block"}}
              ariaLabel='oval-loading'
              secondaryColor="#ccc"
              strokeWidth={1}
              strokeWidthSecondary={1}
            />
      </div>
    }

    const filterNew = () => {
      if(conditionSelected !== 'جديد'){
        // setListing(initialListing);
        setConditionSelected("جديد")
        if(res === null){
        if(categorySearch !== null){
          // only kind 
          if(postKind !== "" && orderSelectedName === ""){
          console.log("b")
          if(postKind === 'يقبل مزايدات'){
            console.log("here")
            setQu(query(collection(db, "Posts"), where("condition", "==", "جديد"), where("acceptsOffers", "==", true), where("category", "==", categorySearch)))
            // setListing(initialListing.filter(post => post.condition === "جديد").filter(post => post.acceptsOffers === true));
          }
          else if(postKind === 'لا يقبل مزايدات'){
            setQu(query(collection(db, "Posts"), where("condition", "==", "جديد"), where("acceptsOffers", "==", false), where("category", "==", categorySearch)))
            // setListing(initialListing.filter(post => post.condition === "جديد").filter(post => post.acceptsOffers === false));
          }
          else if(postKind === 'يقبل مزايدات مع سعر شراء فوري'){
            setQu(query(collection(db, "Posts"), where("condition", "==", "جديد"), where("acceptsOffers", "==", true), where("productPrice", ">", 0), where("category", "==", categorySearch)))
            // setListing(initialListing.filter(post => post.condition === "جديد").filter(post => post.acceptsOffers === true).filter(post => post.productPrice > 0));
          }
          else if(postKind === 'يقبل مزايدات بدون سعر شراء فوري'){
            setQu(query(collection(db, "Posts"), where("condition", "==", "جديد"), where("acceptsOffers", "==", true), where("productPrice", "==", 0), where("category", "==", categorySearch)))
            // setListing(initialListing.filter(post => post.condition === "جديد").filter(post => post.acceptsOffers === true).filter(post => post.productPrice === 0));
          }
        }
        // only order 
        else if(orderSelectedName !== "" && postKind === ""){
          console.log("c")
          if(orderSelectedName === "سعر الشراء تنازليا" || orderSelectedName === "سعر الشراء تصاعديا"){
            setQu(query(collection(db, "Posts"), where("condition", "==", "جديد"), where("productPrice", ">", 0), where("category", "==", categorySearch)))
          }
          else if(orderSelectedName === "سعر المزايدة تصاعديا" || orderSelectedName === "سعر المزايدة تنازليا"){
            setQu(query(collection(db, "Posts"), where("condition", "==", "جديد"), where("acceptsOffers", "==", true), where("category", "==", categorySearch)))
          }
        }
        // both selected
        else if(postKind !== "" && orderSelectedName !== ""){
          console.log("d")
          if(postKind === "يقبل مزايدات"){
            if(orderSelectedName === "سعر الشراء تنازليا" || orderSelectedName === "سعر الشراء تصاعديا"){
              setQu(query(collection(db, "Posts"), where("condition", "==", "جديد"), where("acceptsOffers", "==", true), where("productPrice", ">", 0), where("category", "==", categorySearch)))
            }
            if(orderSelectedName === "سعر المزايدة تنازليا" || orderSelectedName === "سعر المزايدة تصاعديا"){
              setQu(query(collection(db, "Posts"), where("condition", "==", "جديد"), where("acceptsOffers", "==", true), where("category", "==", categorySearch)))
            }
          }
          else if(postKind === "لا يقبل مزايدات"){
            if(orderSelectedName === "سعر الشراء تنازليا" || orderSelectedName === "سعر الشراء تصاعديا"){
              setQu(query(collection(db, "Posts"), where("condition", "==", "جديد"), where("acceptsOffers", "==", false), where("category", "==", categorySearch)))
            }
          }
          else if(postKind === "يقبل مزايدات مع سعر شراء فوري"){
            if(orderSelectedName === "سعر الشراء تنازليا" || orderSelectedName === "سعر الشراء تصاعديا"){
              setQu(query(collection(db, "Posts"), where("condition", "==", "جديد"), where("acceptsOffers", "==", true), where("productPrice", ">", 0), where("category", "==", categorySearch)))
            }
            else if(orderSelectedName === "سعر المزايدة تنازليا" || orderSelectedName === "سعر المزايدة تصاعديا"){
              setQu(query(collection(db, "Posts"), where("condition", "==", "جديد"), where("acceptsOffers", "==", true), where("productPrice", ">", 0), where("category", "==", categorySearch)))
            }
          }
          else if(postKind === "يقبل مزايدات بدون سعر شراء فوري"){
            if(orderSelectedName === "سعر المزايدة تنازليا" || orderSelectedName === "سعر المزايدة تصاعديا"){
              setQu(query(collection(db, "Posts"), where("condition", "==", "جديد"), where("acceptsOffers", "==", true), where("productPrice", "==", 0), where("category", "==", categorySearch)))
            }
          }
        }

        else{
          setQu(query(collection(db, "Posts"), where("condition", "==", "جديد"), where("category", "==", categorySearch)))
          // setListing(Listing.filter(post => post.condition === "جديد"));
        }
        }
        else if(subCategorySearch !== null){
          // only kind 
          if(postKind !== "" && orderSelectedName === ""){
            console.log("b")
            if(postKind === 'يقبل مزايدات'){
              console.log("here")
              setQu(query(collection(db, "Posts"), where("condition", "==", "جديد"), where("acceptsOffers", "==", true), where("subCategory", "==", subCategorySearch)))
              // setListing(initialListing.filter(post => post.condition === "جديد").filter(post => post.acceptsOffers === true));
            }
            else if(postKind === 'لا يقبل مزايدات'){
              setQu(query(collection(db, "Posts"), where("condition", "==", "جديد"), where("acceptsOffers", "==", false), where("subCategory", "==", subCategorySearch)))
              // setListing(initialListing.filter(post => post.condition === "جديد").filter(post => post.acceptsOffers === false));
            }
            else if(postKind === 'يقبل مزايدات مع سعر شراء فوري'){
              setQu(query(collection(db, "Posts"), where("condition", "==", "جديد"), where("acceptsOffers", "==", true), where("productPrice", ">", 0), where("subCategory", "==", subCategorySearch)))
              // setListing(initialListing.filter(post => post.condition === "جديد").filter(post => post.acceptsOffers === true).filter(post => post.productPrice > 0));
            }
            else if(postKind === 'يقبل مزايدات بدون سعر شراء فوري'){
              setQu(query(collection(db, "Posts"), where("condition", "==", "جديد"), where("acceptsOffers", "==", true), where("productPrice", "==", 0), where("subCategory", "==", subCategorySearch)))
              // setListing(initialListing.filter(post => post.condition === "جديد").filter(post => post.acceptsOffers === true).filter(post => post.productPrice === 0));
            }
          }
          // only order 
          else if(orderSelectedName !== "" && postKind === ""){
            console.log("c")
            if(orderSelectedName === "سعر الشراء تنازليا" || orderSelectedName === "سعر الشراء تصاعديا"){
              setQu(query(collection(db, "Posts"), where("condition", "==", "جديد"), where("productPrice", ">", 0), where("subCategory", "==", subCategorySearch)))
            }
            else if(orderSelectedName === "سعر المزايدة تصاعديا" || orderSelectedName === "سعر المزايدة تنازليا"){
              setQu(query(collection(db, "Posts"), where("condition", "==", "جديد"), where("acceptsOffers", "==", true), where("subCategory", "==", subCategorySearch)))
            }
          }
          // both selected
          else if(postKind !== "" && orderSelectedName !== ""){
            console.log("d")
            if(postKind === "يقبل مزايدات"){
              if(orderSelectedName === "سعر الشراء تنازليا" || orderSelectedName === "سعر الشراء تصاعديا"){
                setQu(query(collection(db, "Posts"), where("condition", "==", "جديد"), where("acceptsOffers", "==", true), where("productPrice", ">", 0), where("subCategory", "==", subCategorySearch)))
              }
              if(orderSelectedName === "سعر المزايدة تنازليا" || orderSelectedName === "سعر المزايدة تصاعديا"){
                setQu(query(collection(db, "Posts"), where("condition", "==", "جديد"), where("acceptsOffers", "==", true), where("subCategory", "==", subCategorySearch)))
              }
            }
            else if(postKind === "لا يقبل مزايدات"){
              if(orderSelectedName === "سعر الشراء تنازليا" || orderSelectedName === "سعر الشراء تصاعديا"){
                setQu(query(collection(db, "Posts"), where("condition", "==", "جديد"), where("acceptsOffers", "==", false), where("subCategory", "==", subCategorySearch)))
              }
            }
            else if(postKind === "يقبل مزايدات مع سعر شراء فوري"){
              if(orderSelectedName === "سعر الشراء تنازليا" || orderSelectedName === "سعر الشراء تصاعديا"){
                setQu(query(collection(db, "Posts"), where("condition", "==", "جديد"), where("acceptsOffers", "==", true), where("productPrice", ">", 0), where("subCategory", "==", subCategorySearch)))
              }
              else if(orderSelectedName === "سعر المزايدة تنازليا" || orderSelectedName === "سعر المزايدة تصاعديا"){
                setQu(query(collection(db, "Posts"), where("condition", "==", "جديد"), where("acceptsOffers", "==", true), where("productPrice", ">", 0), where("subCategory", "==", subCategorySearch)))
              }
            }
            else if(postKind === "يقبل مزايدات بدون سعر شراء فوري"){
              if(orderSelectedName === "سعر المزايدة تنازليا" || orderSelectedName === "سعر المزايدة تصاعديا"){
                setQu(query(collection(db, "Posts"), where("condition", "==", "جديد"), where("acceptsOffers", "==", true), where("productPrice", "==", 0), where("subCategory", "==", subCategorySearch)))
              }
            }
          }
  
          else{
            setQu(query(collection(db, "Posts"), where("condition", "==", "جديد"), where("subCategory", "==", subCategorySearch)))
            // setListing(Listing.filter(post => post.condition === "جديد"));
          }
        }
      }
      else{
        console.log(res)
        console.log(res.split(" "))
        setQu(
          query(
            collection(db, "Posts"),
            where("condition", "==", "جديد"),
            where("keywords", "array-contains-any", res.split(" "))
          )
        );
      }

      }
      else {
        setConditionSelected("");
        // setListing(initialListing);
        if(res === null){

        
        if(categorySearch !== null){
          // only kind 
          if(postKind !== "" && orderSelectedName === ""){
          console.log("b")
          if(postKind === 'يقبل مزايدات'){
            console.log("here")
            setQu(query(collection(db, "Posts"),  where("acceptsOffers", "==", true), where("category", "==", categorySearch)))
            // setListing(initialListing.filter(post => post.condition === "جديد").filter(post => post.acceptsOffers === true));
          }
          else if(postKind === 'لا يقبل مزايدات'){
            setQu(query(collection(db, "Posts"),  where("acceptsOffers", "==", false), where("category", "==", categorySearch)))
            // setListing(initialListing.filter(post => post.condition === "جديد").filter(post => post.acceptsOffers === false));
          }
          else if(postKind === 'يقبل مزايدات مع سعر شراء فوري'){
            setQu(query(collection(db, "Posts"),  where("acceptsOffers", "==", true), where("productPrice", ">", 0), where("category", "==", categorySearch)))
            // setListing(initialListing.filter(post => post.condition === "جديد").filter(post => post.acceptsOffers === true).filter(post => post.productPrice > 0));
          }
          else if(postKind === 'يقبل مزايدات بدون سعر شراء فوري'){
            setQu(query(collection(db, "Posts"),  where("acceptsOffers", "==", true), where("productPrice", "==", 0), where("category", "==", categorySearch)))
            // setListing(initialListing.filter(post => post.condition === "جديد").filter(post => post.acceptsOffers === true).filter(post => post.productPrice === 0));
          }
        }
        // only order 
        else if(orderSelectedName !== "" && postKind === ""){
          console.log("c")
          if(orderSelectedName === "سعر الشراء تنازليا" || orderSelectedName === "سعر الشراء تصاعديا"){
            setQu(query(collection(db, "Posts"),  where("productPrice", ">", 0), where("category", "==", categorySearch)))
          }
          else if(orderSelectedName === "سعر المزايدة تصاعديا" || orderSelectedName === "سعر المزايدة تنازليا"){
            setQu(query(collection(db, "Posts"),  where("acceptsOffers", "==", true), where("category", "==", categorySearch)))
          }
        }
        // both selected
        else if(postKind !== "" && orderSelectedName !== ""){
          console.log("d")
          if(postKind === "يقبل مزايدات"){
            if(orderSelectedName === "سعر الشراء تنازليا" || orderSelectedName === "سعر الشراء تصاعديا"){
              setQu(query(collection(db, "Posts"),  where("acceptsOffers", "==", true), where("productPrice", ">", 0), where("category", "==", categorySearch)))
            }
            if(orderSelectedName === "سعر المزايدة تنازليا" || orderSelectedName === "سعر المزايدة تصاعديا"){
              setQu(query(collection(db, "Posts"),  where("acceptsOffers", "==", true), where("category", "==", categorySearch)))
            }
          }
          else if(postKind === "لا يقبل مزايدات"){
            if(orderSelectedName === "سعر الشراء تنازليا" || orderSelectedName === "سعر الشراء تصاعديا"){
              setQu(query(collection(db, "Posts"),  where("acceptsOffers", "==", false), where("category", "==", categorySearch)))
            }
          }
          else if(postKind === "يقبل مزايدات مع سعر شراء فوري"){
            if(orderSelectedName === "سعر الشراء تنازليا" || orderSelectedName === "سعر الشراء تصاعديا"){
              setQu(query(collection(db, "Posts"),  where("acceptsOffers", "==", true), where("productPrice", ">", 0), where("category", "==", categorySearch)))
            }
            else if(orderSelectedName === "سعر المزايدة تنازليا" || orderSelectedName === "سعر المزايدة تصاعديا"){
              setQu(query(collection(db, "Posts"),  where("acceptsOffers", "==", true), where("productPrice", ">", 0), where("category", "==", categorySearch)))
            }
          }
          else if(postKind === "يقبل مزايدات بدون سعر شراء فوري"){
            if(orderSelectedName === "سعر المزايدة تنازليا" || orderSelectedName === "سعر المزايدة تصاعديا"){
              setQu(query(collection(db, "Posts"),  where("acceptsOffers", "==", true), where("productPrice", "==", 0), where("category", "==", categorySearch)))
            }
          }
        }

        else{
          setQu(query(collection(db, "Posts"),  where("category", "==", categorySearch)))
          // setListing(Listing.filter(post => post.condition === "جديد"));
        }
        }
        else if(subCategorySearch !== null){
          // only kind 
          if(postKind !== "" && orderSelectedName === ""){
            console.log("b")
            if(postKind === 'يقبل مزايدات'){
              console.log("here")
              setQu(query(collection(db, "Posts"),  where("acceptsOffers", "==", true), where("subCategory", "==", subCategorySearch)))
              // setListing(initialListing.filter(post => post.condition === "جديد").filter(post => post.acceptsOffers === true));
            }
            else if(postKind === 'لا يقبل مزايدات'){
              setQu(query(collection(db, "Posts"),  where("acceptsOffers", "==", false), where("subCategory", "==", subCategorySearch)))
              // setListing(initialListing.filter(post => post.condition === "جديد").filter(post => post.acceptsOffers === false));
            }
            else if(postKind === 'يقبل مزايدات مع سعر شراء فوري'){
              setQu(query(collection(db, "Posts"),  where("acceptsOffers", "==", true), where("productPrice", ">", 0), where("subCategory", "==", subCategorySearch)))
              // setListing(initialListing.filter(post => post.condition === "جديد").filter(post => post.acceptsOffers === true).filter(post => post.productPrice > 0));
            }
            else if(postKind === 'يقبل مزايدات بدون سعر شراء فوري'){
              setQu(query(collection(db, "Posts"),  where("acceptsOffers", "==", true), where("productPrice", "==", 0), where("subCategory", "==", subCategorySearch)))
              // setListing(initialListing.filter(post => post.condition === "جديد").filter(post => post.acceptsOffers === true).filter(post => post.productPrice === 0));
            }
          }
          // only order 
          else if(orderSelectedName !== "" && postKind === ""){
            console.log("c")
            if(orderSelectedName === "سعر الشراء تنازليا" || orderSelectedName === "سعر الشراء تصاعديا"){
              setQu(query(collection(db, "Posts"),  where("productPrice", ">", 0), where("subCategory", "==", subCategorySearch)))
            }
            else if(orderSelectedName === "سعر المزايدة تصاعديا" || orderSelectedName === "سعر المزايدة تنازليا"){
              setQu(query(collection(db, "Posts"),  where("acceptsOffers", "==", true), where("subCategory", "==", subCategorySearch)))
            }
          }
          // both selected
          else if(postKind !== "" && orderSelectedName !== ""){
            console.log("d")
            if(postKind === "يقبل مزايدات"){
              if(orderSelectedName === "سعر الشراء تنازليا" || orderSelectedName === "سعر الشراء تصاعديا"){
                setQu(query(collection(db, "Posts"),  where("acceptsOffers", "==", true), where("productPrice", ">", 0), where("subCategory", "==", subCategorySearch)))
              }
              if(orderSelectedName === "سعر المزايدة تنازليا" || orderSelectedName === "سعر المزايدة تصاعديا"){
                setQu(query(collection(db, "Posts"),  where("acceptsOffers", "==", true), where("subCategory", "==", subCategorySearch)))
              }
            }
            else if(postKind === "لا يقبل مزايدات"){
              if(orderSelectedName === "سعر الشراء تنازليا" || orderSelectedName === "سعر الشراء تصاعديا"){
                setQu(query(collection(db, "Posts"),  where("acceptsOffers", "==", false), where("subCategory", "==", subCategorySearch)))
              }
            }
            else if(postKind === "يقبل مزايدات مع سعر شراء فوري"){
              if(orderSelectedName === "سعر الشراء تنازليا" || orderSelectedName === "سعر الشراء تصاعديا"){
                setQu(query(collection(db, "Posts"),  where("acceptsOffers", "==", true), where("productPrice", ">", 0), where("subCategory", "==", subCategorySearch)))
              }
              else if(orderSelectedName === "سعر المزايدة تنازليا" || orderSelectedName === "سعر المزايدة تصاعديا"){
                setQu(query(collection(db, "Posts"),  where("acceptsOffers", "==", true), where("productPrice", ">", 0), where("subCategory", "==", subCategorySearch)))
              }
            }
            else if(postKind === "يقبل مزايدات بدون سعر شراء فوري"){
              if(orderSelectedName === "سعر المزايدة تنازليا" || orderSelectedName === "سعر المزايدة تصاعديا"){
                setQu(query(collection(db, "Posts"),  where("acceptsOffers", "==", true), where("productPrice", "==", 0), where("subCategory", "==", subCategorySearch)))
              }
            }
          }
  
          else{
            setQu(query(collection(db, "Posts"),  where("subCategory", "==", subCategorySearch)))
            // setListing(Listing.filter(post => post.condition === "جديد"));
          }
        }
      }
      else{
        console.log("here")
        console.log(res)
        console.log(res.split(" "))
        setQu(
          query(
            collection(db, "Posts"),
            where("keywords", "array-contains-any", res.split(" "))
          )
        );
      }
      }
    }



    return (
      <div 
        id='products-screen-div'  
        >

<div id='numproducts-and-filters'>
          <p style={{fontSize:"17px", marginBottom: "0px", fontWeight: "500", color: "#444444"}}>{numProducts}</p>

      <div style={{padding: "0px 4px 4px 4px ", display: "flex"}} onClick={toggleShowFilters}>
        <p style={{marginBottom: "0px", marginLeft: "4px"}}>تصفية</p>
         <FiFilter color='#444' style={{marginTop: "auto", marginBottom: "auto" , cursor: "pointer"}} />

      </div>
          {/* {!showFilters ? <BiChevronDown size={22} style={{ marginRight: "0px"}}/> : <BiChevronUp size={22} style={{ marginRight: "0px"}}/> } */}
          </div>

          
        <div
          id='no-scroll'
          >
{/* 
        <input  type='text' onChange={(e)=>{
          searchParams.set('search', e.target.value.trim());
          setSearchParams(searchParams)
          // setQu(query(collection(db, "Posts"), where("keywords", "array-contains", e.currentTarget.value.split(" "))))
        }} style={{width: "50%", height: "50px", direction: "rtl"}} placeholder='البحث' />
           */}

          {/* <div
          id='filter-buttons-wrapper'
          > */}

          {/* <ul
          id='filter-buttons-div'
          > */}
          {/* change category names  */}
          {/* <li onClick={()=>{setQu(query(collection(db, "Posts"))); navigate('/')}} id='filter-buttons'>عرض جميع المنتجات</li> */}
          {/* <li onClick={()=>{setQu(query(collection(db, "Posts"), where("category", "==", "الكترونيات"))); navigate('/')}} id='filter-buttons'>عرض جميع الالكترونيات</li>
          <li onClick={()=>{setQu(query(collection(db, "Posts"), where("subCategory", "==", "جوال"))); navigate('/')}} id='filter-buttons'>جوالات</li>
          <li onClick={()=>{setQu(query(collection(db, "Posts"), where("subCategory", "==", "شاشة"))); navigate('/')}} id='filter-buttons'>شاشات</li>
          <li onClick={()=>{setQu(query(collection(db, "Posts"), where("subCategory", "==", "سماعة"))); navigate('/')}} id='filter-buttons'>سماعات</li>
          <li onClick={()=>{setQu(query(collection(db, "Posts"), where("subCategory", "==", "لابتوب"))); navigate('/')}} id='filter-buttons'>لابتوبات</li>
          <li onClick={()=>{setQu(query(collection(db, "Posts"), where("subCategory", "==", "جهاز لوحي"))); navigate('/')}} id='filter-buttons'>أجهزة لوحية</li>
          <li onClick={()=>{setQu(query(collection(db, "Posts"), where("subCategory", "==", "كاميرا"))); navigate('/')}} id='filter-buttons'>كاميرات</li>
          <li onClick={()=>{setQu(query(collection(db, "Posts"), where("subCategory", "==", "طابعة"))); navigate('/')}} id='filter-buttons'>طابعات</li>
          <li onClick={()=>{setQu(query(collection(db, "Posts"), where("subCategory", "==", "ثلاجة"))); navigate('/')}} id='filter-buttons'>ثلاجات</li>
          <li onClick={()=>{setQu(query(collection(db, "Posts"), where("subCategory", "==", "مكيف"))); navigate('/')}} id='filter-buttons'>مكيفات</li>
          <li onClick={()=>{setQu(query(collection(db, "Posts"), where("subCategory", "==", "جهاز العاب"))); navigate('/')}} id='filter-buttons'>اجهزة العاب</li>
          <li onClick={()=>{setQu(query(collection(db, "Posts"), where("category", "==", "اثاث"))); navigate('/')}} id='filter-buttons'>عرض جميع قطع الاثاث</li>
          <li onClick={()=>{setQu(query(collection(db, "Posts"), where("subCategory", "==", "كرسي"))); navigate('/')}} id='filter-buttons'>كراسي</li>
          <li onClick={()=>{setQu(query(collection(db, "Posts"), where("subCategory", "==", "كنب"))); navigate('/')}} id='filter-buttons'>كنب</li>
          <li onClick={()=>{setQu(query(collection(db, "Posts"), where("subCategory", "==", "طاولة"))); navigate('/')}} id='filter-buttons'>طاولات</li>
          <li onClick={()=>{setQu(query(collection(db, "Posts"), where("subCategory", "==", "رف"))); navigate('/')}} id='filter-buttons'>أرفف</li>
          <li onClick={()=>{setQu(query(collection(db, "Posts"), where("subCategory", "==", "دواليب و أدراج"))); navigate('/')}} id='filter-buttons'>دواليب و أدراج</li>
          <li onClick={()=>{setQu(query(collection(db, "Posts"), where("subCategory", "==", "لوحات فنية"))); navigate('/')}} id='filter-buttons'>لوحات</li>
          <li onClick={()=>{setQu(query(collection(db, "Posts"), where("category", "==", "قطع غيار سيارات"))); navigate('/')}} id='filter-buttons'>عرض جميع قطع غيار السيارات</li>
          <li onClick={()=>{setQu(query(collection(db, "Posts"), where("category", "==", "Music Instruments"))); navigate('/')}} id='filter-buttons'>الات موسيقية</li> */}
        {/* </ul> */}
          {/* </div> */}
          <div
          id='filter-buttons-wrapper'
          style={{
            display: showFilters ? "block" : "none",
          }}
          >

          <ul
          id='filter-buttons-div'
          >
            <div
            style={{marginBottom: "auto", marginTop: "auto",justifyContent: "center"}}
            >
              <p style={{ margin : "0px", padding: "8px", whiteSpace: "nowrap", fontSize: "16px"}}>الحالة:</p>
            </div>
          <li 
          style={{
            fontSize: "14px",
          }}
          onClick={()=>{
            filterNew();
          }} id='filter-buttons'> جديد {conditionSelected === 'جديد' ? <FaCheck /> : ""}</li>










          <li 
          style={{
            fontSize: "14px"
          }}
          onClick={()=>{
           if(conditionSelected !== 'مستعمل'){
            // setListing(initialListing);
            setConditionSelected("مستعمل")
            if(res === null){
            if(categorySearch !== null){
              // only kind 
              if(postKind !== "" && orderSelectedName === ""){
              console.log("b")
              if(postKind === 'يقبل مزايدات'){
                console.log("here")
                setQu(query(collection(db, "Posts"), where("condition", "==", "مستعمل"), where("acceptsOffers", "==", true), where("category", "==", categorySearch)))
                // setListing(initialListing.filter(post => post.condition === "مستعمل").filter(post => post.acceptsOffers === true));
              }
              else if(postKind === 'لا يقبل مزايدات'){
                setQu(query(collection(db, "Posts"), where("condition", "==", "مستعمل"), where("acceptsOffers", "==", false), where("category", "==", categorySearch)))
                // setListing(initialListing.filter(post => post.condition === "مستعمل").filter(post => post.acceptsOffers === false));
              }
              else if(postKind === 'يقبل مزايدات مع سعر شراء فوري'){
                setQu(query(collection(db, "Posts"), where("condition", "==", "مستعمل"), where("acceptsOffers", "==", true), where("productPrice", ">", 0), where("category", "==", categorySearch)))
                // setListing(initialListing.filter(post => post.condition === "مستعمل").filter(post => post.acceptsOffers === true).filter(post => post.productPrice > 0));
              }
              else if(postKind === 'يقبل مزايدات بدون سعر شراء فوري'){
                setQu(query(collection(db, "Posts"), where("condition", "==", "مستعمل"), where("acceptsOffers", "==", true), where("productPrice", "==", 0), where("category", "==", categorySearch)))
                // setListing(initialListing.filter(post => post.condition === "مستعمل").filter(post => post.acceptsOffers === true).filter(post => post.productPrice === 0));
              }
            }
            // only order 
            else if(orderSelectedName !== "" && postKind === ""){
              console.log("c")
              if(orderSelectedName === "سعر الشراء تنازليا" || orderSelectedName === "سعر الشراء تصاعديا"){
                setQu(query(collection(db, "Posts"), where("condition", "==", "مستعمل"), where("productPrice", ">", 0), where("category", "==", categorySearch)))
              }
              else if(orderSelectedName === "سعر المزايدة تصاعديا" || orderSelectedName === "سعر المزايدة تنازليا"){
                setQu(query(collection(db, "Posts"), where("condition", "==", "مستعمل"), where("acceptsOffers", "==", true), where("category", "==", categorySearch)))
              }
            }
            // both selected
            else if(postKind !== "" && orderSelectedName !== ""){
              console.log("d")
              if(postKind === "يقبل مزايدات"){
                if(orderSelectedName === "سعر الشراء تنازليا" || orderSelectedName === "سعر الشراء تصاعديا"){
                  setQu(query(collection(db, "Posts"), where("condition", "==", "مستعمل"), where("acceptsOffers", "==", true), where("productPrice", ">", 0), where("category", "==", categorySearch)))
                }
                if(orderSelectedName === "سعر المزايدة تنازليا" || orderSelectedName === "سعر المزايدة تصاعديا"){
                  setQu(query(collection(db, "Posts"), where("condition", "==", "مستعمل"), where("acceptsOffers", "==", true), where("category", "==", categorySearch)))
                }
              }
              else if(postKind === "لا يقبل مزايدات"){
                if(orderSelectedName === "سعر الشراء تنازليا" || orderSelectedName === "سعر الشراء تصاعديا"){
                  setQu(query(collection(db, "Posts"), where("condition", "==", "مستعمل"), where("acceptsOffers", "==", false), where("category", "==", categorySearch)))
                }
              }
              else if(postKind === "يقبل مزايدات مع سعر شراء فوري"){
                if(orderSelectedName === "سعر الشراء تنازليا" || orderSelectedName === "سعر الشراء تصاعديا"){
                  setQu(query(collection(db, "Posts"), where("condition", "==", "مستعمل"), where("acceptsOffers", "==", true), where("productPrice", ">", 0), where("category", "==", categorySearch)))
                }
                else if(orderSelectedName === "سعر المزايدة تنازليا" || orderSelectedName === "سعر المزايدة تصاعديا"){
                  setQu(query(collection(db, "Posts"), where("condition", "==", "مستعمل"), where("acceptsOffers", "==", true), where("productPrice", ">", 0), where("category", "==", categorySearch)))
                }
              }
              else if(postKind === "يقبل مزايدات بدون سعر شراء فوري"){
                if(orderSelectedName === "سعر المزايدة تنازليا" || orderSelectedName === "سعر المزايدة تصاعديا"){
                  setQu(query(collection(db, "Posts"), where("condition", "==", "مستعمل"), where("acceptsOffers", "==", true), where("productPrice", "==", 0), where("category", "==", categorySearch)))
                }
              }
            }
    
            else{
              setQu(query(collection(db, "Posts"), where("condition", "==", "مستعمل"), where("category", "==", categorySearch)))
              // setListing(Listing.filter(post => post.condition === "مستعمل"));
            }
            }
            else if(subCategorySearch !== null){
              // only kind 
              if(postKind !== "" && orderSelectedName === ""){
                console.log("b")
                if(postKind === 'يقبل مزايدات'){
                  console.log("here")
                  setQu(query(collection(db, "Posts"), where("condition", "==", "مستعمل"), where("acceptsOffers", "==", true), where("subCategory", "==", subCategorySearch)))
                  // setListing(initialListing.filter(post => post.condition === "مستعمل").filter(post => post.acceptsOffers === true));
                }
                else if(postKind === 'لا يقبل مزايدات'){
                  setQu(query(collection(db, "Posts"), where("condition", "==", "مستعمل"), where("acceptsOffers", "==", false), where("subCategory", "==", subCategorySearch)))
                  // setListing(initialListing.filter(post => post.condition === "مستعمل").filter(post => post.acceptsOffers === false));
                }
                else if(postKind === 'يقبل مزايدات مع سعر شراء فوري'){
                  setQu(query(collection(db, "Posts"), where("condition", "==", "مستعمل"), where("acceptsOffers", "==", true), where("productPrice", ">", 0), where("subCategory", "==", subCategorySearch)))
                  // setListing(initialListing.filter(post => post.condition === "مستعمل").filter(post => post.acceptsOffers === true).filter(post => post.productPrice > 0));
                }
                else if(postKind === 'يقبل مزايدات بدون سعر شراء فوري'){
                  setQu(query(collection(db, "Posts"), where("condition", "==", "مستعمل"), where("acceptsOffers", "==", true), where("productPrice", "==", 0), where("subCategory", "==", subCategorySearch)))
                  // setListing(initialListing.filter(post => post.condition === "مستعمل").filter(post => post.acceptsOffers === true).filter(post => post.productPrice === 0));
                }
              }
              // only order 
              else if(orderSelectedName !== "" && postKind === ""){
                console.log("c")
                if(orderSelectedName === "سعر الشراء تنازليا" || orderSelectedName === "سعر الشراء تصاعديا"){
                  setQu(query(collection(db, "Posts"), where("condition", "==", "مستعمل"), where("productPrice", ">", 0), where("subCategory", "==", subCategorySearch)))
                }
                else if(orderSelectedName === "سعر المزايدة تصاعديا" || orderSelectedName === "سعر المزايدة تنازليا"){
                  setQu(query(collection(db, "Posts"), where("condition", "==", "مستعمل"), where("acceptsOffers", "==", true), where("subCategory", "==", subCategorySearch)))
                }
              }
              // both selected
              else if(postKind !== "" && orderSelectedName !== ""){
                console.log("d")
                if(postKind === "يقبل مزايدات"){
                  if(orderSelectedName === "سعر الشراء تنازليا" || orderSelectedName === "سعر الشراء تصاعديا"){
                    setQu(query(collection(db, "Posts"), where("condition", "==", "مستعمل"), where("acceptsOffers", "==", true), where("productPrice", ">", 0), where("subCategory", "==", subCategorySearch)))
                  }
                  if(orderSelectedName === "سعر المزايدة تنازليا" || orderSelectedName === "سعر المزايدة تصاعديا"){
                    setQu(query(collection(db, "Posts"), where("condition", "==", "مستعمل"), where("acceptsOffers", "==", true), where("subCategory", "==", subCategorySearch)))
                  }
                }
                else if(postKind === "لا يقبل مزايدات"){
                  if(orderSelectedName === "سعر الشراء تنازليا" || orderSelectedName === "سعر الشراء تصاعديا"){
                    setQu(query(collection(db, "Posts"), where("condition", "==", "مستعمل"), where("acceptsOffers", "==", false), where("subCategory", "==", subCategorySearch)))
                  }
                }
                else if(postKind === "يقبل مزايدات مع سعر شراء فوري"){
                  if(orderSelectedName === "سعر الشراء تنازليا" || orderSelectedName === "سعر الشراء تصاعديا"){
                    setQu(query(collection(db, "Posts"), where("condition", "==", "مستعمل"), where("acceptsOffers", "==", true), where("productPrice", ">", 0), where("subCategory", "==", subCategorySearch)))
                  }
                  else if(orderSelectedName === "سعر المزايدة تنازليا" || orderSelectedName === "سعر المزايدة تصاعديا"){
                    setQu(query(collection(db, "Posts"), where("condition", "==", "مستعمل"), where("acceptsOffers", "==", true), where("productPrice", ">", 0), where("subCategory", "==", subCategorySearch)))
                  }
                }
                else if(postKind === "يقبل مزايدات بدون سعر شراء فوري"){
                  if(orderSelectedName === "سعر المزايدة تنازليا" || orderSelectedName === "سعر المزايدة تصاعديا"){
                    setQu(query(collection(db, "Posts"), where("condition", "==", "مستعمل"), where("acceptsOffers", "==", true), where("productPrice", "==", 0), where("subCategory", "==", subCategorySearch)))
                  }
                }
              }
      
              else{
                setQu(query(collection(db, "Posts"), where("condition", "==", "مستعمل"), where("subCategory", "==", subCategorySearch)))
                // setListing(Listing.filter(post => post.condition === "مستعمل"));
              }
            }
          }
          else{
            console.log(res)
            console.log(res.split(" "))
            setQu(
              query(
                collection(db, "Posts"),
                where("condition", "==", "مستعمل"),
                where("keywords", "array-contains-any", res.split(" "))
              )
            );
          }
    
          }
          else {
            setConditionSelected("");
            // setListing(initialListing);
            if(res === null){
    
            
            if(categorySearch !== null){
              // only kind 
              if(postKind !== "" && orderSelectedName === ""){
              console.log("b")
              if(postKind === 'يقبل مزايدات'){
                console.log("here")
                setQu(query(collection(db, "Posts"),  where("acceptsOffers", "==", true), where("category", "==", categorySearch)))
                // setListing(initialListing.filter(post => post.condition === "جديد").filter(post => post.acceptsOffers === true));
              }
              else if(postKind === 'لا يقبل مزايدات'){
                setQu(query(collection(db, "Posts"),  where("acceptsOffers", "==", false), where("category", "==", categorySearch)))
                // setListing(initialListing.filter(post => post.condition === "جديد").filter(post => post.acceptsOffers === false));
              }
              else if(postKind === 'يقبل مزايدات مع سعر شراء فوري'){
                setQu(query(collection(db, "Posts"),  where("acceptsOffers", "==", true), where("productPrice", ">", 0), where("category", "==", categorySearch)))
                // setListing(initialListing.filter(post => post.condition === "جديد").filter(post => post.acceptsOffers === true).filter(post => post.productPrice > 0));
              }
              else if(postKind === 'يقبل مزايدات بدون سعر شراء فوري'){
                setQu(query(collection(db, "Posts"),  where("acceptsOffers", "==", true), where("productPrice", "==", 0), where("category", "==", categorySearch)))
                // setListing(initialListing.filter(post => post.condition === "جديد").filter(post => post.acceptsOffers === true).filter(post => post.productPrice === 0));
              }
            }
            // only order 
            else if(orderSelectedName !== "" && postKind === ""){
              console.log("c")
              if(orderSelectedName === "سعر الشراء تنازليا" || orderSelectedName === "سعر الشراء تصاعديا"){
                setQu(query(collection(db, "Posts"),  where("productPrice", ">", 0), where("category", "==", categorySearch)))
              }
              else if(orderSelectedName === "سعر المزايدة تصاعديا" || orderSelectedName === "سعر المزايدة تنازليا"){
                setQu(query(collection(db, "Posts"),  where("acceptsOffers", "==", true), where("category", "==", categorySearch)))
              }
            }
            // both selected
            else if(postKind !== "" && orderSelectedName !== ""){
              console.log("d")
              if(postKind === "يقبل مزايدات"){
                if(orderSelectedName === "سعر الشراء تنازليا" || orderSelectedName === "سعر الشراء تصاعديا"){
                  setQu(query(collection(db, "Posts"),  where("acceptsOffers", "==", true), where("productPrice", ">", 0), where("category", "==", categorySearch)))
                }
                if(orderSelectedName === "سعر المزايدة تنازليا" || orderSelectedName === "سعر المزايدة تصاعديا"){
                  setQu(query(collection(db, "Posts"),  where("acceptsOffers", "==", true), where("category", "==", categorySearch)))
                }
              }
              else if(postKind === "لا يقبل مزايدات"){
                if(orderSelectedName === "سعر الشراء تنازليا" || orderSelectedName === "سعر الشراء تصاعديا"){
                  setQu(query(collection(db, "Posts"),  where("acceptsOffers", "==", false), where("category", "==", categorySearch)))
                }
              }
              else if(postKind === "يقبل مزايدات مع سعر شراء فوري"){
                if(orderSelectedName === "سعر الشراء تنازليا" || orderSelectedName === "سعر الشراء تصاعديا"){
                  setQu(query(collection(db, "Posts"),  where("acceptsOffers", "==", true), where("productPrice", ">", 0), where("category", "==", categorySearch)))
                }
                else if(orderSelectedName === "سعر المزايدة تنازليا" || orderSelectedName === "سعر المزايدة تصاعديا"){
                  setQu(query(collection(db, "Posts"),  where("acceptsOffers", "==", true), where("productPrice", ">", 0), where("category", "==", categorySearch)))
                }
              }
              else if(postKind === "يقبل مزايدات بدون سعر شراء فوري"){
                if(orderSelectedName === "سعر المزايدة تنازليا" || orderSelectedName === "سعر المزايدة تصاعديا"){
                  setQu(query(collection(db, "Posts"),  where("acceptsOffers", "==", true), where("productPrice", "==", 0), where("category", "==", categorySearch)))
                }
              }
            }
    
            else{
              setQu(query(collection(db, "Posts"),  where("category", "==", categorySearch)))
              // setListing(Listing.filter(post => post.condition === "جديد"));
            }
            }
            else if(subCategorySearch !== null){
              // only kind 
              if(postKind !== "" && orderSelectedName === ""){
                console.log("b")
                if(postKind === 'يقبل مزايدات'){
                  console.log("here")
                  setQu(query(collection(db, "Posts"),  where("acceptsOffers", "==", true), where("subCategory", "==", subCategorySearch)))
                  // setListing(initialListing.filter(post => post.condition === "جديد").filter(post => post.acceptsOffers === true));
                }
                else if(postKind === 'لا يقبل مزايدات'){
                  setQu(query(collection(db, "Posts"),  where("acceptsOffers", "==", false), where("subCategory", "==", subCategorySearch)))
                  // setListing(initialListing.filter(post => post.condition === "جديد").filter(post => post.acceptsOffers === false));
                }
                else if(postKind === 'يقبل مزايدات مع سعر شراء فوري'){
                  setQu(query(collection(db, "Posts"),  where("acceptsOffers", "==", true), where("productPrice", ">", 0), where("subCategory", "==", subCategorySearch)))
                  // setListing(initialListing.filter(post => post.condition === "جديد").filter(post => post.acceptsOffers === true).filter(post => post.productPrice > 0));
                }
                else if(postKind === 'يقبل مزايدات بدون سعر شراء فوري'){
                  setQu(query(collection(db, "Posts"),  where("acceptsOffers", "==", true), where("productPrice", "==", 0), where("subCategory", "==", subCategorySearch)))
                  // setListing(initialListing.filter(post => post.condition === "جديد").filter(post => post.acceptsOffers === true).filter(post => post.productPrice === 0));
                }
              }
              // only order 
              else if(orderSelectedName !== "" && postKind === ""){
                console.log("c")
                if(orderSelectedName === "سعر الشراء تنازليا" || orderSelectedName === "سعر الشراء تصاعديا"){
                  setQu(query(collection(db, "Posts"),  where("productPrice", ">", 0), where("subCategory", "==", subCategorySearch)))
                }
                else if(orderSelectedName === "سعر المزايدة تصاعديا" || orderSelectedName === "سعر المزايدة تنازليا"){
                  setQu(query(collection(db, "Posts"),  where("acceptsOffers", "==", true), where("subCategory", "==", subCategorySearch)))
                }
              }
              // both selected
              else if(postKind !== "" && orderSelectedName !== ""){
                console.log("d")
                if(postKind === "يقبل مزايدات"){
                  if(orderSelectedName === "سعر الشراء تنازليا" || orderSelectedName === "سعر الشراء تصاعديا"){
                    setQu(query(collection(db, "Posts"),  where("acceptsOffers", "==", true), where("productPrice", ">", 0), where("subCategory", "==", subCategorySearch)))
                  }
                  if(orderSelectedName === "سعر المزايدة تنازليا" || orderSelectedName === "سعر المزايدة تصاعديا"){
                    setQu(query(collection(db, "Posts"),  where("acceptsOffers", "==", true), where("subCategory", "==", subCategorySearch)))
                  }
                }
                else if(postKind === "لا يقبل مزايدات"){
                  if(orderSelectedName === "سعر الشراء تنازليا" || orderSelectedName === "سعر الشراء تصاعديا"){
                    setQu(query(collection(db, "Posts"),  where("acceptsOffers", "==", false), where("subCategory", "==", subCategorySearch)))
                  }
                }
                else if(postKind === "يقبل مزايدات مع سعر شراء فوري"){
                  if(orderSelectedName === "سعر الشراء تنازليا" || orderSelectedName === "سعر الشراء تصاعديا"){
                    setQu(query(collection(db, "Posts"),  where("acceptsOffers", "==", true), where("productPrice", ">", 0), where("subCategory", "==", subCategorySearch)))
                  }
                  else if(orderSelectedName === "سعر المزايدة تنازليا" || orderSelectedName === "سعر المزايدة تصاعديا"){
                    setQu(query(collection(db, "Posts"),  where("acceptsOffers", "==", true), where("productPrice", ">", 0), where("subCategory", "==", subCategorySearch)))
                  }
                }
                else if(postKind === "يقبل مزايدات بدون سعر شراء فوري"){
                  if(orderSelectedName === "سعر المزايدة تنازليا" || orderSelectedName === "سعر المزايدة تصاعديا"){
                    setQu(query(collection(db, "Posts"),  where("acceptsOffers", "==", true), where("productPrice", "==", 0), where("subCategory", "==", subCategorySearch)))
                  }
                }
              }
      
              else{
                setQu(query(collection(db, "Posts"),  where("subCategory", "==", subCategorySearch)))
                // setListing(Listing.filter(post => post.condition === "جديد"));
              }
            }
          }
          else{
            setQu(
              query(
                collection(db, "Posts"),
                where("keywords", "array-contains-any", res.split(" "))
              )
            );
          }
          }
            }} id='filter-buttons'> مستعمل {conditionSelected === 'مستعمل' ? <FaCheck /> : ""}</li>



















          <li 
          style={{
            fontSize: "14px"
          }}
          onClick={()=>{
            if(conditionSelected !== 'ممتازة'){
              // setListing(initialListing);
              setConditionSelected("ممتازة")
              if(res === null){
              if(categorySearch !== null){
                // only kind 
                if(postKind !== "" && orderSelectedName === ""){
                console.log("b")
                if(postKind === 'يقبل مزايدات'){
                  console.log("here")
                  setQu(query(collection(db, "Posts"), where("condition", "==", "ممتازة"), where("acceptsOffers", "==", true), where("category", "==", categorySearch)))
                  // setListing(initialListing.filter(post => post.condition === "ممتازة").filter(post => post.acceptsOffers === true));
                }
                else if(postKind === 'لا يقبل مزايدات'){
                  setQu(query(collection(db, "Posts"), where("condition", "==", "ممتازة"), where("acceptsOffers", "==", false), where("category", "==", categorySearch)))
                  // setListing(initialListing.filter(post => post.condition === "ممتازة").filter(post => post.acceptsOffers === false));
                }
                else if(postKind === 'يقبل مزايدات مع سعر شراء فوري'){
                  setQu(query(collection(db, "Posts"), where("condition", "==", "ممتازة"), where("acceptsOffers", "==", true), where("productPrice", ">", 0), where("category", "==", categorySearch)))
                  // setListing(initialListing.filter(post => post.condition === "ممتازة").filter(post => post.acceptsOffers === true).filter(post => post.productPrice > 0));
                }
                else if(postKind === 'يقبل مزايدات بدون سعر شراء فوري'){
                  setQu(query(collection(db, "Posts"), where("condition", "==", "ممتازة"), where("acceptsOffers", "==", true), where("productPrice", "==", 0), where("category", "==", categorySearch)))
                  // setListing(initialListing.filter(post => post.condition === "ممتازة").filter(post => post.acceptsOffers === true).filter(post => post.productPrice === 0));
                }
              }
              // only order 
              else if(orderSelectedName !== "" && postKind === ""){
                console.log("c")
                if(orderSelectedName === "سعر الشراء تنازليا" || orderSelectedName === "سعر الشراء تصاعديا"){
                  setQu(query(collection(db, "Posts"), where("condition", "==", "ممتازة"), where("productPrice", ">", 0), where("category", "==", categorySearch)))
                }
                else if(orderSelectedName === "سعر المزايدة تصاعديا" || orderSelectedName === "سعر المزايدة تنازليا"){
                  setQu(query(collection(db, "Posts"), where("condition", "==", "ممتازة"), where("acceptsOffers", "==", true), where("category", "==", categorySearch)))
                }
              }
              // both selected
              else if(postKind !== "" && orderSelectedName !== ""){
                console.log("d")
                if(postKind === "يقبل مزايدات"){
                  if(orderSelectedName === "سعر الشراء تنازليا" || orderSelectedName === "سعر الشراء تصاعديا"){
                    setQu(query(collection(db, "Posts"), where("condition", "==", "ممتازة"), where("acceptsOffers", "==", true), where("productPrice", ">", 0), where("category", "==", categorySearch)))
                  }
                  if(orderSelectedName === "سعر المزايدة تنازليا" || orderSelectedName === "سعر المزايدة تصاعديا"){
                    setQu(query(collection(db, "Posts"), where("condition", "==", "ممتازة"), where("acceptsOffers", "==", true), where("category", "==", categorySearch)))
                  }
                }
                else if(postKind === "لا يقبل مزايدات"){
                  if(orderSelectedName === "سعر الشراء تنازليا" || orderSelectedName === "سعر الشراء تصاعديا"){
                    setQu(query(collection(db, "Posts"), where("condition", "==", "ممتازة"), where("acceptsOffers", "==", false), where("category", "==", categorySearch)))
                  }
                }
                else if(postKind === "يقبل مزايدات مع سعر شراء فوري"){
                  if(orderSelectedName === "سعر الشراء تنازليا" || orderSelectedName === "سعر الشراء تصاعديا"){
                    setQu(query(collection(db, "Posts"), where("condition", "==", "ممتازة"), where("acceptsOffers", "==", true), where("productPrice", ">", 0), where("category", "==", categorySearch)))
                  }
                  else if(orderSelectedName === "سعر المزايدة تنازليا" || orderSelectedName === "سعر المزايدة تصاعديا"){
                    setQu(query(collection(db, "Posts"), where("condition", "==", "ممتازة"), where("acceptsOffers", "==", true), where("productPrice", ">", 0), where("category", "==", categorySearch)))
                  }
                }
                else if(postKind === "يقبل مزايدات بدون سعر شراء فوري"){
                  if(orderSelectedName === "سعر المزايدة تنازليا" || orderSelectedName === "سعر المزايدة تصاعديا"){
                    setQu(query(collection(db, "Posts"), where("condition", "==", "ممتازة"), where("acceptsOffers", "==", true), where("productPrice", "==", 0), where("category", "==", categorySearch)))
                  }
                }
              }
      
              else{
                setQu(query(collection(db, "Posts"), where("condition", "==", "ممتازة"), where("category", "==", categorySearch)))
                // setListing(Listing.filter(post => post.condition === "ممتازة"));
              }
              }
              else if(subCategorySearch !== null){
                // only kind 
                if(postKind !== "" && orderSelectedName === ""){
                  console.log("b")
                  if(postKind === 'يقبل مزايدات'){
                    console.log("here")
                    setQu(query(collection(db, "Posts"), where("condition", "==", "ممتازة"), where("acceptsOffers", "==", true), where("subCategory", "==", subCategorySearch)))
                    // setListing(initialListing.filter(post => post.condition === "ممتازة").filter(post => post.acceptsOffers === true));
                  }
                  else if(postKind === 'لا يقبل مزايدات'){
                    setQu(query(collection(db, "Posts"), where("condition", "==", "ممتازة"), where("acceptsOffers", "==", false), where("subCategory", "==", subCategorySearch)))
                    // setListing(initialListing.filter(post => post.condition === "ممتازة").filter(post => post.acceptsOffers === false));
                  }
                  else if(postKind === 'يقبل مزايدات مع سعر شراء فوري'){
                    setQu(query(collection(db, "Posts"), where("condition", "==", "ممتازة"), where("acceptsOffers", "==", true), where("productPrice", ">", 0), where("subCategory", "==", subCategorySearch)))
                    // setListing(initialListing.filter(post => post.condition === "ممتازة").filter(post => post.acceptsOffers === true).filter(post => post.productPrice > 0));
                  }
                  else if(postKind === 'يقبل مزايدات بدون سعر شراء فوري'){
                    setQu(query(collection(db, "Posts"), where("condition", "==", "ممتازة"), where("acceptsOffers", "==", true), where("productPrice", "==", 0), where("subCategory", "==", subCategorySearch)))
                    // setListing(initialListing.filter(post => post.condition === "ممتازة").filter(post => post.acceptsOffers === true).filter(post => post.productPrice === 0));
                  }
                }
                // only order 
                else if(orderSelectedName !== "" && postKind === ""){
                  console.log("c")
                  if(orderSelectedName === "سعر الشراء تنازليا" || orderSelectedName === "سعر الشراء تصاعديا"){
                    setQu(query(collection(db, "Posts"), where("condition", "==", "ممتازة"), where("productPrice", ">", 0), where("subCategory", "==", subCategorySearch)))
                  }
                  else if(orderSelectedName === "سعر المزايدة تصاعديا" || orderSelectedName === "سعر المزايدة تنازليا"){
                    setQu(query(collection(db, "Posts"), where("condition", "==", "ممتازة"), where("acceptsOffers", "==", true), where("subCategory", "==", subCategorySearch)))
                  }
                }
                // both selected
                else if(postKind !== "" && orderSelectedName !== ""){
                  console.log("d")
                  if(postKind === "يقبل مزايدات"){
                    if(orderSelectedName === "سعر الشراء تنازليا" || orderSelectedName === "سعر الشراء تصاعديا"){
                      setQu(query(collection(db, "Posts"), where("condition", "==", "ممتازة"), where("acceptsOffers", "==", true), where("productPrice", ">", 0), where("subCategory", "==", subCategorySearch)))
                    }
                    if(orderSelectedName === "سعر المزايدة تنازليا" || orderSelectedName === "سعر المزايدة تصاعديا"){
                      setQu(query(collection(db, "Posts"), where("condition", "==", "ممتازة"), where("acceptsOffers", "==", true), where("subCategory", "==", subCategorySearch)))
                    }
                  }
                  else if(postKind === "لا يقبل مزايدات"){
                    if(orderSelectedName === "سعر الشراء تنازليا" || orderSelectedName === "سعر الشراء تصاعديا"){
                      setQu(query(collection(db, "Posts"), where("condition", "==", "ممتازة"), where("acceptsOffers", "==", false), where("subCategory", "==", subCategorySearch)))
                    }
                  }
                  else if(postKind === "يقبل مزايدات مع سعر شراء فوري"){
                    if(orderSelectedName === "سعر الشراء تنازليا" || orderSelectedName === "سعر الشراء تصاعديا"){
                      setQu(query(collection(db, "Posts"), where("condition", "==", "ممتازة"), where("acceptsOffers", "==", true), where("productPrice", ">", 0), where("subCategory", "==", subCategorySearch)))
                    }
                    else if(orderSelectedName === "سعر المزايدة تنازليا" || orderSelectedName === "سعر المزايدة تصاعديا"){
                      setQu(query(collection(db, "Posts"), where("condition", "==", "ممتازة"), where("acceptsOffers", "==", true), where("productPrice", ">", 0), where("subCategory", "==", subCategorySearch)))
                    }
                  }
                  else if(postKind === "يقبل مزايدات بدون سعر شراء فوري"){
                    if(orderSelectedName === "سعر المزايدة تنازليا" || orderSelectedName === "سعر المزايدة تصاعديا"){
                      setQu(query(collection(db, "Posts"), where("condition", "==", "ممتازة"), where("acceptsOffers", "==", true), where("productPrice", "==", 0), where("subCategory", "==", subCategorySearch)))
                    }
                  }
                }
        
                else{
                  setQu(query(collection(db, "Posts"), where("condition", "==", "ممتازة"), where("subCategory", "==", subCategorySearch)))
                  // setListing(Listing.filter(post => post.condition === "ممتازة"));
                }
              }
            }
            else{
              console.log("here")
              console.log(res)
              console.log(res.split(" "))
              setQu(
                query(
                  collection(db, "Posts"),
                  where("condition", "==", "ممتازة"),
                  where("keywords", "array-contains-any", res.split(" "))
                )
              );
            }
      
            }
            else {
              setConditionSelected("");
              // setListing(initialListing);
              if(res === null){
      
              
              if(categorySearch !== null){
                // only kind 
                if(postKind !== "" && orderSelectedName === ""){
                console.log("b")
                if(postKind === 'يقبل مزايدات'){
                  console.log("here")
                  setQu(query(collection(db, "Posts"),  where("acceptsOffers", "==", true), where("category", "==", categorySearch)))
                  // setListing(initialListing.filter(post => post.condition === "جديد").filter(post => post.acceptsOffers === true));
                }
                else if(postKind === 'لا يقبل مزايدات'){
                  setQu(query(collection(db, "Posts"),  where("acceptsOffers", "==", false), where("category", "==", categorySearch)))
                  // setListing(initialListing.filter(post => post.condition === "جديد").filter(post => post.acceptsOffers === false));
                }
                else if(postKind === 'يقبل مزايدات مع سعر شراء فوري'){
                  setQu(query(collection(db, "Posts"),  where("acceptsOffers", "==", true), where("productPrice", ">", 0), where("category", "==", categorySearch)))
                  // setListing(initialListing.filter(post => post.condition === "جديد").filter(post => post.acceptsOffers === true).filter(post => post.productPrice > 0));
                }
                else if(postKind === 'يقبل مزايدات بدون سعر شراء فوري'){
                  setQu(query(collection(db, "Posts"),  where("acceptsOffers", "==", true), where("productPrice", "==", 0), where("category", "==", categorySearch)))
                  // setListing(initialListing.filter(post => post.condition === "جديد").filter(post => post.acceptsOffers === true).filter(post => post.productPrice === 0));
                }
              }
              // only order 
              else if(orderSelectedName !== "" && postKind === ""){
                console.log("c")
                if(orderSelectedName === "سعر الشراء تنازليا" || orderSelectedName === "سعر الشراء تصاعديا"){
                  setQu(query(collection(db, "Posts"),  where("productPrice", ">", 0), where("category", "==", categorySearch)))
                }
                else if(orderSelectedName === "سعر المزايدة تصاعديا" || orderSelectedName === "سعر المزايدة تنازليا"){
                  setQu(query(collection(db, "Posts"),  where("acceptsOffers", "==", true), where("category", "==", categorySearch)))
                }
              }
              // both selected
              else if(postKind !== "" && orderSelectedName !== ""){
                console.log("d")
                if(postKind === "يقبل مزايدات"){
                  if(orderSelectedName === "سعر الشراء تنازليا" || orderSelectedName === "سعر الشراء تصاعديا"){
                    setQu(query(collection(db, "Posts"),  where("acceptsOffers", "==", true), where("productPrice", ">", 0), where("category", "==", categorySearch)))
                  }
                  if(orderSelectedName === "سعر المزايدة تنازليا" || orderSelectedName === "سعر المزايدة تصاعديا"){
                    setQu(query(collection(db, "Posts"),  where("acceptsOffers", "==", true), where("category", "==", categorySearch)))
                  }
                }
                else if(postKind === "لا يقبل مزايدات"){
                  if(orderSelectedName === "سعر الشراء تنازليا" || orderSelectedName === "سعر الشراء تصاعديا"){
                    setQu(query(collection(db, "Posts"),  where("acceptsOffers", "==", false), where("category", "==", categorySearch)))
                  }
                }
                else if(postKind === "يقبل مزايدات مع سعر شراء فوري"){
                  if(orderSelectedName === "سعر الشراء تنازليا" || orderSelectedName === "سعر الشراء تصاعديا"){
                    setQu(query(collection(db, "Posts"),  where("acceptsOffers", "==", true), where("productPrice", ">", 0), where("category", "==", categorySearch)))
                  }
                  else if(orderSelectedName === "سعر المزايدة تنازليا" || orderSelectedName === "سعر المزايدة تصاعديا"){
                    setQu(query(collection(db, "Posts"),  where("acceptsOffers", "==", true), where("productPrice", ">", 0), where("category", "==", categorySearch)))
                  }
                }
                else if(postKind === "يقبل مزايدات بدون سعر شراء فوري"){
                  if(orderSelectedName === "سعر المزايدة تنازليا" || orderSelectedName === "سعر المزايدة تصاعديا"){
                    setQu(query(collection(db, "Posts"),  where("acceptsOffers", "==", true), where("productPrice", "==", 0), where("category", "==", categorySearch)))
                  }
                }
              }
      
              else{
                setQu(query(collection(db, "Posts"),  where("category", "==", categorySearch)))
                // setListing(Listing.filter(post => post.condition === "جديد"));
              }
              }
              else if(subCategorySearch !== null){
                // only kind 
                if(postKind !== "" && orderSelectedName === ""){
                  console.log("b")
                  if(postKind === 'يقبل مزايدات'){
                    console.log("here")
                    setQu(query(collection(db, "Posts"),  where("acceptsOffers", "==", true), where("subCategory", "==", subCategorySearch)))
                    // setListing(initialListing.filter(post => post.condition === "جديد").filter(post => post.acceptsOffers === true));
                  }
                  else if(postKind === 'لا يقبل مزايدات'){
                    setQu(query(collection(db, "Posts"),  where("acceptsOffers", "==", false), where("subCategory", "==", subCategorySearch)))
                    // setListing(initialListing.filter(post => post.condition === "جديد").filter(post => post.acceptsOffers === false));
                  }
                  else if(postKind === 'يقبل مزايدات مع سعر شراء فوري'){
                    setQu(query(collection(db, "Posts"),  where("acceptsOffers", "==", true), where("productPrice", ">", 0), where("subCategory", "==", subCategorySearch)))
                    // setListing(initialListing.filter(post => post.condition === "جديد").filter(post => post.acceptsOffers === true).filter(post => post.productPrice > 0));
                  }
                  else if(postKind === 'يقبل مزايدات بدون سعر شراء فوري'){
                    setQu(query(collection(db, "Posts"),  where("acceptsOffers", "==", true), where("productPrice", "==", 0), where("subCategory", "==", subCategorySearch)))
                    // setListing(initialListing.filter(post => post.condition === "جديد").filter(post => post.acceptsOffers === true).filter(post => post.productPrice === 0));
                  }
                }
                // only order 
                else if(orderSelectedName !== "" && postKind === ""){
                  console.log("c")
                  if(orderSelectedName === "سعر الشراء تنازليا" || orderSelectedName === "سعر الشراء تصاعديا"){
                    setQu(query(collection(db, "Posts"),  where("productPrice", ">", 0), where("subCategory", "==", subCategorySearch)))
                  }
                  else if(orderSelectedName === "سعر المزايدة تصاعديا" || orderSelectedName === "سعر المزايدة تنازليا"){
                    setQu(query(collection(db, "Posts"),  where("acceptsOffers", "==", true), where("subCategory", "==", subCategorySearch)))
                  }
                }
                // both selected
                else if(postKind !== "" && orderSelectedName !== ""){
                  console.log("d")
                  if(postKind === "يقبل مزايدات"){
                    if(orderSelectedName === "سعر الشراء تنازليا" || orderSelectedName === "سعر الشراء تصاعديا"){
                      setQu(query(collection(db, "Posts"),  where("acceptsOffers", "==", true), where("productPrice", ">", 0), where("subCategory", "==", subCategorySearch)))
                    }
                    if(orderSelectedName === "سعر المزايدة تنازليا" || orderSelectedName === "سعر المزايدة تصاعديا"){
                      setQu(query(collection(db, "Posts"),  where("acceptsOffers", "==", true), where("subCategory", "==", subCategorySearch)))
                    }
                  }
                  else if(postKind === "لا يقبل مزايدات"){
                    if(orderSelectedName === "سعر الشراء تنازليا" || orderSelectedName === "سعر الشراء تصاعديا"){
                      setQu(query(collection(db, "Posts"),  where("acceptsOffers", "==", false), where("subCategory", "==", subCategorySearch)))
                    }
                  }
                  else if(postKind === "يقبل مزايدات مع سعر شراء فوري"){
                    if(orderSelectedName === "سعر الشراء تنازليا" || orderSelectedName === "سعر الشراء تصاعديا"){
                      setQu(query(collection(db, "Posts"),  where("acceptsOffers", "==", true), where("productPrice", ">", 0), where("subCategory", "==", subCategorySearch)))
                    }
                    else if(orderSelectedName === "سعر المزايدة تنازليا" || orderSelectedName === "سعر المزايدة تصاعديا"){
                      setQu(query(collection(db, "Posts"),  where("acceptsOffers", "==", true), where("productPrice", ">", 0), where("subCategory", "==", subCategorySearch)))
                    }
                  }
                  else if(postKind === "يقبل مزايدات بدون سعر شراء فوري"){
                    if(orderSelectedName === "سعر المزايدة تنازليا" || orderSelectedName === "سعر المزايدة تصاعديا"){
                      setQu(query(collection(db, "Posts"),  where("acceptsOffers", "==", true), where("productPrice", "==", 0), where("subCategory", "==", subCategorySearch)))
                    }
                  }
                }
        
                else{
                  setQu(query(collection(db, "Posts"),  where("subCategory", "==", subCategorySearch)))
                  // setListing(Listing.filter(post => post.condition === "جديد"));
                }
              }
            }
            else{
              console.log("here")
              console.log(res)
              console.log(res.split(" "))
              setQu(
                query(
                  collection(db, "Posts"),
                  where("keywords", "array-contains-any", res.split(" "))
                )
              );
            }
            }
            }} id='filter-buttons'> ممتازة {conditionSelected === 'ممتازة' ? <FaCheck /> : ""}</li>









          <li 
          style={{
            fontSize: "14px"
          }}
          onClick={()=>{
             if(conditionSelected !== 'جيدة جدا'){
              // setListing(initialListing);
              setConditionSelected("جيدة جدا")
              if(res === null){
              if(categorySearch !== null){
                // only kind 
                if(postKind !== "" && orderSelectedName === ""){
                console.log("b")
                if(postKind === 'يقبل مزايدات'){
                  console.log("here")
                  setQu(query(collection(db, "Posts"), where("condition", "==", "جيدة جدا"), where("acceptsOffers", "==", true), where("category", "==", categorySearch)))
                  // setListing(initialListing.filter(post => post.condition === "جيدة جدا").filter(post => post.acceptsOffers === true));
                }
                else if(postKind === 'لا يقبل مزايدات'){
                  setQu(query(collection(db, "Posts"), where("condition", "==", "جيدة جدا"), where("acceptsOffers", "==", false), where("category", "==", categorySearch)))
                  // setListing(initialListing.filter(post => post.condition === "جيدة جدا").filter(post => post.acceptsOffers === false));
                }
                else if(postKind === 'يقبل مزايدات مع سعر شراء فوري'){
                  setQu(query(collection(db, "Posts"), where("condition", "==", "جيدة جدا"), where("acceptsOffers", "==", true), where("productPrice", ">", 0), where("category", "==", categorySearch)))
                  // setListing(initialListing.filter(post => post.condition === "جيدة جدا").filter(post => post.acceptsOffers === true).filter(post => post.productPrice > 0));
                }
                else if(postKind === 'يقبل مزايدات بدون سعر شراء فوري'){
                  setQu(query(collection(db, "Posts"), where("condition", "==", "جيدة جدا"), where("acceptsOffers", "==", true), where("productPrice", "==", 0), where("category", "==", categorySearch)))
                  // setListing(initialListing.filter(post => post.condition === "جيدة جدا").filter(post => post.acceptsOffers === true).filter(post => post.productPrice === 0));
                }
              }
              // only order 
              else if(orderSelectedName !== "" && postKind === ""){
                console.log("c")
                if(orderSelectedName === "سعر الشراء تنازليا" || orderSelectedName === "سعر الشراء تصاعديا"){
                  setQu(query(collection(db, "Posts"), where("condition", "==", "جيدة جدا"), where("productPrice", ">", 0), where("category", "==", categorySearch)))
                }
                else if(orderSelectedName === "سعر المزايدة تصاعديا" || orderSelectedName === "سعر المزايدة تنازليا"){
                  setQu(query(collection(db, "Posts"), where("condition", "==", "جيدة جدا"), where("acceptsOffers", "==", true), where("category", "==", categorySearch)))
                }
              }
              // both selected
              else if(postKind !== "" && orderSelectedName !== ""){
                console.log("d")
                if(postKind === "يقبل مزايدات"){
                  if(orderSelectedName === "سعر الشراء تنازليا" || orderSelectedName === "سعر الشراء تصاعديا"){
                    setQu(query(collection(db, "Posts"), where("condition", "==", "جيدة جدا"), where("acceptsOffers", "==", true), where("productPrice", ">", 0), where("category", "==", categorySearch)))
                  }
                  if(orderSelectedName === "سعر المزايدة تنازليا" || orderSelectedName === "سعر المزايدة تصاعديا"){
                    setQu(query(collection(db, "Posts"), where("condition", "==", "جيدة جدا"), where("acceptsOffers", "==", true), where("category", "==", categorySearch)))
                  }
                }
                else if(postKind === "لا يقبل مزايدات"){
                  if(orderSelectedName === "سعر الشراء تنازليا" || orderSelectedName === "سعر الشراء تصاعديا"){
                    setQu(query(collection(db, "Posts"), where("condition", "==", "جيدة جدا"), where("acceptsOffers", "==", false), where("category", "==", categorySearch)))
                  }
                }
                else if(postKind === "يقبل مزايدات مع سعر شراء فوري"){
                  if(orderSelectedName === "سعر الشراء تنازليا" || orderSelectedName === "سعر الشراء تصاعديا"){
                    setQu(query(collection(db, "Posts"), where("condition", "==", "جيدة جدا"), where("acceptsOffers", "==", true), where("productPrice", ">", 0), where("category", "==", categorySearch)))
                  }
                  else if(orderSelectedName === "سعر المزايدة تنازليا" || orderSelectedName === "سعر المزايدة تصاعديا"){
                    setQu(query(collection(db, "Posts"), where("condition", "==", "جيدة جدا"), where("acceptsOffers", "==", true), where("productPrice", ">", 0), where("category", "==", categorySearch)))
                  }
                }
                else if(postKind === "يقبل مزايدات بدون سعر شراء فوري"){
                  if(orderSelectedName === "سعر المزايدة تنازليا" || orderSelectedName === "سعر المزايدة تصاعديا"){
                    setQu(query(collection(db, "Posts"), where("condition", "==", "جيدة جدا"), where("acceptsOffers", "==", true), where("productPrice", "==", 0), where("category", "==", categorySearch)))
                  }
                }
              }
      
              else{
                setQu(query(collection(db, "Posts"), where("condition", "==", "جيدة جدا"), where("category", "==", categorySearch)))
                // setListing(Listing.filter(post => post.condition === "جيدة جدا"));
              }
              }
              else if(subCategorySearch !== null){
                // only kind 
                if(postKind !== "" && orderSelectedName === ""){
                  console.log("b")
                  if(postKind === 'يقبل مزايدات'){
                    console.log("here")
                    setQu(query(collection(db, "Posts"), where("condition", "==", "جيدة جدا"), where("acceptsOffers", "==", true), where("subCategory", "==", subCategorySearch)))
                    // setListing(initialListing.filter(post => post.condition === "جيدة جدا").filter(post => post.acceptsOffers === true));
                  }
                  else if(postKind === 'لا يقبل مزايدات'){
                    setQu(query(collection(db, "Posts"), where("condition", "==", "جيدة جدا"), where("acceptsOffers", "==", false), where("subCategory", "==", subCategorySearch)))
                    // setListing(initialListing.filter(post => post.condition === "جيدة جدا").filter(post => post.acceptsOffers === false));
                  }
                  else if(postKind === 'يقبل مزايدات مع سعر شراء فوري'){
                    setQu(query(collection(db, "Posts"), where("condition", "==", "جيدة جدا"), where("acceptsOffers", "==", true), where("productPrice", ">", 0), where("subCategory", "==", subCategorySearch)))
                    // setListing(initialListing.filter(post => post.condition === "جيدة جدا").filter(post => post.acceptsOffers === true).filter(post => post.productPrice > 0));
                  }
                  else if(postKind === 'يقبل مزايدات بدون سعر شراء فوري'){
                    setQu(query(collection(db, "Posts"), where("condition", "==", "جيدة جدا"), where("acceptsOffers", "==", true), where("productPrice", "==", 0), where("subCategory", "==", subCategorySearch)))
                    // setListing(initialListing.filter(post => post.condition === "جيدة جدا").filter(post => post.acceptsOffers === true).filter(post => post.productPrice === 0));
                  }
                }
                // only order 
                else if(orderSelectedName !== "" && postKind === ""){
                  console.log("c")
                  if(orderSelectedName === "سعر الشراء تنازليا" || orderSelectedName === "سعر الشراء تصاعديا"){
                    setQu(query(collection(db, "Posts"), where("condition", "==", "جيدة جدا"), where("productPrice", ">", 0), where("subCategory", "==", subCategorySearch)))
                  }
                  else if(orderSelectedName === "سعر المزايدة تصاعديا" || orderSelectedName === "سعر المزايدة تنازليا"){
                    setQu(query(collection(db, "Posts"), where("condition", "==", "جيدة جدا"), where("acceptsOffers", "==", true), where("subCategory", "==", subCategorySearch)))
                  }
                }
                // both selected
                else if(postKind !== "" && orderSelectedName !== ""){
                  console.log("d")
                  if(postKind === "يقبل مزايدات"){
                    if(orderSelectedName === "سعر الشراء تنازليا" || orderSelectedName === "سعر الشراء تصاعديا"){
                      setQu(query(collection(db, "Posts"), where("condition", "==", "جيدة جدا"), where("acceptsOffers", "==", true), where("productPrice", ">", 0), where("subCategory", "==", subCategorySearch)))
                    }
                    if(orderSelectedName === "سعر المزايدة تنازليا" || orderSelectedName === "سعر المزايدة تصاعديا"){
                      setQu(query(collection(db, "Posts"), where("condition", "==", "جيدة جدا"), where("acceptsOffers", "==", true), where("subCategory", "==", subCategorySearch)))
                    }
                  }
                  else if(postKind === "لا يقبل مزايدات"){
                    if(orderSelectedName === "سعر الشراء تنازليا" || orderSelectedName === "سعر الشراء تصاعديا"){
                      setQu(query(collection(db, "Posts"), where("condition", "==", "جيدة جدا"), where("acceptsOffers", "==", false), where("subCategory", "==", subCategorySearch)))
                    }
                  }
                  else if(postKind === "يقبل مزايدات مع سعر شراء فوري"){
                    if(orderSelectedName === "سعر الشراء تنازليا" || orderSelectedName === "سعر الشراء تصاعديا"){
                      setQu(query(collection(db, "Posts"), where("condition", "==", "جيدة جدا"), where("acceptsOffers", "==", true), where("productPrice", ">", 0), where("subCategory", "==", subCategorySearch)))
                    }
                    else if(orderSelectedName === "سعر المزايدة تنازليا" || orderSelectedName === "سعر المزايدة تصاعديا"){
                      setQu(query(collection(db, "Posts"), where("condition", "==", "جيدة جدا"), where("acceptsOffers", "==", true), where("productPrice", ">", 0), where("subCategory", "==", subCategorySearch)))
                    }
                  }
                  else if(postKind === "يقبل مزايدات بدون سعر شراء فوري"){
                    if(orderSelectedName === "سعر المزايدة تنازليا" || orderSelectedName === "سعر المزايدة تصاعديا"){
                      setQu(query(collection(db, "Posts"), where("condition", "==", "جيدة جدا"), where("acceptsOffers", "==", true), where("productPrice", "==", 0), where("subCategory", "==", subCategorySearch)))
                    }
                  }
                }
        
                else{
                  setQu(query(collection(db, "Posts"), where("condition", "==", "جيدة جدا"), where("subCategory", "==", subCategorySearch)))
                  // setListing(Listing.filter(post => post.condition === "جيدة جدا"));
                }
              }
            }
            else{
              console.log("here")
              console.log(res)
              console.log(res.split(" "))
              setQu(
                query(
                  collection(db, "Posts"),
                  where("condition", "==", "جيدة جدا"),
                  where("keywords", "array-contains-any", res.split(" "))
                )
              );
            }
      
            }
            else {
              setConditionSelected("");
              // setListing(initialListing);
              if(res === null){
      
              
              if(categorySearch !== null){
                // only kind 
                if(postKind !== "" && orderSelectedName === ""){
                console.log("b")
                if(postKind === 'يقبل مزايدات'){
                  console.log("here")
                  setQu(query(collection(db, "Posts"),  where("acceptsOffers", "==", true), where("category", "==", categorySearch)))
                  // setListing(initialListing.filter(post => post.condition === "جديد").filter(post => post.acceptsOffers === true));
                }
                else if(postKind === 'لا يقبل مزايدات'){
                  setQu(query(collection(db, "Posts"),  where("acceptsOffers", "==", false), where("category", "==", categorySearch)))
                  // setListing(initialListing.filter(post => post.condition === "جديد").filter(post => post.acceptsOffers === false));
                }
                else if(postKind === 'يقبل مزايدات مع سعر شراء فوري'){
                  setQu(query(collection(db, "Posts"),  where("acceptsOffers", "==", true), where("productPrice", ">", 0), where("category", "==", categorySearch)))
                  // setListing(initialListing.filter(post => post.condition === "جديد").filter(post => post.acceptsOffers === true).filter(post => post.productPrice > 0));
                }
                else if(postKind === 'يقبل مزايدات بدون سعر شراء فوري'){
                  setQu(query(collection(db, "Posts"),  where("acceptsOffers", "==", true), where("productPrice", "==", 0), where("category", "==", categorySearch)))
                  // setListing(initialListing.filter(post => post.condition === "جديد").filter(post => post.acceptsOffers === true).filter(post => post.productPrice === 0));
                }
              }
              // only order 
              else if(orderSelectedName !== "" && postKind === ""){
                console.log("c")
                if(orderSelectedName === "سعر الشراء تنازليا" || orderSelectedName === "سعر الشراء تصاعديا"){
                  setQu(query(collection(db, "Posts"),  where("productPrice", ">", 0), where("category", "==", categorySearch)))
                }
                else if(orderSelectedName === "سعر المزايدة تصاعديا" || orderSelectedName === "سعر المزايدة تنازليا"){
                  setQu(query(collection(db, "Posts"),  where("acceptsOffers", "==", true), where("category", "==", categorySearch)))
                }
              }
              // both selected
              else if(postKind !== "" && orderSelectedName !== ""){
                console.log("d")
                if(postKind === "يقبل مزايدات"){
                  if(orderSelectedName === "سعر الشراء تنازليا" || orderSelectedName === "سعر الشراء تصاعديا"){
                    setQu(query(collection(db, "Posts"),  where("acceptsOffers", "==", true), where("productPrice", ">", 0), where("category", "==", categorySearch)))
                  }
                  if(orderSelectedName === "سعر المزايدة تنازليا" || orderSelectedName === "سعر المزايدة تصاعديا"){
                    setQu(query(collection(db, "Posts"),  where("acceptsOffers", "==", true), where("category", "==", categorySearch)))
                  }
                }
                else if(postKind === "لا يقبل مزايدات"){
                  if(orderSelectedName === "سعر الشراء تنازليا" || orderSelectedName === "سعر الشراء تصاعديا"){
                    setQu(query(collection(db, "Posts"),  where("acceptsOffers", "==", false), where("category", "==", categorySearch)))
                  }
                }
                else if(postKind === "يقبل مزايدات مع سعر شراء فوري"){
                  if(orderSelectedName === "سعر الشراء تنازليا" || orderSelectedName === "سعر الشراء تصاعديا"){
                    setQu(query(collection(db, "Posts"),  where("acceptsOffers", "==", true), where("productPrice", ">", 0), where("category", "==", categorySearch)))
                  }
                  else if(orderSelectedName === "سعر المزايدة تنازليا" || orderSelectedName === "سعر المزايدة تصاعديا"){
                    setQu(query(collection(db, "Posts"),  where("acceptsOffers", "==", true), where("productPrice", ">", 0), where("category", "==", categorySearch)))
                  }
                }
                else if(postKind === "يقبل مزايدات بدون سعر شراء فوري"){
                  if(orderSelectedName === "سعر المزايدة تنازليا" || orderSelectedName === "سعر المزايدة تصاعديا"){
                    setQu(query(collection(db, "Posts"),  where("acceptsOffers", "==", true), where("productPrice", "==", 0), where("category", "==", categorySearch)))
                  }
                }
              }
      
              else{
                setQu(query(collection(db, "Posts"),  where("category", "==", categorySearch)))
                // setListing(Listing.filter(post => post.condition === "جديد"));
              }
              }
              else if(subCategorySearch !== null){
                // only kind 
                if(postKind !== "" && orderSelectedName === ""){
                  console.log("b")
                  if(postKind === 'يقبل مزايدات'){
                    console.log("here")
                    setQu(query(collection(db, "Posts"),  where("acceptsOffers", "==", true), where("subCategory", "==", subCategorySearch)))
                    // setListing(initialListing.filter(post => post.condition === "جديد").filter(post => post.acceptsOffers === true));
                  }
                  else if(postKind === 'لا يقبل مزايدات'){
                    setQu(query(collection(db, "Posts"),  where("acceptsOffers", "==", false), where("subCategory", "==", subCategorySearch)))
                    // setListing(initialListing.filter(post => post.condition === "جديد").filter(post => post.acceptsOffers === false));
                  }
                  else if(postKind === 'يقبل مزايدات مع سعر شراء فوري'){
                    setQu(query(collection(db, "Posts"),  where("acceptsOffers", "==", true), where("productPrice", ">", 0), where("subCategory", "==", subCategorySearch)))
                    // setListing(initialListing.filter(post => post.condition === "جديد").filter(post => post.acceptsOffers === true).filter(post => post.productPrice > 0));
                  }
                  else if(postKind === 'يقبل مزايدات بدون سعر شراء فوري'){
                    setQu(query(collection(db, "Posts"),  where("acceptsOffers", "==", true), where("productPrice", "==", 0), where("subCategory", "==", subCategorySearch)))
                    // setListing(initialListing.filter(post => post.condition === "جديد").filter(post => post.acceptsOffers === true).filter(post => post.productPrice === 0));
                  }
                }
                // only order 
                else if(orderSelectedName !== "" && postKind === ""){
                  console.log("c")
                  if(orderSelectedName === "سعر الشراء تنازليا" || orderSelectedName === "سعر الشراء تصاعديا"){
                    setQu(query(collection(db, "Posts"),  where("productPrice", ">", 0), where("subCategory", "==", subCategorySearch)))
                  }
                  else if(orderSelectedName === "سعر المزايدة تصاعديا" || orderSelectedName === "سعر المزايدة تنازليا"){
                    setQu(query(collection(db, "Posts"),  where("acceptsOffers", "==", true), where("subCategory", "==", subCategorySearch)))
                  }
                }
                // both selected
                else if(postKind !== "" && orderSelectedName !== ""){
                  console.log("d")
                  if(postKind === "يقبل مزايدات"){
                    if(orderSelectedName === "سعر الشراء تنازليا" || orderSelectedName === "سعر الشراء تصاعديا"){
                      setQu(query(collection(db, "Posts"),  where("acceptsOffers", "==", true), where("productPrice", ">", 0), where("subCategory", "==", subCategorySearch)))
                    }
                    if(orderSelectedName === "سعر المزايدة تنازليا" || orderSelectedName === "سعر المزايدة تصاعديا"){
                      setQu(query(collection(db, "Posts"),  where("acceptsOffers", "==", true), where("subCategory", "==", subCategorySearch)))
                    }
                  }
                  else if(postKind === "لا يقبل مزايدات"){
                    if(orderSelectedName === "سعر الشراء تنازليا" || orderSelectedName === "سعر الشراء تصاعديا"){
                      setQu(query(collection(db, "Posts"),  where("acceptsOffers", "==", false), where("subCategory", "==", subCategorySearch)))
                    }
                  }
                  else if(postKind === "يقبل مزايدات مع سعر شراء فوري"){
                    if(orderSelectedName === "سعر الشراء تنازليا" || orderSelectedName === "سعر الشراء تصاعديا"){
                      setQu(query(collection(db, "Posts"),  where("acceptsOffers", "==", true), where("productPrice", ">", 0), where("subCategory", "==", subCategorySearch)))
                    }
                    else if(orderSelectedName === "سعر المزايدة تنازليا" || orderSelectedName === "سعر المزايدة تصاعديا"){
                      setQu(query(collection(db, "Posts"),  where("acceptsOffers", "==", true), where("productPrice", ">", 0), where("subCategory", "==", subCategorySearch)))
                    }
                  }
                  else if(postKind === "يقبل مزايدات بدون سعر شراء فوري"){
                    if(orderSelectedName === "سعر المزايدة تنازليا" || orderSelectedName === "سعر المزايدة تصاعديا"){
                      setQu(query(collection(db, "Posts"),  where("acceptsOffers", "==", true), where("productPrice", "==", 0), where("subCategory", "==", subCategorySearch)))
                    }
                  }
                }
        
                else{
                  setQu(query(collection(db, "Posts"),  where("subCategory", "==", subCategorySearch)))
                  // setListing(Listing.filter(post => post.condition === "جديد"));
                }
              }
            }
            else{
              console.log("here")
              console.log(res)
              console.log(res.split(" "))
              setQu(
                query(
                  collection(db, "Posts"),
                  where("keywords", "array-contains-any", res.split(" "))
                )
              );
            }
            }
            }} id='filter-buttons'> جيدة جدا {conditionSelected === 'جيدة جدا' ? <FaCheck /> : ""}</li>













          <li 
          style={{
            fontSize: "14px"
          }}
          onClick={()=>{
           if(conditionSelected !== 'جيدة'){
            // setListing(initialListing);
            setConditionSelected("جيدة")
            if(res === null){
            if(categorySearch !== null){
              // only kind 
              if(postKind !== "" && orderSelectedName === ""){
              console.log("b")
              if(postKind === 'يقبل مزايدات'){
                console.log("here")
                setQu(query(collection(db, "Posts"), where("condition", "==", "جيدة"), where("acceptsOffers", "==", true), where("category", "==", categorySearch)))
                // setListing(initialListing.filter(post => post.condition === "جيدة").filter(post => post.acceptsOffers === true));
              }
              else if(postKind === 'لا يقبل مزايدات'){
                setQu(query(collection(db, "Posts"), where("condition", "==", "جيدة"), where("acceptsOffers", "==", false), where("category", "==", categorySearch)))
                // setListing(initialListing.filter(post => post.condition === "جيدة").filter(post => post.acceptsOffers === false));
              }
              else if(postKind === 'يقبل مزايدات مع سعر شراء فوري'){
                setQu(query(collection(db, "Posts"), where("condition", "==", "جيدة"), where("acceptsOffers", "==", true), where("productPrice", ">", 0), where("category", "==", categorySearch)))
                // setListing(initialListing.filter(post => post.condition === "جيدة").filter(post => post.acceptsOffers === true).filter(post => post.productPrice > 0));
              }
              else if(postKind === 'يقبل مزايدات بدون سعر شراء فوري'){
                setQu(query(collection(db, "Posts"), where("condition", "==", "جيدة"), where("acceptsOffers", "==", true), where("productPrice", "==", 0), where("category", "==", categorySearch)))
                // setListing(initialListing.filter(post => post.condition === "جيدة").filter(post => post.acceptsOffers === true).filter(post => post.productPrice === 0));
              }
            }
            // only order 
            else if(orderSelectedName !== "" && postKind === ""){
              console.log("c")
              if(orderSelectedName === "سعر الشراء تنازليا" || orderSelectedName === "سعر الشراء تصاعديا"){
                setQu(query(collection(db, "Posts"), where("condition", "==", "جيدة"), where("productPrice", ">", 0), where("category", "==", categorySearch)))
              }
              else if(orderSelectedName === "سعر المزايدة تصاعديا" || orderSelectedName === "سعر المزايدة تنازليا"){
                setQu(query(collection(db, "Posts"), where("condition", "==", "جيدة"), where("acceptsOffers", "==", true), where("category", "==", categorySearch)))
              }
            }
            // both selected
            else if(postKind !== "" && orderSelectedName !== ""){
              console.log("d")
              if(postKind === "يقبل مزايدات"){
                if(orderSelectedName === "سعر الشراء تنازليا" || orderSelectedName === "سعر الشراء تصاعديا"){
                  setQu(query(collection(db, "Posts"), where("condition", "==", "جيدة"), where("acceptsOffers", "==", true), where("productPrice", ">", 0), where("category", "==", categorySearch)))
                }
                if(orderSelectedName === "سعر المزايدة تنازليا" || orderSelectedName === "سعر المزايدة تصاعديا"){
                  setQu(query(collection(db, "Posts"), where("condition", "==", "جيدة"), where("acceptsOffers", "==", true), where("category", "==", categorySearch)))
                }
              }
              else if(postKind === "لا يقبل مزايدات"){
                if(orderSelectedName === "سعر الشراء تنازليا" || orderSelectedName === "سعر الشراء تصاعديا"){
                  setQu(query(collection(db, "Posts"), where("condition", "==", "جيدة"), where("acceptsOffers", "==", false), where("category", "==", categorySearch)))
                }
              }
              else if(postKind === "يقبل مزايدات مع سعر شراء فوري"){
                if(orderSelectedName === "سعر الشراء تنازليا" || orderSelectedName === "سعر الشراء تصاعديا"){
                  setQu(query(collection(db, "Posts"), where("condition", "==", "جيدة"), where("acceptsOffers", "==", true), where("productPrice", ">", 0), where("category", "==", categorySearch)))
                }
                else if(orderSelectedName === "سعر المزايدة تنازليا" || orderSelectedName === "سعر المزايدة تصاعديا"){
                  setQu(query(collection(db, "Posts"), where("condition", "==", "جيدة"), where("acceptsOffers", "==", true), where("productPrice", ">", 0), where("category", "==", categorySearch)))
                }
              }
              else if(postKind === "يقبل مزايدات بدون سعر شراء فوري"){
                if(orderSelectedName === "سعر المزايدة تنازليا" || orderSelectedName === "سعر المزايدة تصاعديا"){
                  setQu(query(collection(db, "Posts"), where("condition", "==", "جيدة"), where("acceptsOffers", "==", true), where("productPrice", "==", 0), where("category", "==", categorySearch)))
                }
              }
            }
    
            else{
              setQu(query(collection(db, "Posts"), where("condition", "==", "جيدة"), where("category", "==", categorySearch)))
              // setListing(Listing.filter(post => post.condition === "جيدة"));
            }
            }
            else if(subCategorySearch !== null){
              // only kind 
              if(postKind !== "" && orderSelectedName === ""){
                console.log("b")
                if(postKind === 'يقبل مزايدات'){
                  console.log("here")
                  setQu(query(collection(db, "Posts"), where("condition", "==", "جيدة"), where("acceptsOffers", "==", true), where("subCategory", "==", subCategorySearch)))
                  // setListing(initialListing.filter(post => post.condition === "جيدة").filter(post => post.acceptsOffers === true));
                }
                else if(postKind === 'لا يقبل مزايدات'){
                  setQu(query(collection(db, "Posts"), where("condition", "==", "جيدة"), where("acceptsOffers", "==", false), where("subCategory", "==", subCategorySearch)))
                  // setListing(initialListing.filter(post => post.condition === "جيدة").filter(post => post.acceptsOffers === false));
                }
                else if(postKind === 'يقبل مزايدات مع سعر شراء فوري'){
                  setQu(query(collection(db, "Posts"), where("condition", "==", "جيدة"), where("acceptsOffers", "==", true), where("productPrice", ">", 0), where("subCategory", "==", subCategorySearch)))
                  // setListing(initialListing.filter(post => post.condition === "جيدة").filter(post => post.acceptsOffers === true).filter(post => post.productPrice > 0));
                }
                else if(postKind === 'يقبل مزايدات بدون سعر شراء فوري'){
                  setQu(query(collection(db, "Posts"), where("condition", "==", "جيدة"), where("acceptsOffers", "==", true), where("productPrice", "==", 0), where("subCategory", "==", subCategorySearch)))
                  // setListing(initialListing.filter(post => post.condition === "جيدة").filter(post => post.acceptsOffers === true).filter(post => post.productPrice === 0));
                }
              }
              // only order 
              else if(orderSelectedName !== "" && postKind === ""){
                console.log("c")
                if(orderSelectedName === "سعر الشراء تنازليا" || orderSelectedName === "سعر الشراء تصاعديا"){
                  setQu(query(collection(db, "Posts"), where("condition", "==", "جيدة"), where("productPrice", ">", 0), where("subCategory", "==", subCategorySearch)))
                }
                else if(orderSelectedName === "سعر المزايدة تصاعديا" || orderSelectedName === "سعر المزايدة تنازليا"){
                  setQu(query(collection(db, "Posts"), where("condition", "==", "جيدة"), where("acceptsOffers", "==", true), where("subCategory", "==", subCategorySearch)))
                }
              }
              // both selected
              else if(postKind !== "" && orderSelectedName !== ""){
                console.log("d")
                if(postKind === "يقبل مزايدات"){
                  if(orderSelectedName === "سعر الشراء تنازليا" || orderSelectedName === "سعر الشراء تصاعديا"){
                    setQu(query(collection(db, "Posts"), where("condition", "==", "جيدة"), where("acceptsOffers", "==", true), where("productPrice", ">", 0), where("subCategory", "==", subCategorySearch)))
                  }
                  if(orderSelectedName === "سعر المزايدة تنازليا" || orderSelectedName === "سعر المزايدة تصاعديا"){
                    setQu(query(collection(db, "Posts"), where("condition", "==", "جيدة"), where("acceptsOffers", "==", true), where("subCategory", "==", subCategorySearch)))
                  }
                }
                else if(postKind === "لا يقبل مزايدات"){
                  if(orderSelectedName === "سعر الشراء تنازليا" || orderSelectedName === "سعر الشراء تصاعديا"){
                    setQu(query(collection(db, "Posts"), where("condition", "==", "جيدة"), where("acceptsOffers", "==", false), where("subCategory", "==", subCategorySearch)))
                  }
                }
                else if(postKind === "يقبل مزايدات مع سعر شراء فوري"){
                  if(orderSelectedName === "سعر الشراء تنازليا" || orderSelectedName === "سعر الشراء تصاعديا"){
                    setQu(query(collection(db, "Posts"), where("condition", "==", "جيدة"), where("acceptsOffers", "==", true), where("productPrice", ">", 0), where("subCategory", "==", subCategorySearch)))
                  }
                  else if(orderSelectedName === "سعر المزايدة تنازليا" || orderSelectedName === "سعر المزايدة تصاعديا"){
                    setQu(query(collection(db, "Posts"), where("condition", "==", "جيدة"), where("acceptsOffers", "==", true), where("productPrice", ">", 0), where("subCategory", "==", subCategorySearch)))
                  }
                }
                else if(postKind === "يقبل مزايدات بدون سعر شراء فوري"){
                  if(orderSelectedName === "سعر المزايدة تنازليا" || orderSelectedName === "سعر المزايدة تصاعديا"){
                    setQu(query(collection(db, "Posts"), where("condition", "==", "جيدة"), where("acceptsOffers", "==", true), where("productPrice", "==", 0), where("subCategory", "==", subCategorySearch)))
                  }
                }
              }
      
              else{
                setQu(query(collection(db, "Posts"), where("condition", "==", "جيدة"), where("subCategory", "==", subCategorySearch)))
                // setListing(Listing.filter(post => post.condition === "جيدة"));
              }
            }
          }
          else{
            console.log("here")
            console.log(res)
            console.log(res.split(" "))
            setQu(
              query(
                collection(db, "Posts"),
                where("condition", "==", "جيدة"),
                where("keywords", "array-contains-any", res.split(" "))
              )
            );
          }
    
          }
          else {
            setConditionSelected("");
            // setListing(initialListing);
            if(res === null){
    
            
            if(categorySearch !== null){
              // only kind 
              if(postKind !== "" && orderSelectedName === ""){
              console.log("b")
              if(postKind === 'يقبل مزايدات'){
                console.log("here")
                setQu(query(collection(db, "Posts"),  where("acceptsOffers", "==", true), where("category", "==", categorySearch)))
                // setListing(initialListing.filter(post => post.condition === "جديد").filter(post => post.acceptsOffers === true));
              }
              else if(postKind === 'لا يقبل مزايدات'){
                setQu(query(collection(db, "Posts"),  where("acceptsOffers", "==", false), where("category", "==", categorySearch)))
                // setListing(initialListing.filter(post => post.condition === "جديد").filter(post => post.acceptsOffers === false));
              }
              else if(postKind === 'يقبل مزايدات مع سعر شراء فوري'){
                setQu(query(collection(db, "Posts"),  where("acceptsOffers", "==", true), where("productPrice", ">", 0), where("category", "==", categorySearch)))
                // setListing(initialListing.filter(post => post.condition === "جديد").filter(post => post.acceptsOffers === true).filter(post => post.productPrice > 0));
              }
              else if(postKind === 'يقبل مزايدات بدون سعر شراء فوري'){
                setQu(query(collection(db, "Posts"),  where("acceptsOffers", "==", true), where("productPrice", "==", 0), where("category", "==", categorySearch)))
                // setListing(initialListing.filter(post => post.condition === "جديد").filter(post => post.acceptsOffers === true).filter(post => post.productPrice === 0));
              }
            }
            // only order 
            else if(orderSelectedName !== "" && postKind === ""){
              console.log("c")
              if(orderSelectedName === "سعر الشراء تنازليا" || orderSelectedName === "سعر الشراء تصاعديا"){
                setQu(query(collection(db, "Posts"),  where("productPrice", ">", 0), where("category", "==", categorySearch)))
              }
              else if(orderSelectedName === "سعر المزايدة تصاعديا" || orderSelectedName === "سعر المزايدة تنازليا"){
                setQu(query(collection(db, "Posts"),  where("acceptsOffers", "==", true), where("category", "==", categorySearch)))
              }
            }
            // both selected
            else if(postKind !== "" && orderSelectedName !== ""){
              console.log("d")
              if(postKind === "يقبل مزايدات"){
                if(orderSelectedName === "سعر الشراء تنازليا" || orderSelectedName === "سعر الشراء تصاعديا"){
                  setQu(query(collection(db, "Posts"),  where("acceptsOffers", "==", true), where("productPrice", ">", 0), where("category", "==", categorySearch)))
                }
                if(orderSelectedName === "سعر المزايدة تنازليا" || orderSelectedName === "سعر المزايدة تصاعديا"){
                  setQu(query(collection(db, "Posts"),  where("acceptsOffers", "==", true), where("category", "==", categorySearch)))
                }
              }
              else if(postKind === "لا يقبل مزايدات"){
                if(orderSelectedName === "سعر الشراء تنازليا" || orderSelectedName === "سعر الشراء تصاعديا"){
                  setQu(query(collection(db, "Posts"),  where("acceptsOffers", "==", false), where("category", "==", categorySearch)))
                }
              }
              else if(postKind === "يقبل مزايدات مع سعر شراء فوري"){
                if(orderSelectedName === "سعر الشراء تنازليا" || orderSelectedName === "سعر الشراء تصاعديا"){
                  setQu(query(collection(db, "Posts"),  where("acceptsOffers", "==", true), where("productPrice", ">", 0), where("category", "==", categorySearch)))
                }
                else if(orderSelectedName === "سعر المزايدة تنازليا" || orderSelectedName === "سعر المزايدة تصاعديا"){
                  setQu(query(collection(db, "Posts"),  where("acceptsOffers", "==", true), where("productPrice", ">", 0), where("category", "==", categorySearch)))
                }
              }
              else if(postKind === "يقبل مزايدات بدون سعر شراء فوري"){
                if(orderSelectedName === "سعر المزايدة تنازليا" || orderSelectedName === "سعر المزايدة تصاعديا"){
                  setQu(query(collection(db, "Posts"),  where("acceptsOffers", "==", true), where("productPrice", "==", 0), where("category", "==", categorySearch)))
                }
              }
            }
    
            else{
              setQu(query(collection(db, "Posts"),  where("category", "==", categorySearch)))
              // setListing(Listing.filter(post => post.condition === "جديد"));
            }
            }
            else if(subCategorySearch !== null){
              // only kind 
              if(postKind !== "" && orderSelectedName === ""){
                console.log("b")
                if(postKind === 'يقبل مزايدات'){
                  console.log("here")
                  setQu(query(collection(db, "Posts"),  where("acceptsOffers", "==", true), where("subCategory", "==", subCategorySearch)))
                  // setListing(initialListing.filter(post => post.condition === "جديد").filter(post => post.acceptsOffers === true));
                }
                else if(postKind === 'لا يقبل مزايدات'){
                  setQu(query(collection(db, "Posts"),  where("acceptsOffers", "==", false), where("subCategory", "==", subCategorySearch)))
                  // setListing(initialListing.filter(post => post.condition === "جديد").filter(post => post.acceptsOffers === false));
                }
                else if(postKind === 'يقبل مزايدات مع سعر شراء فوري'){
                  setQu(query(collection(db, "Posts"),  where("acceptsOffers", "==", true), where("productPrice", ">", 0), where("subCategory", "==", subCategorySearch)))
                  // setListing(initialListing.filter(post => post.condition === "جديد").filter(post => post.acceptsOffers === true).filter(post => post.productPrice > 0));
                }
                else if(postKind === 'يقبل مزايدات بدون سعر شراء فوري'){
                  setQu(query(collection(db, "Posts"),  where("acceptsOffers", "==", true), where("productPrice", "==", 0), where("subCategory", "==", subCategorySearch)))
                  // setListing(initialListing.filter(post => post.condition === "جديد").filter(post => post.acceptsOffers === true).filter(post => post.productPrice === 0));
                }
              }
              // only order 
              else if(orderSelectedName !== "" && postKind === ""){
                console.log("c")
                if(orderSelectedName === "سعر الشراء تنازليا" || orderSelectedName === "سعر الشراء تصاعديا"){
                  setQu(query(collection(db, "Posts"),  where("productPrice", ">", 0), where("subCategory", "==", subCategorySearch)))
                }
                else if(orderSelectedName === "سعر المزايدة تصاعديا" || orderSelectedName === "سعر المزايدة تنازليا"){
                  setQu(query(collection(db, "Posts"),  where("acceptsOffers", "==", true), where("subCategory", "==", subCategorySearch)))
                }
              }
              // both selected
              else if(postKind !== "" && orderSelectedName !== ""){
                console.log("d")
                if(postKind === "يقبل مزايدات"){
                  if(orderSelectedName === "سعر الشراء تنازليا" || orderSelectedName === "سعر الشراء تصاعديا"){
                    setQu(query(collection(db, "Posts"),  where("acceptsOffers", "==", true), where("productPrice", ">", 0), where("subCategory", "==", subCategorySearch)))
                  }
                  if(orderSelectedName === "سعر المزايدة تنازليا" || orderSelectedName === "سعر المزايدة تصاعديا"){
                    setQu(query(collection(db, "Posts"),  where("acceptsOffers", "==", true), where("subCategory", "==", subCategorySearch)))
                  }
                }
                else if(postKind === "لا يقبل مزايدات"){
                  if(orderSelectedName === "سعر الشراء تنازليا" || orderSelectedName === "سعر الشراء تصاعديا"){
                    setQu(query(collection(db, "Posts"),  where("acceptsOffers", "==", false), where("subCategory", "==", subCategorySearch)))
                  }
                }
                else if(postKind === "يقبل مزايدات مع سعر شراء فوري"){
                  if(orderSelectedName === "سعر الشراء تنازليا" || orderSelectedName === "سعر الشراء تصاعديا"){
                    setQu(query(collection(db, "Posts"),  where("acceptsOffers", "==", true), where("productPrice", ">", 0), where("subCategory", "==", subCategorySearch)))
                  }
                  else if(orderSelectedName === "سعر المزايدة تنازليا" || orderSelectedName === "سعر المزايدة تصاعديا"){
                    setQu(query(collection(db, "Posts"),  where("acceptsOffers", "==", true), where("productPrice", ">", 0), where("subCategory", "==", subCategorySearch)))
                  }
                }
                else if(postKind === "يقبل مزايدات بدون سعر شراء فوري"){
                  if(orderSelectedName === "سعر المزايدة تنازليا" || orderSelectedName === "سعر المزايدة تصاعديا"){
                    setQu(query(collection(db, "Posts"),  where("acceptsOffers", "==", true), where("productPrice", "==", 0), where("subCategory", "==", subCategorySearch)))
                  }
                }
              }
      
              else{
                setQu(query(collection(db, "Posts"),  where("subCategory", "==", subCategorySearch)))
                // setListing(Listing.filter(post => post.condition === "جديد"));
              }
            }
          }
          else{
            console.log("here")
            console.log(res)
            console.log(res.split(" "))
            setQu(
              query(
                collection(db, "Posts"),
                where("keywords", "array-contains-any", res.split(" "))
              )
            );
          }
          }
            }} id='filter-buttons'> جيدة {conditionSelected === 'جيدة' ? <FaCheck /> : ""}</li>









          <li 
          style={{
            fontSize: "14px"
          }}
          onClick={()=>{
            if(conditionSelected !== 'مقبولة'){
        // setListing(initialListing);
        setConditionSelected("مقبولة")
        if(res === null){
        if(categorySearch !== null){
          // only kind 
          if(postKind !== "" && orderSelectedName === ""){
          console.log("b")
          if(postKind === 'يقبل مزايدات'){
            console.log("here")
            setQu(query(collection(db, "Posts"), where("condition", "==", "مقبولة"), where("acceptsOffers", "==", true), where("category", "==", categorySearch)))
            // setListing(initialListing.filter(post => post.condition === "مقبولة").filter(post => post.acceptsOffers === true));
          }
          else if(postKind === 'لا يقبل مزايدات'){
            setQu(query(collection(db, "Posts"), where("condition", "==", "مقبولة"), where("acceptsOffers", "==", false), where("category", "==", categorySearch)))
            // setListing(initialListing.filter(post => post.condition === "مقبولة").filter(post => post.acceptsOffers === false));
          }
          else if(postKind === 'يقبل مزايدات مع سعر شراء فوري'){
            setQu(query(collection(db, "Posts"), where("condition", "==", "مقبولة"), where("acceptsOffers", "==", true), where("productPrice", ">", 0), where("category", "==", categorySearch)))
            // setListing(initialListing.filter(post => post.condition === "مقبولة").filter(post => post.acceptsOffers === true).filter(post => post.productPrice > 0));
          }
          else if(postKind === 'يقبل مزايدات بدون سعر شراء فوري'){
            setQu(query(collection(db, "Posts"), where("condition", "==", "مقبولة"), where("acceptsOffers", "==", true), where("productPrice", "==", 0), where("category", "==", categorySearch)))
            // setListing(initialListing.filter(post => post.condition === "مقبولة").filter(post => post.acceptsOffers === true).filter(post => post.productPrice === 0));
          }
        }
        // only order 
        else if(orderSelectedName !== "" && postKind === ""){
          console.log("c")
          if(orderSelectedName === "سعر الشراء تنازليا" || orderSelectedName === "سعر الشراء تصاعديا"){
            setQu(query(collection(db, "Posts"), where("condition", "==", "مقبولة"), where("productPrice", ">", 0), where("category", "==", categorySearch)))
          }
          else if(orderSelectedName === "سعر المزايدة تصاعديا" || orderSelectedName === "سعر المزايدة تنازليا"){
            setQu(query(collection(db, "Posts"), where("condition", "==", "مقبولة"), where("acceptsOffers", "==", true), where("category", "==", categorySearch)))
          }
        }
        // both selected
        else if(postKind !== "" && orderSelectedName !== ""){
          console.log("d")
          if(postKind === "يقبل مزايدات"){
            if(orderSelectedName === "سعر الشراء تنازليا" || orderSelectedName === "سعر الشراء تصاعديا"){
              setQu(query(collection(db, "Posts"), where("condition", "==", "مقبولة"), where("acceptsOffers", "==", true), where("productPrice", ">", 0), where("category", "==", categorySearch)))
            }
            if(orderSelectedName === "سعر المزايدة تنازليا" || orderSelectedName === "سعر المزايدة تصاعديا"){
              setQu(query(collection(db, "Posts"), where("condition", "==", "مقبولة"), where("acceptsOffers", "==", true), where("category", "==", categorySearch)))
            }
          }
          else if(postKind === "لا يقبل مزايدات"){
            if(orderSelectedName === "سعر الشراء تنازليا" || orderSelectedName === "سعر الشراء تصاعديا"){
              setQu(query(collection(db, "Posts"), where("condition", "==", "مقبولة"), where("acceptsOffers", "==", false), where("category", "==", categorySearch)))
            }
          }
          else if(postKind === "يقبل مزايدات مع سعر شراء فوري"){
            if(orderSelectedName === "سعر الشراء تنازليا" || orderSelectedName === "سعر الشراء تصاعديا"){
              setQu(query(collection(db, "Posts"), where("condition", "==", "مقبولة"), where("acceptsOffers", "==", true), where("productPrice", ">", 0), where("category", "==", categorySearch)))
            }
            else if(orderSelectedName === "سعر المزايدة تنازليا" || orderSelectedName === "سعر المزايدة تصاعديا"){
              setQu(query(collection(db, "Posts"), where("condition", "==", "مقبولة"), where("acceptsOffers", "==", true), where("productPrice", ">", 0), where("category", "==", categorySearch)))
            }
          }
          else if(postKind === "يقبل مزايدات بدون سعر شراء فوري"){
            if(orderSelectedName === "سعر المزايدة تنازليا" || orderSelectedName === "سعر المزايدة تصاعديا"){
              setQu(query(collection(db, "Posts"), where("condition", "==", "مقبولة"), where("acceptsOffers", "==", true), where("productPrice", "==", 0), where("category", "==", categorySearch)))
            }
          }
        }

        else{
          setQu(query(collection(db, "Posts"), where("condition", "==", "مقبولة"), where("category", "==", categorySearch)))
          // setListing(Listing.filter(post => post.condition === "مقبولة"));
        }
        }
        else if(subCategorySearch !== null){
          // only kind 
          if(postKind !== "" && orderSelectedName === ""){
            console.log("b")
            if(postKind === 'يقبل مزايدات'){
              console.log("here")
              setQu(query(collection(db, "Posts"), where("condition", "==", "مقبولة"), where("acceptsOffers", "==", true), where("subCategory", "==", subCategorySearch)))
              // setListing(initialListing.filter(post => post.condition === "مقبولة").filter(post => post.acceptsOffers === true));
            }
            else if(postKind === 'لا يقبل مزايدات'){
              setQu(query(collection(db, "Posts"), where("condition", "==", "مقبولة"), where("acceptsOffers", "==", false), where("subCategory", "==", subCategorySearch)))
              // setListing(initialListing.filter(post => post.condition === "مقبولة").filter(post => post.acceptsOffers === false));
            }
            else if(postKind === 'يقبل مزايدات مع سعر شراء فوري'){
              setQu(query(collection(db, "Posts"), where("condition", "==", "مقبولة"), where("acceptsOffers", "==", true), where("productPrice", ">", 0), where("subCategory", "==", subCategorySearch)))
              // setListing(initialListing.filter(post => post.condition === "مقبولة").filter(post => post.acceptsOffers === true).filter(post => post.productPrice > 0));
            }
            else if(postKind === 'يقبل مزايدات بدون سعر شراء فوري'){
              setQu(query(collection(db, "Posts"), where("condition", "==", "مقبولة"), where("acceptsOffers", "==", true), where("productPrice", "==", 0), where("subCategory", "==", subCategorySearch)))
              // setListing(initialListing.filter(post => post.condition === "مقبولة").filter(post => post.acceptsOffers === true).filter(post => post.productPrice === 0));
            }
          }
          // only order 
          else if(orderSelectedName !== "" && postKind === ""){
            console.log("c")
            if(orderSelectedName === "سعر الشراء تنازليا" || orderSelectedName === "سعر الشراء تصاعديا"){
              setQu(query(collection(db, "Posts"), where("condition", "==", "مقبولة"), where("productPrice", ">", 0), where("subCategory", "==", subCategorySearch)))
            }
            else if(orderSelectedName === "سعر المزايدة تصاعديا" || orderSelectedName === "سعر المزايدة تنازليا"){
              setQu(query(collection(db, "Posts"), where("condition", "==", "مقبولة"), where("acceptsOffers", "==", true), where("subCategory", "==", subCategorySearch)))
            }
          }
          // both selected
          else if(postKind !== "" && orderSelectedName !== ""){
            console.log("d")
            if(postKind === "يقبل مزايدات"){
              if(orderSelectedName === "سعر الشراء تنازليا" || orderSelectedName === "سعر الشراء تصاعديا"){
                setQu(query(collection(db, "Posts"), where("condition", "==", "مقبولة"), where("acceptsOffers", "==", true), where("productPrice", ">", 0), where("subCategory", "==", subCategorySearch)))
              }
              if(orderSelectedName === "سعر المزايدة تنازليا" || orderSelectedName === "سعر المزايدة تصاعديا"){
                setQu(query(collection(db, "Posts"), where("condition", "==", "مقبولة"), where("acceptsOffers", "==", true), where("subCategory", "==", subCategorySearch)))
              }
            }
            else if(postKind === "لا يقبل مزايدات"){
              if(orderSelectedName === "سعر الشراء تنازليا" || orderSelectedName === "سعر الشراء تصاعديا"){
                setQu(query(collection(db, "Posts"), where("condition", "==", "مقبولة"), where("acceptsOffers", "==", false), where("subCategory", "==", subCategorySearch)))
              }
            }
            else if(postKind === "يقبل مزايدات مع سعر شراء فوري"){
              if(orderSelectedName === "سعر الشراء تنازليا" || orderSelectedName === "سعر الشراء تصاعديا"){
                setQu(query(collection(db, "Posts"), where("condition", "==", "مقبولة"), where("acceptsOffers", "==", true), where("productPrice", ">", 0), where("subCategory", "==", subCategorySearch)))
              }
              else if(orderSelectedName === "سعر المزايدة تنازليا" || orderSelectedName === "سعر المزايدة تصاعديا"){
                setQu(query(collection(db, "Posts"), where("condition", "==", "مقبولة"), where("acceptsOffers", "==", true), where("productPrice", ">", 0), where("subCategory", "==", subCategorySearch)))
              }
            }
            else if(postKind === "يقبل مزايدات بدون سعر شراء فوري"){
              if(orderSelectedName === "سعر المزايدة تنازليا" || orderSelectedName === "سعر المزايدة تصاعديا"){
                setQu(query(collection(db, "Posts"), where("condition", "==", "مقبولة"), where("acceptsOffers", "==", true), where("productPrice", "==", 0), where("subCategory", "==", subCategorySearch)))
              }
            }
          }
  
          else{
            setQu(query(collection(db, "Posts"), where("condition", "==", "مقبولة"), where("subCategory", "==", subCategorySearch)))
            // setListing(Listing.filter(post => post.condition === "مقبولة"));
          }
        }
      }
      else{
        console.log("here")
        console.log(res)
        console.log(res.split(" "))
        setQu(
          query(
            collection(db, "Posts"),
            where("condition", "==", "مقبولة"),
            where("keywords", "array-contains-any", res.split(" "))
          )
        );
      }

      }
      else {
        setConditionSelected("");
        // setListing(initialListing);
        if(res === null){

        
        if(categorySearch !== null){
          // only kind 
          if(postKind !== "" && orderSelectedName === ""){
          console.log("b")
          if(postKind === 'يقبل مزايدات'){
            console.log("here")
            setQu(query(collection(db, "Posts"),  where("acceptsOffers", "==", true), where("category", "==", categorySearch)))
            // setListing(initialListing.filter(post => post.condition === "جديد").filter(post => post.acceptsOffers === true));
          }
          else if(postKind === 'لا يقبل مزايدات'){
            setQu(query(collection(db, "Posts"),  where("acceptsOffers", "==", false), where("category", "==", categorySearch)))
            // setListing(initialListing.filter(post => post.condition === "جديد").filter(post => post.acceptsOffers === false));
          }
          else if(postKind === 'يقبل مزايدات مع سعر شراء فوري'){
            setQu(query(collection(db, "Posts"),  where("acceptsOffers", "==", true), where("productPrice", ">", 0), where("category", "==", categorySearch)))
            // setListing(initialListing.filter(post => post.condition === "جديد").filter(post => post.acceptsOffers === true).filter(post => post.productPrice > 0));
          }
          else if(postKind === 'يقبل مزايدات بدون سعر شراء فوري'){
            setQu(query(collection(db, "Posts"),  where("acceptsOffers", "==", true), where("productPrice", "==", 0), where("category", "==", categorySearch)))
            // setListing(initialListing.filter(post => post.condition === "جديد").filter(post => post.acceptsOffers === true).filter(post => post.productPrice === 0));
          }
        }
        // only order 
        else if(orderSelectedName !== "" && postKind === ""){
          console.log("c")
          if(orderSelectedName === "سعر الشراء تنازليا" || orderSelectedName === "سعر الشراء تصاعديا"){
            setQu(query(collection(db, "Posts"),  where("productPrice", ">", 0), where("category", "==", categorySearch)))
          }
          else if(orderSelectedName === "سعر المزايدة تصاعديا" || orderSelectedName === "سعر المزايدة تنازليا"){
            setQu(query(collection(db, "Posts"),  where("acceptsOffers", "==", true), where("category", "==", categorySearch)))
          }
        }
        // both selected
        else if(postKind !== "" && orderSelectedName !== ""){
          console.log("d")
          if(postKind === "يقبل مزايدات"){
            if(orderSelectedName === "سعر الشراء تنازليا" || orderSelectedName === "سعر الشراء تصاعديا"){
              setQu(query(collection(db, "Posts"),  where("acceptsOffers", "==", true), where("productPrice", ">", 0), where("category", "==", categorySearch)))
            }
            if(orderSelectedName === "سعر المزايدة تنازليا" || orderSelectedName === "سعر المزايدة تصاعديا"){
              setQu(query(collection(db, "Posts"),  where("acceptsOffers", "==", true), where("category", "==", categorySearch)))
            }
          }
          else if(postKind === "لا يقبل مزايدات"){
            if(orderSelectedName === "سعر الشراء تنازليا" || orderSelectedName === "سعر الشراء تصاعديا"){
              setQu(query(collection(db, "Posts"),  where("acceptsOffers", "==", false), where("category", "==", categorySearch)))
            }
          }
          else if(postKind === "يقبل مزايدات مع سعر شراء فوري"){
            if(orderSelectedName === "سعر الشراء تنازليا" || orderSelectedName === "سعر الشراء تصاعديا"){
              setQu(query(collection(db, "Posts"),  where("acceptsOffers", "==", true), where("productPrice", ">", 0), where("category", "==", categorySearch)))
            }
            else if(orderSelectedName === "سعر المزايدة تنازليا" || orderSelectedName === "سعر المزايدة تصاعديا"){
              setQu(query(collection(db, "Posts"),  where("acceptsOffers", "==", true), where("productPrice", ">", 0), where("category", "==", categorySearch)))
            }
          }
          else if(postKind === "يقبل مزايدات بدون سعر شراء فوري"){
            if(orderSelectedName === "سعر المزايدة تنازليا" || orderSelectedName === "سعر المزايدة تصاعديا"){
              setQu(query(collection(db, "Posts"),  where("acceptsOffers", "==", true), where("productPrice", "==", 0), where("category", "==", categorySearch)))
            }
          }
        }

        else{
          setQu(query(collection(db, "Posts"),  where("category", "==", categorySearch)))
          // setListing(Listing.filter(post => post.condition === "جديد"));
        }
        }
        else if(subCategorySearch !== null){
          // only kind 
          if(postKind !== "" && orderSelectedName === ""){
            console.log("b")
            if(postKind === 'يقبل مزايدات'){
              console.log("here")
              setQu(query(collection(db, "Posts"),  where("acceptsOffers", "==", true), where("subCategory", "==", subCategorySearch)))
              // setListing(initialListing.filter(post => post.condition === "جديد").filter(post => post.acceptsOffers === true));
            }
            else if(postKind === 'لا يقبل مزايدات'){
              setQu(query(collection(db, "Posts"),  where("acceptsOffers", "==", false), where("subCategory", "==", subCategorySearch)))
              // setListing(initialListing.filter(post => post.condition === "جديد").filter(post => post.acceptsOffers === false));
            }
            else if(postKind === 'يقبل مزايدات مع سعر شراء فوري'){
              setQu(query(collection(db, "Posts"),  where("acceptsOffers", "==", true), where("productPrice", ">", 0), where("subCategory", "==", subCategorySearch)))
              // setListing(initialListing.filter(post => post.condition === "جديد").filter(post => post.acceptsOffers === true).filter(post => post.productPrice > 0));
            }
            else if(postKind === 'يقبل مزايدات بدون سعر شراء فوري'){
              setQu(query(collection(db, "Posts"),  where("acceptsOffers", "==", true), where("productPrice", "==", 0), where("subCategory", "==", subCategorySearch)))
              // setListing(initialListing.filter(post => post.condition === "جديد").filter(post => post.acceptsOffers === true).filter(post => post.productPrice === 0));
            }
          }
          // only order 
          else if(orderSelectedName !== "" && postKind === ""){
            console.log("c")
            if(orderSelectedName === "سعر الشراء تنازليا" || orderSelectedName === "سعر الشراء تصاعديا"){
              setQu(query(collection(db, "Posts"),  where("productPrice", ">", 0), where("subCategory", "==", subCategorySearch)))
            }
            else if(orderSelectedName === "سعر المزايدة تصاعديا" || orderSelectedName === "سعر المزايدة تنازليا"){
              setQu(query(collection(db, "Posts"),  where("acceptsOffers", "==", true), where("subCategory", "==", subCategorySearch)))
            }
          }
          // both selected
          else if(postKind !== "" && orderSelectedName !== ""){
            console.log("d")
            if(postKind === "يقبل مزايدات"){
              if(orderSelectedName === "سعر الشراء تنازليا" || orderSelectedName === "سعر الشراء تصاعديا"){
                setQu(query(collection(db, "Posts"),  where("acceptsOffers", "==", true), where("productPrice", ">", 0), where("subCategory", "==", subCategorySearch)))
              }
              if(orderSelectedName === "سعر المزايدة تنازليا" || orderSelectedName === "سعر المزايدة تصاعديا"){
                setQu(query(collection(db, "Posts"),  where("acceptsOffers", "==", true), where("subCategory", "==", subCategorySearch)))
              }
            }
            else if(postKind === "لا يقبل مزايدات"){
              if(orderSelectedName === "سعر الشراء تنازليا" || orderSelectedName === "سعر الشراء تصاعديا"){
                setQu(query(collection(db, "Posts"),  where("acceptsOffers", "==", false), where("subCategory", "==", subCategorySearch)))
              }
            }
            else if(postKind === "يقبل مزايدات مع سعر شراء فوري"){
              if(orderSelectedName === "سعر الشراء تنازليا" || orderSelectedName === "سعر الشراء تصاعديا"){
                setQu(query(collection(db, "Posts"),  where("acceptsOffers", "==", true), where("productPrice", ">", 0), where("subCategory", "==", subCategorySearch)))
              }
              else if(orderSelectedName === "سعر المزايدة تنازليا" || orderSelectedName === "سعر المزايدة تصاعديا"){
                setQu(query(collection(db, "Posts"),  where("acceptsOffers", "==", true), where("productPrice", ">", 0), where("subCategory", "==", subCategorySearch)))
              }
            }
            else if(postKind === "يقبل مزايدات بدون سعر شراء فوري"){
              if(orderSelectedName === "سعر المزايدة تنازليا" || orderSelectedName === "سعر المزايدة تصاعديا"){
                setQu(query(collection(db, "Posts"),  where("acceptsOffers", "==", true), where("productPrice", "==", 0), where("subCategory", "==", subCategorySearch)))
              }
            }
          }
  
          else{
            setQu(query(collection(db, "Posts"),  where("subCategory", "==", subCategorySearch)))
            // setListing(Listing.filter(post => post.condition === "جديد"));
          }
        }
      }
      else{
        console.log("here")
        console.log(res)
        console.log(res.split(" "))
        setQu(
          query(
            collection(db, "Posts"),
            where("keywords", "array-contains-any", res.split(" "))
          )
        );
      }
      }
            }} id='filter-buttons'> مقبولة {conditionSelected === 'مقبولة' ? <FaCheck /> : ""}</li>












        </ul>
          </div>
          <div
          id='filter-buttons-wrapper'
          style={{
            display: showFilters ? "block" : "none"
          }}
          >
          <ul
          id='filter-buttons-div'
          >
            <div
            style={{marginBottom: "auto", marginTop: "auto",justifyContent: "center",
          }}
            >
              <p style={{ margin : "0px", padding: "8px", whiteSpace: "nowrap", fontSize: "16px"}}>نوع المنتج:</p>
            </div>














          <li 
                    style={{
                      fontSize: "14px"
                    }}
          onClick={()=>{
if(postKind !== 'يقبل مزايدات'){
  // setListing(initialListing);
  setPostKind("يقبل مزايدات")

  if(res === null){
    if(categorySearch !== null){
      // only condition selected
  if(conditionSelected !== "" && orderSelectedName === ""){
    if(conditionSelected === 'جديد'){
      setQu(query(collection(db, "Posts"), where("condition", "==", "جديد"), where("acceptsOffers", "==", true), where("category", "==", categorySearch)))
      // setListing(Listing.filter(post => post.acceptsOffers === true));
    }
    else if(conditionSelected === 'مستعمل'){
      setQu(query(collection(db, "Posts"), where("condition", "==", "مستعمل"), where("acceptsOffers", "==", true), where("category", "==", categorySearch)))
      // setListing(initialListing.filter(post => post.condition === "مستعمل").filter(post => post.acceptsOffers === true));
    }
    else if(conditionSelected === 'ممتازة'){
      setQu(query(collection(db, "Posts"), where("condition", "==", "ممتازة"), where("acceptsOffers", "==", true), where("category", "==", categorySearch)))
      // setListing(initialListing.filter(post => post.condition === "ممتازة").filter(post => post.acceptsOffers === true));
    }
    else if(conditionSelected === 'جيدة جدا'){
      setQu(query(collection(db, "Posts"), where("condition", "==", "جيدة جدا"), where("acceptsOffers", "==", true), where("category", "==", categorySearch)))
      // setListing(initialListing.filter(post => post.condition === "جيدة جدا").filter(post => post.acceptsOffers === true));
    }
    else if(conditionSelected === 'جيدة'){
      setQu(query(collection(db, "Posts"), where("condition", "==", "جيدة"), where("acceptsOffers", "==", true), where("category", "==", categorySearch)))
      // setListing(initialListing.filter(post => post.condition === "جيدة").filter(post => post.acceptsOffers === true));
    }
    else if(conditionSelected === 'مقبولة'){
      setQu(query(collection(db, "Posts"), where("condition", "==", "مقبولة"), where("acceptsOffers", "==", true), where("category", "==", categorySearch)))
      // setListing(initialListing.filter(post => post.condition === "مقبولة").filter(post => post.acceptsOffers === true));
    }
  }

  // todo : add only order test for accepts offers button along with unclicking

  // only order
  else if(orderSelectedName !== "" && conditionSelected === ""){
    if(orderSelectedName === "سعر الشراء تنازليا" || orderSelectedName === "سعر الشراء تصاعديا" ){
      setQu(query(collection(db, "Posts"), where("acceptsOffers", "==", true), where("productPrice", ">", 0), where("category", "==", categorySearch)))
    }
    else if(orderSelectedName === "سعر المزايدة تنازليا" || orderSelectedName === "سعر المزايدة تصاعديا" ){
      setQu(query(collection(db, "Posts"), where("acceptsOffers", "==", true), where("category", "==", categorySearch)))
    }
  }

  // both selected
  else if(conditionSelected !== "" && orderSelectedName !== ""){
    if(orderSelectedName === "سعر الشراء تنازليا" && conditionSelected === 'جديد'){
      setQu(query(collection(db, "Posts"), where("condition", "==", "جديد"), where("acceptsOffers", "==", true), where("productPrice", ">", 0), where("category", "==", categorySearch)))
    }
    else if(orderSelectedName === "سعر الشراء تنازليا" && conditionSelected === 'مستعمل'){
      setQu(query(collection(db, "Posts"), where("condition", "==", "مستعمل"), where("acceptsOffers", "==", true), where("productPrice", ">", 0), where("category", "==", categorySearch)))
    }
    else if(orderSelectedName === "سعر الشراء تنازليا" && conditionSelected === 'ممتازة'){
      setQu(query(collection(db, "Posts"), where("condition", "==", "ممتازة"), where("acceptsOffers", "==", true), where("productPrice", ">", 0), where("category", "==", categorySearch)))
    }
    else if(orderSelectedName === "سعر الشراء تنازليا" && conditionSelected === 'جبدة جدا'){
      setQu(query(collection(db, "Posts"), where("condition", "==", "جبدة جدا"), where("acceptsOffers", "==", true), where("productPrice", ">", 0), where("category", "==", categorySearch)))
    }
    else if(orderSelectedName === "سعر الشراء تنازليا" && conditionSelected === 'جبدة'){
      setQu(query(collection(db, "Posts"), where("condition", "==", "جبدة"), where("acceptsOffers", "==", true), where("productPrice", ">", 0), where("category", "==", categorySearch)))
    }
    else if(orderSelectedName === "سعر الشراء تنازليا" && conditionSelected === 'مقبولة'){
      setQu(query(collection(db, "Posts"), where("condition", "==", "مقبولة"), where("acceptsOffers", "==", true), where("productPrice", ">", 0), where("category", "==", categorySearch)))
    }

    else if(orderSelectedName === "سعر الشراء تصاعديا" && conditionSelected === 'جديد'){
      setQu(query(collection(db, "Posts"), where("condition", "==", "جديد"), where("acceptsOffers", "==", true), where("productPrice", ">", 0), where("category", "==", categorySearch)))
    }
    else if(orderSelectedName === "سعر الشراء تصاعديا" && conditionSelected === 'مستعمل'){
      setQu(query(collection(db, "Posts"), where("condition", "==", "مستعمل"), where("acceptsOffers", "==", true), where("productPrice", ">", 0), where("category", "==", categorySearch)))
    }
    else if(orderSelectedName === "سعر الشراء تصاعديا" && conditionSelected === 'ممتازة'){
      setQu(query(collection(db, "Posts"), where("condition", "==", "ممتازة"), where("acceptsOffers", "==", true), where("productPrice", ">", 0), where("category", "==", categorySearch)))
    }
    else if(orderSelectedName === "سعر الشراء تصاعديا" && conditionSelected === 'جبدة جدا'){
      setQu(query(collection(db, "Posts"), where("condition", "==", "جبدة جدا"), where("acceptsOffers", "==", true), where("productPrice", ">", 0), where("category", "==", categorySearch)))
    }
    else if(orderSelectedName === "سعر الشراء تصاعديا" && conditionSelected === 'جبدة'){
      setQu(query(collection(db, "Posts"), where("condition", "==", "جبدة"), where("acceptsOffers", "==", true), where("productPrice", ">", 0), where("category", "==", categorySearch)))
    }
    else if(orderSelectedName === "سعر الشراء تصاعديا" && conditionSelected === 'مقبولة'){
      setQu(query(collection(db, "Posts"), where("condition", "==", "مقبولة"), where("acceptsOffers", "==", true), where("productPrice", ">", 0), where("category", "==", categorySearch)))
    }

    else if(orderSelectedName === "سعر المزايدة تصاعديا" && conditionSelected === 'جديد'){
      setQu(query(collection(db, "Posts"), where("condition", "==", "جديد"), where("acceptsOffers", "==", true), where("category", "==", categorySearch)))
    }
    else if(orderSelectedName === "سعر المزايدة تصاعديا" && conditionSelected === 'ممتازة'){
      setQu(query(collection(db, "Posts"), where("condition", "==", "ممتازة"), where("acceptsOffers", "==", true), where("category", "==", categorySearch)))
    }
    else if(orderSelectedName === "سعر المزايدة تصاعديا" && conditionSelected === 'جبدة جدا'){
      setQu(query(collection(db, "Posts"), where("condition", "==", "جبدة جدا"), where("acceptsOffers", "==", true), where("category", "==", categorySearch)))
    }
    else if(orderSelectedName === "سعر المزايدة تصاعديا" && conditionSelected === 'جبدة'){
      setQu(query(collection(db, "Posts"), where("condition", "==", "جبدة"), where("acceptsOffers", "==", true), where("category", "==", categorySearch)))
    }
    else if(orderSelectedName === "سعر المزايدة تصاعديا" && conditionSelected === 'مقبولة'){
      setQu(query(collection(db, "Posts"), where("condition", "==", "مقبولة"), where("acceptsOffers", "==", true), where("category", "==", categorySearch)))
    }


    else if(orderSelectedName === "سعر المزايدة تنازليا" && conditionSelected === 'جديد'){
      setQu(query(collection(db, "Posts"), where("condition", "==", "جديد"), where("acceptsOffers", "==", true), where("category", "==", categorySearch)))
    }
    else if(orderSelectedName === "سعر المزايدة تنازليا" && conditionSelected === 'ممتازة'){
      setQu(query(collection(db, "Posts"), where("condition", "==", "ممتازة"), where("acceptsOffers", "==", true), where("category", "==", categorySearch)))
    }
    else if(orderSelectedName === "سعر المزايدة تنازليا" && conditionSelected === 'جبدة جدا'){
      setQu(query(collection(db, "Posts"), where("condition", "==", "جبدة جدا"), where("acceptsOffers", "==", true), where("category", "==", categorySearch)))
    }
    else if(orderSelectedName === "سعر المزايدة تنازليا" && conditionSelected === 'جبدة'){
      setQu(query(collection(db, "Posts"), where("condition", "==", "جبدة"), where("acceptsOffers", "==", true), where("category", "==", categorySearch)))
    }
    else if(orderSelectedName === "سعر المزايدة تنازليا" && conditionSelected === 'مقبولة'){
      setQu(query(collection(db, "Posts"), where("condition", "==", "مقبولة"), where("acceptsOffers", "==", true), where("category", "==", categorySearch)))
    }
  }

  // neither selected
  else{
    setQu(query(collection(db, "Posts"), where("acceptsOffers", "==", true), where("category", "==", categorySearch)))
  }
    }
    else if(subCategorySearch !== null){
       // only condition selected
  if(conditionSelected !== "" && orderSelectedName === ""){
    if(conditionSelected === 'جديد'){
      setQu(query(collection(db, "Posts"), where("condition", "==", "جديد"), where("acceptsOffers", "==", true), where("subCategory", "==", subCategorySearch)))
      // setListing(Listing.filter(post => post.acceptsOffers === true));
    }
    else if(conditionSelected === 'مستعمل'){
      setQu(query(collection(db, "Posts"), where("condition", "==", "مستعمل"), where("acceptsOffers", "==", true), where("subCategory", "==", subCategorySearch)))
      // setListing(initialListing.filter(post => post.condition === "مستعمل").filter(post => post.acceptsOffers === true));
    }
    else if(conditionSelected === 'ممتازة'){
      setQu(query(collection(db, "Posts"), where("condition", "==", "ممتازة"), where("acceptsOffers", "==", true), where("subCategory", "==", subCategorySearch)))
      // setListing(initialListing.filter(post => post.condition === "ممتازة").filter(post => post.acceptsOffers === true));
    }
    else if(conditionSelected === 'جيدة جدا'){
      setQu(query(collection(db, "Posts"), where("condition", "==", "جيدة جدا"), where("acceptsOffers", "==", true), where("subCategory", "==", subCategorySearch)))
      // setListing(initialListing.filter(post => post.condition === "جيدة جدا").filter(post => post.acceptsOffers === true));
    }
    else if(conditionSelected === 'جيدة'){
      setQu(query(collection(db, "Posts"), where("condition", "==", "جيدة"), where("acceptsOffers", "==", true), where("subCategory", "==", subCategorySearch)))
      // setListing(initialListing.filter(post => post.condition === "جيدة").filter(post => post.acceptsOffers === true));
    }
    else if(conditionSelected === 'مقبولة'){
      setQu(query(collection(db, "Posts"), where("condition", "==", "مقبولة"), where("acceptsOffers", "==", true), where("subCategory", "==", subCategorySearch)))
      // setListing(initialListing.filter(post => post.condition === "مقبولة").filter(post => post.acceptsOffers === true));
    }
  }

  // todo : add only order test for accepts offers button along with unclicking

  // only order
  else if(orderSelectedName !== "" && conditionSelected === ""){
    if(orderSelectedName === "سعر الشراء تنازليا" || orderSelectedName === "سعر الشراء تصاعديا" ){
      setQu(query(collection(db, "Posts"), where("acceptsOffers", "==", true), where("productPrice", ">", 0), where("subCategory", "==", subCategorySearch)))
    }
    else if(orderSelectedName === "سعر المزايدة تنازليا" || orderSelectedName === "سعر المزايدة تصاعديا" ){
      setQu(query(collection(db, "Posts"), where("acceptsOffers", "==", true), where("subCategory", "==", subCategorySearch)))
    }
  }

  // both selected
  else if(conditionSelected !== "" && orderSelectedName !== ""){
    if(orderSelectedName === "سعر الشراء تنازليا" && conditionSelected === 'جديد'){
      setQu(query(collection(db, "Posts"), where("condition", "==", "جديد"), where("acceptsOffers", "==", true), where("productPrice", ">", 0), where("subCategory", "==", subCategorySearch)))
    }
    else if(orderSelectedName === "سعر الشراء تنازليا" && conditionSelected === 'مستعمل'){
      setQu(query(collection(db, "Posts"), where("condition", "==", "مستعمل"), where("acceptsOffers", "==", true), where("productPrice", ">", 0), where("subCategory", "==", subCategorySearch)))
    }
    else if(orderSelectedName === "سعر الشراء تنازليا" && conditionSelected === 'ممتازة'){
      setQu(query(collection(db, "Posts"), where("condition", "==", "ممتازة"), where("acceptsOffers", "==", true), where("productPrice", ">", 0), where("subCategory", "==", subCategorySearch)))
    }
    else if(orderSelectedName === "سعر الشراء تنازليا" && conditionSelected === 'جبدة جدا'){
      setQu(query(collection(db, "Posts"), where("condition", "==", "جبدة جدا"), where("acceptsOffers", "==", true), where("productPrice", ">", 0), where("subCategory", "==", subCategorySearch)))
    }
    else if(orderSelectedName === "سعر الشراء تنازليا" && conditionSelected === 'جبدة'){
      setQu(query(collection(db, "Posts"), where("condition", "==", "جبدة"), where("acceptsOffers", "==", true), where("productPrice", ">", 0), where("subCategory", "==", subCategorySearch)))
    }
    else if(orderSelectedName === "سعر الشراء تنازليا" && conditionSelected === 'مقبولة'){
      setQu(query(collection(db, "Posts"), where("condition", "==", "مقبولة"), where("acceptsOffers", "==", true), where("productPrice", ">", 0), where("subCategory", "==", subCategorySearch)))
    }

    else if(orderSelectedName === "سعر الشراء تصاعديا" && conditionSelected === 'جديد'){
      setQu(query(collection(db, "Posts"), where("condition", "==", "جديد"), where("acceptsOffers", "==", true), where("productPrice", ">", 0), where("subCategory", "==", subCategorySearch)))
    }
    else if(orderSelectedName === "سعر الشراء تصاعديا" && conditionSelected === 'مستعمل'){
      setQu(query(collection(db, "Posts"), where("condition", "==", "مستعمل"), where("acceptsOffers", "==", true), where("productPrice", ">", 0), where("subCategory", "==", subCategorySearch)))
    }
    else if(orderSelectedName === "سعر الشراء تصاعديا" && conditionSelected === 'ممتازة'){
      setQu(query(collection(db, "Posts"), where("condition", "==", "ممتازة"), where("acceptsOffers", "==", true), where("productPrice", ">", 0), where("subCategory", "==", subCategorySearch)))
    }
    else if(orderSelectedName === "سعر الشراء تصاعديا" && conditionSelected === 'جبدة جدا'){
      setQu(query(collection(db, "Posts"), where("condition", "==", "جبدة جدا"), where("acceptsOffers", "==", true), where("productPrice", ">", 0), where("subCategory", "==", subCategorySearch)))
    }
    else if(orderSelectedName === "سعر الشراء تصاعديا" && conditionSelected === 'جبدة'){
      setQu(query(collection(db, "Posts"), where("condition", "==", "جبدة"), where("acceptsOffers", "==", true), where("productPrice", ">", 0), where("subCategory", "==", subCategorySearch)))
    }
    else if(orderSelectedName === "سعر الشراء تصاعديا" && conditionSelected === 'مقبولة'){
      setQu(query(collection(db, "Posts"), where("condition", "==", "مقبولة"), where("acceptsOffers", "==", true), where("productPrice", ">", 0), where("subCategory", "==", subCategorySearch)))
    }

    else if(orderSelectedName === "سعر المزايدة تصاعديا" && conditionSelected === 'جديد'){
      setQu(query(collection(db, "Posts"), where("condition", "==", "جديد"), where("acceptsOffers", "==", true), where("subCategory", "==", subCategorySearch)))
    }
    else if(orderSelectedName === "سعر المزايدة تصاعديا" && conditionSelected === 'ممتازة'){
      setQu(query(collection(db, "Posts"), where("condition", "==", "ممتازة"), where("acceptsOffers", "==", true), where("subCategory", "==", subCategorySearch)))
    }
    else if(orderSelectedName === "سعر المزايدة تصاعديا" && conditionSelected === 'جبدة جدا'){
      setQu(query(collection(db, "Posts"), where("condition", "==", "جبدة جدا"), where("acceptsOffers", "==", true), where("subCategory", "==", subCategorySearch)))
    }
    else if(orderSelectedName === "سعر المزايدة تصاعديا" && conditionSelected === 'جبدة'){
      setQu(query(collection(db, "Posts"), where("condition", "==", "جبدة"), where("acceptsOffers", "==", true), where("subCategory", "==", subCategorySearch)))
    }
    else if(orderSelectedName === "سعر المزايدة تصاعديا" && conditionSelected === 'مقبولة'){
      setQu(query(collection(db, "Posts"), where("condition", "==", "مقبولة"), where("acceptsOffers", "==", true), where("subCategory", "==", subCategorySearch)))
    }


    else if(orderSelectedName === "سعر المزايدة تنازليا" && conditionSelected === 'جديد'){
      setQu(query(collection(db, "Posts"), where("condition", "==", "جديد"), where("acceptsOffers", "==", true), where("subCategory", "==", subCategorySearch)))
    }
    else if(orderSelectedName === "سعر المزايدة تنازليا" && conditionSelected === 'ممتازة'){
      setQu(query(collection(db, "Posts"), where("condition", "==", "ممتازة"), where("acceptsOffers", "==", true), where("subCategory", "==", subCategorySearch)))
    }
    else if(orderSelectedName === "سعر المزايدة تنازليا" && conditionSelected === 'جبدة جدا'){
      setQu(query(collection(db, "Posts"), where("condition", "==", "جبدة جدا"), where("acceptsOffers", "==", true), where("subCategory", "==", subCategorySearch)))
    }
    else if(orderSelectedName === "سعر المزايدة تنازليا" && conditionSelected === 'جبدة'){
      setQu(query(collection(db, "Posts"), where("condition", "==", "جبدة"), where("acceptsOffers", "==", true), where("subCategory", "==", subCategorySearch)))
    }
    else if(orderSelectedName === "سعر المزايدة تنازليا" && conditionSelected === 'مقبولة'){
      setQu(query(collection(db, "Posts"), where("condition", "==", "مقبولة"), where("acceptsOffers", "==", true), where("subCategory", "==", subCategorySearch)))
    }
  }

  // neither selected
  else{
    setQu(query(collection(db, "Posts"), where("acceptsOffers", "==", true), where("subCategory", "==", subCategorySearch)))
  }
    }
  }
  else{
// res is not null
setQu(query(collection(db, "Posts"), where("acceptsOffers", "==", true), where("keywords", "array-contains-any", res.split(" "))))

  }
}
else {
  setPostKind("");
  // setListing(initialListing);

  if(res === null){
    if(categorySearch !== null){
      // condition selected
  if(conditionSelected !== "" && orderSelectedName === ""){
    if(conditionSelected === 'جديد'){
      setQu(query(collection(db, "Posts"), where("condition", "==", "جديد"), where("category", "==", categorySearch)))
      // setListing(initialListing.filter(post => post.condition === "جديد"));
    }
    else if(conditionSelected === 'مستعمل'){
      setQu(query(collection(db, "Posts"), where("condition", "==", "مستعمل"), where("category", "==", categorySearch)))
      // setListing(initialListing.filter(post => post.condition === "مستعمل"));
    }
    else if(conditionSelected === 'ممتازة'){
      setQu(query(collection(db, "Posts"), where("condition", "==", "ممتازة"), where("category", "==", categorySearch)))
      // setListing(initialListing.filter(post => post.condition === "ممتازة"));
    }
    else if(conditionSelected === 'جيدة جدا'){
      setQu(query(collection(db, "Posts"), where("condition", "==", "جيدة جدا"), where("category", "==", categorySearch)))
      // setListing(initialListing.filter(post => post.condition === "جيدة جدا"));
    }
    else if(conditionSelected === 'جيدة'){
      setQu(query(collection(db, "Posts"), where("condition", "==", "جيدة"), where("category", "==", categorySearch)))
      // setListing(initialListing.filter(post => post.condition === "جيدة"));
    }
    else if(conditionSelected === 'مقبولة'){
      setQu(query(collection(db, "Posts"), where("condition", "==", "مقبولة"), where("category", "==", categorySearch)))
      // setListing(initialListing.filter(post => post.condition === "مقبولة"));
    }

  }

    // only order
    else if(orderSelectedName !== "" && conditionSelected === ""){
      if(orderSelectedName === "سعر الشراء تنازليا" || orderSelectedName === "سعر الشراء تصاعديا" ){
        setQu(query(collection(db, "Posts"), where("productPrice", ">", 0), where("category", "==", categorySearch)))
      }
      else if(orderSelectedName === "سعر المزايدة تنازليا" || orderSelectedName === "سعر المزايدة تصاعديا" ){
        setQu(query(collection(db, "Posts"), where("acceptsOffers", "==", true), where("category", "==", categorySearch)))
      }
    }

  // both selected
  else if(conditionSelected !== "" && orderSelectedName !== ""){
    if(orderSelectedName === "سعر الشراء تنازليا" && conditionSelected === 'جديد'){
      setQu(query(collection(db, "Posts"), where("condition", "==", "جديد"), where("productPrice", ">", 0), where("category", "==", categorySearch)))
    }
    else if(orderSelectedName === "سعر الشراء تنازليا" && conditionSelected === 'مستعمل'){
      setQu(query(collection(db, "Posts"), where("condition", "==", "مستعمل"), where("productPrice", ">", 0), where("category", "==", categorySearch)))
    }
    else if(orderSelectedName === "سعر الشراء تنازليا" && conditionSelected === 'ممتازة'){
      setQu(query(collection(db, "Posts"), where("condition", "==", "ممتازة"), where("productPrice", ">", 0), where("category", "==", categorySearch)))
    }
    else if(orderSelectedName === "سعر الشراء تنازليا" && conditionSelected === 'جبدة جدا'){
      setQu(query(collection(db, "Posts"), where("condition", "==", "جبدة جدا"), where("productPrice", ">", 0), where("category", "==", categorySearch)))
    }
    else if(orderSelectedName === "سعر الشراء تنازليا" && conditionSelected === 'جبدة'){
      setQu(query(collection(db, "Posts"), where("condition", "==", "جبدة"), where("productPrice", ">", 0), where("category", "==", categorySearch)))
    }
    else if(orderSelectedName === "سعر الشراء تنازليا" && conditionSelected === 'مقبولة'){
      setQu(query(collection(db, "Posts"), where("condition", "==", "مقبولة"), where("productPrice", ">", 0), where("category", "==", categorySearch)))
    }

    else if(orderSelectedName === "سعر الشراء تصاعديا" && conditionSelected === 'جديد'){
      setQu(query(collection(db, "Posts"), where("condition", "==", "جديد"),  where("productPrice", ">", 0), where("category", "==", categorySearch)))
    }
    else if(orderSelectedName === "سعر الشراء تصاعديا" && conditionSelected === 'مستعمل'){
      setQu(query(collection(db, "Posts"), where("condition", "==", "مستعمل"),  where("productPrice", ">", 0), where("category", "==", categorySearch)))
    }
    else if(orderSelectedName === "سعر الشراء تصاعديا" && conditionSelected === 'ممتازة'){
      setQu(query(collection(db, "Posts"), where("condition", "==", "ممتازة"),  where("productPrice", ">", 0), where("category", "==", categorySearch)))
    }
    else if(orderSelectedName === "سعر الشراء تصاعديا" && conditionSelected === 'جبدة جدا'){
      setQu(query(collection(db, "Posts"), where("condition", "==", "جبدة جدا"),  where("productPrice", ">", 0), where("category", "==", categorySearch)))
    }
    else if(orderSelectedName === "سعر الشراء تصاعديا" && conditionSelected === 'جبدة'){
      setQu(query(collection(db, "Posts"), where("condition", "==", "جبدة"),  where("productPrice", ">", 0), where("category", "==", categorySearch)))
    }
    else if(orderSelectedName === "سعر الشراء تصاعديا" && conditionSelected === 'مقبولة'){
      setQu(query(collection(db, "Posts"), where("condition", "==", "مقبولة"),  where("productPrice", ">", 0), where("category", "==", categorySearch)))
    }

    else if(orderSelectedName === "سعر المزايدة تصاعديا" && conditionSelected === 'جديد'){
      setQu(query(collection(db, "Posts"), where("condition", "==", "جديد"), where("acceptsOffers", "==", true), where("category", "==", categorySearch)))
    }
    else if(orderSelectedName === "سعر المزايدة تصاعديا" && conditionSelected === 'ممتازة'){
      setQu(query(collection(db, "Posts"), where("condition", "==", "ممتازة"), where("acceptsOffers", "==", true), where("category", "==", categorySearch)))
    }
    else if(orderSelectedName === "سعر المزايدة تصاعديا" && conditionSelected === 'جبدة جدا'){
      setQu(query(collection(db, "Posts"), where("condition", "==", "جبدة جدا"), where("acceptsOffers", "==", true), where("category", "==", categorySearch)))
    }
    else if(orderSelectedName === "سعر المزايدة تصاعديا" && conditionSelected === 'جبدة'){
      setQu(query(collection(db, "Posts"), where("condition", "==", "جبدة"), where("acceptsOffers", "==", true), where("category", "==", categorySearch)))
    }
    else if(orderSelectedName === "سعر المزايدة تصاعديا" && conditionSelected === 'مقبولة'){
      setQu(query(collection(db, "Posts"), where("condition", "==", "مقبولة"), where("acceptsOffers", "==", true), where("category", "==", categorySearch)))
    }


    else if(orderSelectedName === "سعر المزايدة تنازليا" && conditionSelected === 'جديد'){
      setQu(query(collection(db, "Posts"), where("condition", "==", "جديد"), where("acceptsOffers", "==", true), where("category", "==", categorySearch)))
    }
    else if(orderSelectedName === "سعر المزايدة تنازليا" && conditionSelected === 'ممتازة'){
      setQu(query(collection(db, "Posts"), where("condition", "==", "ممتازة"), where("acceptsOffers", "==", true), where("category", "==", categorySearch)))
    }
    else if(orderSelectedName === "سعر المزايدة تنازليا" && conditionSelected === 'جبدة جدا'){
      setQu(query(collection(db, "Posts"), where("condition", "==", "جبدة جدا"), where("acceptsOffers", "==", true), where("category", "==", categorySearch)))
    }
    else if(orderSelectedName === "سعر المزايدة تنازليا" && conditionSelected === 'جبدة'){
      setQu(query(collection(db, "Posts"), where("condition", "==", "جبدة"), where("acceptsOffers", "==", true), where("category", "==", categorySearch)))
    }
    else if(orderSelectedName === "سعر المزايدة تنازليا" && conditionSelected === 'مقبولة'){
      setQu(query(collection(db, "Posts"), where("condition", "==", "مقبولة"), where("acceptsOffers", "==", true), where("category", "==", categorySearch)))
    }
  }

  // neither selected
  else {
    setQu(query(collection(db, "Posts"), where("category", "==", categorySearch)))
  }
    }
    else if(subCategorySearch !== null){
       // condition selected
  if(conditionSelected !== "" && orderSelectedName === ""){
    if(conditionSelected === 'جديد'){
      setQu(query(collection(db, "Posts"), where("condition", "==", "جديد"), where("subCategory", "==", subCategorySearch)))
      // setListing(initialListing.filter(post => post.condition === "جديد"));
    }
    else if(conditionSelected === 'مستعمل'){
      setQu(query(collection(db, "Posts"), where("condition", "==", "مستعمل"), where("subCategory", "==", subCategorySearch)))
      // setListing(initialListing.filter(post => post.condition === "مستعمل"));
    }
    else if(conditionSelected === 'ممتازة'){
      setQu(query(collection(db, "Posts"), where("condition", "==", "ممتازة"), where("subCategory", "==", subCategorySearch)))
      // setListing(initialListing.filter(post => post.condition === "ممتازة"));
    }
    else if(conditionSelected === 'جيدة جدا'){
      setQu(query(collection(db, "Posts"), where("condition", "==", "جيدة جدا"), where("subCategory", "==", subCategorySearch)))
      // setListing(initialListing.filter(post => post.condition === "جيدة جدا"));
    }
    else if(conditionSelected === 'جيدة'){
      setQu(query(collection(db, "Posts"), where("condition", "==", "جيدة"), where("subCategory", "==", subCategorySearch)))
      // setListing(initialListing.filter(post => post.condition === "جيدة"));
    }
    else if(conditionSelected === 'مقبولة'){
      setQu(query(collection(db, "Posts"), where("condition", "==", "مقبولة"), where("subCategory", "==", subCategorySearch)))
      // setListing(initialListing.filter(post => post.condition === "مقبولة"));
    }

  }

    // only order
    else if(orderSelectedName !== "" && conditionSelected === ""){
      if(orderSelectedName === "سعر الشراء تنازليا" || orderSelectedName === "سعر الشراء تصاعديا" ){
        setQu(query(collection(db, "Posts"), where("productPrice", ">", 0), where("subCategory", "==", subCategorySearch)))
      }
      else if(orderSelectedName === "سعر المزايدة تنازليا" || orderSelectedName === "سعر المزايدة تصاعديا" ){
        setQu(query(collection(db, "Posts"), where("acceptsOffers", "==", true), where("subCategory", "==", subCategorySearch)))
      }
    }

  // both selected
  else if(conditionSelected !== "" && orderSelectedName !== ""){
    if(orderSelectedName === "سعر الشراء تنازليا" && conditionSelected === 'جديد'){
      setQu(query(collection(db, "Posts"), where("condition", "==", "جديد"), where("productPrice", ">", 0), where("subCategory", "==", subCategorySearch)))
    }
    else if(orderSelectedName === "سعر الشراء تنازليا" && conditionSelected === 'مستعمل'){
      setQu(query(collection(db, "Posts"), where("condition", "==", "مستعمل"), where("productPrice", ">", 0), where("subCategory", "==", subCategorySearch)))
    }
    else if(orderSelectedName === "سعر الشراء تنازليا" && conditionSelected === 'ممتازة'){
      setQu(query(collection(db, "Posts"), where("condition", "==", "ممتازة"), where("productPrice", ">", 0), where("subCategory", "==", subCategorySearch)))
    }
    else if(orderSelectedName === "سعر الشراء تنازليا" && conditionSelected === 'جبدة جدا'){
      setQu(query(collection(db, "Posts"), where("condition", "==", "جبدة جدا"), where("productPrice", ">", 0), where("subCategory", "==", subCategorySearch)))
    }
    else if(orderSelectedName === "سعر الشراء تنازليا" && conditionSelected === 'جبدة'){
      setQu(query(collection(db, "Posts"), where("condition", "==", "جبدة"), where("productPrice", ">", 0), where("subCategory", "==", subCategorySearch)))
    }
    else if(orderSelectedName === "سعر الشراء تنازليا" && conditionSelected === 'مقبولة'){
      setQu(query(collection(db, "Posts"), where("condition", "==", "مقبولة"), where("productPrice", ">", 0), where("subCategory", "==", subCategorySearch)))
    }

    else if(orderSelectedName === "سعر الشراء تصاعديا" && conditionSelected === 'جديد'){
      setQu(query(collection(db, "Posts"), where("condition", "==", "جديد"),  where("productPrice", ">", 0), where("subCategory", "==", subCategorySearch)))
    }
    else if(orderSelectedName === "سعر الشراء تصاعديا" && conditionSelected === 'مستعمل'){
      setQu(query(collection(db, "Posts"), where("condition", "==", "مستعمل"),  where("productPrice", ">", 0), where("subCategory", "==", subCategorySearch)))
    }
    else if(orderSelectedName === "سعر الشراء تصاعديا" && conditionSelected === 'ممتازة'){
      setQu(query(collection(db, "Posts"), where("condition", "==", "ممتازة"),  where("productPrice", ">", 0), where("subCategory", "==", subCategorySearch)))
    }
    else if(orderSelectedName === "سعر الشراء تصاعديا" && conditionSelected === 'جبدة جدا'){
      setQu(query(collection(db, "Posts"), where("condition", "==", "جبدة جدا"),  where("productPrice", ">", 0), where("subCategory", "==", subCategorySearch)))
    }
    else if(orderSelectedName === "سعر الشراء تصاعديا" && conditionSelected === 'جبدة'){
      setQu(query(collection(db, "Posts"), where("condition", "==", "جبدة"),  where("productPrice", ">", 0), where("subCategory", "==", subCategorySearch)))
    }
    else if(orderSelectedName === "سعر الشراء تصاعديا" && conditionSelected === 'مقبولة'){
      setQu(query(collection(db, "Posts"), where("condition", "==", "مقبولة"),  where("productPrice", ">", 0), where("subCategory", "==", subCategorySearch)))
    }

    else if(orderSelectedName === "سعر المزايدة تصاعديا" && conditionSelected === 'جديد'){
      setQu(query(collection(db, "Posts"), where("condition", "==", "جديد"), where("acceptsOffers", "==", true), where("subCategory", "==", subCategorySearch)))
    }
    else if(orderSelectedName === "سعر المزايدة تصاعديا" && conditionSelected === 'ممتازة'){
      setQu(query(collection(db, "Posts"), where("condition", "==", "ممتازة"), where("acceptsOffers", "==", true), where("subCategory", "==", subCategorySearch)))
    }
    else if(orderSelectedName === "سعر المزايدة تصاعديا" && conditionSelected === 'جبدة جدا'){
      setQu(query(collection(db, "Posts"), where("condition", "==", "جبدة جدا"), where("acceptsOffers", "==", true), where("subCategory", "==", subCategorySearch)))
    }
    else if(orderSelectedName === "سعر المزايدة تصاعديا" && conditionSelected === 'جبدة'){
      setQu(query(collection(db, "Posts"), where("condition", "==", "جبدة"), where("acceptsOffers", "==", true), where("subCategory", "==", subCategorySearch)))
    }
    else if(orderSelectedName === "سعر المزايدة تصاعديا" && conditionSelected === 'مقبولة'){
      setQu(query(collection(db, "Posts"), where("condition", "==", "مقبولة"), where("acceptsOffers", "==", true), where("subCategory", "==", subCategorySearch)))
    }


    else if(orderSelectedName === "سعر المزايدة تنازليا" && conditionSelected === 'جديد'){
      setQu(query(collection(db, "Posts"), where("condition", "==", "جديد"), where("acceptsOffers", "==", true), where("subCategory", "==", subCategorySearch)))
    }
    else if(orderSelectedName === "سعر المزايدة تنازليا" && conditionSelected === 'ممتازة'){
      setQu(query(collection(db, "Posts"), where("condition", "==", "ممتازة"), where("acceptsOffers", "==", true), where("subCategory", "==", subCategorySearch)))
    }
    else if(orderSelectedName === "سعر المزايدة تنازليا" && conditionSelected === 'جبدة جدا'){
      setQu(query(collection(db, "Posts"), where("condition", "==", "جبدة جدا"), where("acceptsOffers", "==", true), where("subCategory", "==", subCategorySearch)))
    }
    else if(orderSelectedName === "سعر المزايدة تنازليا" && conditionSelected === 'جبدة'){
      setQu(query(collection(db, "Posts"), where("condition", "==", "جبدة"), where("acceptsOffers", "==", true), where("subCategory", "==", subCategorySearch)))
    }
    else if(orderSelectedName === "سعر المزايدة تنازليا" && conditionSelected === 'مقبولة'){
      setQu(query(collection(db, "Posts"), where("condition", "==", "مقبولة"), where("acceptsOffers", "==", true), where("subCategory", "==", subCategorySearch)))
    }
  }
    }
  }
  else{
    setQu(query(collection(db, "Posts"), where("keywords", "array-contains-any", res.split(" "))))
  }

  
}
          }} id='filter-buttons'> يقبل مزايدات {postKind === 'يقبل مزايدات' ? <FaCheck /> : ""}</li>











          
          <li 
          style={{
            fontSize: "14px"
          }}
          onClick={()=>{
           if(postKind !== 'لا يقبل مزايدات'){
            // setListing(initialListing);
            setPostKind("لا يقبل مزايدات")
          
            if(res === null){
              if(categorySearch !== null){
                // only condition selected
            if(conditionSelected !== "" && orderSelectedName === ""){
              if(conditionSelected === 'جديد'){
                setQu(query(collection(db, "Posts"), where("condition", "==", "جديد"), where("acceptsOffers", "==", false), where("category", "==", categorySearch)))
                // setListing(Listing.filter(post => post.acceptsOffers === true));
              }
              else if(conditionSelected === 'مستعمل'){
                setQu(query(collection(db, "Posts"), where("condition", "==", "مستعمل"), where("acceptsOffers", "==", false), where("category", "==", categorySearch)))
                // setListing(initialListing.filter(post => post.condition === "مستعمل").filter(post => post.acceptsOffers === true));
              }
              else if(conditionSelected === 'ممتازة'){
                setQu(query(collection(db, "Posts"), where("condition", "==", "ممتازة"), where("acceptsOffers", "==", false), where("category", "==", categorySearch)))
                // setListing(initialListing.filter(post => post.condition === "ممتازة").filter(post => post.acceptsOffers === true));
              }
              else if(conditionSelected === 'جيدة جدا'){
                setQu(query(collection(db, "Posts"), where("condition", "==", "جيدة جدا"), where("acceptsOffers", "==", false), where("category", "==", categorySearch)))
                // setListing(initialListing.filter(post => post.condition === "جيدة جدا").filter(post => post.acceptsOffers === true));
              }
              else if(conditionSelected === 'جيدة'){
                setQu(query(collection(db, "Posts"), where("condition", "==", "جيدة"), where("acceptsOffers", "==", false), where("category", "==", categorySearch)))
                // setListing(initialListing.filter(post => post.condition === "جيدة").filter(post => post.acceptsOffers === true));
              }
              else if(conditionSelected === 'مقبولة'){
                setQu(query(collection(db, "Posts"), where("condition", "==", "مقبولة"), where("acceptsOffers", "==", false), where("category", "==", categorySearch)))
                // setListing(initialListing.filter(post => post.condition === "مقبولة").filter(post => post.acceptsOffers === true));
              }
            }
          
            // todo : add only order test for accepts offers button along with unclicking
          
            // only order
            else if(orderSelectedName !== "" && conditionSelected === ""){
              if(orderSelectedName === "سعر الشراء تنازليا" || orderSelectedName === "سعر الشراء تصاعديا" ){
                setQu(query(collection(db, "Posts"), where("acceptsOffers", "==", false), where("productPrice", ">", 0), where("category", "==", categorySearch)))
              }
              else if(orderSelectedName === "سعر المزايدة تنازليا" || orderSelectedName === "سعر المزايدة تصاعديا" ){
                setQu(query(collection(db, "Posts"), where("acceptsOffers", "==", false), where("category", "==", categorySearch)))
              }
            }
          
            // both selected
            else if(conditionSelected !== "" && orderSelectedName !== ""){
              if(orderSelectedName === "سعر الشراء تنازليا" && conditionSelected === 'جديد'){
                setQu(query(collection(db, "Posts"), where("condition", "==", "جديد"), where("acceptsOffers", "==", false), where("productPrice", ">", 0), where("category", "==", categorySearch)))
              }
              else if(orderSelectedName === "سعر الشراء تنازليا" && conditionSelected === 'مستعمل'){
                setQu(query(collection(db, "Posts"), where("condition", "==", "مستعمل"), where("acceptsOffers", "==", false), where("productPrice", ">", 0), where("category", "==", categorySearch)))
              }
              else if(orderSelectedName === "سعر الشراء تنازليا" && conditionSelected === 'ممتازة'){
                setQu(query(collection(db, "Posts"), where("condition", "==", "ممتازة"), where("acceptsOffers", "==", false), where("productPrice", ">", 0), where("category", "==", categorySearch)))
              }
              else if(orderSelectedName === "سعر الشراء تنازليا" && conditionSelected === 'جبدة جدا'){
                setQu(query(collection(db, "Posts"), where("condition", "==", "جبدة جدا"), where("acceptsOffers", "==", false), where("productPrice", ">", 0), where("category", "==", categorySearch)))
              }
              else if(orderSelectedName === "سعر الشراء تنازليا" && conditionSelected === 'جبدة'){
                setQu(query(collection(db, "Posts"), where("condition", "==", "جبدة"), where("acceptsOffers", "==", false), where("productPrice", ">", 0), where("category", "==", categorySearch)))
              }
              else if(orderSelectedName === "سعر الشراء تنازليا" && conditionSelected === 'مقبولة'){
                setQu(query(collection(db, "Posts"), where("condition", "==", "مقبولة"), where("acceptsOffers", "==", false), where("productPrice", ">", 0), where("category", "==", categorySearch)))
              }
          
              else if(orderSelectedName === "سعر الشراء تصاعديا" && conditionSelected === 'جديد'){
                setQu(query(collection(db, "Posts"), where("condition", "==", "جديد"), where("acceptsOffers", "==", false), where("productPrice", ">", 0), where("category", "==", categorySearch)))
              }
              else if(orderSelectedName === "سعر الشراء تصاعديا" && conditionSelected === 'مستعمل'){
                setQu(query(collection(db, "Posts"), where("condition", "==", "مستعمل"), where("acceptsOffers", "==", false), where("productPrice", ">", 0), where("category", "==", categorySearch)))
              }
              else if(orderSelectedName === "سعر الشراء تصاعديا" && conditionSelected === 'ممتازة'){
                setQu(query(collection(db, "Posts"), where("condition", "==", "ممتازة"), where("acceptsOffers", "==", false), where("productPrice", ">", 0), where("category", "==", categorySearch)))
              }
              else if(orderSelectedName === "سعر الشراء تصاعديا" && conditionSelected === 'جبدة جدا'){
                setQu(query(collection(db, "Posts"), where("condition", "==", "جبدة جدا"), where("acceptsOffers", "==", false), where("productPrice", ">", 0), where("category", "==", categorySearch)))
              }
              else if(orderSelectedName === "سعر الشراء تصاعديا" && conditionSelected === 'جبدة'){
                setQu(query(collection(db, "Posts"), where("condition", "==", "جبدة"), where("acceptsOffers", "==", false), where("productPrice", ">", 0), where("category", "==", categorySearch)))
              }
              else if(orderSelectedName === "سعر الشراء تصاعديا" && conditionSelected === 'مقبولة'){
                setQu(query(collection(db, "Posts"), where("condition", "==", "مقبولة"), where("acceptsOffers", "==", false), where("productPrice", ">", 0), where("category", "==", categorySearch)))
              }
          
              else if(orderSelectedName === "سعر المزايدة تصاعديا" && conditionSelected === 'جديد'){
                setQu(query(collection(db, "Posts"), where("condition", "==", "جديد"), where("acceptsOffers", "==", false), where("category", "==", categorySearch)))
              }
              else if(orderSelectedName === "سعر المزايدة تصاعديا" && conditionSelected === 'ممتازة'){
                setQu(query(collection(db, "Posts"), where("condition", "==", "ممتازة"), where("acceptsOffers", "==", false), where("category", "==", categorySearch)))
              }
              else if(orderSelectedName === "سعر المزايدة تصاعديا" && conditionSelected === 'جبدة جدا'){
                setQu(query(collection(db, "Posts"), where("condition", "==", "جبدة جدا"), where("acceptsOffers", "==", false), where("category", "==", categorySearch)))
              }
              else if(orderSelectedName === "سعر المزايدة تصاعديا" && conditionSelected === 'جبدة'){
                setQu(query(collection(db, "Posts"), where("condition", "==", "جبدة"), where("acceptsOffers", "==", false), where("category", "==", categorySearch)))
              }
              else if(orderSelectedName === "سعر المزايدة تصاعديا" && conditionSelected === 'مقبولة'){
                setQu(query(collection(db, "Posts"), where("condition", "==", "مقبولة"), where("acceptsOffers", "==", false), where("category", "==", categorySearch)))
              }
          
          
              else if(orderSelectedName === "سعر المزايدة تنازليا" && conditionSelected === 'جديد'){
                setQu(query(collection(db, "Posts"), where("condition", "==", "جديد"), where("acceptsOffers", "==", false), where("category", "==", categorySearch)))
              }
              else if(orderSelectedName === "سعر المزايدة تنازليا" && conditionSelected === 'ممتازة'){
                setQu(query(collection(db, "Posts"), where("condition", "==", "ممتازة"), where("acceptsOffers", "==", false), where("category", "==", categorySearch)))
              }
              else if(orderSelectedName === "سعر المزايدة تنازليا" && conditionSelected === 'جبدة جدا'){
                setQu(query(collection(db, "Posts"), where("condition", "==", "جبدة جدا"), where("acceptsOffers", "==", false), where("category", "==", categorySearch)))
              }
              else if(orderSelectedName === "سعر المزايدة تنازليا" && conditionSelected === 'جبدة'){
                setQu(query(collection(db, "Posts"), where("condition", "==", "جبدة"), where("acceptsOffers", "==", false), where("category", "==", categorySearch)))
              }
              else if(orderSelectedName === "سعر المزايدة تنازليا" && conditionSelected === 'مقبولة'){
                setQu(query(collection(db, "Posts"), where("condition", "==", "مقبولة"), where("acceptsOffers", "==", false), where("category", "==", categorySearch)))
              }
            }
          
            // neither selected
            else{
              setQu(query(collection(db, "Posts"), where("acceptsOffers", "==", false), where("category", "==", categorySearch)))
            }
              }
              else if(subCategorySearch !== null){
                 // only condition selected
            if(conditionSelected !== "" && orderSelectedName === ""){
              if(conditionSelected === 'جديد'){
                setQu(query(collection(db, "Posts"), where("condition", "==", "جديد"), where("acceptsOffers", "==", false), where("subCategory", "==", subCategorySearch)))
                // setListing(Listing.filter(post => post.acceptsOffers === true));
              }
              else if(conditionSelected === 'مستعمل'){
                setQu(query(collection(db, "Posts"), where("condition", "==", "مستعمل"), where("acceptsOffers", "==", false), where("subCategory", "==", subCategorySearch)))
                // setListing(initialListing.filter(post => post.condition === "مستعمل").filter(post => post.acceptsOffers === true));
              }
              else if(conditionSelected === 'ممتازة'){
                setQu(query(collection(db, "Posts"), where("condition", "==", "ممتازة"), where("acceptsOffers", "==", false), where("subCategory", "==", subCategorySearch)))
                // setListing(initialListing.filter(post => post.condition === "ممتازة").filter(post => post.acceptsOffers === true));
              }
              else if(conditionSelected === 'جيدة جدا'){
                setQu(query(collection(db, "Posts"), where("condition", "==", "جيدة جدا"), where("acceptsOffers", "==", false), where("subCategory", "==", subCategorySearch)))
                // setListing(initialListing.filter(post => post.condition === "جيدة جدا").filter(post => post.acceptsOffers === true));
              }
              else if(conditionSelected === 'جيدة'){
                setQu(query(collection(db, "Posts"), where("condition", "==", "جيدة"), where("acceptsOffers", "==", false), where("subCategory", "==", subCategorySearch)))
                // setListing(initialListing.filter(post => post.condition === "جيدة").filter(post => post.acceptsOffers === true));
              }
              else if(conditionSelected === 'مقبولة'){
                setQu(query(collection(db, "Posts"), where("condition", "==", "مقبولة"), where("acceptsOffers", "==", false), where("subCategory", "==", subCategorySearch)))
                // setListing(initialListing.filter(post => post.condition === "مقبولة").filter(post => post.acceptsOffers === true));
              }
            }
          
            // todo : add only order test for accepts offers button along with unclicking
          
            // only order
            else if(orderSelectedName !== "" && conditionSelected === ""){
              if(orderSelectedName === "سعر الشراء تنازليا" || orderSelectedName === "سعر الشراء تصاعديا" ){
                setQu(query(collection(db, "Posts"), where("acceptsOffers", "==", false), where("productPrice", ">", 0), where("subCategory", "==", subCategorySearch)))
              }
              else if(orderSelectedName === "سعر المزايدة تنازليا" || orderSelectedName === "سعر المزايدة تصاعديا" ){
                setQu(query(collection(db, "Posts"), where("acceptsOffers", "==", false), where("subCategory", "==", subCategorySearch)))
              }
            }
          
            // both selected
            else if(conditionSelected !== "" && orderSelectedName !== ""){
              if(orderSelectedName === "سعر الشراء تنازليا" && conditionSelected === 'جديد'){
                setQu(query(collection(db, "Posts"), where("condition", "==", "جديد"), where("acceptsOffers", "==", false), where("productPrice", ">", 0), where("subCategory", "==", subCategorySearch)))
              }
              else if(orderSelectedName === "سعر الشراء تنازليا" && conditionSelected === 'مستعمل'){
                setQu(query(collection(db, "Posts"), where("condition", "==", "مستعمل"), where("acceptsOffers", "==", false), where("productPrice", ">", 0), where("subCategory", "==", subCategorySearch)))
              }
              else if(orderSelectedName === "سعر الشراء تنازليا" && conditionSelected === 'ممتازة'){
                setQu(query(collection(db, "Posts"), where("condition", "==", "ممتازة"), where("acceptsOffers", "==", false), where("productPrice", ">", 0), where("subCategory", "==", subCategorySearch)))
              }
              else if(orderSelectedName === "سعر الشراء تنازليا" && conditionSelected === 'جبدة جدا'){
                setQu(query(collection(db, "Posts"), where("condition", "==", "جبدة جدا"), where("acceptsOffers", "==", false), where("productPrice", ">", 0), where("subCategory", "==", subCategorySearch)))
              }
              else if(orderSelectedName === "سعر الشراء تنازليا" && conditionSelected === 'جبدة'){
                setQu(query(collection(db, "Posts"), where("condition", "==", "جبدة"), where("acceptsOffers", "==", false), where("productPrice", ">", 0), where("subCategory", "==", subCategorySearch)))
              }
              else if(orderSelectedName === "سعر الشراء تنازليا" && conditionSelected === 'مقبولة'){
                setQu(query(collection(db, "Posts"), where("condition", "==", "مقبولة"), where("acceptsOffers", "==", false), where("productPrice", ">", 0), where("subCategory", "==", subCategorySearch)))
              }
          
              else if(orderSelectedName === "سعر الشراء تصاعديا" && conditionSelected === 'جديد'){
                setQu(query(collection(db, "Posts"), where("condition", "==", "جديد"), where("acceptsOffers", "==", false), where("productPrice", ">", 0), where("subCategory", "==", subCategorySearch)))
              }
              else if(orderSelectedName === "سعر الشراء تصاعديا" && conditionSelected === 'مستعمل'){
                setQu(query(collection(db, "Posts"), where("condition", "==", "مستعمل"), where("acceptsOffers", "==", false), where("productPrice", ">", 0), where("subCategory", "==", subCategorySearch)))
              }
              else if(orderSelectedName === "سعر الشراء تصاعديا" && conditionSelected === 'ممتازة'){
                setQu(query(collection(db, "Posts"), where("condition", "==", "ممتازة"), where("acceptsOffers", "==", false), where("productPrice", ">", 0), where("subCategory", "==", subCategorySearch)))
              }
              else if(orderSelectedName === "سعر الشراء تصاعديا" && conditionSelected === 'جبدة جدا'){
                setQu(query(collection(db, "Posts"), where("condition", "==", "جبدة جدا"), where("acceptsOffers", "==", false), where("productPrice", ">", 0), where("subCategory", "==", subCategorySearch)))
              }
              else if(orderSelectedName === "سعر الشراء تصاعديا" && conditionSelected === 'جبدة'){
                setQu(query(collection(db, "Posts"), where("condition", "==", "جبدة"), where("acceptsOffers", "==", false), where("productPrice", ">", 0), where("subCategory", "==", subCategorySearch)))
              }
              else if(orderSelectedName === "سعر الشراء تصاعديا" && conditionSelected === 'مقبولة'){
                setQu(query(collection(db, "Posts"), where("condition", "==", "مقبولة"), where("acceptsOffers", "==", false), where("productPrice", ">", 0), where("subCategory", "==", subCategorySearch)))
              }
          
              else if(orderSelectedName === "سعر المزايدة تصاعديا" && conditionSelected === 'جديد'){
                setQu(query(collection(db, "Posts"), where("condition", "==", "جديد"), where("acceptsOffers", "==", false), where("subCategory", "==", subCategorySearch)))
              }
              else if(orderSelectedName === "سعر المزايدة تصاعديا" && conditionSelected === 'ممتازة'){
                setQu(query(collection(db, "Posts"), where("condition", "==", "ممتازة"), where("acceptsOffers", "==", false), where("subCategory", "==", subCategorySearch)))
              }
              else if(orderSelectedName === "سعر المزايدة تصاعديا" && conditionSelected === 'جبدة جدا'){
                setQu(query(collection(db, "Posts"), where("condition", "==", "جبدة جدا"), where("acceptsOffers", "==", false), where("subCategory", "==", subCategorySearch)))
              }
              else if(orderSelectedName === "سعر المزايدة تصاعديا" && conditionSelected === 'جبدة'){
                setQu(query(collection(db, "Posts"), where("condition", "==", "جبدة"), where("acceptsOffers", "==", false), where("subCategory", "==", subCategorySearch)))
              }
              else if(orderSelectedName === "سعر المزايدة تصاعديا" && conditionSelected === 'مقبولة'){
                setQu(query(collection(db, "Posts"), where("condition", "==", "مقبولة"), where("acceptsOffers", "==", false), where("subCategory", "==", subCategorySearch)))
              }
          
          
              else if(orderSelectedName === "سعر المزايدة تنازليا" && conditionSelected === 'جديد'){
                setQu(query(collection(db, "Posts"), where("condition", "==", "جديد"), where("acceptsOffers", "==", false), where("subCategory", "==", subCategorySearch)))
              }
              else if(orderSelectedName === "سعر المزايدة تنازليا" && conditionSelected === 'ممتازة'){
                setQu(query(collection(db, "Posts"), where("condition", "==", "ممتازة"), where("acceptsOffers", "==", false), where("subCategory", "==", subCategorySearch)))
              }
              else if(orderSelectedName === "سعر المزايدة تنازليا" && conditionSelected === 'جبدة جدا'){
                setQu(query(collection(db, "Posts"), where("condition", "==", "جبدة جدا"), where("acceptsOffers", "==", false), where("subCategory", "==", subCategorySearch)))
              }
              else if(orderSelectedName === "سعر المزايدة تنازليا" && conditionSelected === 'جبدة'){
                setQu(query(collection(db, "Posts"), where("condition", "==", "جبدة"), where("acceptsOffers", "==", false), where("subCategory", "==", subCategorySearch)))
              }
              else if(orderSelectedName === "سعر المزايدة تنازليا" && conditionSelected === 'مقبولة'){
                setQu(query(collection(db, "Posts"), where("condition", "==", "مقبولة"), where("acceptsOffers", "==", false), where("subCategory", "==", subCategorySearch)))
              }
            }
          
            // neither selected
            else{
              setQu(query(collection(db, "Posts"), where("acceptsOffers", "==", false), where("subCategory", "==", subCategorySearch)))
            }
              }
            }
            else{
          // res is not null
          setQu(query(collection(db, "Posts"), where("acceptsOffers", "==", false), where("keywords", "array-contains-any", res.split(" "))))
          
            }
          }
          else {
            setPostKind("");
            // setListing(initialListing);
          
            if(res === null){
              if(categorySearch !== null){
                // condition selected
            if(conditionSelected !== "" && orderSelectedName === ""){
              if(conditionSelected === 'جديد'){
                setQu(query(collection(db, "Posts"), where("condition", "==", "جديد"), where("category", "==", categorySearch)))
                // setListing(initialListing.filter(post => post.condition === "جديد"));
              }
              else if(conditionSelected === 'مستعمل'){
                setQu(query(collection(db, "Posts"), where("condition", "==", "مستعمل"), where("category", "==", categorySearch)))
                // setListing(initialListing.filter(post => post.condition === "مستعمل"));
              }
              else if(conditionSelected === 'ممتازة'){
                setQu(query(collection(db, "Posts"), where("condition", "==", "ممتازة"), where("category", "==", categorySearch)))
                // setListing(initialListing.filter(post => post.condition === "ممتازة"));
              }
              else if(conditionSelected === 'جيدة جدا'){
                setQu(query(collection(db, "Posts"), where("condition", "==", "جيدة جدا"), where("category", "==", categorySearch)))
                // setListing(initialListing.filter(post => post.condition === "جيدة جدا"));
              }
              else if(conditionSelected === 'جيدة'){
                setQu(query(collection(db, "Posts"), where("condition", "==", "جيدة"), where("category", "==", categorySearch)))
                // setListing(initialListing.filter(post => post.condition === "جيدة"));
              }
              else if(conditionSelected === 'مقبولة'){
                setQu(query(collection(db, "Posts"), where("condition", "==", "مقبولة"), where("category", "==", categorySearch)))
                // setListing(initialListing.filter(post => post.condition === "مقبولة"));
              }
          
            }
          
              // only order
              else if(orderSelectedName !== "" && conditionSelected === ""){
                if(orderSelectedName === "سعر الشراء تنازليا" || orderSelectedName === "سعر الشراء تصاعديا" ){
                  setQu(query(collection(db, "Posts"), where("productPrice", ">", 0), where("category", "==", categorySearch)))
                }
                else if(orderSelectedName === "سعر المزايدة تنازليا" || orderSelectedName === "سعر المزايدة تصاعديا" ){
                  setQu(query(collection(db, "Posts"), where("acceptsOffers", "==", false), where("category", "==", categorySearch)))
                }
              }
          
            // both selected
            else if(conditionSelected !== "" && orderSelectedName !== ""){
              if(orderSelectedName === "سعر الشراء تنازليا" && conditionSelected === 'جديد'){
                setQu(query(collection(db, "Posts"), where("condition", "==", "جديد"), where("productPrice", ">", 0), where("category", "==", categorySearch)))
              }
              else if(orderSelectedName === "سعر الشراء تنازليا" && conditionSelected === 'مستعمل'){
                setQu(query(collection(db, "Posts"), where("condition", "==", "مستعمل"), where("productPrice", ">", 0), where("category", "==", categorySearch)))
              }
              else if(orderSelectedName === "سعر الشراء تنازليا" && conditionSelected === 'ممتازة'){
                setQu(query(collection(db, "Posts"), where("condition", "==", "ممتازة"), where("productPrice", ">", 0), where("category", "==", categorySearch)))
              }
              else if(orderSelectedName === "سعر الشراء تنازليا" && conditionSelected === 'جبدة جدا'){
                setQu(query(collection(db, "Posts"), where("condition", "==", "جبدة جدا"), where("productPrice", ">", 0), where("category", "==", categorySearch)))
              }
              else if(orderSelectedName === "سعر الشراء تنازليا" && conditionSelected === 'جبدة'){
                setQu(query(collection(db, "Posts"), where("condition", "==", "جبدة"), where("productPrice", ">", 0), where("category", "==", categorySearch)))
              }
              else if(orderSelectedName === "سعر الشراء تنازليا" && conditionSelected === 'مقبولة'){
                setQu(query(collection(db, "Posts"), where("condition", "==", "مقبولة"), where("productPrice", ">", 0), where("category", "==", categorySearch)))
              }
          
              else if(orderSelectedName === "سعر الشراء تصاعديا" && conditionSelected === 'جديد'){
                setQu(query(collection(db, "Posts"), where("condition", "==", "جديد"),  where("productPrice", ">", 0), where("category", "==", categorySearch)))
              }
              else if(orderSelectedName === "سعر الشراء تصاعديا" && conditionSelected === 'مستعمل'){
                setQu(query(collection(db, "Posts"), where("condition", "==", "مستعمل"),  where("productPrice", ">", 0), where("category", "==", categorySearch)))
              }
              else if(orderSelectedName === "سعر الشراء تصاعديا" && conditionSelected === 'ممتازة'){
                setQu(query(collection(db, "Posts"), where("condition", "==", "ممتازة"),  where("productPrice", ">", 0), where("category", "==", categorySearch)))
              }
              else if(orderSelectedName === "سعر الشراء تصاعديا" && conditionSelected === 'جبدة جدا'){
                setQu(query(collection(db, "Posts"), where("condition", "==", "جبدة جدا"),  where("productPrice", ">", 0), where("category", "==", categorySearch)))
              }
              else if(orderSelectedName === "سعر الشراء تصاعديا" && conditionSelected === 'جبدة'){
                setQu(query(collection(db, "Posts"), where("condition", "==", "جبدة"),  where("productPrice", ">", 0), where("category", "==", categorySearch)))
              }
              else if(orderSelectedName === "سعر الشراء تصاعديا" && conditionSelected === 'مقبولة'){
                setQu(query(collection(db, "Posts"), where("condition", "==", "مقبولة"),  where("productPrice", ">", 0), where("category", "==", categorySearch)))
              }
          
              else if(orderSelectedName === "سعر المزايدة تصاعديا" && conditionSelected === 'جديد'){
                setQu(query(collection(db, "Posts"), where("condition", "==", "جديد"), where("acceptsOffers", "==", false), where("category", "==", categorySearch)))
              }
              else if(orderSelectedName === "سعر المزايدة تصاعديا" && conditionSelected === 'ممتازة'){
                setQu(query(collection(db, "Posts"), where("condition", "==", "ممتازة"), where("acceptsOffers", "==", false), where("category", "==", categorySearch)))
              }
              else if(orderSelectedName === "سعر المزايدة تصاعديا" && conditionSelected === 'جبدة جدا'){
                setQu(query(collection(db, "Posts"), where("condition", "==", "جبدة جدا"), where("acceptsOffers", "==", false), where("category", "==", categorySearch)))
              }
              else if(orderSelectedName === "سعر المزايدة تصاعديا" && conditionSelected === 'جبدة'){
                setQu(query(collection(db, "Posts"), where("condition", "==", "جبدة"), where("acceptsOffers", "==", false), where("category", "==", categorySearch)))
              }
              else if(orderSelectedName === "سعر المزايدة تصاعديا" && conditionSelected === 'مقبولة'){
                setQu(query(collection(db, "Posts"), where("condition", "==", "مقبولة"), where("acceptsOffers", "==", false), where("category", "==", categorySearch)))
              }
          
          
              else if(orderSelectedName === "سعر المزايدة تنازليا" && conditionSelected === 'جديد'){
                setQu(query(collection(db, "Posts"), where("condition", "==", "جديد"), where("acceptsOffers", "==", false), where("category", "==", categorySearch)))
              }
              else if(orderSelectedName === "سعر المزايدة تنازليا" && conditionSelected === 'ممتازة'){
                setQu(query(collection(db, "Posts"), where("condition", "==", "ممتازة"), where("acceptsOffers", "==", false), where("category", "==", categorySearch)))
              }
              else if(orderSelectedName === "سعر المزايدة تنازليا" && conditionSelected === 'جبدة جدا'){
                setQu(query(collection(db, "Posts"), where("condition", "==", "جبدة جدا"), where("acceptsOffers", "==", false), where("category", "==", categorySearch)))
              }
              else if(orderSelectedName === "سعر المزايدة تنازليا" && conditionSelected === 'جبدة'){
                setQu(query(collection(db, "Posts"), where("condition", "==", "جبدة"), where("acceptsOffers", "==", false), where("category", "==", categorySearch)))
              }
              else if(orderSelectedName === "سعر المزايدة تنازليا" && conditionSelected === 'مقبولة'){
                setQu(query(collection(db, "Posts"), where("condition", "==", "مقبولة"), where("acceptsOffers", "==", false), where("category", "==", categorySearch)))
              }
            }
          
            // neither selected
            else {
              setQu(query(collection(db, "Posts"), where("category", "==", categorySearch)))
            }
              }
              else if(subCategorySearch !== null){
                 // condition selected
            if(conditionSelected !== "" && orderSelectedName === ""){
              if(conditionSelected === 'جديد'){
                setQu(query(collection(db, "Posts"), where("condition", "==", "جديد"), where("subCategory", "==", subCategorySearch)))
                // setListing(initialListing.filter(post => post.condition === "جديد"));
              }
              else if(conditionSelected === 'مستعمل'){
                setQu(query(collection(db, "Posts"), where("condition", "==", "مستعمل"), where("subCategory", "==", subCategorySearch)))
                // setListing(initialListing.filter(post => post.condition === "مستعمل"));
              }
              else if(conditionSelected === 'ممتازة'){
                setQu(query(collection(db, "Posts"), where("condition", "==", "ممتازة"), where("subCategory", "==", subCategorySearch)))
                // setListing(initialListing.filter(post => post.condition === "ممتازة"));
              }
              else if(conditionSelected === 'جيدة جدا'){
                setQu(query(collection(db, "Posts"), where("condition", "==", "جيدة جدا"), where("subCategory", "==", subCategorySearch)))
                // setListing(initialListing.filter(post => post.condition === "جيدة جدا"));
              }
              else if(conditionSelected === 'جيدة'){
                setQu(query(collection(db, "Posts"), where("condition", "==", "جيدة"), where("subCategory", "==", subCategorySearch)))
                // setListing(initialListing.filter(post => post.condition === "جيدة"));
              }
              else if(conditionSelected === 'مقبولة'){
                setQu(query(collection(db, "Posts"), where("condition", "==", "مقبولة"), where("subCategory", "==", subCategorySearch)))
                // setListing(initialListing.filter(post => post.condition === "مقبولة"));
              }
          
            }
          
              // only order
              else if(orderSelectedName !== "" && conditionSelected === ""){
                if(orderSelectedName === "سعر الشراء تنازليا" || orderSelectedName === "سعر الشراء تصاعديا" ){
                  setQu(query(collection(db, "Posts"), where("productPrice", ">", 0), where("subCategory", "==", subCategorySearch)))
                }
                else if(orderSelectedName === "سعر المزايدة تنازليا" || orderSelectedName === "سعر المزايدة تصاعديا" ){
                  setQu(query(collection(db, "Posts"), where("acceptsOffers", "==", false), where("subCategory", "==", subCategorySearch)))
                }
              }
          
            // both selected
            else if(conditionSelected !== "" && orderSelectedName !== ""){
              if(orderSelectedName === "سعر الشراء تنازليا" && conditionSelected === 'جديد'){
                setQu(query(collection(db, "Posts"), where("condition", "==", "جديد"), where("productPrice", ">", 0), where("subCategory", "==", subCategorySearch)))
              }
              else if(orderSelectedName === "سعر الشراء تنازليا" && conditionSelected === 'مستعمل'){
                setQu(query(collection(db, "Posts"), where("condition", "==", "مستعمل"), where("productPrice", ">", 0), where("subCategory", "==", subCategorySearch)))
              }
              else if(orderSelectedName === "سعر الشراء تنازليا" && conditionSelected === 'ممتازة'){
                setQu(query(collection(db, "Posts"), where("condition", "==", "ممتازة"), where("productPrice", ">", 0), where("subCategory", "==", subCategorySearch)))
              }
              else if(orderSelectedName === "سعر الشراء تنازليا" && conditionSelected === 'جبدة جدا'){
                setQu(query(collection(db, "Posts"), where("condition", "==", "جبدة جدا"), where("productPrice", ">", 0), where("subCategory", "==", subCategorySearch)))
              }
              else if(orderSelectedName === "سعر الشراء تنازليا" && conditionSelected === 'جبدة'){
                setQu(query(collection(db, "Posts"), where("condition", "==", "جبدة"), where("productPrice", ">", 0), where("subCategory", "==", subCategorySearch)))
              }
              else if(orderSelectedName === "سعر الشراء تنازليا" && conditionSelected === 'مقبولة'){
                setQu(query(collection(db, "Posts"), where("condition", "==", "مقبولة"), where("productPrice", ">", 0), where("subCategory", "==", subCategorySearch)))
              }
          
              else if(orderSelectedName === "سعر الشراء تصاعديا" && conditionSelected === 'جديد'){
                setQu(query(collection(db, "Posts"), where("condition", "==", "جديد"),  where("productPrice", ">", 0), where("subCategory", "==", subCategorySearch)))
              }
              else if(orderSelectedName === "سعر الشراء تصاعديا" && conditionSelected === 'مستعمل'){
                setQu(query(collection(db, "Posts"), where("condition", "==", "مستعمل"),  where("productPrice", ">", 0), where("subCategory", "==", subCategorySearch)))
              }
              else if(orderSelectedName === "سعر الشراء تصاعديا" && conditionSelected === 'ممتازة'){
                setQu(query(collection(db, "Posts"), where("condition", "==", "ممتازة"),  where("productPrice", ">", 0), where("subCategory", "==", subCategorySearch)))
              }
              else if(orderSelectedName === "سعر الشراء تصاعديا" && conditionSelected === 'جبدة جدا'){
                setQu(query(collection(db, "Posts"), where("condition", "==", "جبدة جدا"),  where("productPrice", ">", 0), where("subCategory", "==", subCategorySearch)))
              }
              else if(orderSelectedName === "سعر الشراء تصاعديا" && conditionSelected === 'جبدة'){
                setQu(query(collection(db, "Posts"), where("condition", "==", "جبدة"),  where("productPrice", ">", 0), where("subCategory", "==", subCategorySearch)))
              }
              else if(orderSelectedName === "سعر الشراء تصاعديا" && conditionSelected === 'مقبولة'){
                setQu(query(collection(db, "Posts"), where("condition", "==", "مقبولة"),  where("productPrice", ">", 0), where("subCategory", "==", subCategorySearch)))
              }
          
              else if(orderSelectedName === "سعر المزايدة تصاعديا" && conditionSelected === 'جديد'){
                setQu(query(collection(db, "Posts"), where("condition", "==", "جديد"), where("acceptsOffers", "==", false), where("subCategory", "==", subCategorySearch)))
              }
              else if(orderSelectedName === "سعر المزايدة تصاعديا" && conditionSelected === 'ممتازة'){
                setQu(query(collection(db, "Posts"), where("condition", "==", "ممتازة"), where("acceptsOffers", "==", false), where("subCategory", "==", subCategorySearch)))
              }
              else if(orderSelectedName === "سعر المزايدة تصاعديا" && conditionSelected === 'جبدة جدا'){
                setQu(query(collection(db, "Posts"), where("condition", "==", "جبدة جدا"), where("acceptsOffers", "==", false), where("subCategory", "==", subCategorySearch)))
              }
              else if(orderSelectedName === "سعر المزايدة تصاعديا" && conditionSelected === 'جبدة'){
                setQu(query(collection(db, "Posts"), where("condition", "==", "جبدة"), where("acceptsOffers", "==", false), where("subCategory", "==", subCategorySearch)))
              }
              else if(orderSelectedName === "سعر المزايدة تصاعديا" && conditionSelected === 'مقبولة'){
                setQu(query(collection(db, "Posts"), where("condition", "==", "مقبولة"), where("acceptsOffers", "==", false), where("subCategory", "==", subCategorySearch)))
              }
          
          
              else if(orderSelectedName === "سعر المزايدة تنازليا" && conditionSelected === 'جديد'){
                setQu(query(collection(db, "Posts"), where("condition", "==", "جديد"), where("acceptsOffers", "==", false), where("subCategory", "==", subCategorySearch)))
              }
              else if(orderSelectedName === "سعر المزايدة تنازليا" && conditionSelected === 'ممتازة'){
                setQu(query(collection(db, "Posts"), where("condition", "==", "ممتازة"), where("acceptsOffers", "==", false), where("subCategory", "==", subCategorySearch)))
              }
              else if(orderSelectedName === "سعر المزايدة تنازليا" && conditionSelected === 'جبدة جدا'){
                setQu(query(collection(db, "Posts"), where("condition", "==", "جبدة جدا"), where("acceptsOffers", "==", false), where("subCategory", "==", subCategorySearch)))
              }
              else if(orderSelectedName === "سعر المزايدة تنازليا" && conditionSelected === 'جبدة'){
                setQu(query(collection(db, "Posts"), where("condition", "==", "جبدة"), where("acceptsOffers", "==", false), where("subCategory", "==", subCategorySearch)))
              }
              else if(orderSelectedName === "سعر المزايدة تنازليا" && conditionSelected === 'مقبولة'){
                setQu(query(collection(db, "Posts"), where("condition", "==", "مقبولة"), where("acceptsOffers", "==", false), where("subCategory", "==", subCategorySearch)))
              }
            }
              }
            }
            else{
              setQu(query(collection(db, "Posts"), where("keywords", "array-contains-any", res.split(" "))))
            }
          
            
          }
          }} id={orderSelectedName === "سعر المزايدة تصاعديا" || orderSelectedName === "سعر المزايدة تنازليا" ? "filter-buttons-disabled" : "filter-buttons"}> لا يقبل مزايدات {postKind === 'لا يقبل مزايدات' ? <FaCheck /> : ""}</li>
         









         
          <li 
          style={{
            fontSize: "14px"
          }}
          onClick={()=>{
            if(postKind !== 'يقبل مزايدات مع سعر شراء فوري'){
              // setListing(initialListing);
              setPostKind("يقبل مزايدات مع سعر شراء فوري")
            
              if(res === null){
                if(categorySearch !== null){
                  // only condition selected
              if(conditionSelected !== "" && orderSelectedName === ""){
                if(conditionSelected === 'جديد'){
                  setQu(query(collection(db, "Posts"), where("condition", "==", "جديد"), where("acceptsOffers", "==", true), where("productPrice", ">", 0), where("category", "==", categorySearch)))
                  // setListing(Listing.filter(post => post.acceptsOffers === true));
                }
                else if(conditionSelected === 'مستعمل'){
                  setQu(query(collection(db, "Posts"), where("condition", "==", "مستعمل"), where("acceptsOffers", "==", true), where("productPrice", ">", 0), where("category", "==", categorySearch)))
                  // setListing(initialListing.filter(post => post.condition === "مستعمل").filter(post => post.acceptsOffers === true));
                }
                else if(conditionSelected === 'ممتازة'){
                  setQu(query(collection(db, "Posts"), where("condition", "==", "ممتازة"), where("acceptsOffers", "==", true), where("productPrice", ">", 0), where("category", "==", categorySearch)))
                  // setListing(initialListing.filter(post => post.condition === "ممتازة").filter(post => post.acceptsOffers === true));
                }
                else if(conditionSelected === 'جيدة جدا'){
                  setQu(query(collection(db, "Posts"), where("condition", "==", "جيدة جدا"), where("acceptsOffers", "==", true), where("productPrice", ">", 0), where("category", "==", categorySearch)))
                  // setListing(initialListing.filter(post => post.condition === "جيدة جدا").filter(post => post.acceptsOffers === true));
                }
                else if(conditionSelected === 'جيدة'){
                  setQu(query(collection(db, "Posts"), where("condition", "==", "جيدة"), where("acceptsOffers", "==", true), where("productPrice", ">", 0), where("category", "==", categorySearch)))
                  // setListing(initialListing.filter(post => post.condition === "جيدة").filter(post => post.acceptsOffers === true));
                }
                else if(conditionSelected === 'مقبولة'){
                  setQu(query(collection(db, "Posts"), where("condition", "==", "مقبولة"), where("acceptsOffers", "==", true), where("productPrice", ">", 0), where("category", "==", categorySearch)))
                  // setListing(initialListing.filter(post => post.condition === "مقبولة").filter(post => post.acceptsOffers === true));
                }
              }
            
              // todo : add only order test for accepts offers button along with unclicking
            
              // only order
              else if(orderSelectedName !== "" && conditionSelected === ""){
                if(orderSelectedName === "سعر الشراء تنازليا" || orderSelectedName === "سعر الشراء تصاعديا" ){
                  setQu(query(collection(db, "Posts"), where("acceptsOffers", "==", true),  where("productPrice", ">", 0), where("category", "==", categorySearch)))
                }
                else if(orderSelectedName === "سعر المزايدة تنازليا" || orderSelectedName === "سعر المزايدة تصاعديا" ){
                  setQu(query(collection(db, "Posts"), where("acceptsOffers", "==", true), where("productPrice", ">", 0), where("category", "==", categorySearch)))
                }
              }
            
              // both selected
              else if(conditionSelected !== "" && orderSelectedName !== ""){
                if(orderSelectedName === "سعر الشراء تنازليا" && conditionSelected === 'جديد'){
                  setQu(query(collection(db, "Posts"), where("condition", "==", "جديد"), where("acceptsOffers", "==", true), where("productPrice", ">", 0), where("category", "==", categorySearch)))
                }
                else if(orderSelectedName === "سعر الشراء تنازليا" && conditionSelected === 'مستعمل'){
                  setQu(query(collection(db, "Posts"), where("condition", "==", "مستعمل"), where("acceptsOffers", "==", true), where("productPrice", ">", 0), where("category", "==", categorySearch)))
                }
                else if(orderSelectedName === "سعر الشراء تنازليا" && conditionSelected === 'ممتازة'){
                  setQu(query(collection(db, "Posts"), where("condition", "==", "ممتازة"), where("acceptsOffers", "==", true), where("productPrice", ">", 0), where("category", "==", categorySearch)))
                }
                else if(orderSelectedName === "سعر الشراء تنازليا" && conditionSelected === 'جبدة جدا'){
                  setQu(query(collection(db, "Posts"), where("condition", "==", "جبدة جدا"), where("acceptsOffers", "==", true), where("productPrice", ">", 0), where("category", "==", categorySearch)))
                }
                else if(orderSelectedName === "سعر الشراء تنازليا" && conditionSelected === 'جبدة'){
                  setQu(query(collection(db, "Posts"), where("condition", "==", "جبدة"), where("acceptsOffers", "==", true), where("productPrice", ">", 0), where("category", "==", categorySearch)))
                }
                else if(orderSelectedName === "سعر الشراء تنازليا" && conditionSelected === 'مقبولة'){
                  setQu(query(collection(db, "Posts"), where("condition", "==", "مقبولة"), where("acceptsOffers", "==", true), where("productPrice", ">", 0), where("category", "==", categorySearch)))
                }
            
                else if(orderSelectedName === "سعر الشراء تصاعديا" && conditionSelected === 'جديد'){
                  setQu(query(collection(db, "Posts"), where("condition", "==", "جديد"), where("acceptsOffers", "==", true), where("productPrice", ">", 0), where("category", "==", categorySearch)))
                }
                else if(orderSelectedName === "سعر الشراء تصاعديا" && conditionSelected === 'مستعمل'){
                  setQu(query(collection(db, "Posts"), where("condition", "==", "مستعمل"), where("acceptsOffers", "==", true), where("productPrice", ">", 0), where("category", "==", categorySearch)))
                }
                else if(orderSelectedName === "سعر الشراء تصاعديا" && conditionSelected === 'ممتازة'){
                  setQu(query(collection(db, "Posts"), where("condition", "==", "ممتازة"), where("acceptsOffers", "==", true), where("productPrice", ">", 0), where("category", "==", categorySearch)))
                }
                else if(orderSelectedName === "سعر الشراء تصاعديا" && conditionSelected === 'جبدة جدا'){
                  setQu(query(collection(db, "Posts"), where("condition", "==", "جبدة جدا"), where("acceptsOffers", "==", true), where("productPrice", ">", 0), where("category", "==", categorySearch)))
                }
                else if(orderSelectedName === "سعر الشراء تصاعديا" && conditionSelected === 'جبدة'){
                  setQu(query(collection(db, "Posts"), where("condition", "==", "جبدة"), where("acceptsOffers", "==", true), where("productPrice", ">", 0), where("category", "==", categorySearch)))
                }
                else if(orderSelectedName === "سعر الشراء تصاعديا" && conditionSelected === 'مقبولة'){
                  setQu(query(collection(db, "Posts"), where("condition", "==", "مقبولة"), where("acceptsOffers", "==", true), where("productPrice", ">", 0), where("category", "==", categorySearch)))
                }
            
                else if(orderSelectedName === "سعر المزايدة تصاعديا" && conditionSelected === 'جديد'){
                  setQu(query(collection(db, "Posts"), where("condition", "==", "جديد"), where("acceptsOffers", "==", true), where("productPrice", ">", 0), where("category", "==", categorySearch)))
                }
                else if(orderSelectedName === "سعر المزايدة تصاعديا" && conditionSelected === 'ممتازة'){
                  setQu(query(collection(db, "Posts"), where("condition", "==", "ممتازة"), where("acceptsOffers", "==", true), where("productPrice", ">", 0), where("category", "==", categorySearch)))
                }
                else if(orderSelectedName === "سعر المزايدة تصاعديا" && conditionSelected === 'جبدة جدا'){
                  setQu(query(collection(db, "Posts"), where("condition", "==", "جبدة جدا"), where("acceptsOffers", "==", true), where("productPrice", ">", 0), where("category", "==", categorySearch)))
                }
                else if(orderSelectedName === "سعر المزايدة تصاعديا" && conditionSelected === 'جبدة'){
                  setQu(query(collection(db, "Posts"), where("condition", "==", "جبدة"), where("acceptsOffers", "==", true), where("productPrice", ">", 0), where("category", "==", categorySearch)))
                }
                else if(orderSelectedName === "سعر المزايدة تصاعديا" && conditionSelected === 'مقبولة'){
                  setQu(query(collection(db, "Posts"), where("condition", "==", "مقبولة"), where("acceptsOffers", "==", true), where("productPrice", ">", 0), where("category", "==", categorySearch)))
                }
            
            
                else if(orderSelectedName === "سعر المزايدة تنازليا" && conditionSelected === 'جديد'){
                  setQu(query(collection(db, "Posts"), where("condition", "==", "جديد"), where("acceptsOffers", "==", true), where("productPrice", ">", 0), where("category", "==", categorySearch)))
                }
                else if(orderSelectedName === "سعر المزايدة تنازليا" && conditionSelected === 'ممتازة'){
                  setQu(query(collection(db, "Posts"), where("condition", "==", "ممتازة"), where("acceptsOffers", "==", true), where("productPrice", ">", 0), where("category", "==", categorySearch)))
                }
                else if(orderSelectedName === "سعر المزايدة تنازليا" && conditionSelected === 'جبدة جدا'){
                  setQu(query(collection(db, "Posts"), where("condition", "==", "جبدة جدا"), where("acceptsOffers", "==", true), where("productPrice", ">", 0), where("category", "==", categorySearch)))
                }
                else if(orderSelectedName === "سعر المزايدة تنازليا" && conditionSelected === 'جبدة'){
                  setQu(query(collection(db, "Posts"), where("condition", "==", "جبدة"), where("acceptsOffers", "==", true), where("productPrice", ">", 0), where("category", "==", categorySearch)))
                }
                else if(orderSelectedName === "سعر المزايدة تنازليا" && conditionSelected === 'مقبولة'){
                  setQu(query(collection(db, "Posts"), where("condition", "==", "مقبولة"), where("acceptsOffers", "==", true), where("productPrice", ">", 0), where("category", "==", categorySearch)))
                }
              }
            
              // neither selected
              else{
                setQu(query(collection(db, "Posts"), where("acceptsOffers", "==", true), where("productPrice", ">", 0), where("category", "==", categorySearch)))
              }
                }
                else if(subCategorySearch !== null){
                   // only condition selected
              if(conditionSelected !== "" && orderSelectedName === ""){
                if(conditionSelected === 'جديد'){
                  setQu(query(collection(db, "Posts"), where("condition", "==", "جديد"), where("acceptsOffers", "==", true), where("productPrice", ">", 0), where("subCategory", "==", subCategorySearch)))
                  // setListing(Listing.filter(post => post.acceptsOffers === true));
                }
                else if(conditionSelected === 'مستعمل'){
                  setQu(query(collection(db, "Posts"), where("condition", "==", "مستعمل"), where("acceptsOffers", "==", true), where("productPrice", ">", 0), where("subCategory", "==", subCategorySearch)))
                  // setListing(initialListing.filter(post => post.condition === "مستعمل").filter(post => post.acceptsOffers === true));
                }
                else if(conditionSelected === 'ممتازة'){
                  setQu(query(collection(db, "Posts"), where("condition", "==", "ممتازة"), where("acceptsOffers", "==", true), where("productPrice", ">", 0), where("subCategory", "==", subCategorySearch)))
                  // setListing(initialListing.filter(post => post.condition === "ممتازة").filter(post => post.acceptsOffers === true));
                }
                else if(conditionSelected === 'جيدة جدا'){
                  setQu(query(collection(db, "Posts"), where("condition", "==", "جيدة جدا"), where("acceptsOffers", "==", true), where("productPrice", ">", 0), where("subCategory", "==", subCategorySearch)))
                  // setListing(initialListing.filter(post => post.condition === "جيدة جدا").filter(post => post.acceptsOffers === true));
                }
                else if(conditionSelected === 'جيدة'){
                  setQu(query(collection(db, "Posts"), where("condition", "==", "جيدة"), where("acceptsOffers", "==", true), where("productPrice", ">", 0), where("subCategory", "==", subCategorySearch)))
                  // setListing(initialListing.filter(post => post.condition === "جيدة").filter(post => post.acceptsOffers === true));
                }
                else if(conditionSelected === 'مقبولة'){
                  setQu(query(collection(db, "Posts"), where("condition", "==", "مقبولة"), where("acceptsOffers", "==", true), where("productPrice", ">", 0), where("subCategory", "==", subCategorySearch)))
                  // setListing(initialListing.filter(post => post.condition === "مقبولة").filter(post => post.acceptsOffers === true));
                }
              }
            
              // todo : add only order test for accepts offers button along with unclicking
            
              // only order
              else if(orderSelectedName !== "" && conditionSelected === ""){
                if(orderSelectedName === "سعر الشراء تنازليا" || orderSelectedName === "سعر الشراء تصاعديا" ){
                  setQu(query(collection(db, "Posts"), where("acceptsOffers", "==", true), where("productPrice", ">", 0), where("subCategory", "==", subCategorySearch)))
                }
                else if(orderSelectedName === "سعر المزايدة تنازليا" || orderSelectedName === "سعر المزايدة تصاعديا" ){
                  setQu(query(collection(db, "Posts"), where("acceptsOffers", "==", true), where("subCategory", "==", subCategorySearch)))
                }
              }
            
              // both selected
              else if(conditionSelected !== "" && orderSelectedName !== ""){
                if(orderSelectedName === "سعر الشراء تنازليا" && conditionSelected === 'جديد'){
                  setQu(query(collection(db, "Posts"), where("condition", "==", "جديد"), where("acceptsOffers", "==", true), where("productPrice", ">", 0), where("subCategory", "==", subCategorySearch)))
                }
                else if(orderSelectedName === "سعر الشراء تنازليا" && conditionSelected === 'مستعمل'){
                  setQu(query(collection(db, "Posts"), where("condition", "==", "مستعمل"), where("acceptsOffers", "==", true), where("productPrice", ">", 0), where("subCategory", "==", subCategorySearch)))
                }
                else if(orderSelectedName === "سعر الشراء تنازليا" && conditionSelected === 'ممتازة'){
                  setQu(query(collection(db, "Posts"), where("condition", "==", "ممتازة"), where("acceptsOffers", "==", true), where("productPrice", ">", 0), where("subCategory", "==", subCategorySearch)))
                }
                else if(orderSelectedName === "سعر الشراء تنازليا" && conditionSelected === 'جبدة جدا'){
                  setQu(query(collection(db, "Posts"), where("condition", "==", "جبدة جدا"), where("acceptsOffers", "==", true), where("productPrice", ">", 0), where("subCategory", "==", subCategorySearch)))
                }
                else if(orderSelectedName === "سعر الشراء تنازليا" && conditionSelected === 'جبدة'){
                  setQu(query(collection(db, "Posts"), where("condition", "==", "جبدة"), where("acceptsOffers", "==", true), where("productPrice", ">", 0), where("subCategory", "==", subCategorySearch)))
                }
                else if(orderSelectedName === "سعر الشراء تنازليا" && conditionSelected === 'مقبولة'){
                  setQu(query(collection(db, "Posts"), where("condition", "==", "مقبولة"), where("acceptsOffers", "==", true), where("productPrice", ">", 0), where("subCategory", "==", subCategorySearch)))
                }
            
                else if(orderSelectedName === "سعر الشراء تصاعديا" && conditionSelected === 'جديد'){
                  setQu(query(collection(db, "Posts"), where("condition", "==", "جديد"), where("acceptsOffers", "==", true), where("productPrice", ">", 0), where("subCategory", "==", subCategorySearch)))
                }
                else if(orderSelectedName === "سعر الشراء تصاعديا" && conditionSelected === 'مستعمل'){
                  setQu(query(collection(db, "Posts"), where("condition", "==", "مستعمل"), where("acceptsOffers", "==", true), where("productPrice", ">", 0), where("subCategory", "==", subCategorySearch)))
                }
                else if(orderSelectedName === "سعر الشراء تصاعديا" && conditionSelected === 'ممتازة'){
                  setQu(query(collection(db, "Posts"), where("condition", "==", "ممتازة"), where("acceptsOffers", "==", true), where("productPrice", ">", 0), where("subCategory", "==", subCategorySearch)))
                }
                else if(orderSelectedName === "سعر الشراء تصاعديا" && conditionSelected === 'جبدة جدا'){
                  setQu(query(collection(db, "Posts"), where("condition", "==", "جبدة جدا"), where("acceptsOffers", "==", true), where("productPrice", ">", 0), where("subCategory", "==", subCategorySearch)))
                }
                else if(orderSelectedName === "سعر الشراء تصاعديا" && conditionSelected === 'جبدة'){
                  setQu(query(collection(db, "Posts"), where("condition", "==", "جبدة"), where("acceptsOffers", "==", true), where("productPrice", ">", 0), where("subCategory", "==", subCategorySearch)))
                }
                else if(orderSelectedName === "سعر الشراء تصاعديا" && conditionSelected === 'مقبولة'){
                  setQu(query(collection(db, "Posts"), where("condition", "==", "مقبولة"), where("acceptsOffers", "==", true), where("productPrice", ">", 0), where("subCategory", "==", subCategorySearch)))
                }
            
                else if(orderSelectedName === "سعر المزايدة تصاعديا" && conditionSelected === 'جديد'){
                  setQu(query(collection(db, "Posts"), where("condition", "==", "جديد"), where("acceptsOffers", "==", true), where("productPrice", ">", 0), where("subCategory", "==", subCategorySearch)))
                }
                else if(orderSelectedName === "سعر المزايدة تصاعديا" && conditionSelected === 'ممتازة'){
                  setQu(query(collection(db, "Posts"), where("condition", "==", "ممتازة"), where("acceptsOffers", "==", true), where("productPrice", ">", 0), where("subCategory", "==", subCategorySearch)))
                }
                else if(orderSelectedName === "سعر المزايدة تصاعديا" && conditionSelected === 'جبدة جدا'){
                  setQu(query(collection(db, "Posts"), where("condition", "==", "جبدة جدا"), where("acceptsOffers", "==", true), where("productPrice", ">", 0), where("subCategory", "==", subCategorySearch)))
                }
                else if(orderSelectedName === "سعر المزايدة تصاعديا" && conditionSelected === 'جبدة'){
                  setQu(query(collection(db, "Posts"), where("condition", "==", "جبدة"), where("acceptsOffers", "==", true), where("productPrice", ">", 0), where("subCategory", "==", subCategorySearch)))
                }
                else if(orderSelectedName === "سعر المزايدة تصاعديا" && conditionSelected === 'مقبولة'){
                  setQu(query(collection(db, "Posts"), where("condition", "==", "مقبولة"), where("acceptsOffers", "==", true), where("productPrice", ">", 0), where("subCategory", "==", subCategorySearch)))
                }
            
            
                else if(orderSelectedName === "سعر المزايدة تنازليا" && conditionSelected === 'جديد'){
                  setQu(query(collection(db, "Posts"), where("condition", "==", "جديد"), where("acceptsOffers", "==", true), where("productPrice", ">", 0), where("subCategory", "==", subCategorySearch)))
                }
                else if(orderSelectedName === "سعر المزايدة تنازليا" && conditionSelected === 'ممتازة'){
                  setQu(query(collection(db, "Posts"), where("condition", "==", "ممتازة"), where("acceptsOffers", "==", true), where("productPrice", ">", 0), where("subCategory", "==", subCategorySearch)))
                }
                else if(orderSelectedName === "سعر المزايدة تنازليا" && conditionSelected === 'جبدة جدا'){
                  setQu(query(collection(db, "Posts"), where("condition", "==", "جبدة جدا"), where("acceptsOffers", "==", true), where("productPrice", ">", 0), where("subCategory", "==", subCategorySearch)))
                }
                else if(orderSelectedName === "سعر المزايدة تنازليا" && conditionSelected === 'جبدة'){
                  setQu(query(collection(db, "Posts"), where("condition", "==", "جبدة"), where("acceptsOffers", "==", true), where("productPrice", ">", 0), where("subCategory", "==", subCategorySearch)))
                }
                else if(orderSelectedName === "سعر المزايدة تنازليا" && conditionSelected === 'مقبولة'){
                  setQu(query(collection(db, "Posts"), where("condition", "==", "مقبولة"), where("acceptsOffers", "==", true), where("productPrice", ">", 0), where("subCategory", "==", subCategorySearch)))
                }
              }
            
              // neither selected
              else{
                setQu(query(collection(db, "Posts"), where("acceptsOffers", "==", true), where("productPrice", ">", 0), where("subCategory", "==", subCategorySearch)))
              }
                }
              }
              else{
            // res is not null
            setQu(query(collection(db, "Posts"), where("acceptsOffers", "==", true), where("productPrice", ">", 0), where("keywords", "array-contains-any", res.split(" "))))
            
              }
            }
            else {
              setPostKind("");
              // setListing(initialListing);
            
              if(res === null){
                if(categorySearch !== null){
                  // condition selected
              if(conditionSelected !== "" && orderSelectedName === ""){
                if(conditionSelected === 'جديد'){
                  setQu(query(collection(db, "Posts"), where("condition", "==", "جديد"), where("category", "==", categorySearch)))
                  // setListing(initialListing.filter(post => post.condition === "جديد"));
                }
                else if(conditionSelected === 'مستعمل'){
                  setQu(query(collection(db, "Posts"), where("condition", "==", "مستعمل"), where("category", "==", categorySearch)))
                  // setListing(initialListing.filter(post => post.condition === "مستعمل"));
                }
                else if(conditionSelected === 'ممتازة'){
                  setQu(query(collection(db, "Posts"), where("condition", "==", "ممتازة"), where("category", "==", categorySearch)))
                  // setListing(initialListing.filter(post => post.condition === "ممتازة"));
                }
                else if(conditionSelected === 'جيدة جدا'){
                  setQu(query(collection(db, "Posts"), where("condition", "==", "جيدة جدا"), where("category", "==", categorySearch)))
                  // setListing(initialListing.filter(post => post.condition === "جيدة جدا"));
                }
                else if(conditionSelected === 'جيدة'){
                  setQu(query(collection(db, "Posts"), where("condition", "==", "جيدة"), where("category", "==", categorySearch)))
                  // setListing(initialListing.filter(post => post.condition === "جيدة"));
                }
                else if(conditionSelected === 'مقبولة'){
                  setQu(query(collection(db, "Posts"), where("condition", "==", "مقبولة"), where("category", "==", categorySearch)))
                  // setListing(initialListing.filter(post => post.condition === "مقبولة"));
                }
            
              }
            
                // only order
                else if(orderSelectedName !== "" && conditionSelected === ""){
                  if(orderSelectedName === "سعر الشراء تنازليا" || orderSelectedName === "سعر الشراء تصاعديا" ){
                    setQu(query(collection(db, "Posts"), where("productPrice", ">", 0), where("category", "==", categorySearch)))
                  }
                  else if(orderSelectedName === "سعر المزايدة تنازليا" || orderSelectedName === "سعر المزايدة تصاعديا" ){
                    setQu(query(collection(db, "Posts"), where("acceptsOffers", "==", true), where("category", "==", categorySearch)))
                  }
                }
            
              // both selected
              else if(conditionSelected !== "" && orderSelectedName !== ""){
                if(orderSelectedName === "سعر الشراء تنازليا" && conditionSelected === 'جديد'){
                  setQu(query(collection(db, "Posts"), where("condition", "==", "جديد"), where("productPrice", ">", 0), where("category", "==", categorySearch)))
                }
                else if(orderSelectedName === "سعر الشراء تنازليا" && conditionSelected === 'مستعمل'){
                  setQu(query(collection(db, "Posts"), where("condition", "==", "مستعمل"), where("productPrice", ">", 0), where("category", "==", categorySearch)))
                }
                else if(orderSelectedName === "سعر الشراء تنازليا" && conditionSelected === 'ممتازة'){
                  setQu(query(collection(db, "Posts"), where("condition", "==", "ممتازة"), where("productPrice", ">", 0), where("category", "==", categorySearch)))
                }
                else if(orderSelectedName === "سعر الشراء تنازليا" && conditionSelected === 'جبدة جدا'){
                  setQu(query(collection(db, "Posts"), where("condition", "==", "جبدة جدا"), where("productPrice", ">", 0), where("category", "==", categorySearch)))
                }
                else if(orderSelectedName === "سعر الشراء تنازليا" && conditionSelected === 'جبدة'){
                  setQu(query(collection(db, "Posts"), where("condition", "==", "جبدة"), where("productPrice", ">", 0), where("category", "==", categorySearch)))
                }
                else if(orderSelectedName === "سعر الشراء تنازليا" && conditionSelected === 'مقبولة'){
                  setQu(query(collection(db, "Posts"), where("condition", "==", "مقبولة"), where("productPrice", ">", 0), where("category", "==", categorySearch)))
                }
            
                else if(orderSelectedName === "سعر الشراء تصاعديا" && conditionSelected === 'جديد'){
                  setQu(query(collection(db, "Posts"), where("condition", "==", "جديد"),  where("productPrice", ">", 0), where("category", "==", categorySearch)))
                }
                else if(orderSelectedName === "سعر الشراء تصاعديا" && conditionSelected === 'مستعمل'){
                  setQu(query(collection(db, "Posts"), where("condition", "==", "مستعمل"),  where("productPrice", ">", 0), where("category", "==", categorySearch)))
                }
                else if(orderSelectedName === "سعر الشراء تصاعديا" && conditionSelected === 'ممتازة'){
                  setQu(query(collection(db, "Posts"), where("condition", "==", "ممتازة"),  where("productPrice", ">", 0), where("category", "==", categorySearch)))
                }
                else if(orderSelectedName === "سعر الشراء تصاعديا" && conditionSelected === 'جبدة جدا'){
                  setQu(query(collection(db, "Posts"), where("condition", "==", "جبدة جدا"),  where("productPrice", ">", 0), where("category", "==", categorySearch)))
                }
                else if(orderSelectedName === "سعر الشراء تصاعديا" && conditionSelected === 'جبدة'){
                  setQu(query(collection(db, "Posts"), where("condition", "==", "جبدة"),  where("productPrice", ">", 0), where("category", "==", categorySearch)))
                }
                else if(orderSelectedName === "سعر الشراء تصاعديا" && conditionSelected === 'مقبولة'){
                  setQu(query(collection(db, "Posts"), where("condition", "==", "مقبولة"),  where("productPrice", ">", 0), where("category", "==", categorySearch)))
                }
            
                else if(orderSelectedName === "سعر المزايدة تصاعديا" && conditionSelected === 'جديد'){
                  setQu(query(collection(db, "Posts"), where("condition", "==", "جديد"), where("acceptsOffers", "==", true), where("category", "==", categorySearch)))
                }
                else if(orderSelectedName === "سعر المزايدة تصاعديا" && conditionSelected === 'ممتازة'){
                  setQu(query(collection(db, "Posts"), where("condition", "==", "ممتازة"), where("acceptsOffers", "==", true), where("category", "==", categorySearch)))
                }
                else if(orderSelectedName === "سعر المزايدة تصاعديا" && conditionSelected === 'جبدة جدا'){
                  setQu(query(collection(db, "Posts"), where("condition", "==", "جبدة جدا"), where("acceptsOffers", "==", true), where("category", "==", categorySearch)))
                }
                else if(orderSelectedName === "سعر المزايدة تصاعديا" && conditionSelected === 'جبدة'){
                  setQu(query(collection(db, "Posts"), where("condition", "==", "جبدة"), where("acceptsOffers", "==", true), where("category", "==", categorySearch)))
                }
                else if(orderSelectedName === "سعر المزايدة تصاعديا" && conditionSelected === 'مقبولة'){
                  setQu(query(collection(db, "Posts"), where("condition", "==", "مقبولة"), where("acceptsOffers", "==", true), where("category", "==", categorySearch)))
                }
            
            
                else if(orderSelectedName === "سعر المزايدة تنازليا" && conditionSelected === 'جديد'){
                  setQu(query(collection(db, "Posts"), where("condition", "==", "جديد"), where("acceptsOffers", "==", true), where("category", "==", categorySearch)))
                }
                else if(orderSelectedName === "سعر المزايدة تنازليا" && conditionSelected === 'ممتازة'){
                  setQu(query(collection(db, "Posts"), where("condition", "==", "ممتازة"), where("acceptsOffers", "==", true), where("category", "==", categorySearch)))
                }
                else if(orderSelectedName === "سعر المزايدة تنازليا" && conditionSelected === 'جبدة جدا'){
                  setQu(query(collection(db, "Posts"), where("condition", "==", "جبدة جدا"), where("acceptsOffers", "==", true), where("category", "==", categorySearch)))
                }
                else if(orderSelectedName === "سعر المزايدة تنازليا" && conditionSelected === 'جبدة'){
                  setQu(query(collection(db, "Posts"), where("condition", "==", "جبدة"), where("acceptsOffers", "==", true), where("category", "==", categorySearch)))
                }
                else if(orderSelectedName === "سعر المزايدة تنازليا" && conditionSelected === 'مقبولة'){
                  setQu(query(collection(db, "Posts"), where("condition", "==", "مقبولة"), where("acceptsOffers", "==", true), where("category", "==", categorySearch)))
                }
              }
            
              // neither selected
              else {
                setQu(query(collection(db, "Posts"), where("category", "==", categorySearch)))
              }
                }
                else if(subCategorySearch !== null){
                   // condition selected
              if(conditionSelected !== "" && orderSelectedName === ""){
                if(conditionSelected === 'جديد'){
                  setQu(query(collection(db, "Posts"), where("condition", "==", "جديد"), where("subCategory", "==", subCategorySearch)))
                  // setListing(initialListing.filter(post => post.condition === "جديد"));
                }
                else if(conditionSelected === 'مستعمل'){
                  setQu(query(collection(db, "Posts"), where("condition", "==", "مستعمل"), where("subCategory", "==", subCategorySearch)))
                  // setListing(initialListing.filter(post => post.condition === "مستعمل"));
                }
                else if(conditionSelected === 'ممتازة'){
                  setQu(query(collection(db, "Posts"), where("condition", "==", "ممتازة"), where("subCategory", "==", subCategorySearch)))
                  // setListing(initialListing.filter(post => post.condition === "ممتازة"));
                }
                else if(conditionSelected === 'جيدة جدا'){
                  setQu(query(collection(db, "Posts"), where("condition", "==", "جيدة جدا"), where("subCategory", "==", subCategorySearch)))
                  // setListing(initialListing.filter(post => post.condition === "جيدة جدا"));
                }
                else if(conditionSelected === 'جيدة'){
                  setQu(query(collection(db, "Posts"), where("condition", "==", "جيدة"), where("subCategory", "==", subCategorySearch)))
                  // setListing(initialListing.filter(post => post.condition === "جيدة"));
                }
                else if(conditionSelected === 'مقبولة'){
                  setQu(query(collection(db, "Posts"), where("condition", "==", "مقبولة"), where("subCategory", "==", subCategorySearch)))
                  // setListing(initialListing.filter(post => post.condition === "مقبولة"));
                }
            
              }
            
                // only order
                else if(orderSelectedName !== "" && conditionSelected === ""){
                  if(orderSelectedName === "سعر الشراء تنازليا" || orderSelectedName === "سعر الشراء تصاعديا" ){
                    setQu(query(collection(db, "Posts"), where("productPrice", ">", 0), where("subCategory", "==", subCategorySearch)))
                  }
                  else if(orderSelectedName === "سعر المزايدة تنازليا" || orderSelectedName === "سعر المزايدة تصاعديا" ){
                    setQu(query(collection(db, "Posts"), where("acceptsOffers", "==", true), where("subCategory", "==", subCategorySearch)))
                  }
                }
            
              // both selected
              else if(conditionSelected !== "" && orderSelectedName !== ""){
                if(orderSelectedName === "سعر الشراء تنازليا" && conditionSelected === 'جديد'){
                  setQu(query(collection(db, "Posts"), where("condition", "==", "جديد"), where("productPrice", ">", 0), where("subCategory", "==", subCategorySearch)))
                }
                else if(orderSelectedName === "سعر الشراء تنازليا" && conditionSelected === 'مستعمل'){
                  setQu(query(collection(db, "Posts"), where("condition", "==", "مستعمل"), where("productPrice", ">", 0), where("subCategory", "==", subCategorySearch)))
                }
                else if(orderSelectedName === "سعر الشراء تنازليا" && conditionSelected === 'ممتازة'){
                  setQu(query(collection(db, "Posts"), where("condition", "==", "ممتازة"), where("productPrice", ">", 0), where("subCategory", "==", subCategorySearch)))
                }
                else if(orderSelectedName === "سعر الشراء تنازليا" && conditionSelected === 'جبدة جدا'){
                  setQu(query(collection(db, "Posts"), where("condition", "==", "جبدة جدا"), where("productPrice", ">", 0), where("subCategory", "==", subCategorySearch)))
                }
                else if(orderSelectedName === "سعر الشراء تنازليا" && conditionSelected === 'جبدة'){
                  setQu(query(collection(db, "Posts"), where("condition", "==", "جبدة"), where("productPrice", ">", 0), where("subCategory", "==", subCategorySearch)))
                }
                else if(orderSelectedName === "سعر الشراء تنازليا" && conditionSelected === 'مقبولة'){
                  setQu(query(collection(db, "Posts"), where("condition", "==", "مقبولة"), where("productPrice", ">", 0), where("subCategory", "==", subCategorySearch)))
                }
            
                else if(orderSelectedName === "سعر الشراء تصاعديا" && conditionSelected === 'جديد'){
                  setQu(query(collection(db, "Posts"), where("condition", "==", "جديد"),  where("productPrice", ">", 0), where("subCategory", "==", subCategorySearch)))
                }
                else if(orderSelectedName === "سعر الشراء تصاعديا" && conditionSelected === 'مستعمل'){
                  setQu(query(collection(db, "Posts"), where("condition", "==", "مستعمل"),  where("productPrice", ">", 0), where("subCategory", "==", subCategorySearch)))
                }
                else if(orderSelectedName === "سعر الشراء تصاعديا" && conditionSelected === 'ممتازة'){
                  setQu(query(collection(db, "Posts"), where("condition", "==", "ممتازة"),  where("productPrice", ">", 0), where("subCategory", "==", subCategorySearch)))
                }
                else if(orderSelectedName === "سعر الشراء تصاعديا" && conditionSelected === 'جبدة جدا'){
                  setQu(query(collection(db, "Posts"), where("condition", "==", "جبدة جدا"),  where("productPrice", ">", 0), where("subCategory", "==", subCategorySearch)))
                }
                else if(orderSelectedName === "سعر الشراء تصاعديا" && conditionSelected === 'جبدة'){
                  setQu(query(collection(db, "Posts"), where("condition", "==", "جبدة"),  where("productPrice", ">", 0), where("subCategory", "==", subCategorySearch)))
                }
                else if(orderSelectedName === "سعر الشراء تصاعديا" && conditionSelected === 'مقبولة'){
                  setQu(query(collection(db, "Posts"), where("condition", "==", "مقبولة"),  where("productPrice", ">", 0), where("subCategory", "==", subCategorySearch)))
                }
            
                else if(orderSelectedName === "سعر المزايدة تصاعديا" && conditionSelected === 'جديد'){
                  setQu(query(collection(db, "Posts"), where("condition", "==", "جديد"), where("acceptsOffers", "==", true), where("subCategory", "==", subCategorySearch)))
                }
                else if(orderSelectedName === "سعر المزايدة تصاعديا" && conditionSelected === 'ممتازة'){
                  setQu(query(collection(db, "Posts"), where("condition", "==", "ممتازة"), where("acceptsOffers", "==", true), where("subCategory", "==", subCategorySearch)))
                }
                else if(orderSelectedName === "سعر المزايدة تصاعديا" && conditionSelected === 'جبدة جدا'){
                  setQu(query(collection(db, "Posts"), where("condition", "==", "جبدة جدا"), where("acceptsOffers", "==", true), where("subCategory", "==", subCategorySearch)))
                }
                else if(orderSelectedName === "سعر المزايدة تصاعديا" && conditionSelected === 'جبدة'){
                  setQu(query(collection(db, "Posts"), where("condition", "==", "جبدة"), where("acceptsOffers", "==", true), where("subCategory", "==", subCategorySearch)))
                }
                else if(orderSelectedName === "سعر المزايدة تصاعديا" && conditionSelected === 'مقبولة'){
                  setQu(query(collection(db, "Posts"), where("condition", "==", "مقبولة"), where("acceptsOffers", "==", true), where("subCategory", "==", subCategorySearch)))
                }
            
            
                else if(orderSelectedName === "سعر المزايدة تنازليا" && conditionSelected === 'جديد'){
                  setQu(query(collection(db, "Posts"), where("condition", "==", "جديد"), where("acceptsOffers", "==", true), where("subCategory", "==", subCategorySearch)))
                }
                else if(orderSelectedName === "سعر المزايدة تنازليا" && conditionSelected === 'ممتازة'){
                  setQu(query(collection(db, "Posts"), where("condition", "==", "ممتازة"), where("acceptsOffers", "==", true), where("subCategory", "==", subCategorySearch)))
                }
                else if(orderSelectedName === "سعر المزايدة تنازليا" && conditionSelected === 'جبدة جدا'){
                  setQu(query(collection(db, "Posts"), where("condition", "==", "جبدة جدا"), where("acceptsOffers", "==", true), where("subCategory", "==", subCategorySearch)))
                }
                else if(orderSelectedName === "سعر المزايدة تنازليا" && conditionSelected === 'جبدة'){
                  setQu(query(collection(db, "Posts"), where("condition", "==", "جبدة"), where("acceptsOffers", "==", true), where("subCategory", "==", subCategorySearch)))
                }
                else if(orderSelectedName === "سعر المزايدة تنازليا" && conditionSelected === 'مقبولة'){
                  setQu(query(collection(db, "Posts"), where("condition", "==", "مقبولة"), where("acceptsOffers", "==", true), where("subCategory", "==", subCategorySearch)))
                }
              }
                }
              }
              else{
                setQu(query(collection(db, "Posts"), where("keywords", "array-contains-any", res.split(" "))))
              }
            
              
            }
          }} id='filter-buttons'> يقبل مزايدات مع سعر شراء فوري {postKind === 'يقبل مزايدات مع سعر شراء فوري' ? <FaCheck /> : ""}</li>
         
         










          <li 
          style={{
            fontSize: "14px"
          }}
          onClick={()=>{
          if(postKind !== 'يقبل مزايدات بدون سعر شراء فوري'){
            // setListing(initialListing);
            setPostKind("يقبل مزايدات بدون سعر شراء فوري")
          
            if(res === null){
              if(categorySearch !== null){
                // only condition selected
            if(conditionSelected !== "" && orderSelectedName === ""){
              if(conditionSelected === 'جديد'){
                setQu(query(collection(db, "Posts"), where("condition", "==", "جديد"), where("acceptsOffers", "==", true), where("productPrice", "==", 0), where("category", "==", categorySearch)))
                // setListing(Listing.filter(post => post.acceptsOffers === true));
              }
              else if(conditionSelected === 'مستعمل'){
                setQu(query(collection(db, "Posts"), where("condition", "==", "مستعمل"), where("acceptsOffers", "==", true), where("productPrice", "==", 0), where("category", "==", categorySearch)))
                // setListing(initialListing.filter(post => post.condition === "مستعمل").filter(post => post.acceptsOffers === true));
              }
              else if(conditionSelected === 'ممتازة'){
                setQu(query(collection(db, "Posts"), where("condition", "==", "ممتازة"), where("acceptsOffers", "==", true), where("productPrice", "==", 0), where("category", "==", categorySearch)))
                // setListing(initialListing.filter(post => post.condition === "ممتازة").filter(post => post.acceptsOffers === true));
              }
              else if(conditionSelected === 'جيدة جدا'){
                setQu(query(collection(db, "Posts"), where("condition", "==", "جيدة جدا"), where("acceptsOffers", "==", true), where("productPrice", "==", 0), where("category", "==", categorySearch)))
                // setListing(initialListing.filter(post => post.condition === "جيدة جدا").filter(post => post.acceptsOffers === true));
              }
              else if(conditionSelected === 'جيدة'){
                setQu(query(collection(db, "Posts"), where("condition", "==", "جيدة"), where("acceptsOffers", "==", true), where("productPrice", "==", 0), where("category", "==", categorySearch)))
                // setListing(initialListing.filter(post => post.condition === "جيدة").filter(post => post.acceptsOffers === true));
              }
              else if(conditionSelected === 'مقبولة'){
                setQu(query(collection(db, "Posts"), where("condition", "==", "مقبولة"), where("acceptsOffers", "==", true), where("productPrice", "==", 0), where("category", "==", categorySearch)))
                // setListing(initialListing.filter(post => post.condition === "مقبولة").filter(post => post.acceptsOffers === true));
              }
            }
          
            // todo : add only order test for accepts offers button along with unclicking
          
            // only order
            else if(orderSelectedName !== "" && conditionSelected === ""){
              if(orderSelectedName === "سعر الشراء تنازليا" || orderSelectedName === "سعر الشراء تصاعديا" ){
                setQu(query(collection(db, "Posts"), where("acceptsOffers", "==", true),  where("productPrice", "==", 0), where("category", "==", categorySearch)))
              }
              else if(orderSelectedName === "سعر المزايدة تنازليا" || orderSelectedName === "سعر المزايدة تصاعديا" ){
                setQu(query(collection(db, "Posts"), where("acceptsOffers", "==", true), where("productPrice", "==", 0), where("category", "==", categorySearch)))
              }
            }
          
            // both selected
            else if(conditionSelected !== "" && orderSelectedName !== ""){
              if(orderSelectedName === "سعر الشراء تنازليا" && conditionSelected === 'جديد'){
                setQu(query(collection(db, "Posts"), where("condition", "==", "جديد"), where("acceptsOffers", "==", true), where("productPrice", "==", 0), where("category", "==", categorySearch)))
              }
              else if(orderSelectedName === "سعر الشراء تنازليا" && conditionSelected === 'مستعمل'){
                setQu(query(collection(db, "Posts"), where("condition", "==", "مستعمل"), where("acceptsOffers", "==", true), where("productPrice", "==", 0), where("category", "==", categorySearch)))
              }
              else if(orderSelectedName === "سعر الشراء تنازليا" && conditionSelected === 'ممتازة'){
                setQu(query(collection(db, "Posts"), where("condition", "==", "ممتازة"), where("acceptsOffers", "==", true), where("productPrice", "==", 0), where("category", "==", categorySearch)))
              }
              else if(orderSelectedName === "سعر الشراء تنازليا" && conditionSelected === 'جبدة جدا'){
                setQu(query(collection(db, "Posts"), where("condition", "==", "جبدة جدا"), where("acceptsOffers", "==", true), where("productPrice", "==", 0), where("category", "==", categorySearch)))
              }
              else if(orderSelectedName === "سعر الشراء تنازليا" && conditionSelected === 'جبدة'){
                setQu(query(collection(db, "Posts"), where("condition", "==", "جبدة"), where("acceptsOffers", "==", true), where("productPrice", "==", 0), where("category", "==", categorySearch)))
              }
              else if(orderSelectedName === "سعر الشراء تنازليا" && conditionSelected === 'مقبولة'){
                setQu(query(collection(db, "Posts"), where("condition", "==", "مقبولة"), where("acceptsOffers", "==", true), where("productPrice", "==", 0), where("category", "==", categorySearch)))
              }
          
              else if(orderSelectedName === "سعر الشراء تصاعديا" && conditionSelected === 'جديد'){
                setQu(query(collection(db, "Posts"), where("condition", "==", "جديد"), where("acceptsOffers", "==", true), where("productPrice", "==", 0), where("category", "==", categorySearch)))
              }
              else if(orderSelectedName === "سعر الشراء تصاعديا" && conditionSelected === 'مستعمل'){
                setQu(query(collection(db, "Posts"), where("condition", "==", "مستعمل"), where("acceptsOffers", "==", true), where("productPrice", "==", 0), where("category", "==", categorySearch)))
              }
              else if(orderSelectedName === "سعر الشراء تصاعديا" && conditionSelected === 'ممتازة'){
                setQu(query(collection(db, "Posts"), where("condition", "==", "ممتازة"), where("acceptsOffers", "==", true), where("productPrice", "==", 0), where("category", "==", categorySearch)))
              }
              else if(orderSelectedName === "سعر الشراء تصاعديا" && conditionSelected === 'جبدة جدا'){
                setQu(query(collection(db, "Posts"), where("condition", "==", "جبدة جدا"), where("acceptsOffers", "==", true), where("productPrice", "==", 0), where("category", "==", categorySearch)))
              }
              else if(orderSelectedName === "سعر الشراء تصاعديا" && conditionSelected === 'جبدة'){
                setQu(query(collection(db, "Posts"), where("condition", "==", "جبدة"), where("acceptsOffers", "==", true), where("productPrice", "==", 0), where("category", "==", categorySearch)))
              }
              else if(orderSelectedName === "سعر الشراء تصاعديا" && conditionSelected === 'مقبولة'){
                setQu(query(collection(db, "Posts"), where("condition", "==", "مقبولة"), where("acceptsOffers", "==", true), where("productPrice", "==", 0), where("category", "==", categorySearch)))
              }
          
              else if(orderSelectedName === "سعر المزايدة تصاعديا" && conditionSelected === 'جديد'){
                setQu(query(collection(db, "Posts"), where("condition", "==", "جديد"), where("acceptsOffers", "==", true), where("productPrice", "==", 0), where("category", "==", categorySearch)))
              }
              else if(orderSelectedName === "سعر المزايدة تصاعديا" && conditionSelected === 'ممتازة'){
                setQu(query(collection(db, "Posts"), where("condition", "==", "ممتازة"), where("acceptsOffers", "==", true), where("productPrice", "==", 0), where("category", "==", categorySearch)))
              }
              else if(orderSelectedName === "سعر المزايدة تصاعديا" && conditionSelected === 'جبدة جدا'){
                setQu(query(collection(db, "Posts"), where("condition", "==", "جبدة جدا"), where("acceptsOffers", "==", true), where("productPrice", "==", 0), where("category", "==", categorySearch)))
              }
              else if(orderSelectedName === "سعر المزايدة تصاعديا" && conditionSelected === 'جبدة'){
                setQu(query(collection(db, "Posts"), where("condition", "==", "جبدة"), where("acceptsOffers", "==", true), where("productPrice", "==", 0), where("category", "==", categorySearch)))
              }
              else if(orderSelectedName === "سعر المزايدة تصاعديا" && conditionSelected === 'مقبولة'){
                setQu(query(collection(db, "Posts"), where("condition", "==", "مقبولة"), where("acceptsOffers", "==", true), where("productPrice", "==", 0), where("category", "==", categorySearch)))
              }
          
          
              else if(orderSelectedName === "سعر المزايدة تنازليا" && conditionSelected === 'جديد'){
                setQu(query(collection(db, "Posts"), where("condition", "==", "جديد"), where("acceptsOffers", "==", true), where("productPrice", "==", 0), where("category", "==", categorySearch)))
              }
              else if(orderSelectedName === "سعر المزايدة تنازليا" && conditionSelected === 'ممتازة'){
                setQu(query(collection(db, "Posts"), where("condition", "==", "ممتازة"), where("acceptsOffers", "==", true), where("productPrice", "==", 0), where("category", "==", categorySearch)))
              }
              else if(orderSelectedName === "سعر المزايدة تنازليا" && conditionSelected === 'جبدة جدا'){
                setQu(query(collection(db, "Posts"), where("condition", "==", "جبدة جدا"), where("acceptsOffers", "==", true), where("productPrice", "==", 0), where("category", "==", categorySearch)))
              }
              else if(orderSelectedName === "سعر المزايدة تنازليا" && conditionSelected === 'جبدة'){
                setQu(query(collection(db, "Posts"), where("condition", "==", "جبدة"), where("acceptsOffers", "==", true), where("productPrice", "==", 0), where("category", "==", categorySearch)))
              }
              else if(orderSelectedName === "سعر المزايدة تنازليا" && conditionSelected === 'مقبولة'){
                setQu(query(collection(db, "Posts"), where("condition", "==", "مقبولة"), where("acceptsOffers", "==", true), where("productPrice", "==", 0), where("category", "==", categorySearch)))
              }
            }
          
            // neither selected
            else{
              setQu(query(collection(db, "Posts"), where("acceptsOffers", "==", true), where("productPrice", "==", 0), where("category", "==", categorySearch)))
            }
              }
              else if(subCategorySearch !== null){
                 // only condition selected
            if(conditionSelected !== "" && orderSelectedName === ""){
              if(conditionSelected === 'جديد'){
                setQu(query(collection(db, "Posts"), where("condition", "==", "جديد"), where("acceptsOffers", "==", true), where("productPrice", "==", 0), where("subCategory", "==", subCategorySearch)))
                // setListing(Listing.filter(post => post.acceptsOffers === true));
              }
              else if(conditionSelected === 'مستعمل'){
                setQu(query(collection(db, "Posts"), where("condition", "==", "مستعمل"), where("acceptsOffers", "==", true), where("productPrice", "==", 0), where("subCategory", "==", subCategorySearch)))
                // setListing(initialListing.filter(post => post.condition === "مستعمل").filter(post => post.acceptsOffers === true));
              }
              else if(conditionSelected === 'ممتازة'){
                setQu(query(collection(db, "Posts"), where("condition", "==", "ممتازة"), where("acceptsOffers", "==", true), where("productPrice", "==", 0), where("subCategory", "==", subCategorySearch)))
                // setListing(initialListing.filter(post => post.condition === "ممتازة").filter(post => post.acceptsOffers === true));
              }
              else if(conditionSelected === 'جيدة جدا'){
                setQu(query(collection(db, "Posts"), where("condition", "==", "جيدة جدا"), where("acceptsOffers", "==", true), where("productPrice", "==", 0), where("subCategory", "==", subCategorySearch)))
                // setListing(initialListing.filter(post => post.condition === "جيدة جدا").filter(post => post.acceptsOffers === true));
              }
              else if(conditionSelected === 'جيدة'){
                setQu(query(collection(db, "Posts"), where("condition", "==", "جيدة"), where("acceptsOffers", "==", true), where("productPrice", "==", 0), where("subCategory", "==", subCategorySearch)))
                // setListing(initialListing.filter(post => post.condition === "جيدة").filter(post => post.acceptsOffers === true));
              }
              else if(conditionSelected === 'مقبولة'){
                setQu(query(collection(db, "Posts"), where("condition", "==", "مقبولة"), where("acceptsOffers", "==", true), where("productPrice", "==", 0), where("subCategory", "==", subCategorySearch)))
                // setListing(initialListing.filter(post => post.condition === "مقبولة").filter(post => post.acceptsOffers === true));
              }
            }
          
            // todo : add only order test for accepts offers button along with unclicking
          
            // only order
            else if(orderSelectedName !== "" && conditionSelected === ""){
              if(orderSelectedName === "سعر الشراء تنازليا" || orderSelectedName === "سعر الشراء تصاعديا" ){
                setQu(query(collection(db, "Posts"), where("acceptsOffers", "==", true), where("productPrice", "==", 0), where("subCategory", "==", subCategorySearch)))
              }
              else if(orderSelectedName === "سعر المزايدة تنازليا" || orderSelectedName === "سعر المزايدة تصاعديا" ){
                setQu(query(collection(db, "Posts"), where("acceptsOffers", "==", true), where("subCategory", "==", subCategorySearch)))
              }
            }
          
            // both selected
            else if(conditionSelected !== "" && orderSelectedName !== ""){
              if(orderSelectedName === "سعر الشراء تنازليا" && conditionSelected === 'جديد'){
                setQu(query(collection(db, "Posts"), where("condition", "==", "جديد"), where("acceptsOffers", "==", true), where("productPrice", "==", 0), where("subCategory", "==", subCategorySearch)))
              }
              else if(orderSelectedName === "سعر الشراء تنازليا" && conditionSelected === 'مستعمل'){
                setQu(query(collection(db, "Posts"), where("condition", "==", "مستعمل"), where("acceptsOffers", "==", true), where("productPrice", "==", 0), where("subCategory", "==", subCategorySearch)))
              }
              else if(orderSelectedName === "سعر الشراء تنازليا" && conditionSelected === 'ممتازة'){
                setQu(query(collection(db, "Posts"), where("condition", "==", "ممتازة"), where("acceptsOffers", "==", true), where("productPrice", "==", 0), where("subCategory", "==", subCategorySearch)))
              }
              else if(orderSelectedName === "سعر الشراء تنازليا" && conditionSelected === 'جبدة جدا'){
                setQu(query(collection(db, "Posts"), where("condition", "==", "جبدة جدا"), where("acceptsOffers", "==", true), where("productPrice", "==", 0), where("subCategory", "==", subCategorySearch)))
              }
              else if(orderSelectedName === "سعر الشراء تنازليا" && conditionSelected === 'جبدة'){
                setQu(query(collection(db, "Posts"), where("condition", "==", "جبدة"), where("acceptsOffers", "==", true), where("productPrice", "==", 0), where("subCategory", "==", subCategorySearch)))
              }
              else if(orderSelectedName === "سعر الشراء تنازليا" && conditionSelected === 'مقبولة'){
                setQu(query(collection(db, "Posts"), where("condition", "==", "مقبولة"), where("acceptsOffers", "==", true), where("productPrice", "==", 0), where("subCategory", "==", subCategorySearch)))
              }
          
              else if(orderSelectedName === "سعر الشراء تصاعديا" && conditionSelected === 'جديد'){
                setQu(query(collection(db, "Posts"), where("condition", "==", "جديد"), where("acceptsOffers", "==", true), where("productPrice", "==", 0), where("subCategory", "==", subCategorySearch)))
              }
              else if(orderSelectedName === "سعر الشراء تصاعديا" && conditionSelected === 'مستعمل'){
                setQu(query(collection(db, "Posts"), where("condition", "==", "مستعمل"), where("acceptsOffers", "==", true), where("productPrice", "==", 0), where("subCategory", "==", subCategorySearch)))
              }
              else if(orderSelectedName === "سعر الشراء تصاعديا" && conditionSelected === 'ممتازة'){
                setQu(query(collection(db, "Posts"), where("condition", "==", "ممتازة"), where("acceptsOffers", "==", true), where("productPrice", "==", 0), where("subCategory", "==", subCategorySearch)))
              }
              else if(orderSelectedName === "سعر الشراء تصاعديا" && conditionSelected === 'جبدة جدا'){
                setQu(query(collection(db, "Posts"), where("condition", "==", "جبدة جدا"), where("acceptsOffers", "==", true), where("productPrice", "==", 0), where("subCategory", "==", subCategorySearch)))
              }
              else if(orderSelectedName === "سعر الشراء تصاعديا" && conditionSelected === 'جبدة'){
                setQu(query(collection(db, "Posts"), where("condition", "==", "جبدة"), where("acceptsOffers", "==", true), where("productPrice", "==", 0), where("subCategory", "==", subCategorySearch)))
              }
              else if(orderSelectedName === "سعر الشراء تصاعديا" && conditionSelected === 'مقبولة'){
                setQu(query(collection(db, "Posts"), where("condition", "==", "مقبولة"), where("acceptsOffers", "==", true), where("productPrice", "==", 0), where("subCategory", "==", subCategorySearch)))
              }
          
              else if(orderSelectedName === "سعر المزايدة تصاعديا" && conditionSelected === 'جديد'){
                setQu(query(collection(db, "Posts"), where("condition", "==", "جديد"), where("acceptsOffers", "==", true), where("productPrice", "==", 0), where("subCategory", "==", subCategorySearch)))
              }
              else if(orderSelectedName === "سعر المزايدة تصاعديا" && conditionSelected === 'ممتازة'){
                setQu(query(collection(db, "Posts"), where("condition", "==", "ممتازة"), where("acceptsOffers", "==", true), where("productPrice", "==", 0), where("subCategory", "==", subCategorySearch)))
              }
              else if(orderSelectedName === "سعر المزايدة تصاعديا" && conditionSelected === 'جبدة جدا'){
                setQu(query(collection(db, "Posts"), where("condition", "==", "جبدة جدا"), where("acceptsOffers", "==", true), where("productPrice", "==", 0), where("subCategory", "==", subCategorySearch)))
              }
              else if(orderSelectedName === "سعر المزايدة تصاعديا" && conditionSelected === 'جبدة'){
                setQu(query(collection(db, "Posts"), where("condition", "==", "جبدة"), where("acceptsOffers", "==", true), where("productPrice", "==", 0), where("subCategory", "==", subCategorySearch)))
              }
              else if(orderSelectedName === "سعر المزايدة تصاعديا" && conditionSelected === 'مقبولة'){
                setQu(query(collection(db, "Posts"), where("condition", "==", "مقبولة"), where("acceptsOffers", "==", true), where("productPrice", "==", 0), where("subCategory", "==", subCategorySearch)))
              }
          
          
              else if(orderSelectedName === "سعر المزايدة تنازليا" && conditionSelected === 'جديد'){
                setQu(query(collection(db, "Posts"), where("condition", "==", "جديد"), where("acceptsOffers", "==", true), where("productPrice", "==", 0), where("subCategory", "==", subCategorySearch)))
              }
              else if(orderSelectedName === "سعر المزايدة تنازليا" && conditionSelected === 'ممتازة'){
                setQu(query(collection(db, "Posts"), where("condition", "==", "ممتازة"), where("acceptsOffers", "==", true), where("productPrice", "==", 0), where("subCategory", "==", subCategorySearch)))
              }
              else if(orderSelectedName === "سعر المزايدة تنازليا" && conditionSelected === 'جبدة جدا'){
                setQu(query(collection(db, "Posts"), where("condition", "==", "جبدة جدا"), where("acceptsOffers", "==", true), where("productPrice", "==", 0), where("subCategory", "==", subCategorySearch)))
              }
              else if(orderSelectedName === "سعر المزايدة تنازليا" && conditionSelected === 'جبدة'){
                setQu(query(collection(db, "Posts"), where("condition", "==", "جبدة"), where("acceptsOffers", "==", true), where("productPrice", "==", 0), where("subCategory", "==", subCategorySearch)))
              }
              else if(orderSelectedName === "سعر المزايدة تنازليا" && conditionSelected === 'مقبولة'){
                setQu(query(collection(db, "Posts"), where("condition", "==", "مقبولة"), where("acceptsOffers", "==", true), where("productPrice", "==", 0), where("subCategory", "==", subCategorySearch)))
              }
            }
          
            // neither selected
            else{
              setQu(query(collection(db, "Posts"), where("acceptsOffers", "==", true), where("productPrice", "==", 0), where("subCategory", "==", subCategorySearch)))
            }
              }
            }
            else{
          // res is not null
          setQu(query(collection(db, "Posts"), where("acceptsOffers", "==", true), where("productPrice", "==", 0), where("keywords", "array-contains-any", res.split(" "))))
          
            }
          }
          else {
            setPostKind("");
            // setListing(initialListing);
          
            if(res === null){
              if(categorySearch !== null){
                // condition selected
            if(conditionSelected !== "" && orderSelectedName === ""){
              if(conditionSelected === 'جديد'){
                setQu(query(collection(db, "Posts"), where("condition", "==", "جديد"), where("category", "==", categorySearch)))
                // setListing(initialListing.filter(post => post.condition === "جديد"));
              }
              else if(conditionSelected === 'مستعمل'){
                setQu(query(collection(db, "Posts"), where("condition", "==", "مستعمل"), where("category", "==", categorySearch)))
                // setListing(initialListing.filter(post => post.condition === "مستعمل"));
              }
              else if(conditionSelected === 'ممتازة'){
                setQu(query(collection(db, "Posts"), where("condition", "==", "ممتازة"), where("category", "==", categorySearch)))
                // setListing(initialListing.filter(post => post.condition === "ممتازة"));
              }
              else if(conditionSelected === 'جيدة جدا'){
                setQu(query(collection(db, "Posts"), where("condition", "==", "جيدة جدا"), where("category", "==", categorySearch)))
                // setListing(initialListing.filter(post => post.condition === "جيدة جدا"));
              }
              else if(conditionSelected === 'جيدة'){
                setQu(query(collection(db, "Posts"), where("condition", "==", "جيدة"), where("category", "==", categorySearch)))
                // setListing(initialListing.filter(post => post.condition === "جيدة"));
              }
              else if(conditionSelected === 'مقبولة'){
                setQu(query(collection(db, "Posts"), where("condition", "==", "مقبولة"), where("category", "==", categorySearch)))
                // setListing(initialListing.filter(post => post.condition === "مقبولة"));
              }
          
            }
          
              // only order
              else if(orderSelectedName !== "" && conditionSelected === ""){
                if(orderSelectedName === "سعر الشراء تنازليا" || orderSelectedName === "سعر الشراء تصاعديا" ){
                  setQu(query(collection(db, "Posts"), where("productPrice", "==", 0), where("category", "==", categorySearch)))
                }
                else if(orderSelectedName === "سعر المزايدة تنازليا" || orderSelectedName === "سعر المزايدة تصاعديا" ){
                  setQu(query(collection(db, "Posts"), where("acceptsOffers", "==", true), where("category", "==", categorySearch)))
                }
              }
          
            // both selected
            else if(conditionSelected !== "" && orderSelectedName !== ""){
              if(orderSelectedName === "سعر الشراء تنازليا" && conditionSelected === 'جديد'){
                setQu(query(collection(db, "Posts"), where("condition", "==", "جديد"), where("productPrice", "==", 0), where("category", "==", categorySearch)))
              }
              else if(orderSelectedName === "سعر الشراء تنازليا" && conditionSelected === 'مستعمل'){
                setQu(query(collection(db, "Posts"), where("condition", "==", "مستعمل"), where("productPrice", "==", 0), where("category", "==", categorySearch)))
              }
              else if(orderSelectedName === "سعر الشراء تنازليا" && conditionSelected === 'ممتازة'){
                setQu(query(collection(db, "Posts"), where("condition", "==", "ممتازة"), where("productPrice", "==", 0), where("category", "==", categorySearch)))
              }
              else if(orderSelectedName === "سعر الشراء تنازليا" && conditionSelected === 'جبدة جدا'){
                setQu(query(collection(db, "Posts"), where("condition", "==", "جبدة جدا"), where("productPrice", "==", 0), where("category", "==", categorySearch)))
              }
              else if(orderSelectedName === "سعر الشراء تنازليا" && conditionSelected === 'جبدة'){
                setQu(query(collection(db, "Posts"), where("condition", "==", "جبدة"), where("productPrice", "==", 0), where("category", "==", categorySearch)))
              }
              else if(orderSelectedName === "سعر الشراء تنازليا" && conditionSelected === 'مقبولة'){
                setQu(query(collection(db, "Posts"), where("condition", "==", "مقبولة"), where("productPrice", "==", 0), where("category", "==", categorySearch)))
              }
          
              else if(orderSelectedName === "سعر الشراء تصاعديا" && conditionSelected === 'جديد'){
                setQu(query(collection(db, "Posts"), where("condition", "==", "جديد"),  where("productPrice", "==", 0), where("category", "==", categorySearch)))
              }
              else if(orderSelectedName === "سعر الشراء تصاعديا" && conditionSelected === 'مستعمل'){
                setQu(query(collection(db, "Posts"), where("condition", "==", "مستعمل"),  where("productPrice", "==", 0), where("category", "==", categorySearch)))
              }
              else if(orderSelectedName === "سعر الشراء تصاعديا" && conditionSelected === 'ممتازة'){
                setQu(query(collection(db, "Posts"), where("condition", "==", "ممتازة"),  where("productPrice", "==", 0), where("category", "==", categorySearch)))
              }
              else if(orderSelectedName === "سعر الشراء تصاعديا" && conditionSelected === 'جبدة جدا'){
                setQu(query(collection(db, "Posts"), where("condition", "==", "جبدة جدا"),  where("productPrice", "==", 0), where("category", "==", categorySearch)))
              }
              else if(orderSelectedName === "سعر الشراء تصاعديا" && conditionSelected === 'جبدة'){
                setQu(query(collection(db, "Posts"), where("condition", "==", "جبدة"),  where("productPrice", "==", 0), where("category", "==", categorySearch)))
              }
              else if(orderSelectedName === "سعر الشراء تصاعديا" && conditionSelected === 'مقبولة'){
                setQu(query(collection(db, "Posts"), where("condition", "==", "مقبولة"),  where("productPrice", "==", 0), where("category", "==", categorySearch)))
              }
          
              else if(orderSelectedName === "سعر المزايدة تصاعديا" && conditionSelected === 'جديد'){
                setQu(query(collection(db, "Posts"), where("condition", "==", "جديد"), where("acceptsOffers", "==", true), where("category", "==", categorySearch)))
              }
              else if(orderSelectedName === "سعر المزايدة تصاعديا" && conditionSelected === 'ممتازة'){
                setQu(query(collection(db, "Posts"), where("condition", "==", "ممتازة"), where("acceptsOffers", "==", true), where("category", "==", categorySearch)))
              }
              else if(orderSelectedName === "سعر المزايدة تصاعديا" && conditionSelected === 'جبدة جدا'){
                setQu(query(collection(db, "Posts"), where("condition", "==", "جبدة جدا"), where("acceptsOffers", "==", true), where("category", "==", categorySearch)))
              }
              else if(orderSelectedName === "سعر المزايدة تصاعديا" && conditionSelected === 'جبدة'){
                setQu(query(collection(db, "Posts"), where("condition", "==", "جبدة"), where("acceptsOffers", "==", true), where("category", "==", categorySearch)))
              }
              else if(orderSelectedName === "سعر المزايدة تصاعديا" && conditionSelected === 'مقبولة'){
                setQu(query(collection(db, "Posts"), where("condition", "==", "مقبولة"), where("acceptsOffers", "==", true), where("category", "==", categorySearch)))
              }
          
          
              else if(orderSelectedName === "سعر المزايدة تنازليا" && conditionSelected === 'جديد'){
                setQu(query(collection(db, "Posts"), where("condition", "==", "جديد"), where("acceptsOffers", "==", true), where("category", "==", categorySearch)))
              }
              else if(orderSelectedName === "سعر المزايدة تنازليا" && conditionSelected === 'ممتازة'){
                setQu(query(collection(db, "Posts"), where("condition", "==", "ممتازة"), where("acceptsOffers", "==", true), where("category", "==", categorySearch)))
              }
              else if(orderSelectedName === "سعر المزايدة تنازليا" && conditionSelected === 'جبدة جدا'){
                setQu(query(collection(db, "Posts"), where("condition", "==", "جبدة جدا"), where("acceptsOffers", "==", true), where("category", "==", categorySearch)))
              }
              else if(orderSelectedName === "سعر المزايدة تنازليا" && conditionSelected === 'جبدة'){
                setQu(query(collection(db, "Posts"), where("condition", "==", "جبدة"), where("acceptsOffers", "==", true), where("category", "==", categorySearch)))
              }
              else if(orderSelectedName === "سعر المزايدة تنازليا" && conditionSelected === 'مقبولة'){
                setQu(query(collection(db, "Posts"), where("condition", "==", "مقبولة"), where("acceptsOffers", "==", true), where("category", "==", categorySearch)))
              }
            }
          
            // neither selected
            else {
              setQu(query(collection(db, "Posts"), where("category", "==", categorySearch)))
            }
              }
              else if(subCategorySearch !== null){
                 // condition selected
            if(conditionSelected !== "" && orderSelectedName === ""){
              if(conditionSelected === 'جديد'){
                setQu(query(collection(db, "Posts"), where("condition", "==", "جديد"), where("subCategory", "==", subCategorySearch)))
                // setListing(initialListing.filter(post => post.condition === "جديد"));
              }
              else if(conditionSelected === 'مستعمل'){
                setQu(query(collection(db, "Posts"), where("condition", "==", "مستعمل"), where("subCategory", "==", subCategorySearch)))
                // setListing(initialListing.filter(post => post.condition === "مستعمل"));
              }
              else if(conditionSelected === 'ممتازة'){
                setQu(query(collection(db, "Posts"), where("condition", "==", "ممتازة"), where("subCategory", "==", subCategorySearch)))
                // setListing(initialListing.filter(post => post.condition === "ممتازة"));
              }
              else if(conditionSelected === 'جيدة جدا'){
                setQu(query(collection(db, "Posts"), where("condition", "==", "جيدة جدا"), where("subCategory", "==", subCategorySearch)))
                // setListing(initialListing.filter(post => post.condition === "جيدة جدا"));
              }
              else if(conditionSelected === 'جيدة'){
                setQu(query(collection(db, "Posts"), where("condition", "==", "جيدة"), where("subCategory", "==", subCategorySearch)))
                // setListing(initialListing.filter(post => post.condition === "جيدة"));
              }
              else if(conditionSelected === 'مقبولة'){
                setQu(query(collection(db, "Posts"), where("condition", "==", "مقبولة"), where("subCategory", "==", subCategorySearch)))
                // setListing(initialListing.filter(post => post.condition === "مقبولة"));
              }
          
            }
          
              // only order
              else if(orderSelectedName !== "" && conditionSelected === ""){
                if(orderSelectedName === "سعر الشراء تنازليا" || orderSelectedName === "سعر الشراء تصاعديا" ){
                  setQu(query(collection(db, "Posts"), where("productPrice", "==", 0), where("subCategory", "==", subCategorySearch)))
                }
                else if(orderSelectedName === "سعر المزايدة تنازليا" || orderSelectedName === "سعر المزايدة تصاعديا" ){
                  setQu(query(collection(db, "Posts"), where("acceptsOffers", "==", true), where("subCategory", "==", subCategorySearch)))
                }
              }
          
            // both selected
            else if(conditionSelected !== "" && orderSelectedName !== ""){
              if(orderSelectedName === "سعر الشراء تنازليا" && conditionSelected === 'جديد'){
                setQu(query(collection(db, "Posts"), where("condition", "==", "جديد"), where("productPrice", "==", 0), where("subCategory", "==", subCategorySearch)))
              }
              else if(orderSelectedName === "سعر الشراء تنازليا" && conditionSelected === 'مستعمل'){
                setQu(query(collection(db, "Posts"), where("condition", "==", "مستعمل"), where("productPrice", "==", 0), where("subCategory", "==", subCategorySearch)))
              }
              else if(orderSelectedName === "سعر الشراء تنازليا" && conditionSelected === 'ممتازة'){
                setQu(query(collection(db, "Posts"), where("condition", "==", "ممتازة"), where("productPrice", "==", 0), where("subCategory", "==", subCategorySearch)))
              }
              else if(orderSelectedName === "سعر الشراء تنازليا" && conditionSelected === 'جبدة جدا'){
                setQu(query(collection(db, "Posts"), where("condition", "==", "جبدة جدا"), where("productPrice", "==", 0), where("subCategory", "==", subCategorySearch)))
              }
              else if(orderSelectedName === "سعر الشراء تنازليا" && conditionSelected === 'جبدة'){
                setQu(query(collection(db, "Posts"), where("condition", "==", "جبدة"), where("productPrice", "==", 0), where("subCategory", "==", subCategorySearch)))
              }
              else if(orderSelectedName === "سعر الشراء تنازليا" && conditionSelected === 'مقبولة'){
                setQu(query(collection(db, "Posts"), where("condition", "==", "مقبولة"), where("productPrice", "==", 0), where("subCategory", "==", subCategorySearch)))
              }
          
              else if(orderSelectedName === "سعر الشراء تصاعديا" && conditionSelected === 'جديد'){
                setQu(query(collection(db, "Posts"), where("condition", "==", "جديد"),  where("productPrice", "==", 0), where("subCategory", "==", subCategorySearch)))
              }
              else if(orderSelectedName === "سعر الشراء تصاعديا" && conditionSelected === 'مستعمل'){
                setQu(query(collection(db, "Posts"), where("condition", "==", "مستعمل"),  where("productPrice", "==", 0), where("subCategory", "==", subCategorySearch)))
              }
              else if(orderSelectedName === "سعر الشراء تصاعديا" && conditionSelected === 'ممتازة'){
                setQu(query(collection(db, "Posts"), where("condition", "==", "ممتازة"),  where("productPrice", "==", 0), where("subCategory", "==", subCategorySearch)))
              }
              else if(orderSelectedName === "سعر الشراء تصاعديا" && conditionSelected === 'جبدة جدا'){
                setQu(query(collection(db, "Posts"), where("condition", "==", "جبدة جدا"),  where("productPrice", "==", 0), where("subCategory", "==", subCategorySearch)))
              }
              else if(orderSelectedName === "سعر الشراء تصاعديا" && conditionSelected === 'جبدة'){
                setQu(query(collection(db, "Posts"), where("condition", "==", "جبدة"),  where("productPrice", "==", 0), where("subCategory", "==", subCategorySearch)))
              }
              else if(orderSelectedName === "سعر الشراء تصاعديا" && conditionSelected === 'مقبولة'){
                setQu(query(collection(db, "Posts"), where("condition", "==", "مقبولة"),  where("productPrice", "==", 0), where("subCategory", "==", subCategorySearch)))
              }
          
              else if(orderSelectedName === "سعر المزايدة تصاعديا" && conditionSelected === 'جديد'){
                setQu(query(collection(db, "Posts"), where("condition", "==", "جديد"), where("acceptsOffers", "==", true), where("subCategory", "==", subCategorySearch)))
              }
              else if(orderSelectedName === "سعر المزايدة تصاعديا" && conditionSelected === 'ممتازة'){
                setQu(query(collection(db, "Posts"), where("condition", "==", "ممتازة"), where("acceptsOffers", "==", true), where("subCategory", "==", subCategorySearch)))
              }
              else if(orderSelectedName === "سعر المزايدة تصاعديا" && conditionSelected === 'جبدة جدا'){
                setQu(query(collection(db, "Posts"), where("condition", "==", "جبدة جدا"), where("acceptsOffers", "==", true), where("subCategory", "==", subCategorySearch)))
              }
              else if(orderSelectedName === "سعر المزايدة تصاعديا" && conditionSelected === 'جبدة'){
                setQu(query(collection(db, "Posts"), where("condition", "==", "جبدة"), where("acceptsOffers", "==", true), where("subCategory", "==", subCategorySearch)))
              }
              else if(orderSelectedName === "سعر المزايدة تصاعديا" && conditionSelected === 'مقبولة'){
                setQu(query(collection(db, "Posts"), where("condition", "==", "مقبولة"), where("acceptsOffers", "==", true), where("subCategory", "==", subCategorySearch)))
              }
          
          
              else if(orderSelectedName === "سعر المزايدة تنازليا" && conditionSelected === 'جديد'){
                setQu(query(collection(db, "Posts"), where("condition", "==", "جديد"), where("acceptsOffers", "==", true), where("subCategory", "==", subCategorySearch)))
              }
              else if(orderSelectedName === "سعر المزايدة تنازليا" && conditionSelected === 'ممتازة'){
                setQu(query(collection(db, "Posts"), where("condition", "==", "ممتازة"), where("acceptsOffers", "==", true), where("subCategory", "==", subCategorySearch)))
              }
              else if(orderSelectedName === "سعر المزايدة تنازليا" && conditionSelected === 'جبدة جدا'){
                setQu(query(collection(db, "Posts"), where("condition", "==", "جبدة جدا"), where("acceptsOffers", "==", true), where("subCategory", "==", subCategorySearch)))
              }
              else if(orderSelectedName === "سعر المزايدة تنازليا" && conditionSelected === 'جبدة'){
                setQu(query(collection(db, "Posts"), where("condition", "==", "جبدة"), where("acceptsOffers", "==", true), where("subCategory", "==", subCategorySearch)))
              }
              else if(orderSelectedName === "سعر المزايدة تنازليا" && conditionSelected === 'مقبولة'){
                setQu(query(collection(db, "Posts"), where("condition", "==", "مقبولة"), where("acceptsOffers", "==", true), where("subCategory", "==", subCategorySearch)))
              }
            }
              }
            }
            else{
              setQu(query(collection(db, "Posts"), where("keywords", "array-contains-any", res.split(" "))))
            }
          
            
          }
          }} id={orderSelectedName === "سعر الشراء تصاعديا" || orderSelectedName === "سعر الشراء تنازليا" ? 'filter-buttons-disabled' : 'filter-buttons'}> يقبل مزايدات بدون سعر شراء فوري {postKind === 'يقبل مزايدات بدون سعر شراء فوري' ? <FaCheck /> : ""}</li>
        
        
        
        
        
        
        
        </ul>
          </div>

          <div
          id='filter-buttons-wrapper'
          style={{
            display: showFilters ? "block" : "none"
          }}
          >

          <ul
          id='filter-buttons-div'
          >
            <div
            style={{marginBottom: "auto", marginTop: "auto",justifyContent: "center"}}
            >
              <p style={{ margin : "0px", padding: "8px", whiteSpace: "nowrap"}}>الترتيب :</p>
            </div>
          <li 
          style={{
            fontSize: "14px"
          }}
          onClick={()=>{
            if(orderSelectedName!== "الاحدث فالأقدم"){
              setOrderSelectedName("الاحدث فالأقدم")
              Listing.sort((a, b) => a.createdAt - b.createdAt).reverse();
            }
            else{
            setOrderSelectedName("");
            }
          }} id='filter-buttons'> الاحدث فالأقدم {orderSelectedName === 'الاحدث فالأقدم' ? <FaCheck /> : ""}</li>
          <li 
          style={{
            fontSize: "14px"
          }}
          onClick={()=>{
            if(orderSelectedName!== "الأقدم فالأحدث"){
              setOrderSelectedName("الأقدم فالأحدث")
              Listing.sort((a, b) => a.createdAt - b.createdAt)
            }
            else{
            setOrderSelectedName("");
            Listing.sort((a, b) => a.createdAt - b.createdAt).reverse();
            }
            }} id='filter-buttons'> الأقدم فالأحدث {orderSelectedName === 'الأقدم فالأحدث' ? <FaCheck /> : ""}</li>
          <li 
          style={{
            fontSize: "14px"
          }}
          onClick={()=>{
              if(orderSelectedName !== "سعر الشراء تنازليا"){
                setOrderSelectedName("سعر الشراء تنازليا")
                // only condition
                if(conditionSelected !== "" && postKind === ""){
                  if(conditionSelected === 'جديد'){
                    // setQu(query(collection(db, "Posts"), where("condition", "==", "جديد"), where("productPrice", ">", 0)))
                    // setListing(Listing.filter(post => post.productPrice > 0))
                    setListing(initialListing.sort((a, b) => a.productPrice - b.productPrice).reverse().filter(post => post.condition === "جديد").filter(post => post.productPrice > 0));
                  }
                  else if(conditionSelected === 'مستعمل'){
                    // setQu(query(collection(db, "Posts"), where("condition", "==", "مستعمل"), where("productPrice", ">", 0)))
                    // setListing(Listing.filter(post => post.productPrice > 0))
                    setListing(initialListing.sort((a, b) => a.productPrice - b.productPrice).reverse().filter(post => post.condition === "مستعمل").filter(post => post.productPrice > 0));
                  }
                  else if(conditionSelected === 'ممتازة'){
                    // setQu(query(collection(db, "Posts"), where("condition", "==", "ممتازة"), where("productPrice", ">", 0)))
                    // setListing(Listing.filter(post => post.productPrice > 0))
                    setListing(initialListing.sort((a, b) => a.productPrice - b.productPrice).reverse().filter(post => post.condition === "ممتازة").filter(post => post.productPrice > 0));
                  }
                  else if(conditionSelected === 'جيدة جدا'){
                    // setQu(query(collection(db, "Posts"), where("condition", "==", "جيدة جدا"), where("productPrice", ">", 0)))
                    // setListing(Listing.filter(post => post.productPrice > 0))
                    setListing(initialListing.sort((a, b) => a.productPrice - b.productPrice).reverse().filter(post => post.condition === "جيدة جدا").filter(post => post.productPrice > 0));
                  }
                  else if(conditionSelected === 'جيدة'){
                    // setQu(query(collection(db, "Posts"), where("condition", "==", "جيدة"), where("productPrice", ">", 0)))
                    // setListing(Listing.filter(post => post.productPrice > 0))
                    setListing(initialListing.sort((a, b) => a.productPrice - b.productPrice).reverse().filter(post => post.condition === "جيدة").filter(post => post.productPrice > 0));
                  }
                  else if(conditionSelected === 'مقبولة'){
                    // setQu(query(collection(db, "Posts"), where("condition", "==", "مقبولة"), where("productPrice", ">", 0)))
                    // setListing(Listing.filter(post => post.productPrice > 0))
                    setListing(initialListing.sort((a, b) => a.productPrice - b.productPrice).reverse().filter(post => post.condition === "مقبولة").filter(post => post.productPrice > 0));
                  }
                }
                // only kind
                else if(postKind !== "" && conditionSelected === ""){
                  if(postKind === 'يقبل مزايدات'){
                    // setQu(query(collection(db, "Posts"), where("acceptsOffers", "==", true), where("productPrice", ">", 0)))
                    setListing(initialListing.sort((a, b) => a.productPrice - b.productPrice).reverse().filter(post => post.acceptsOffers === true).filter(post => post.productPrice > 0));
                  }
                  else if(postKind === 'لا يقبل مزايدات'){
                    // setQu(query(collection(db, "Posts"), where("acceptsOffers", "==", false), where("productPrice", ">", 0)))
                    setListing(initialListing.sort((a, b) => a.productPrice - b.productPrice).reverse().filter(post => post.acceptsOffers === false).filter(post => post.productPrice > 0));
                  }
                  else if(postKind === 'يقبل مزايدات مع سعر شراء فوري'){
                    // setQu(query(collection(db, "Posts"), where("acceptsOffers", "==", true), where("productPrice", ">", 0)))
                    setListing(initialListing.sort((a, b) => a.productPrice - b.productPrice).reverse().filter(post => post.acceptsOffers === true).filter(post => post.productPrice > 0));
                  }
                }

                // both selected
                else if(postKind !== "" && conditionSelected !== ""){
                  if(conditionSelected === 'جديد' && postKind === 'يقبل مزايدات'){
                    // setQu(query(collection(db, "Posts"), where("condition", "==", "جديد"), where("acceptsOffers", "==", true), where("productPrice", ">", 0)))
                    setListing(initialListing.sort((a, b) => a.productPrice - b.productPrice).reverse().filter(post => post.condition === "جديد").filter(post => post.productPrice > 0).filter(post => post.acceptsOffers === true));
                  }
                  else if(conditionSelected === 'جديد' && postKind === 'لا يقبل مزايدات'){
                    // setQu(query(collection(db, "Posts"), where("condition", "==", "جديد"), where("acceptsOffers", "==", false), where("productPrice", ">", 0)))
                    setListing(initialListing.sort((a, b) => a.productPrice - b.productPrice).reverse().filter(post => post.condition === "جديد").filter(post => post.productPrice > 0).filter(post => post.acceptsOffers === false));
                  }
                  else if(conditionSelected === 'جديد' && postKind === 'يقبل مزايدات مع سعر شراء فوري'){
                    // setQu(query(collection(db, "Posts"), where("condition", "==", "جديد"), where("acceptsOffers", "==", true), where("productPrice", ">", 0)))
                    setListing(initialListing.sort((a, b) => a.productPrice - b.productPrice).reverse().filter(post => post.condition === "جديد").filter(post => post.productPrice > 0).filter(post => post.acceptsOffers === true));
                  }
                  else if(conditionSelected === 'مستعمل' && postKind === 'يقبل مزايدات'){
                    // setQu(query(collection(db, "Posts"), where("condition", "==", "مستعمل"), where("acceptsOffers", "==", true), where("productPrice", ">", 0)))
                    setListing(initialListing.sort((a, b) => a.productPrice - b.productPrice).reverse().filter(post => post.condition === "مستعمل").filter(post => post.productPrice > 0).filter(post => post.acceptsOffers === true));
                  }
                  else if(conditionSelected === 'مستعمل' && postKind === 'لا يقبل مزايدات'){
                    // setQu(query(collection(db, "Posts"), where("condition", "==", "مستعمل"), where("acceptsOffers", "==", false), where("productPrice", ">", 0)))
                    setListing(initialListing.sort((a, b) => a.productPrice - b.productPrice).reverse().filter(post => post.condition === "مستعمل").filter(post => post.productPrice > 0).filter(post => post.acceptsOffers === false));
                  }
                  else if(conditionSelected === 'مستعمل' && postKind === 'يقبل مزايدات مع سعر شراء فوري'){
                    // setQu(query(collection(db, "Posts"), where("condition", "==", "جديد"), where("acceptsOffers", "==", true), where("productPrice", ">", 0)))
                    setListing(initialListing.sort((a, b) => a.productPrice - b.productPrice).reverse().filter(post => post.condition === "مستعمل").filter(post => post.productPrice > 0).filter(post => post.acceptsOffers === true));
                  }
                  else if(conditionSelected === 'ممتازة' && postKind === 'يقبل مزايدات'){
                    // setQu(query(collection(db, "Posts"), where("condition", "==", "ممتازة"), where("acceptsOffers", "==", true), where("productPrice", ">", 0)))
                    setListing(initialListing.sort((a, b) => a.productPrice - b.productPrice).reverse().filter(post => post.condition === "ممتازة").filter(post => post.productPrice > 0).filter(post => post.acceptsOffers === true));
                  }
                  else if(conditionSelected === 'ممتازة' && postKind === 'لا يقبل مزايدات'){
                    // setQu(query(collection(db, "Posts"), where("condition", "==", "ممتازة"), where("acceptsOffers", "==", false), where("productPrice", ">", 0)))
                    setListing(initialListing.sort((a, b) => a.productPrice - b.productPrice).reverse().filter(post => post.condition === "ممتازة").filter(post => post.productPrice > 0).filter(post => post.acceptsOffers === false));
                  }
                  else if(conditionSelected === 'ممتازة' && postKind === 'يقبل مزايدات مع سعر شراء فوري'){
                    // setQu(query(collection(db, "Posts"), where("condition", "==", "ممتازة"), where("acceptsOffers", "==", true), where("productPrice", ">", 0)))
                    setListing(initialListing.sort((a, b) => a.productPrice - b.productPrice).reverse().filter(post => post.condition === "ممتازة").filter(post => post.productPrice > 0).filter(post => post.acceptsOffers === true));
                  }
                  else if(conditionSelected === 'جيدة جدا' && postKind === 'يقبل مزايدات'){
                    // setQu(query(collection(db, "Posts"), where("condition", "==", "جيدة جدا"), where("acceptsOffers", "==", true), where("productPrice", ">", 0)))
                    setListing(initialListing.sort((a, b) => a.productPrice - b.productPrice).reverse().filter(post => post.condition === "جيدة جدا").filter(post => post.productPrice > 0).filter(post => post.acceptsOffers === true));
                  }
                  else if(conditionSelected === 'جيدة جدا' && postKind === 'لا يقبل مزايدات'){
                    // setQu(query(collection(db, "Posts"), where("condition", "==", "جيدة جدا"), where("acceptsOffers", "==", false), where("productPrice", ">", 0)))
                    setListing(initialListing.sort((a, b) => a.productPrice - b.productPrice).reverse().filter(post => post.condition === "جيدة جدا").filter(post => post.productPrice > 0).filter(post => post.acceptsOffers === false));
                  }
                  else if(conditionSelected === 'جيدة جدا' && postKind === 'يقبل مزايدات مع سعر شراء فوري'){
                    // setQu(query(collection(db, "Posts"), where("condition", "==", "جيدة جدا"), where("acceptsOffers", "==", true), where("productPrice", ">", 0)))
                    setListing(initialListing.sort((a, b) => a.productPrice - b.productPrice).reverse().filter(post => post.condition === "جيدة جدا").filter(post => post.productPrice > 0).filter(post => post.acceptsOffers === true));
                  }
                  else if(conditionSelected === 'جيدة' && postKind === 'يقبل مزايدات'){
                    // setQu(query(collection(db, "Posts"), where("condition", "==", "جيدة"), where("acceptsOffers", "==", true), where("productPrice", ">", 0)))
                    setListing(initialListing.sort((a, b) => a.productPrice - b.productPrice).reverse().filter(post => post.condition === "جيدة").filter(post => post.productPrice > 0).filter(post => post.acceptsOffers === true));
                  }
                  else if(conditionSelected === 'جيدة' && postKind === 'لا يقبل مزايدات'){
                    // setQu(query(collection(db, "Posts"), where("condition", "==", "جيدة"), where("acceptsOffers", "==", false), where("productPrice", ">", 0)))
                    setListing(initialListing.sort((a, b) => a.productPrice - b.productPrice).reverse().filter(post => post.condition === "جيدة").filter(post => post.productPrice > 0).filter(post => post.acceptsOffers === false));
                  }
                  else if(conditionSelected === 'جيدة' && postKind === 'يقبل مزايدات مع سعر شراء فوري'){
                    // setQu(query(collection(db, "Posts"), where("condition", "==", "جيدة"), where("acceptsOffers", "==", true), where("productPrice", ">", 0)))
                    setListing(initialListing.sort((a, b) => a.productPrice - b.productPrice).reverse().filter(post => post.condition === "جيدة").filter(post => post.productPrice > 0).filter(post => post.acceptsOffers === true));
                  }
                  else if(conditionSelected === 'مقبولة' && postKind === 'يقبل مزايدات'){
                    // setQu(query(collection(db, "Posts"), where("condition", "==", "مقبولة"), where("acceptsOffers", "==", true), where("productPrice", ">", 0)))
                    setListing(initialListing.sort((a, b) => a.productPrice - b.productPrice).reverse().filter(post => post.condition === "مقبولة").filter(post => post.productPrice > 0).filter(post => post.acceptsOffers === true));
                  }
                  else if(conditionSelected === 'مقبولة' && postKind === 'لا يقبل مزايدات'){
                    // setQu(query(collection(db, "Posts"), where("condition", "==", "مقبولة"), where("acceptsOffers", "==", false), where("productPrice", ">", 0)))
                    setListing(initialListing.sort((a, b) => a.productPrice - b.productPrice).reverse().filter(post => post.condition === "مقبولة").filter(post => post.productPrice > 0).filter(post => post.acceptsOffers === false));
                  }
                  else if(conditionSelected === 'مقبولة' && postKind === 'يقبل مزايدات مع سعر شراء فوري'){
                    // setQu(query(collection(db, "Posts"), where("condition", "==", "مقبولة"), where("acceptsOffers", "==", true), where("productPrice", ">", 0)))
                    setListing(initialListing.sort((a, b) => a.productPrice - b.productPrice).reverse().filter(post => post.condition === "مقبولة").filter(post => post.productPrice > 0).filter(post => post.acceptsOffers === true));
                  }
                }

                // neither
                else{
                  setQu(query(collection(db, "Posts"), where("productPrice", ">", 0)))
                }
              }
              else{
              setOrderSelectedName("");
              if(conditionSelected !== "" && postKind === ""){
                if(conditionSelected === 'جديد'){
                  setQu(query(collection(db, "Posts"), where("condition", "==", "جديد")))
                  // setListing(initialListing.sort((a, b) => a.createdAt - b.createdAt).reverse().filter(post => post.condition === "جديد"));
                }
                else if(conditionSelected === 'مستعمل'){
                  // setQu(query(collection(db, "Posts"), where("condition", "==", "مستعمل")))
                  setListing(initialListing.sort((a, b) => a.createdAt - b.createdAt).reverse().filter(post => post.condition === "مستعمل"));
                }
                else if(conditionSelected === 'ممتازة'){
                  // setQu(query(collection(db, "Posts"), where("condition", "==", "ممتازة")))
                  setListing(initialListing.sort((a, b) => a.createdAt - b.createdAt).reverse().filter(post => post.condition === "ممتازة"));
                }
                else if(conditionSelected === 'جيدة جدا'){
                  // setQu(query(collection(db, "Posts"), where("condition", "==", "جيدة جدا")))
                  setListing(initialListing.sort((a, b) => a.createdAt - b.createdAt).reverse().filter(post => post.condition === "جيدة جدا"));
                }
                else if(conditionSelected === 'جيدة'){
                  // setQu(query(collection(db, "Posts"), where("condition", "==", "جيدة")))
                  setListing(initialListing.sort((a, b) => a.createdAt - b.createdAt).reverse().filter(post => post.condition === "جيدة"));
                }
                else if(conditionSelected === 'مقبولة'){
                  // setQu(query(collection(db, "Posts"), where("condition", "==", "مقبولة")))
                  setListing(initialListing.sort((a, b) => a.createdAt - b.createdAt).reverse().filter(post => post.condition === "مقبولة"));
                }
              }
              // only kind
              else if(postKind !== "" && conditionSelected === ""){
                if(postKind === 'يقبل مزايدات'){
                  // setQu(query(collection(db, "Posts"), where("acceptsOffers", "==", true), where("productPrice", ">", 0)))
                  // setListing(Listing.filter(post => post.productPrice > 0))
                  setListing(initialListing.sort((a, b) => a.createdAt - b.createdAt).reverse().filter(post => post.acceptsOffers === true));
                }
                else if(postKind === 'لا يقبل مزايدات'){
                  // setQu(query(collection(db, "Posts"), where("acceptsOffers", "==", false), where("productPrice", ">", 0)))
                  // setListing(Listing.filter(post => post.productPrice > 0))
                  setListing(initialListing.sort((a, b) => a.createdAt - b.createdAt).reverse().filter(post => post.acceptsOffers === false));
                }
                else if(postKind === 'يقبل مزايدات مع سعر شراء فوري'){
                  // setQu(query(collection(db, "Posts"), where("acceptsOffers", "==", true), where("productPrice", ">", 0)))
                  // setListing(Listing.filter(post => post.productPrice > 0))
                  setListing(initialListing.sort((a, b) => a.createdAt - b.createdAt).reverse().filter(post => post.acceptsOffers === true).filter(post => post.productPrice > 0));
                }
              }

              // both selected
              else if(postKind !== "" && conditionSelected !== ""){
                if(conditionSelected === 'جديد' && postKind === 'يقبل مزايدات'){
                  // setQu(query(collection(db, "Posts"), where("condition", "==", "جديد"), where("acceptsOffers", "==", true)))
                  setListing(initialListing.sort((a, b) => a.createdAt - b.createdAt).reverse().filter(post => post.condition === "جديد").filter(post => post.acceptsOffers === true));
                }
                else if(conditionSelected === 'جديد' && postKind === 'لا يقبل مزايدات'){
                  // setQu(query(collection(db, "Posts"), where("condition", "==", "جديد"), where("acceptsOffers", "==", false)))
                  setListing(initialListing.sort((a, b) => a.createdAt - b.createdAt).reverse().filter(post => post.condition === "جديد").filter(post => post.acceptsOffers === false));
                }
                else if(conditionSelected === 'جديد' && postKind === 'يقبل مزايدات مع سعر شراء فوري'){
                  // setQu(query(collection(db, "Posts"), where("condition", "==", "جديد"), where("acceptsOffers", "==", true), where("productPrice", ">", 0)))
                  setListing(initialListing.sort((a, b) => a.createdAt - b.createdAt).reverse().filter(post => post.condition === "جديد").filter(post => post.productPrice > 0).filter(post => post.acceptsOffers === true));
                }
                else if(conditionSelected === 'مستعمل' && postKind === 'يقبل مزايدات'){
                  // setQu(query(collection(db, "Posts"), where("condition", "==", "مستعمل"), where("acceptsOffers", "==", true), where("productPrice", ">", 0)))
                  setListing(initialListing.sort((a, b) => a.createdAt - b.createdAt).reverse().filter(post => post.condition === "جديد").filter(post => post.acceptsOffers === true));
                }
                else if(conditionSelected === 'مستعمل' && postKind === 'لا يقبل مزايدات'){
                  // setQu(query(collection(db, "Posts"), where("condition", "==", "مستعمل"), where("acceptsOffers", "==", false)))
                  setListing(initialListing.sort((a, b) => a.createdAt - b.createdAt).reverse().filter(post => post.condition === "جديد").filter(post => post.acceptsOffers === false));
                }
                else if(conditionSelected === 'مستعمل' && postKind === 'يقبل مزايدات مع سعر شراء فوري'){
                  // setQu(query(collection(db, "Posts"), where("condition", "==", "مستعمل"), where("acceptsOffers", "==", true), where("productPrice", ">", 0)))
                  setListing(initialListing.sort((a, b) => a.createdAt - b.createdAt).reverse().filter(post => post.condition === "جديد").filter(post => post.productPrice > 0).filter(post => post.acceptsOffers === true));
                }
                else if(conditionSelected === 'ممتازة' && postKind === 'يقبل مزايدات'){
                  // setQu(query(collection(db, "Posts"), where("condition", "==", "ممتازة"), where("acceptsOffers", "==", true), where("productPrice", ">", 0)))
                  setListing(initialListing.sort((a, b) => a.createdAt - b.createdAt).reverse().filter(post => post.condition === "جديد").filter(post => post.acceptsOffers === true));
                }
                else if(conditionSelected === 'ممتازة' && postKind === 'لا يقبل مزايدات'){
                  // setQu(query(collection(db, "Posts"), where("condition", "==", "ممتازة"), where("acceptsOffers", "==", false)))
                  setListing(initialListing.sort((a, b) => a.createdAt - b.createdAt).reverse().filter(post => post.condition === "جديد").filter(post => post.acceptsOffers === false));
                }
                else if(conditionSelected === 'ممتازة' && postKind === 'يقبل مزايدات مع سعر شراء فوري'){
                  // setQu(query(collection(db, "Posts"), where("condition", "==", "ممتازة"), where("acceptsOffers", "==", true), where("productPrice", ">", 0)))
                  setListing(initialListing.sort((a, b) => a.createdAt - b.createdAt).reverse().filter(post => post.condition === "جديد").filter(post => post.productPrice > 0).filter(post => post.acceptsOffers === true));
                }
                
                else if(conditionSelected === 'جيدة جدا' && postKind === 'يقبل مزايدات'){
                  // setQu(query(collection(db, "Posts"), where("condition", "==", "جيدة جدا"), where("acceptsOffers", "==", true), where("productPrice", ">", 0)))
                  setListing(initialListing.sort((a, b) => a.createdAt - b.createdAt).reverse().filter(post => post.condition === "جديد").filter(post => post.acceptsOffers === true));
                }
                else if(conditionSelected === 'جيدة جدا' && postKind === 'لا يقبل مزايدات'){
                  // setQu(query(collection(db, "Posts"), where("condition", "==", "جيدة جدا"), where("acceptsOffers", "==", false)))
                  setListing(initialListing.sort((a, b) => a.createdAt - b.createdAt).reverse().filter(post => post.condition === "جديد").filter(post => post.acceptsOffers === false));
                }
                else if(conditionSelected === 'جيدة جدا' && postKind === 'يقبل مزايدات مع سعر شراء فوري'){
                  // setQu(query(collection(db, "Posts"), where("condition", "==", "جيدة جدا"), where("acceptsOffers", "==", true), where("productPrice", ">", 0)))
                  setListing(initialListing.sort((a, b) => a.createdAt - b.createdAt).reverse().filter(post => post.condition === "جديد").filter(post => post.productPrice > 0).filter(post => post.acceptsOffers === true));
                }
                
                else if(conditionSelected === 'جيدة' && postKind === 'يقبل مزايدات'){
                  // setQu(query(collection(db, "Posts"), where("condition", "==", "جيدة"), where("acceptsOffers", "==", true), where("productPrice", ">", 0)))
                  setListing(initialListing.sort((a, b) => a.createdAt - b.createdAt).reverse().filter(post => post.condition === "جديد").filter(post => post.acceptsOffers === true));
                }
                else if(conditionSelected === 'جيدة' && postKind === 'لا يقبل مزايدات'){
                  // setQu(query(collection(db, "Posts"), where("condition", "==", "جيدة"), where("acceptsOffers", "==", false)))
                  setListing(initialListing.sort((a, b) => a.createdAt - b.createdAt).reverse().filter(post => post.condition === "جديد").filter(post => post.acceptsOffers === false));
                }
                else if(conditionSelected === 'جيدة' && postKind === 'يقبل مزايدات مع سعر شراء فوري'){
                  // setQu(query(collection(db, "Posts"), where("condition", "==", "جيدة"), where("acceptsOffers", "==", true), where("productPrice", ">", 0)))
                  setListing(initialListing.sort((a, b) => a.createdAt - b.createdAt).reverse().filter(post => post.condition === "جديد").filter(post => post.productPrice > 0).filter(post => post.acceptsOffers === true));
                }
                
                else if(conditionSelected === 'مقبولة' && postKind === 'يقبل مزايدات'){
                  // setQu(query(collection(db, "Posts"), where("condition", "==", "مقبولة"), where("acceptsOffers", "==", true), where("productPrice", ">", 0)))
                  setListing(initialListing.sort((a, b) => a.createdAt - b.createdAt).reverse().filter(post => post.condition === "جديد").filter(post => post.acceptsOffers === true));
                }
                else if(conditionSelected === 'مقبولة' && postKind === 'لا يقبل مزايدات'){
                  // setQu(query(collection(db, "Posts"), where("condition", "==", "مقبولة"), where("acceptsOffers", "==", false)))
                  setListing(initialListing.sort((a, b) => a.createdAt - b.createdAt).reverse().filter(post => post.condition === "جديد").filter(post => post.acceptsOffers === false));
                }
                else if(conditionSelected === 'مقبولة' && postKind === 'يقبل مزايدات مع سعر شراء فوري'){
                  // setQu(query(collection(db, "Posts"), where("condition", "==", "مقبولة"), where("acceptsOffers", "==", true), where("productPrice", ">", 0)))
                  setListing(initialListing.sort((a, b) => a.createdAt - b.createdAt).reverse().filter(post => post.condition === "جديد").filter(post => post.productPrice > 0).filter(post => post.acceptsOffers === true));
                }
              }
              else{
                setQu(query(collection(db, "Posts")))
                // setListing(initialListing.sort((a, b) => a.createdAt - b.createdAt).reverse())
              }
              }
            }} id={postKind === "يقبل مزايدات بدون سعر شراء فوري" ? 'filter-buttons-disabled' : "filter-buttons"}> سعر الشراء تنازليا {orderSelectedName === 'سعر الشراء تنازليا' ? <FaCheck /> : ""}</li>
          
          
          
          
          
          
          
          <li 
          style={{
            fontSize: "14px"
          }}
          onClick={()=>{
              if(orderSelectedName !== "سعر الشراء تصاعديا"){
                setOrderSelectedName("سعر الشراء تصاعديا")
              }
              else{
                setOrderSelectedName("")
              }
            //  if(orderSelectedName !== "سعر الشراء تصاعديا"){
            //    setOrderSelectedName("سعر الشراء تصاعديا")
            //    // only condition
            //    if(conditionSelected !== "" && postKind === ""){
            //      if(conditionSelected === 'جديد'){
            //       //  setQu(query(collection(db, "Posts"), where("condition", "==", "جديد"), where("productPrice", ">", 0)))
            //       //  setListing(Listing.filter(post => post.productPrice > 0))
            //        setListing(initialListing.sort((a, b) => a.productPrice - b.productPrice).reverse().filter(post => post.condition === "جديد").filter(post => post.productPrice > 0));
            //      }
            //      else if(conditionSelected === 'مستعمل'){
            //       //  setQu(query(collection(db, "Posts"), where("condition", "==", "مستعمل"), where("productPrice", ">", 0)))
            //       //  setListing(Listing.filter(post => post.productPrice > 0))
            //        setListing(initialListing.sort((a, b) => a.productPrice - b.productPrice).reverse().filter(post => post.condition === "مستعمل").filter(post => post.productPrice > 0));
            //      }
            //      else if(conditionSelected === 'ممتازة'){
            //       //  setQu(query(collection(db, "Posts"), where("condition", "==", "ممتازة"), where("productPrice", ">", 0)))
            //       //  setListing(Listing.filter(post => post.productPrice > 0))
            //        setListing(initialListing.sort((a, b) => a.productPrice - b.productPrice).reverse().filter(post => post.condition === "ممتازة").filter(post => post.productPrice > 0));
            //      }
            //      else if(conditionSelected === 'جيدة جدا'){
            //       //  setQu(query(collection(db, "Posts"), where("condition", "==", "جيدة جدا"), where("productPrice", ">", 0)))
            //       //  setListing(Listing.filter(post => post.productPrice > 0))
            //        setListing(initialListing.sort((a, b) => a.productPrice - b.productPrice).reverse().filter(post => post.condition === "جيدة جدا").filter(post => post.productPrice > 0));
            //      }
            //      else if(conditionSelected === 'جيدة'){
            //       //  setQu(query(collection(db, "Posts"), where("condition", "==", "جيدة"), where("productPrice", ">", 0)))
            //       //  setListing(Listing.filter(post => post.productPrice > 0))
            //        setListing(initialListing.sort((a, b) => a.productPrice - b.productPrice).reverse().filter(post => post.condition === "جيدة").filter(post => post.productPrice > 0));
            //      }
            //      else if(conditionSelected === 'مقبولة'){
            //       //  setQu(query(collection(db, "Posts"), where("condition", "==", "مقبولة"), where("productPrice", ">", 0)))
            //       //  setListing(Listing.filter(post => post.productPrice > 0))
            //        setListing(initialListing.sort((a, b) => a.productPrice - b.productPrice).reverse().filter(post => post.condition === "مقبولة").filter(post => post.productPrice > 0));
            //      }
            //    }
            //    // only kind
            //    else if(postKind !== "" && conditionSelected === ""){
            //      if(postKind === 'يقبل مزايدات'){
            //       //  setQu(query(collection(db, "Posts"), where("acceptsOffers", "==", true), where("productPrice", ">", 0)))
            //        setListing(initialListing.sort((a, b) => a.productPrice - b.productPrice).reverse().filter(post => post.acceptsOffers === true).filter(post => post.productPrice > 0));
            //      }
            //      else if(postKind === 'لا يقبل مزايدات'){
            //       //  setQu(query(collection(db, "Posts"), where("acceptsOffers", "==", false), where("productPrice", ">", 0)))
            //        setListing(initialListing.sort((a, b) => a.productPrice - b.productPrice).reverse().filter(post => post.acceptsOffers === false).filter(post => post.productPrice > 0));
            //      }
            //      else if(postKind === 'يقبل مزايدات مع سعر شراء فوري'){
            //       //  setQu(query(collection(db, "Posts"), where("acceptsOffers", "==", true), where("productPrice", ">", 0)))
            //        setListing(initialListing.sort((a, b) => a.productPrice - b.productPrice).reverse().filter(post => post.acceptsOffers === true).filter(post => post.productPrice > 0));
            //      }
            //    }

            //    // both selected
            //    else if(postKind !== "" && conditionSelected !== ""){
            //      if(conditionSelected === 'جديد' && postKind === 'يقبل مزايدات'){
            //       //  setQu(query(collection(db, "Posts"), where("condition", "==", "جديد"), where("acceptsOffers", "==", true), where("productPrice", ">", 0)))
            //        setListing(initialListing.sort((a, b) => a.productPrice - b.productPrice).reverse().filter(post => post.condition === "جديد").filter(post => post.productPrice > 0).filter(post => post.acceptsOffers === true));
            //      }
            //      else if(conditionSelected === 'جديد' && postKind === 'لا يقبل مزايدات'){
            //       //  setQu(query(collection(db, "Posts"), where("condition", "==", "جديد"), where("acceptsOffers", "==", false), where("productPrice", ">", 0)))
            //        setListing(initialListing.sort((a, b) => a.productPrice - b.productPrice).reverse().filter(post => post.condition === "جديد").filter(post => post.productPrice > 0).filter(post => post.acceptsOffers === false));
            //      }
            //      else if(conditionSelected === 'جديد' && postKind === 'يقبل مزايدات مع سعر شراء فوري'){
            //       //  setQu(query(collection(db, "Posts"), where("condition", "==", "جديد"), where("acceptsOffers", "==", true), where("productPrice", ">", 0)))
            //        setListing(initialListing.sort((a, b) => a.productPrice - b.productPrice).reverse().filter(post => post.condition === "جديد").filter(post => post.productPrice > 0).filter(post => post.acceptsOffers === true));
            //      }
            //      else if(conditionSelected === 'مستعمل' && postKind === 'يقبل مزايدات'){
            //       //  setQu(query(collection(db, "Posts"), where("condition", "==", "مستعمل"), where("acceptsOffers", "==", true), where("productPrice", ">", 0)))
            //        setListing(initialListing.sort((a, b) => a.productPrice - b.productPrice).reverse().filter(post => post.condition === "مستعمل").filter(post => post.productPrice > 0).filter(post => post.acceptsOffers === true));
            //      }
            //      else if(conditionSelected === 'مستعمل' && postKind === 'لا يقبل مزايدات'){
            //       //  setQu(query(collection(db, "Posts"), where("condition", "==", "مستعمل"), where("acceptsOffers", "==", false), where("productPrice", ">", 0)))
            //        setListing(initialListing.sort((a, b) => a.productPrice - b.productPrice).reverse().filter(post => post.condition === "مستعمل").filter(post => post.productPrice > 0).filter(post => post.acceptsOffers === false));
            //      }
            //      else if(conditionSelected === 'مستعمل' && postKind === 'يقبل مزايدات مع سعر شراء فوري'){
            //       //  setQu(query(collection(db, "Posts"), where("condition", "==", "جديد"), where("acceptsOffers", "==", true), where("productPrice", ">", 0)))
            //        setListing(initialListing.sort((a, b) => a.productPrice - b.productPrice).reverse().filter(post => post.condition === "مستعمل").filter(post => post.productPrice > 0).filter(post => post.acceptsOffers === true));
            //      }
            //      else if(conditionSelected === 'ممتازة' && postKind === 'يقبل مزايدات'){
            //       //  setQu(query(collection(db, "Posts"), where("condition", "==", "ممتازة"), where("acceptsOffers", "==", true), where("productPrice", ">", 0)))
            //        setListing(initialListing.sort((a, b) => a.productPrice - b.productPrice).reverse().filter(post => post.condition === "ممتازة").filter(post => post.productPrice > 0).filter(post => post.acceptsOffers === true));
            //      }
            //      else if(conditionSelected === 'ممتازة' && postKind === 'لا يقبل مزايدات'){
            //       //  setQu(query(collection(db, "Posts"), where("condition", "==", "ممتازة"), where("acceptsOffers", "==", false), where("productPrice", ">", 0)))
            //        setListing(initialListing.sort((a, b) => a.productPrice - b.productPrice).reverse().filter(post => post.condition === "ممتازة").filter(post => post.productPrice > 0).filter(post => post.acceptsOffers === false));
            //      }
            //      else if(conditionSelected === 'ممتازة' && postKind === 'يقبل مزايدات مع سعر شراء فوري'){
            //       //  setQu(query(collection(db, "Posts"), where("condition", "==", "ممتازة"), where("acceptsOffers", "==", true), where("productPrice", ">", 0)))
            //        setListing(initialListing.sort((a, b) => a.productPrice - b.productPrice).reverse().filter(post => post.condition === "ممتازة").filter(post => post.productPrice > 0).filter(post => post.acceptsOffers === true));
            //      }
            //      else if(conditionSelected === 'جيدة جدا' && postKind === 'يقبل مزايدات'){
            //       //  setQu(query(collection(db, "Posts"), where("condition", "==", "جيدة جدا"), where("acceptsOffers", "==", true), where("productPrice", ">", 0)))
            //        setListing(initialListing.sort((a, b) => a.productPrice - b.productPrice).reverse().filter(post => post.condition === "جيدة جدا").filter(post => post.productPrice > 0).filter(post => post.acceptsOffers === true));
            //      }
            //      else if(conditionSelected === 'جيدة جدا' && postKind === 'لا يقبل مزايدات'){
            //       //  setQu(query(collection(db, "Posts"), where("condition", "==", "جيدة جدا"), where("acceptsOffers", "==", false), where("productPrice", ">", 0)))
            //        setListing(initialListing.sort((a, b) => a.productPrice - b.productPrice).reverse().filter(post => post.condition === "جيدة جدا").filter(post => post.productPrice > 0).filter(post => post.acceptsOffers === false));
            //      }
            //      else if(conditionSelected === 'جيدة جدا' && postKind === 'يقبل مزايدات مع سعر شراء فوري'){
            //       //  setQu(query(collection(db, "Posts"), where("condition", "==", "جيدة جدا"), where("acceptsOffers", "==", true), where("productPrice", ">", 0)))
            //        setListing(initialListing.sort((a, b) => a.productPrice - b.productPrice).reverse().filter(post => post.condition === "جيدة جدا").filter(post => post.productPrice > 0).filter(post => post.acceptsOffers === true));
            //      }
            //      else if(conditionSelected === 'جيدة' && postKind === 'يقبل مزايدات'){
            //       //  setQu(query(collection(db, "Posts"), where("condition", "==", "جيدة"), where("acceptsOffers", "==", true), where("productPrice", ">", 0)))
            //        setListing(initialListing.sort((a, b) => a.productPrice - b.productPrice).reverse().filter(post => post.condition === "جيدة").filter(post => post.productPrice > 0).filter(post => post.acceptsOffers === true));
            //      }
            //      else if(conditionSelected === 'جيدة' && postKind === 'لا يقبل مزايدات'){
            //       //  setQu(query(collection(db, "Posts"), where("condition", "==", "جيدة"), where("acceptsOffers", "==", false), where("productPrice", ">", 0)))
            //        setListing(initialListing.sort((a, b) => a.productPrice - b.productPrice).reverse().filter(post => post.condition === "جيدة").filter(post => post.productPrice > 0).filter(post => post.acceptsOffers === false));
            //      }
            //      else if(conditionSelected === 'جيدة' && postKind === 'يقبل مزايدات مع سعر شراء فوري'){
            //       //  setQu(query(collection(db, "Posts"), where("condition", "==", "جيدة"), where("acceptsOffers", "==", true), where("productPrice", ">", 0)))
            //       //  setListing(initialListing.sort((a, b) => a.productPrice - b.productPrice).reverse().filter(post => post.condition === "جيدة").filter(post => post.productPrice > 0).filter(post => post.acceptsOffers === true));
            //      }
            //      else if(conditionSelected === 'مقبولة' && postKind === 'يقبل مزايدات'){
            //       //  setQu(query(collection(db, "Posts"), where("condition", "==", "مقبولة"), where("acceptsOffers", "==", true), where("productPrice", ">", 0)))
            //        setListing(initialListing.sort((a, b) => a.productPrice - b.productPrice).reverse().filter(post => post.condition === "مقبولة").filter(post => post.productPrice > 0).filter(post => post.acceptsOffers === true));
            //      }
            //      else if(conditionSelected === 'مقبولة' && postKind === 'لا يقبل مزايدات'){
            //       //  setQu(query(collection(db, "Posts"), where("condition", "==", "مقبولة"), where("acceptsOffers", "==", false), where("productPrice", ">", 0)))
            //        setListing(initialListing.sort((a, b) => a.productPrice - b.productPrice).reverse().filter(post => post.condition === "مقبولة").filter(post => post.productPrice > 0).filter(post => post.acceptsOffers === false));
            //      }
            //      else if(conditionSelected === 'مقبولة' && postKind === 'يقبل مزايدات مع سعر شراء فوري'){
            //       //  setQu(query(collection(db, "Posts"), where("condition", "==", "مقبولة"), where("acceptsOffers", "==", true), where("productPrice", ">", 0)))
            //        setListing(initialListing.sort((a, b) => a.productPrice - b.productPrice).reverse().filter(post => post.condition === "مقبولة").filter(post => post.productPrice > 0).filter(post => post.acceptsOffers === true));
            //      }
            //    }

            //    // neither
            //    else{
            //      setQu(query(collection(db, "Posts"), where("productPrice", ">", 0)))
            //    }
            //  }
            //  else{
            //  setOrderSelectedName("");
            //  if(conditionSelected !== "" && postKind === ""){
            //    if(conditionSelected === 'جديد'){
            //     //  setQu(query(collection(db, "Posts"), where("condition", "==", "جديد")))
            //      setListing(initialListing.sort((a, b) => a.createdAt - b.createdAt).reverse().filter(post => post.condition === "جديد"));
            //    }
            //    else if(conditionSelected === 'مستعمل'){
            //     //  setQu(query(collection(db, "Posts"), where("condition", "==", "مستعمل")))
            //      setListing(initialListing.sort((a, b) => a.createdAt - b.createdAt).reverse().filter(post => post.condition === "مستعمل"));
            //    }
            //    else if(conditionSelected === 'ممتازة'){
            //     //  setQu(query(collection(db, "Posts"), where("condition", "==", "ممتازة")))
            //      setListing(initialListing.sort((a, b) => a.createdAt - b.createdAt).reverse().filter(post => post.condition === "ممتازة"));
            //    }
            //    else if(conditionSelected === 'جيدة جدا'){
            //     //  setQu(query(collection(db, "Posts"), where("condition", "==", "جيدة جدا")))
            //      setListing(initialListing.sort((a, b) => a.createdAt - b.createdAt).reverse().filter(post => post.condition === "جيدة جدا"));
            //    }
            //    else if(conditionSelected === 'جيدة'){
            //     //  setQu(query(collection(db, "Posts"), where("condition", "==", "جيدة")))
            //      setListing(initialListing.sort((a, b) => a.createdAt - b.createdAt).reverse().filter(post => post.condition === "جيدة"));
            //    }
            //    else if(conditionSelected === 'مقبولة'){
            //     //  setQu(query(collection(db, "Posts"), where("condition", "==", "مقبولة")))
            //      setListing(initialListing.sort((a, b) => a.createdAt - b.createdAt).reverse().filter(post => post.condition === "مقبولة"));
            //    }
            //  }
            //  // only kind
            //  else if(postKind !== "" && conditionSelected === ""){
            //    if(postKind === 'يقبل مزايدات'){
            //     //  setQu(query(collection(db, "Posts"), where("acceptsOffers", "==", true), where("productPrice", ">", 0)))
            //     //  setListing(Listing.filter(post => post.productPrice > 0))
            //      setListing(initialListing.sort((a, b) => a.createdAt - b.createdAt).reverse().filter(post => post.acceptsOffers === true));
            //    }
            //    else if(postKind === 'لا يقبل مزايدات'){
            //     //  setQu(query(collection(db, "Posts"), where("acceptsOffers", "==", false), where("productPrice", ">", 0)))
            //     //  setListing(Listing.filter(post => post.productPrice > 0))
            //      setListing(initialListing.sort((a, b) => a.createdAt - b.createdAt).reverse().filter(post => post.acceptsOffers === false));
            //    }
            //    else if(postKind === 'يقبل مزايدات مع سعر شراء فوري'){
            //     //  setQu(query(collection(db, "Posts"), where("acceptsOffers", "==", true), where("productPrice", ">", 0)))
            //     //  setListing(Listing.filter(post => post.productPrice > 0))
            //      setListing(initialListing.sort((a, b) => a.createdAt - b.createdAt).reverse().filter(post => post.acceptsOffers === true).filter(post => post.productPrice > 0));
            //    }
            //  }

            //  // both selected
            //  else if(postKind !== "" && conditionSelected !== ""){
            //    if(conditionSelected === 'جديد' && postKind === 'يقبل مزايدات'){
            //     //  setQu(query(collection(db, "Posts"), where("condition", "==", "جديد"), where("acceptsOffers", "==", true)))
            //      setListing(initialListing.sort((a, b) => a.createdAt - b.createdAt).reverse().filter(post => post.condition === "جديد").filter(post => post.acceptsOffers === true));
            //    }
            //    else if(conditionSelected === 'جديد' && postKind === 'لا يقبل مزايدات'){
            //     //  setQu(query(collection(db, "Posts"), where("condition", "==", "جديد"), where("acceptsOffers", "==", false)))
            //      setListing(initialListing.sort((a, b) => a.createdAt - b.createdAt).reverse().filter(post => post.condition === "جديد").filter(post => post.acceptsOffers === false));
            //    }
            //    else if(conditionSelected === 'جديد' && postKind === 'يقبل مزايدات مع سعر شراء فوري'){
            //     //  setQu(query(collection(db, "Posts"), where("condition", "==", "جديد"), where("acceptsOffers", "==", true), where("productPrice", ">", 0)))
            //      setListing(initialListing.sort((a, b) => a.createdAt - b.createdAt).reverse().filter(post => post.condition === "جديد").filter(post => post.productPrice > 0).filter(post => post.acceptsOffers === true));
            //    }
            //    else if(conditionSelected === 'مستعمل' && postKind === 'يقبل مزايدات'){
            //     //  setQu(query(collection(db, "Posts"), where("condition", "==", "مستعمل"), where("acceptsOffers", "==", true), where("productPrice", ">", 0)))
            //      setListing(initialListing.sort((a, b) => a.createdAt - b.createdAt).reverse().filter(post => post.condition === "جديد").filter(post => post.acceptsOffers === true));
            //    }
            //    else if(conditionSelected === 'مستعمل' && postKind === 'لا يقبل مزايدات'){
            //     //  setQu(query(collection(db, "Posts"), where("condition", "==", "مستعمل"), where("acceptsOffers", "==", false)))
            //      setListing(initialListing.sort((a, b) => a.createdAt - b.createdAt).reverse().filter(post => post.condition === "جديد").filter(post => post.acceptsOffers === false));
            //    }
            //    else if(conditionSelected === 'مستعمل' && postKind === 'يقبل مزايدات مع سعر شراء فوري'){
            //     //  setQu(query(collection(db, "Posts"), where("condition", "==", "مستعمل"), where("acceptsOffers", "==", true), where("productPrice", ">", 0)))
            //      setListing(initialListing.sort((a, b) => a.createdAt - b.createdAt).reverse().filter(post => post.condition === "جديد").filter(post => post.productPrice > 0).filter(post => post.acceptsOffers === true));
            //    }
            //    else if(conditionSelected === 'ممتازة' && postKind === 'يقبل مزايدات'){
            //     //  setQu(query(collection(db, "Posts"), where("condition", "==", "ممتازة"), where("acceptsOffers", "==", true), where("productPrice", ">", 0)))
            //      setListing(initialListing.sort((a, b) => a.createdAt - b.createdAt).reverse().filter(post => post.condition === "جديد").filter(post => post.acceptsOffers === true));
            //    }
            //    else if(conditionSelected === 'ممتازة' && postKind === 'لا يقبل مزايدات'){
            //     //  setQu(query(collection(db, "Posts"), where("condition", "==", "ممتازة"), where("acceptsOffers", "==", false)))
            //      setListing(initialListing.sort((a, b) => a.createdAt - b.createdAt).reverse().filter(post => post.condition === "جديد").filter(post => post.acceptsOffers === false));
            //    }
            //    else if(conditionSelected === 'ممتازة' && postKind === 'يقبل مزايدات مع سعر شراء فوري'){
            //     //  setQu(query(collection(db, "Posts"), where("condition", "==", "ممتازة"), where("acceptsOffers", "==", true), where("productPrice", ">", 0)))
            //      setListing(initialListing.sort((a, b) => a.createdAt - b.createdAt).reverse().filter(post => post.condition === "جديد").filter(post => post.productPrice > 0).filter(post => post.acceptsOffers === true));
            //    }
               
            //    else if(conditionSelected === 'جيدة جدا' && postKind === 'يقبل مزايدات'){
            //     //  setQu(query(collection(db, "Posts"), where("condition", "==", "جيدة جدا"), where("acceptsOffers", "==", true), where("productPrice", ">", 0)))
            //      setListing(initialListing.sort((a, b) => a.createdAt - b.createdAt).reverse().filter(post => post.condition === "جديد").filter(post => post.acceptsOffers === true));
            //    }
            //    else if(conditionSelected === 'جيدة جدا' && postKind === 'لا يقبل مزايدات'){
            //     //  setQu(query(collection(db, "Posts"), where("condition", "==", "جيدة جدا"), where("acceptsOffers", "==", false)))
            //      setListing(initialListing.sort((a, b) => a.createdAt - b.createdAt).reverse().filter(post => post.condition === "جديد").filter(post => post.acceptsOffers === false));
            //    }
            //    else if(conditionSelected === 'جيدة جدا' && postKind === 'يقبل مزايدات مع سعر شراء فوري'){
            //     //  setQu(query(collection(db, "Posts"), where("condition", "==", "جيدة جدا"), where("acceptsOffers", "==", true), where("productPrice", ">", 0)))
            //      setListing(initialListing.sort((a, b) => a.createdAt - b.createdAt).reverse().filter(post => post.condition === "جديد").filter(post => post.productPrice > 0).filter(post => post.acceptsOffers === true));
            //    }
               
            //    else if(conditionSelected === 'جيدة' && postKind === 'يقبل مزايدات'){
            //     //  setQu(query(collection(db, "Posts"), where("condition", "==", "جيدة"), where("acceptsOffers", "==", true), where("productPrice", ">", 0)))
            //      setListing(initialListing.sort((a, b) => a.createdAt - b.createdAt).reverse().filter(post => post.condition === "جديد").filter(post => post.acceptsOffers === true));
            //    }
            //    else if(conditionSelected === 'جيدة' && postKind === 'لا يقبل مزايدات'){
            //     //  setQu(query(collection(db, "Posts"), where("condition", "==", "جيدة"), where("acceptsOffers", "==", false)))
            //      setListing(initialListing.sort((a, b) => a.createdAt - b.createdAt).reverse().filter(post => post.condition === "جديد").filter(post => post.acceptsOffers === false));
            //    }
            //    else if(conditionSelected === 'جيدة' && postKind === 'يقبل مزايدات مع سعر شراء فوري'){
            //     //  setQu(query(collection(db, "Posts"), where("condition", "==", "جيدة"), where("acceptsOffers", "==", true), where("productPrice", ">", 0)))
            //      setListing(initialListing.sort((a, b) => a.createdAt - b.createdAt).reverse().filter(post => post.condition === "جديد").filter(post => post.productPrice > 0).filter(post => post.acceptsOffers === true));
            //    }
               
            //    else if(conditionSelected === 'مقبولة' && postKind === 'يقبل مزايدات'){
            //     //  setQu(query(collection(db, "Posts"), where("condition", "==", "مقبولة"), where("acceptsOffers", "==", true), where("productPrice", ">", 0)))
            //      setListing(initialListing.sort((a, b) => a.createdAt - b.createdAt).reverse().filter(post => post.condition === "جديد").filter(post => post.acceptsOffers === true));
            //    }
            //    else if(conditionSelected === 'مقبولة' && postKind === 'لا يقبل مزايدات'){
            //     //  setQu(query(collection(db, "Posts"), where("condition", "==", "مقبولة"), where("acceptsOffers", "==", false)))
            //      setListing(initialListing.sort((a, b) => a.createdAt - b.createdAt).reverse().filter(post => post.condition === "جديد").filter(post => post.acceptsOffers === false));
            //    }
            //    else if(conditionSelected === 'مقبولة' && postKind === 'يقبل مزايدات مع سعر شراء فوري'){
            //     //  setQu(query(collection(db, "Posts"), where("condition", "==", "مقبولة"), where("acceptsOffers", "==", true), where("productPrice", ">", 0)))
            //      setListing(initialListing.sort((a, b) => a.createdAt - b.createdAt).reverse().filter(post => post.condition === "جديد").filter(post => post.productPrice > 0).filter(post => post.acceptsOffers === true));
            //    }
            //  }
            //  else{
            //    setQu(query(collection(db, "Posts")))
            //    // setListing(initialListing.sort((a, b) => a.createdAt - b.createdAt).reverse())
            //  }
            //  }
            }} id={postKind === "يقبل مزايدات بدون سعر شراء فوري" ? 'filter-buttons-disabled' : "filter-buttons"}> سعر الشراء تصاعديا {orderSelectedName === 'سعر الشراء تصاعديا' ? <FaCheck /> : ""}</li>
          
          
          
          
















          <li 
          style={{
            fontSize: "14px"
          }}
          onClick={()=>{

            if(orderSelectedName !== "سعر المزايدة تصاعديا"){
              setOrderSelectedName("سعر المزايدة تصاعديا")
            }
            else{
              setOrderSelectedName("")
            }
            
            // // setListing(initialListing);
            // if(orderSelectedName !== "سعر المزايدة تصاعديا"){
            //   setOrderSelectedName("سعر المزايدة تصاعديا")
            //   // only condition
            //   if(conditionSelected !== "" && postKind === ""){
            //     if(conditionSelected === 'جديد'){

            //       setQu(query(collection(db, "Posts"), where("condition", "==", "جديد"), where("acceptsOffers", "==", true)))
            //       // setListing(Listing.filter(post => post.acceptsOffers === true))
            //       // setListing(initialListing.sort((a, b) => a.currentBid - b.currentBid).reverse().filter(post => post.condition === "جديد").filter(post => post.acceptsOffers === true));
            //     }
            //     else if(conditionSelected === 'مستعمل'){
            //       setQu(query(collection(db, "Posts"), where("condition", "==", "مستعمل"), where("acceptsOffers", "==", true)))
            //       // setListing(Listing.filter(post => post.productPrice > 0))
            //       // setListing(initialListing.sort((a, b) => a.currentBid - b.currentBid).reverse().filter(post => post.condition === "مستعمل").filter(post => post.acceptsOffers === true));
            //     }
            //     else if(conditionSelected === 'ممتازة'){
            //       setQu(query(collection(db, "Posts"), where("condition", "==", "ممتازة"), where("acceptsOffers", "==", true)))
            //       // setListing(Listing.filter(post => post.productPrice > 0))
            //       // setListing(initialListing.sort((a, b) => a.currentBid - b.currentBid).reverse().filter(post => post.condition === "ممتازة").filter(post.acceptsOffers === true));
            //     }
            //     else if(conditionSelected === 'جيدة جدا'){
            //       setQu(query(collection(db, "Posts"), where("condition", "==", "جيدة جدا"), where("acceptsOffers", "==", true)))
            //       setListing(Listing.filter(post => post.productPrice > 0))
            //       // setListing(initialListing.sort((a, b) => a.currentBid - b.currentBid).reverse().filter(post => post.condition === "جيدة جدا").filter(post.acceptsOffers === true));
            //     }
            //     else if(conditionSelected === 'جيدة'){
            //       setQu(query(collection(db, "Posts"), where("condition", "==", "جيدة"), where("acceptsOffers", "==", true)))
            //       // setListing(Listing.filter(post => post.productPrice > 0))
            //       // setListing(initialListing.sort((a, b) => a.currentBid - b.currentBid).reverse().filter(post => post.condition === "جيدة").filter(post.acceptsOffers === true));
            //     }
            //     else if(conditionSelected === 'مقبولة'){
            //       setQu(query(collection(db, "Posts"), where("condition", "==", "مقبولة"), where("acceptsOffers", "==", true)))
            //       // setListing(Listing.filter(post => post.productPrice > 0))
            //       // setListing(initialListing.sort((a, b) => a.currentBid - b.currentBid).reverse().filter(post => post.condition === "مقبولة").filter(post.acceptsOffers === true));
            //     }
            //   }
            //   // only kind
            //   else if(postKind !== "" && conditionSelected === ""){
            //     if(postKind === 'يقبل مزايدات'){
            //       setQu(query(collection(db, "Posts"), where("acceptsOffers", "==", true), where("acceptsOffers", "==", true)))
            //       // setListing(initialListing.sort((a, b) => a.currentBid - b.currentBid).reverse().filter(post => post.acceptsOffers === true).filter(post.acceptsOffers === true));
            //     }
            //     else if(postKind === 'يقبل مزايدات مع سعر شراء فوري'){
            //       setQu(query(collection(db, "Posts"), where("acceptsOffers", "==", true), where("productPrice", ">", 0)))
            //       // setListing(initialListing.sort((a, b) => a.currentBid - b.currentBid).reverse().filter(post => post.acceptsOffers === true).filter(post.acceptsOffers === true));
            //     }
            //     else if(postKind === 'يقبل مزايدات بدون سعر شراء فوري'){
            //       setQu(query(collection(db, "Posts"), where("acceptsOffers", "==", true), where("productPrice", "==", 0)))
            //       // setListing(initialListing.sort((a, b) => a.currentBid - b.currentBid).reverse().filter(post => post.acceptsOffers === true).filter(post.acceptsOffers === true));
            //     }
            //   }

            //   // both selected
            //   else if(postKind !== "" && conditionSelected !== ""){
            //     if(conditionSelected === 'جديد' && postKind === 'يقبل مزايدات'){
            //       setQu(query(collection(db, "Posts"), where("condition", "==", "جديد"), where("acceptsOffers", "==", true)))
            //       // setListing(initialListing.sort((a, b) => a.currentBid - b.currentBid).reverse().filter(post => post.condition === "جديد").filter(post.acceptsOffers === true).filter(post => post.acceptsOffers === true));
            //     }
            //     else if(conditionSelected === 'جديد' && postKind === 'يقبل مزايدات مع سعر شراء فوري'){
            //       setQu(query(collection(db, "Posts"), where("condition", "==", "جديد"), where("acceptsOffers", "==", true), where("productPrice", ">", 0)))
            //       // setListing(initialListing.sort((a, b) => a.currentBid - b.currentBid).reverse().filter(post => post.condition === "جديد").filter(post.acceptsOffers === true).filter(post => post.acceptsOffers === true));
            //     }
            //     else if(conditionSelected === 'جديد' && postKind === 'يقبل مزايدات بدون سعر شراء فوري'){
            //       console.log("here")
            //       setQu(query(collection(db, "Posts"), where("condition", "==", "جديد"), where("acceptsOffers", "==", true), where("productPrice", "==", 0)))
            //       // setListing(initialListing.sort((a, b) => a.currentBid - b.currentBid).reverse().filter(post => post.condition === "جديد").filter(post.acceptsOffers === true).filter(post => post.acceptsOffers === true));
            //     }
            //     else if(conditionSelected === 'مستعمل' && postKind === 'يقبل مزايدات'){
            //       setQu(query(collection(db, "Posts"), where("condition", "==", "مستعمل"), where("acceptsOffers", "==", true), where("productPrice", ">", 0)))
            //       // setListing(initialListing.sort((a, b) => a.currentBid - b.currentBid).reverse().filter(post => post.condition === "مستعمل").filter(post.acceptsOffers === true).filter(post => post.acceptsOffers === true));
            //     }
            //     else if(conditionSelected === 'مستعمل' && postKind === 'لا يقبل مزايدات'){
            //       setQu(query(collection(db, "Posts"), where("condition", "==", "مستعمل"), where("acceptsOffers", "==", false), where("productPrice", ">", 0)))
            //       // setListing(initialListing.sort((a, b) => a.currentBid - b.currentBid).reverse().filter(post => post.condition === "مستعمل").filter(post.acceptsOffers === true).filter(post => post.acceptsOffers === false));
            //     }
            //     else if(conditionSelected === 'مستعمل' && postKind === 'يقبل مزايدات مع سعر شراء فوري'){
            //       setQu(query(collection(db, "Posts"), where("condition", "==", "جديد"), where("acceptsOffers", "==", true), where("productPrice", ">", 0)))
            //       // setListing(initialListing.sort((a, b) => a.currentBid - b.currentBid).reverse().filter(post => post.condition === "مستعمل").filter(post.acceptsOffers === true).filter(post => post.acceptsOffers === true));
            //     }
            //     else if(conditionSelected === 'ممتازة' && postKind === 'يقبل مزايدات'){
            //       setQu(query(collection(db, "Posts"), where("condition", "==", "ممتازة"), where("acceptsOffers", "==", true), where("productPrice", ">", 0)))
            //       // setListing(initialListing.sort((a, b) => a.currentBid - b.currentBid).reverse().filter(post => post.condition === "ممتازة").filter(post.acceptsOffers === true).filter(post => post.acceptsOffers === true));
            //     }
            //     else if(conditionSelected === 'ممتازة' && postKind === 'لا يقبل مزايدات'){
            //       setQu(query(collection(db, "Posts"), where("condition", "==", "ممتازة"), where("acceptsOffers", "==", false), where("productPrice", ">", 0)))
            //       // setListing(initialListing.sort((a, b) => a.currentBid - b.currentBid).reverse().filter(post => post.condition === "ممتازة").filter(post.acceptsOffers === true).filter(post => post.acceptsOffers === false));
            //     }
            //     else if(conditionSelected === 'ممتازة' && postKind === 'يقبل مزايدات مع سعر شراء فوري'){
            //       setQu(query(collection(db, "Posts"), where("condition", "==", "ممتازة"), where("acceptsOffers", "==", true), where("productPrice", ">", 0)))
            //       // setListing(initialListing.sort((a, b) => a.currentBid - b.currentBid).reverse().filter(post => post.condition === "ممتازة").filter(post.acceptsOffers === true).filter(post => post.acceptsOffers === true));
            //     }
            //     else if(conditionSelected === 'جيدة جدا' && postKind === 'يقبل مزايدات'){
            //       setQu(query(collection(db, "Posts"), where("condition", "==", "جيدة جدا"), where("acceptsOffers", "==", true), where("productPrice", ">", 0)))
            //       // setListing(initialListing.sort((a, b) => a.currentBid - b.currentBid).reverse().filter(post => post.condition === "جيدة جدا").filter(post.acceptsOffers === true).filter(post => post.acceptsOffers === true));
            //     }
            //     else if(conditionSelected === 'جيدة جدا' && postKind === 'لا يقبل مزايدات'){
            //       setQu(query(collection(db, "Posts"), where("condition", "==", "جيدة جدا"), where("acceptsOffers", "==", false), where("productPrice", ">", 0)))
            //       // setListing(initialListing.sort((a, b) => a.currentBid - b.currentBid).reverse().filter(post => post.condition === "جيدة جدا").filter(post.acceptsOffers === true).filter(post => post.acceptsOffers === false));
            //     }
            //     else if(conditionSelected === 'جيدة جدا' && postKind === 'يقبل مزايدات مع سعر شراء فوري'){
            //       setQu(query(collection(db, "Posts"), where("condition", "==", "جيدة جدا"), where("acceptsOffers", "==", true), where("productPrice", ">", 0)))
            //       // setListing(initialListing.sort((a, b) => a.currentBid - b.currentBid).reverse().filter(post => post.condition === "جيدة جدا").filter(post.acceptsOffers === true).filter(post => post.acceptsOffers === true));
            //     }
            //     else if(conditionSelected === 'جيدة' && postKind === 'يقبل مزايدات'){
            //       setQu(query(collection(db, "Posts"), where("condition", "==", "جيدة"), where("acceptsOffers", "==", true), where("productPrice", ">", 0)))
            //       // setListing(initialListing.sort((a, b) => a.currentBid - b.currentBid).reverse().filter(post => post.condition === "جيدة").filter(post.acceptsOffers === true).filter(post => post.acceptsOffers === true));
            //     }
            //     else if(conditionSelected === 'جيدة' && postKind === 'لا يقبل مزايدات'){
            //       setQu(query(collection(db, "Posts"), where("condition", "==", "جيدة"), where("acceptsOffers", "==", false), where("productPrice", ">", 0)))
            //       // setListing(initialListing.sort((a, b) => a.currentBid - b.currentBid).reverse().filter(post => post.condition === "جيدة").filter(post => post.productPrice > 0).filter(post => post.acceptsOffers === false));
            //     }
            //     else if(conditionSelected === 'جيدة' && postKind === 'يقبل مزايدات مع سعر شراء فوري'){
            //       // setQu(query(collection(db, "Posts"), where("condition", "==", "جيدة"), where("acceptsOffers", "==", true), where("productPrice", ">", 0)))
            //       setListing(initialListing.sort((a, b) => a.currentBid - b.currentBid).reverse().filter(post => post.condition === "جيدة").filter(post.acceptsOffers === true).filter(post => post.acceptsOffers === true));
            //     }
            //     else if(conditionSelected === 'مقبولة' && postKind === 'يقبل مزايدات'){
            //       setQu(query(collection(db, "Posts"), where("condition", "==", "مقبولة"), where("acceptsOffers", "==", true), where("productPrice", ">", 0)))
            //       // setListing(initialListing.sort((a, b) => a.currentBid - b.currentBid).reverse().filter(post => post.condition === "مقبولة").filter(post.acceptsOffers === true).filter(post => post.acceptsOffers === true));
            //     }
            //     else if(conditionSelected === 'مقبولة' && postKind === 'لا يقبل مزايدات'){
            //       setQu(query(collection(db, "Posts"), where("condition", "==", "مقبولة"), where("acceptsOffers", "==", false), where("productPrice", ">", 0)))
            //       // setListing(initialListing.sort((a, b) => a.currentBid - b.currentBid).reverse().filter(post => post.condition === "مقبولة").filter(post.acceptsOffers === true).filter(post => post.acceptsOffers === false));
            //     }
            //     else if(conditionSelected === 'مقبولة' && postKind === 'يقبل مزايدات مع سعر شراء فوري'){
            //       setQu(query(collection(db, "Posts"), where("condition", "==", "مقبولة"), where("acceptsOffers", "==", true), where("productPrice", ">", 0)))
            //       // setListing(initialListing.sort((a, b) => a.currentBid - b.currentBid).reverse().filter(post => post.condition === "مقبولة").filter(post.acceptsOffers === true).filter(post => post.acceptsOffers === true));
            //     }
            //   }

            //   // neither
            //   else{
            //     setQu(query(collection(db, "Posts"), where("acceptsOffers", "==", true)))
            //   }
            // }
            // else{
            // setOrderSelectedName("");
            // if(conditionSelected !== "" && postKind === ""){
            //   if(conditionSelected === 'جديد'){
            //     setQu(query(collection(db, "Posts"), where("condition", "==", "جديد")))
            //     // setListing(initialListing.sort((a, b) => a.createdAt - b.createdAt).reverse().filter(post => post.condition === "جديد"));
            //   }
            //   else if(conditionSelected === 'مستعمل'){
            //     setQu(query(collection(db, "Posts"), where("condition", "==", "مستعمل")))
            //     // setListing(initialListing.sort((a, b) => a.createdAt - b.createdAt).reverse().filter(post => post.condition === "مستعمل"));
            //   }
            //   else if(conditionSelected === 'ممتازة'){
            //     setQu(query(collection(db, "Posts"), where("condition", "==", "ممتازة")))
            //     // setListing(initialListing.sort((a, b) => a.createdAt - b.createdAt).reverse().filter(post => post.condition === "ممتازة"));
            //   }
            //   else if(conditionSelected === 'جيدة جدا'){
            //     setQu(query(collection(db, "Posts"), where("condition", "==", "جيدة جدا")))
            //     // setListing(initialListing.sort((a, b) => a.createdAt - b.createdAt).reverse().filter(post => post.condition === "جيدة جدا"));
            //   }
            //   else if(conditionSelected === 'جيدة'){
            //     setQu(query(collection(db, "Posts"), where("condition", "==", "جيدة")))
            //     // setListing(initialListing.sort((a, b) => a.createdAt - b.createdAt).reverse().filter(post => post.condition === "جيدة"));
            //   }
            //   else if(conditionSelected === 'مقبولة'){
            //     setQu(query(collection(db, "Posts"), where("condition", "==", "مقبولة")))
            //     // setListing(initialListing.sort((a, b) => a.createdAt - b.createdAt).reverse().filter(post => post.condition === "مقبولة"));
            //   }
            // }
            // // only kind
            // else if(postKind !== "" && conditionSelected === ""){
            //   if(postKind === 'يقبل مزايدات'){
            //     setQu(query(collection(db, "Posts"), where("acceptsOffers", "==", true), where("productPrice", ">", 0)))
            //     setListing(Listing.filter(post => post.productPrice > 0))
            //     // setListing(initialListing.sort((a, b) => a.createdAt - b.createdAt).reverse().filter(post => post.acceptsOffers === true));
            //   }
            //   else if(postKind === 'لا يقبل مزايدات'){
            //     setQu(query(collection(db, "Posts"), where("acceptsOffers", "==", false), where("productPrice", ">", 0)))
            //     setListing(Listing.filter(post => post.productPrice > 0))
            //     // setListing(initialListing.sort((a, b) => a.createdAt - b.createdAt).reverse().filter(post => post.acceptsOffers === false));
            //   }
            //   else if(postKind === 'يقبل مزايدات مع سعر شراء فوري'){
            //     setQu(query(collection(db, "Posts"), where("acceptsOffers", "==", true), where("productPrice", ">", 0)))
            //     setListing(Listing.filter(post => post.productPrice > 0))
            //     // setListing(initialListing.sort((a, b) => a.createdAt - b.createdAt).reverse().filter(post => post.acceptsOffers === true).filter(post => post.productPrice > 0));
            //   }
            // }

            // // both selected
            // else if(postKind !== "" && conditionSelected !== ""){
            //   if(conditionSelected === 'جديد' && postKind === 'يقبل مزايدات'){
            //     setQu(query(collection(db, "Posts"), where("condition", "==", "جديد"), where("acceptsOffers", "==", true)))
            //     // setListing(initialListing.sort((a, b) => a.createdAt - b.createdAt).reverse().filter(post => post.condition === "جديد").filter(post => post.acceptsOffers === true));
            //   }
            //   else if(conditionSelected === 'جديد' && postKind === 'لا يقبل مزايدات'){
            //     setQu(query(collection(db, "Posts"), where("condition", "==", "جديد"), where("acceptsOffers", "==", false)))
            //     // setListing(initialListing.sort((a, b) => a.createdAt - b.createdAt).reverse().filter(post => post.condition === "جديد").filter(post => post.acceptsOffers === false));
            //   }
            //   else if(conditionSelected === 'جديد' && postKind === 'يقبل مزايدات مع سعر شراء فوري'){
            //     setQu(query(collection(db, "Posts"), where("condition", "==", "جديد"), where("acceptsOffers", "==", true), where("productPrice", ">", 0)))
            //     // setListing(initialListing.sort((a, b) => a.createdAt - b.createdAt).reverse().filter(post => post.condition === "جديد").filter(post => post.productPrice > 0).filter(post => post.acceptsOffers === true));
            //   }
            //   else if(conditionSelected === 'مستعمل' && postKind === 'يقبل مزايدات'){
            //     setQu(query(collection(db, "Posts"), where("condition", "==", "مستعمل"), where("acceptsOffers", "==", true), where("productPrice", ">", 0)))
            //     // setListing(initialListing.sort((a, b) => a.createdAt - b.createdAt).reverse().filter(post => post.condition === "جديد").filter(post => post.acceptsOffers === true));
            //   }
            //   else if(conditionSelected === 'مستعمل' && postKind === 'لا يقبل مزايدات'){
            //     setQu(query(collection(db, "Posts"), where("condition", "==", "مستعمل"), where("acceptsOffers", "==", false)))
            //     // setListing(initialListing.sort((a, b) => a.createdAt - b.createdAt).reverse().filter(post => post.condition === "جديد").filter(post => post.acceptsOffers === false));
            //   }
            //   else if(conditionSelected === 'مستعمل' && postKind === 'يقبل مزايدات مع سعر شراء فوري'){
            //     setQu(query(collection(db, "Posts"), where("condition", "==", "مستعمل"), where("acceptsOffers", "==", true), where("productPrice", ">", 0)))
            //     // setListing(initialListing.sort((a, b) => a.createdAt - b.createdAt).reverse().filter(post => post.condition === "جديد").filter(post => post.productPrice > 0).filter(post => post.acceptsOffers === true));
            //   }
            //   else if(conditionSelected === 'ممتازة' && postKind === 'يقبل مزايدات'){
            //     setQu(query(collection(db, "Posts"), where("condition", "==", "ممتازة"), where("acceptsOffers", "==", true), where("productPrice", ">", 0)))
            //     // setListing(initialListing.sort((a, b) => a.createdAt - b.createdAt).reverse().filter(post => post.condition === "جديد").filter(post => post.acceptsOffers === true));
            //   }
            //   else if(conditionSelected === 'ممتازة' && postKind === 'لا يقبل مزايدات'){
            //     setQu(query(collection(db, "Posts"), where("condition", "==", "ممتازة"), where("acceptsOffers", "==", false)))
            //     // setListing(initialListing.sort((a, b) => a.createdAt - b.createdAt).reverse().filter(post => post.condition === "جديد").filter(post => post.acceptsOffers === false));
            //   }
            //   else if(conditionSelected === 'ممتازة' && postKind === 'يقبل مزايدات مع سعر شراء فوري'){
            //     setQu(query(collection(db, "Posts"), where("condition", "==", "ممتازة"), where("acceptsOffers", "==", true), where("productPrice", ">", 0)))
            //     // setListing(initialListing.sort((a, b) => a.createdAt - b.createdAt).reverse().filter(post => post.condition === "جديد").filter(post => post.productPrice > 0).filter(post => post.acceptsOffers === true));
            //   }
              
            //   else if(conditionSelected === 'جيدة جدا' && postKind === 'يقبل مزايدات'){
            //     setQu(query(collection(db, "Posts"), where("condition", "==", "جيدة جدا"), where("acceptsOffers", "==", true), where("productPrice", ">", 0)))
            //     // setListing(initialListing.sort((a, b) => a.createdAt - b.createdAt).reverse().filter(post => post.condition === "جديد").filter(post => post.acceptsOffers === true));
            //   }
            //   else if(conditionSelected === 'جيدة جدا' && postKind === 'لا يقبل مزايدات'){
            //     setQu(query(collection(db, "Posts"), where("condition", "==", "جيدة جدا"), where("acceptsOffers", "==", false)))
            //     // setListing(initialListing.sort((a, b) => a.createdAt - b.createdAt).reverse().filter(post => post.condition === "جديد").filter(post => post.acceptsOffers === false));
            //   }
            //   else if(conditionSelected === 'جيدة جدا' && postKind === 'يقبل مزايدات مع سعر شراء فوري'){
            //     setQu(query(collection(db, "Posts"), where("condition", "==", "جيدة جدا"), where("acceptsOffers", "==", true), where("productPrice", ">", 0)))
            //     // setListing(initialListing.sort((a, b) => a.createdAt - b.createdAt).reverse().filter(post => post.condition === "جديد").filter(post => post.productPrice > 0).filter(post => post.acceptsOffers === true));
            //   }
              
            //   else if(conditionSelected === 'جيدة' && postKind === 'يقبل مزايدات'){
            //     setQu(query(collection(db, "Posts"), where("condition", "==", "جيدة"), where("acceptsOffers", "==", true), where("productPrice", ">", 0)))
            //     // setListing(initialListing.sort((a, b) => a.createdAt - b.createdAt).reverse().filter(post => post.condition === "جديد").filter(post => post.acceptsOffers === true));
            //   }
            //   else if(conditionSelected === 'جيدة' && postKind === 'لا يقبل مزايدات'){
            //     setQu(query(collection(db, "Posts"), where("condition", "==", "جيدة"), where("acceptsOffers", "==", false)))
            //     // setListing(initialListing.sort((a, b) => a.createdAt - b.createdAt).reverse().filter(post => post.condition === "جديد").filter(post => post.acceptsOffers === false));
            //   }
            //   else if(conditionSelected === 'جيدة' && postKind === 'يقبل مزايدات مع سعر شراء فوري'){
            //     setQu(query(collection(db, "Posts"), where("condition", "==", "جيدة"), where("acceptsOffers", "==", true), where("productPrice", ">", 0)))
            //     // setListing(initialListing.sort((a, b) => a.createdAt - b.createdAt).reverse().filter(post => post.condition === "جديد").filter(post => post.productPrice > 0).filter(post => post.acceptsOffers === true));
            //   }
              
            //   else if(conditionSelected === 'مقبولة' && postKind === 'يقبل مزايدات'){
            //     setQu(query(collection(db, "Posts"), where("condition", "==", "مقبولة"), where("acceptsOffers", "==", true), where("productPrice", ">", 0)))
            //     // setListing(initialListing.sort((a, b) => a.createdAt - b.createdAt).reverse().filter(post => post.condition === "جديد").filter(post => post.acceptsOffers === true));
            //   }
            //   else if(conditionSelected === 'مقبولة' && postKind === 'لا يقبل مزايدات'){
            //     setQu(query(collection(db, "Posts"), where("condition", "==", "مقبولة"), where("acceptsOffers", "==", false)))
            //     // setListing(initialListing.sort((a, b) => a.createdAt - b.createdAt).reverse().filter(post => post.condition === "جديد").filter(post => post.acceptsOffers === false));
            //   }
            //   else if(conditionSelected === 'مقبولة' && postKind === 'يقبل مزايدات مع سعر شراء فوري'){
            //     setQu(query(collection(db, "Posts"), where("condition", "==", "مقبولة"), where("acceptsOffers", "==", true), where("productPrice", ">", 0)))
            //     // setListing(initialListing.sort((a, b) => a.createdAt - b.createdAt).reverse().filter(post => post.condition === "جديد").filter(post => post.productPrice > 0).filter(post => post.acceptsOffers === true));
            //   }
            // }
            // else{
            //   setQu(query(collection(db, "Posts")))
            //   // setListing(initialListing.sort((a, b) => a.createdAt - b.createdAt).reverse())
            // }
            // }
            }} id={postKind === "لا يقبل مزايدات" ? 'filter-buttons-disabled' : "filter-buttons"}> سعر المزايدة تصاعديا {orderSelectedName === 'سعر المزايدة تصاعديا' ? <FaCheck /> : ""}</li>
          
          
          
          
          
          
          
          
          
          
          
          
          
          
          <li 
          style={{
            fontSize: "14px"
          }}
          onClick={()=>{
             // setListing(initialListing);
             if(orderSelectedName !== "سعر المزايدة تنازليا"){
              setOrderSelectedName("سعر المزايدة تنازليا")
              // only condition
              if(conditionSelected !== "" && postKind === ""){
                if(conditionSelected === 'جديد'){

                  setQu(query(collection(db, "Posts"), where("condition", "==", "جديد"), where("acceptsOffers", "==", true)))
                  // setListing(Listing.filter(post => post.acceptsOffers === true))
                  // setListing(initialListing.sort((a, b) => a.currentBid - b.currentBid).reverse().filter(post => post.condition === "جديد").filter(post => post.productPrice > 0));
                }
                else if(conditionSelected === 'مستعمل'){
                  setQu(query(collection(db, "Posts"), where("condition", "==", "مستعمل"), where("acceptsOffers", "==", true)))
                  // setListing(Listing.filter(post => post.productPrice > 0))
                  // setListing(initialListing.sort((a, b) => a.currentBid - b.currentBid).reverse().filter(post => post.condition === "مستعمل").filter(post => post.productPrice > 0));
                }
                else if(conditionSelected === 'ممتازة'){
                  setQu(query(collection(db, "Posts"), where("condition", "==", "ممتازة"), where("acceptsOffers", "==", true)))
                  // setListing(Listing.filter(post => post.productPrice > 0))
                  // setListing(initialListing.sort((a, b) => a.currentBid - b.currentBid).reverse().filter(post => post.condition === "ممتازة").filter(post => post.productPrice > 0));
                }
                else if(conditionSelected === 'جيدة جدا'){
                  setQu(query(collection(db, "Posts"), where("condition", "==", "جيدة جدا"), where("acceptsOffers", "==", true)))
                  setListing(Listing.filter(post => post.productPrice > 0))
                  // setListing(initialListing.sort((a, b) => a.currentBid - b.currentBid).reverse().filter(post => post.condition === "جيدة جدا").filter(post => post.productPrice > 0));
                }
                else if(conditionSelected === 'جيدة'){
                  setQu(query(collection(db, "Posts"), where("condition", "==", "جيدة"), where("acceptsOffers", "==", true)))
                  // setListing(Listing.filter(post => post.productPrice > 0))
                  // setListing(initialListing.sort((a, b) => a.currentBid - b.currentBid).reverse().filter(post => post.condition === "جيدة").filter(post => post.productPrice > 0));
                }
                else if(conditionSelected === 'مقبولة'){
                  setQu(query(collection(db, "Posts"), where("condition", "==", "مقبولة"), where("acceptsOffers", "==", true)))
                  // setListing(Listing.filter(post => post.productPrice > 0))
                  // setListing(initialListing.sort((a, b) => a.currentBid - b.currentBid).reverse().filter(post => post.condition === "مقبولة").filter(post => post.productPrice > 0));
                }
              }
              // only kind
              else if(postKind !== "" && conditionSelected === ""){
                if(postKind === 'يقبل مزايدات'){
                  setQu(query(collection(db, "Posts"), where("acceptsOffers", "==", true), where("acceptsOffers", "==", true)))
                  // setListing(initialListing.sort((a, b) => a.currentBid - b.currentBid).reverse().filter(post => post.acceptsOffers === true).filter(post => post.productPrice > 0));
                }
                else if(postKind === 'يقبل مزايدات مع سعر شراء فوري'){
                  setQu(query(collection(db, "Posts"), where("acceptsOffers", "==", true), where("productPrice", ">", 0)))
                  // setListing(initialListing.sort((a, b) => a.currentBid - b.currentBid).reverse().filter(post => post.acceptsOffers === true).filter(post => post.productPrice > 0));
                }
                else if(postKind === 'يقبل مزايدات بدون سعر شراء فوري'){
                  setQu(query(collection(db, "Posts"), where("acceptsOffers", "==", true), where("productPrice", "==", 0)))
                  // setListing(initialListing.sort((a, b) => a.currentBid - b.currentBid).reverse().filter(post => post.acceptsOffers === true).filter(post => post.productPrice > 0));
                }
              }

              // both selected
              else if(postKind !== "" && conditionSelected !== ""){
                if(conditionSelected === 'جديد' && postKind === 'يقبل مزايدات'){
                  setQu(query(collection(db, "Posts"), where("condition", "==", "جديد"), where("acceptsOffers", "==", true)))
                  // setListing(initialListing.sort((a, b) => a.currentBid - b.currentBid).reverse().filter(post => post.condition === "جديد").filter(post => post.productPrice > 0).filter(post => post.acceptsOffers === true));
                }
                else if(conditionSelected === 'جديد' && postKind === 'يقبل مزايدات مع سعر شراء فوري'){
                  setQu(query(collection(db, "Posts"), where("condition", "==", "جديد"), where("acceptsOffers", "==", true), where("productPrice", ">", 0)))
                  // setListing(initialListing.sort((a, b) => a.currentBid - b.currentBid).reverse().filter(post => post.condition === "جديد").filter(post => post.productPrice > 0).filter(post => post.acceptsOffers === true));
                }
                else if(conditionSelected === 'جديد' && postKind === 'يقبل مزايدات بدون سعر شراء فوري'){
                  console.log("here")
                  setQu(query(collection(db, "Posts"), where("condition", "==", "جديد"), where("acceptsOffers", "==", true), where("productPrice", "==", 0)))
                  // setListing(initialListing.sort((a, b) => a.currentBid - b.currentBid).reverse().filter(post => post.condition === "جديد").filter(post => post.productPrice > 0).filter(post => post.acceptsOffers === true));
                }
                else if(conditionSelected === 'مستعمل' && postKind === 'يقبل مزايدات'){
                  setQu(query(collection(db, "Posts"), where("condition", "==", "مستعمل"), where("acceptsOffers", "==", true), where("productPrice", ">", 0)))
                  // setListing(initialListing.sort((a, b) => a.currentBid - b.currentBid).reverse().filter(post => post.condition === "مستعمل").filter(post => post.productPrice > 0).filter(post => post.acceptsOffers === true));
                }
                else if(conditionSelected === 'مستعمل' && postKind === 'لا يقبل مزايدات'){
                  setQu(query(collection(db, "Posts"), where("condition", "==", "مستعمل"), where("acceptsOffers", "==", false), where("productPrice", ">", 0)))
                  // setListing(initialListing.sort((a, b) => a.currentBid - b.currentBid).reverse().filter(post => post.condition === "مستعمل").filter(post => post.productPrice > 0).filter(post => post.acceptsOffers === false));
                }
                else if(conditionSelected === 'مستعمل' && postKind === 'يقبل مزايدات مع سعر شراء فوري'){
                  setQu(query(collection(db, "Posts"), where("condition", "==", "جديد"), where("acceptsOffers", "==", true), where("productPrice", ">", 0)))
                  // setListing(initialListing.sort((a, b) => a.currentBid - b.currentBid).reverse().filter(post => post.condition === "مستعمل").filter(post => post.productPrice > 0).filter(post => post.acceptsOffers === true));
                }
                else if(conditionSelected === 'ممتازة' && postKind === 'يقبل مزايدات'){
                  setQu(query(collection(db, "Posts"), where("condition", "==", "ممتازة"), where("acceptsOffers", "==", true), where("productPrice", ">", 0)))
                  // setListing(initialListing.sort((a, b) => a.currentBid - b.currentBid).reverse().filter(post => post.condition === "ممتازة").filter(post => post.productPrice > 0).filter(post => post.acceptsOffers === true));
                }
                else if(conditionSelected === 'ممتازة' && postKind === 'لا يقبل مزايدات'){
                  setQu(query(collection(db, "Posts"), where("condition", "==", "ممتازة"), where("acceptsOffers", "==", false), where("productPrice", ">", 0)))
                  // setListing(initialListing.sort((a, b) => a.currentBid - b.currentBid).reverse().filter(post => post.condition === "ممتازة").filter(post => post.productPrice > 0).filter(post => post.acceptsOffers === false));
                }
                else if(conditionSelected === 'ممتازة' && postKind === 'يقبل مزايدات مع سعر شراء فوري'){
                  setQu(query(collection(db, "Posts"), where("condition", "==", "ممتازة"), where("acceptsOffers", "==", true), where("productPrice", ">", 0)))
                  // setListing(initialListing.sort((a, b) => a.currentBid - b.currentBid).reverse().filter(post => post.condition === "ممتازة").filter(post => post.productPrice > 0).filter(post => post.acceptsOffers === true));
                }
                else if(conditionSelected === 'جيدة جدا' && postKind === 'يقبل مزايدات'){
                  setQu(query(collection(db, "Posts"), where("condition", "==", "جيدة جدا"), where("acceptsOffers", "==", true), where("productPrice", ">", 0)))
                  // setListing(initialListing.sort((a, b) => a.currentBid - b.currentBid).reverse().filter(post => post.condition === "جيدة جدا").filter(post => post.productPrice > 0).filter(post => post.acceptsOffers === true));
                }
                else if(conditionSelected === 'جيدة جدا' && postKind === 'لا يقبل مزايدات'){
                  setQu(query(collection(db, "Posts"), where("condition", "==", "جيدة جدا"), where("acceptsOffers", "==", false), where("productPrice", ">", 0)))
                  // setListing(initialListing.sort((a, b) => a.currentBid - b.currentBid).reverse().filter(post => post.condition === "جيدة جدا").filter(post => post.productPrice > 0).filter(post => post.acceptsOffers === false));
                }
                else if(conditionSelected === 'جيدة جدا' && postKind === 'يقبل مزايدات مع سعر شراء فوري'){
                  setQu(query(collection(db, "Posts"), where("condition", "==", "جيدة جدا"), where("acceptsOffers", "==", true), where("productPrice", ">", 0)))
                  // setListing(initialListing.sort((a, b) => a.currentBid - b.currentBid).reverse().filter(post => post.condition === "جيدة جدا").filter(post => post.productPrice > 0).filter(post => post.acceptsOffers === true));
                }
                else if(conditionSelected === 'جيدة' && postKind === 'يقبل مزايدات'){
                  setQu(query(collection(db, "Posts"), where("condition", "==", "جيدة"), where("acceptsOffers", "==", true), where("productPrice", ">", 0)))
                  // setListing(initialListing.sort((a, b) => a.currentBid - b.currentBid).reverse().filter(post => post.condition === "جيدة").filter(post => post.productPrice > 0).filter(post => post.acceptsOffers === true));
                }
                else if(conditionSelected === 'جيدة' && postKind === 'لا يقبل مزايدات'){
                  setQu(query(collection(db, "Posts"), where("condition", "==", "جيدة"), where("acceptsOffers", "==", false), where("productPrice", ">", 0)))
                  // setListing(initialListing.sort((a, b) => a.currentBid - b.currentBid).reverse().filter(post => post.condition === "جيدة").filter(post => post.productPrice > 0).filter(post => post.acceptsOffers === false));
                }
                else if(conditionSelected === 'جيدة' && postKind === 'يقبل مزايدات مع سعر شراء فوري'){
                  setQu(query(collection(db, "Posts"), where("condition", "==", "جيدة"), where("acceptsOffers", "==", true), where("productPrice", ">", 0)))
                  setListing(initialListing.sort((a, b) => a.currentBid - b.currentBid).reverse().filter(post => post.condition === "جيدة").filter(post => post.productPrice > 0).filter(post => post.acceptsOffers === true));
                }
                else if(conditionSelected === 'مقبولة' && postKind === 'يقبل مزايدات'){
                  setQu(query(collection(db, "Posts"), where("condition", "==", "مقبولة"), where("acceptsOffers", "==", true), where("productPrice", ">", 0)))
                  // setListing(initialListing.sort((a, b) => a.currentBid - b.currentBid).reverse().filter(post => post.condition === "مقبولة").filter(post => post.productPrice > 0).filter(post => post.acceptsOffers === true));
                }
                else if(conditionSelected === 'مقبولة' && postKind === 'لا يقبل مزايدات'){
                  setQu(query(collection(db, "Posts"), where("condition", "==", "مقبولة"), where("acceptsOffers", "==", false), where("productPrice", ">", 0)))
                  // setListing(initialListing.sort((a, b) => a.currentBid - b.currentBid).reverse().filter(post => post.condition === "مقبولة").filter(post => post.productPrice > 0).filter(post => post.acceptsOffers === false));
                }
                else if(conditionSelected === 'مقبولة' && postKind === 'يقبل مزايدات مع سعر شراء فوري'){
                  setQu(query(collection(db, "Posts"), where("condition", "==", "مقبولة"), where("acceptsOffers", "==", true), where("productPrice", ">", 0)))
                  // setListing(initialListing.sort((a, b) => a.currentBid - b.currentBid).reverse().filter(post => post.condition === "مقبولة").filter(post => post.productPrice > 0).filter(post => post.acceptsOffers === true));
                }
              }

              // neither
              else{
                setQu(query(collection(db, "Posts"), where("acceptsOffers", "==", true)))
              }
            }
            else{
            setOrderSelectedName("");
            if(conditionSelected !== "" && postKind === ""){
              if(conditionSelected === 'جديد'){
                setQu(query(collection(db, "Posts"), where("condition", "==", "جديد")))
                // setListing(initialListing.sort((a, b) => a.createdAt - b.createdAt).reverse().filter(post => post.condition === "جديد"));
              }
              else if(conditionSelected === 'مستعمل'){
                setQu(query(collection(db, "Posts"), where("condition", "==", "مستعمل")))
                // setListing(initialListing.sort((a, b) => a.createdAt - b.createdAt).reverse().filter(post => post.condition === "مستعمل"));
              }
              else if(conditionSelected === 'ممتازة'){
                setQu(query(collection(db, "Posts"), where("condition", "==", "ممتازة")))
                // setListing(initialListing.sort((a, b) => a.createdAt - b.createdAt).reverse().filter(post => post.condition === "ممتازة"));
              }
              else if(conditionSelected === 'جيدة جدا'){
                setQu(query(collection(db, "Posts"), where("condition", "==", "جيدة جدا")))
                // setListing(initialListing.sort((a, b) => a.createdAt - b.createdAt).reverse().filter(post => post.condition === "جيدة جدا"));
              }
              else if(conditionSelected === 'جيدة'){
                setQu(query(collection(db, "Posts"), where("condition", "==", "جيدة")))
                // setListing(initialListing.sort((a, b) => a.createdAt - b.createdAt).reverse().filter(post => post.condition === "جيدة"));
              }
              else if(conditionSelected === 'مقبولة'){
                setQu(query(collection(db, "Posts"), where("condition", "==", "مقبولة")))
                // setListing(initialListing.sort((a, b) => a.createdAt - b.createdAt).reverse().filter(post => post.condition === "مقبولة"));
              }
            }
            // only kind
            else if(postKind !== "" && conditionSelected === ""){
              if(postKind === 'يقبل مزايدات'){
                setQu(query(collection(db, "Posts"), where("acceptsOffers", "==", true), where("productPrice", ">", 0)))
                setListing(Listing.filter(post => post.productPrice > 0))
                // setListing(initialListing.sort((a, b) => a.createdAt - b.createdAt).reverse().filter(post => post.acceptsOffers === true));
              }
              else if(postKind === 'لا يقبل مزايدات'){
                setQu(query(collection(db, "Posts"), where("acceptsOffers", "==", false), where("productPrice", ">", 0)))
                setListing(Listing.filter(post => post.productPrice > 0))
                // setListing(initialListing.sort((a, b) => a.createdAt - b.createdAt).reverse().filter(post => post.acceptsOffers === false));
              }
              else if(postKind === 'يقبل مزايدات مع سعر شراء فوري'){
                setQu(query(collection(db, "Posts"), where("acceptsOffers", "==", true), where("productPrice", ">", 0)))
                setListing(Listing.filter(post => post.productPrice > 0))
                // setListing(initialListing.sort((a, b) => a.createdAt - b.createdAt).reverse().filter(post => post.acceptsOffers === true).filter(post => post.productPrice > 0));
              }
            }

            // both selected
            else if(postKind !== "" && conditionSelected !== ""){
              if(conditionSelected === 'جديد' && postKind === 'يقبل مزايدات'){
                setQu(query(collection(db, "Posts"), where("condition", "==", "جديد"), where("acceptsOffers", "==", true)))
                // setListing(initialListing.sort((a, b) => a.createdAt - b.createdAt).reverse().filter(post => post.condition === "جديد").filter(post => post.acceptsOffers === true));
              }
              else if(conditionSelected === 'جديد' && postKind === 'لا يقبل مزايدات'){
                setQu(query(collection(db, "Posts"), where("condition", "==", "جديد"), where("acceptsOffers", "==", false)))
                // setListing(initialListing.sort((a, b) => a.createdAt - b.createdAt).reverse().filter(post => post.condition === "جديد").filter(post => post.acceptsOffers === false));
              }
              else if(conditionSelected === 'جديد' && postKind === 'يقبل مزايدات مع سعر شراء فوري'){
                setQu(query(collection(db, "Posts"), where("condition", "==", "جديد"), where("acceptsOffers", "==", true), where("productPrice", ">", 0)))
                // setListing(initialListing.sort((a, b) => a.createdAt - b.createdAt).reverse().filter(post => post.condition === "جديد").filter(post => post.productPrice > 0).filter(post => post.acceptsOffers === true));
              }
              else if(conditionSelected === 'مستعمل' && postKind === 'يقبل مزايدات'){
                setQu(query(collection(db, "Posts"), where("condition", "==", "مستعمل"), where("acceptsOffers", "==", true), where("productPrice", ">", 0)))
                // setListing(initialListing.sort((a, b) => a.createdAt - b.createdAt).reverse().filter(post => post.condition === "جديد").filter(post => post.acceptsOffers === true));
              }
              else if(conditionSelected === 'مستعمل' && postKind === 'لا يقبل مزايدات'){
                setQu(query(collection(db, "Posts"), where("condition", "==", "مستعمل"), where("acceptsOffers", "==", false)))
                // setListing(initialListing.sort((a, b) => a.createdAt - b.createdAt).reverse().filter(post => post.condition === "جديد").filter(post => post.acceptsOffers === false));
              }
              else if(conditionSelected === 'مستعمل' && postKind === 'يقبل مزايدات مع سعر شراء فوري'){
                setQu(query(collection(db, "Posts"), where("condition", "==", "مستعمل"), where("acceptsOffers", "==", true), where("productPrice", ">", 0)))
                // setListing(initialListing.sort((a, b) => a.createdAt - b.createdAt).reverse().filter(post => post.condition === "جديد").filter(post => post.productPrice > 0).filter(post => post.acceptsOffers === true));
              }
              else if(conditionSelected === 'ممتازة' && postKind === 'يقبل مزايدات'){
                setQu(query(collection(db, "Posts"), where("condition", "==", "ممتازة"), where("acceptsOffers", "==", true), where("productPrice", ">", 0)))
                // setListing(initialListing.sort((a, b) => a.createdAt - b.createdAt).reverse().filter(post => post.condition === "جديد").filter(post => post.acceptsOffers === true));
              }
              else if(conditionSelected === 'ممتازة' && postKind === 'لا يقبل مزايدات'){
                setQu(query(collection(db, "Posts"), where("condition", "==", "ممتازة"), where("acceptsOffers", "==", false)))
                // setListing(initialListing.sort((a, b) => a.createdAt - b.createdAt).reverse().filter(post => post.condition === "جديد").filter(post => post.acceptsOffers === false));
              }
              else if(conditionSelected === 'ممتازة' && postKind === 'يقبل مزايدات مع سعر شراء فوري'){
                setQu(query(collection(db, "Posts"), where("condition", "==", "ممتازة"), where("acceptsOffers", "==", true), where("productPrice", ">", 0)))
                // setListing(initialListing.sort((a, b) => a.createdAt - b.createdAt).reverse().filter(post => post.condition === "جديد").filter(post => post.productPrice > 0).filter(post => post.acceptsOffers === true));
              }
              
              else if(conditionSelected === 'جيدة جدا' && postKind === 'يقبل مزايدات'){
                setQu(query(collection(db, "Posts"), where("condition", "==", "جيدة جدا"), where("acceptsOffers", "==", true), where("productPrice", ">", 0)))
                // setListing(initialListing.sort((a, b) => a.createdAt - b.createdAt).reverse().filter(post => post.condition === "جديد").filter(post => post.acceptsOffers === true));
              }
              else if(conditionSelected === 'جيدة جدا' && postKind === 'لا يقبل مزايدات'){
                setQu(query(collection(db, "Posts"), where("condition", "==", "جيدة جدا"), where("acceptsOffers", "==", false)))
                // setListing(initialListing.sort((a, b) => a.createdAt - b.createdAt).reverse().filter(post => post.condition === "جديد").filter(post => post.acceptsOffers === false));
              }
              else if(conditionSelected === 'جيدة جدا' && postKind === 'يقبل مزايدات مع سعر شراء فوري'){
                setQu(query(collection(db, "Posts"), where("condition", "==", "جيدة جدا"), where("acceptsOffers", "==", true), where("productPrice", ">", 0)))
                // setListing(initialListing.sort((a, b) => a.createdAt - b.createdAt).reverse().filter(post => post.condition === "جديد").filter(post => post.productPrice > 0).filter(post => post.acceptsOffers === true));
              }
              
              else if(conditionSelected === 'جيدة' && postKind === 'يقبل مزايدات'){
                setQu(query(collection(db, "Posts"), where("condition", "==", "جيدة"), where("acceptsOffers", "==", true), where("productPrice", ">", 0)))
                // setListing(initialListing.sort((a, b) => a.createdAt - b.createdAt).reverse().filter(post => post.condition === "جديد").filter(post => post.acceptsOffers === true));
              }
              else if(conditionSelected === 'جيدة' && postKind === 'لا يقبل مزايدات'){
                setQu(query(collection(db, "Posts"), where("condition", "==", "جيدة"), where("acceptsOffers", "==", false)))
                // setListing(initialListing.sort((a, b) => a.createdAt - b.createdAt).reverse().filter(post => post.condition === "جديد").filter(post => post.acceptsOffers === false));
              }
              else if(conditionSelected === 'جيدة' && postKind === 'يقبل مزايدات مع سعر شراء فوري'){
                setQu(query(collection(db, "Posts"), where("condition", "==", "جيدة"), where("acceptsOffers", "==", true), where("productPrice", ">", 0)))
                // setListing(initialListing.sort((a, b) => a.createdAt - b.createdAt).reverse().filter(post => post.condition === "جديد").filter(post => post.productPrice > 0).filter(post => post.acceptsOffers === true));
              }
              
              else if(conditionSelected === 'مقبولة' && postKind === 'يقبل مزايدات'){
                setQu(query(collection(db, "Posts"), where("condition", "==", "مقبولة"), where("acceptsOffers", "==", true), where("productPrice", ">", 0)))
                // setListing(initialListing.sort((a, b) => a.createdAt - b.createdAt).reverse().filter(post => post.condition === "جديد").filter(post => post.acceptsOffers === true));
              }
              else if(conditionSelected === 'مقبولة' && postKind === 'لا يقبل مزايدات'){
                setQu(query(collection(db, "Posts"), where("condition", "==", "مقبولة"), where("acceptsOffers", "==", false)))
                // setListing(initialListing.sort((a, b) => a.createdAt - b.createdAt).reverse().filter(post => post.condition === "جديد").filter(post => post.acceptsOffers === false));
              }
              else if(conditionSelected === 'مقبولة' && postKind === 'يقبل مزايدات مع سعر شراء فوري'){
                setQu(query(collection(db, "Posts"), where("condition", "==", "مقبولة"), where("acceptsOffers", "==", true), where("productPrice", ">", 0)))
                // setListing(initialListing.sort((a, b) => a.createdAt - b.createdAt).reverse().filter(post => post.condition === "جديد").filter(post => post.productPrice > 0).filter(post => post.acceptsOffers === true));
              }
            }
            else{
              setQu(query(collection(db, "Posts")))
              // setListing(initialListing.sort((a, b) => a.createdAt - b.createdAt).reverse())
            }
            }
            }} id={postKind === "لا يقبل مزايدات" ? 'filter-buttons-disabled' : "filter-buttons"}> سعر المزايدة تنازليا {orderSelectedName === 'سعر المزايدة تنازليا' ? <FaCheck /> : ""}</li>
        </ul>
          </div>
        </div>

{/* <InfiniteScroll
dataLength={8}
hasMore={!hasMore}
next={fetchMore}
endMessage={
  <p style={{textAlign:'center'}}><b>You've reached the bottom</b></p>
}
scrollThreshold={1}
loader={<h5>Loading...</h5>}
> */}




<div style={{display:"grid", gap: "4px", width:"100%", height: "fit-content", gridTemplateColumns: window.innerWidth > 600 ? "repeat(auto-fill, minmax(30%, 1fr))" : "repeat(auto-fill, minmax(40%, 1fr))", alignItems: "center", textAlign:"center"}}>
  {Listing.map((item) => (
    <div key={item.id}
     >

{/* <div style={{width:"100%", height: "fit-content", backgroundColor:"#f7f7f7", border: "1px solid #E8E9E9", padding: "4px", borderRadius:"14px", textAlign: "right"}}>
<img style={{width:"100%", height: "50%", objectFit:"cover", borderRadius:"14px"}} src={FurnitureImg} />
<p style={{direction:"rtl", margin:"2px 0px 0px 0px ",width:"100%"}} id='product-name-div'>اسم المنتجاسم المنتجاسم المنتجاسم المنتجاسم المنتجاسم المنتج</p>
<p style={{direction:"rtl", color:"#00C6B7", marginBottom:"2px"}}>40.44 ر.س</p>
<div style={{display: "flex", direction: "rtl", alignItems: "center", marginBottom: "2px", marginTop: "2px"}}>
      <div style={{display:"flex", justifyContent: "space-between"}}>
        <PiGavelFill
    style={{color: "#00C6B7", marginTop:"4px"}}
    />
    <p style={{marginBottom: "0px", marginRight: "4px", color: "#F00057"}}>{item.numBidders}</p>
    </div>

    <div style={{display: "flex",  marginRight:"auto"}}>

    <Countdown
    
    className='product-countdown-class'
    date={item.expiryDate * 1000}
    >
      <p style={{ marginBottom: "0px", color: "#F00057" }}>expired</p>
    </Countdown>
    <BsStopwatchFill color='#F00057' style={{marginRight: "4px", marginTop:"2px"}}/>
        </div>
    </div>
<p style={{direction:"rtl", color:"#00C6B7",display:"flex", marginBottom:"0px"}}>الحالة: <p style={{color:"#F00057", marginRight:"4px"}}>ممتازة</p></p>
<button style={{width:"100%", marginBottom:"4px", backgroundColor:"#00C6B7", color:"white", padding:"4px", border:"none", borderRadius:"4px", fontSize:"16px"}}>المزيد</button>
</div> */}


      <Product
        postId={item.id}
        expiryDate={`${item.expiryDate.seconds}`}
        link={`/product/${item.id}`}
        numBidders={Object.keys(item.bidders).length}
        currentBid={item.currentBid}
        imgs={item.imgs[0]}
        condition={item.condition}
        acceptsOffers={item.acceptsOffers}
        productPrice={item.productPrice}
        productDescription={item.productDescription}
        productSeller={item.seller}
        sellerId={item.seller['id']}
        startingPrice={item.startingPrice}
        sellerCity={item.seller}
        productName={item.productName}
      />
    </div>
  ))}
      </div>


          </div>
    );
  }
  
  export default  HomeScreen;