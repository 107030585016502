import {React, useState} from "react"; 
import './App.css';
import CustomNavbar from "./components/CustomNavbar";
import ProductsScreen from "./screens/ProductsScreen";
import { BrowserRouter, Routes, Route, Link, Outlet, useParams, router, useNavigate } from "react-router-dom";
import ElectronicsScreen from './screens/ElectronicsScreen';
import FurnitureScreen from "./screens/FurnitureScreen";
import CarPartsScreen from "./screens/CarPartsScreen";
import PostDetailsScreen from "./screens/PostDetailsScreen";
import AddPostScreen from "./screens/AddPostScreen";
import SignUpScreen from "./screens/SignUpScreen";
import SignInScreen from "./screens/SignInScreen";
import { getAuth, signInAnonymously } from "firebase/auth";
import { initializeApp } from 'firebase/app';
import MyAccountScreen from "./screens/MyAccountScreen";
import MyPostsScreen from "./screens/MyPostsScreen";
import MyPostDetailsScreen from "./screens/MyPostDetailsScreen";
import UserScreen from './screens/UserScreen'
import MyBidsScreen from "./screens/MyBidsScreen";
import LandingScreen from "./screens/LandingScreen";
import SignInOrSignUpScreen from "./screens/SignInOrSignUpScreen";
import logo from './assets/logo2.png'
import payment from './assets/payment_methods.png'
import { Divider } from "@mui/material";
import { IoLogoWhatsapp } from "react-icons/io5";
import Footer from "./components/Footer";
import UserAgreement from "./screens/UserAgreement";
import AboutUsScreen from "./screens/AboutUs";
import ContactUs from "./screens/ContactUs";
import MoreScreen from "./screens/MoreScreen";
import Checkout from "./screens/Checkout";
import ComingSoon from "./screens/ComingSoonScreen";

const App = () => { 
  const {id} = useParams();


  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };
	return ( 
    <BrowserRouter>
    {/* <BasicExample/> */}
    
      <Routes>
        <Route path="/" element={
          // <ComingSoon />
          <>
          <CustomNavbar/>
          <div style={{paddingTop: "65px"}}>
          <Outlet/>

          </div>
          <Footer />
          </>
        }>
          <Route index  element={<LandingScreen/>}/>
          <Route path="/?search" element={<ProductsScreen />} />
          {/* <Route path="electronics" element={<ElectronicsScreen />} /> */}
          {/* <Route path="furniture" element={<FurnitureScreen />} /> */}
          {/* <Route path="car-parts" element={<CarPartsScreen />} /> */}
          <Route path='product/:id' element={ <PostDetailsScreen />} />
          <Route path='products' element={ <ProductsScreen />} />
          <Route path='user/:id' element={ < UserScreen />} />
          <Route path="add_post" element={<AddPostScreen/>} />
          <Route path="my_bids" element={<MyBidsScreen />} />
          <Route path="my_account" element={<MyAccountScreen />} />
          {/* <Route path="sign_up" element={<SignUpScreen />} /> */}
          <Route path="sign_in" element={<SignInOrSignUpScreen />} />
          <Route path="my_posts" element={<MyPostsScreen />} />
          <Route  path='my_products/:id'   element={<MyPostDetailsScreen />} />
          <Route  path='more' element={<MoreScreen />} />
          <Route  path='user_policy' element={<UserAgreement />} />
          <Route  path='about_us' element={<AboutUsScreen />} />
          <Route  path='contact_us' element={<ContactUs />} />
          <Route  path='more' element={<ContactUs />} />
          <Route  path='product/:id/checkout' element={<Checkout />} />
          <Route path="*" element={<div style={{height: "100vh"}}><h1 style={{marginTop : "100px"}}>Page Not Found</h1></div>} />
        </Route>
      </Routes>
{/*     
    <div id="slider-div">
    
    <Slider autoplaySpeed={3000} autoplay>
    
    <div id="test-div">
    
    <img id="test-img" src={s1}/>
    </div>
    <div id="test-div">
    
    <img id="test-img" src={s2}/>
    </div>
    <div id="test-div">
    
    <img id="test-img" src={s3}/>
    </div>
    
    </Slider>
  </div> */}

  </BrowserRouter>
	); 
}; 

export default App;
