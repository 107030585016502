import React, {useState, useEffect, useRef} from 'react'
import payment from '../assets/payment_methods.png'
import furnitureImg from '../assets/furniture.jpg'
import { IoLocation } from "react-icons/io5";
import { MdModeEdit } from "react-icons/md";
import { FaTrashCan } from "react-icons/fa6";
import cards from '../assets/cards.png';
import applePay from '../assets/applePay.png'
import { CiCreditCard1 } from "react-icons/ci";
import {useNavigate, useLocation, useSearchParams, useParams, Link} from "react-router-dom";
import { getFirestore, collection, getDocs, onSnapshot, query, where, updateDoc, limit, doc, getDoc, serverTimestamp, arrayUnion, FieldValue, increment, addDoc, orderBy , getCountFromServer, setDoc} from 'firebase/firestore';
import { db, auth, storage } from '../config/firebase'
import { Divider } from '@mui/material';
import SignInOrSignUpScreen from './SignInOrSignUpScreen';
import { Oval } from 'react-loader-spinner'


function Checkout () {

        const [selectedOption, setSelectedOption] = useState(null);
        const [isSafari, setIsSafari] = useState(false);
        const [isLoading, setIsLoading] = useState(true);
        const [cardNumber, setCardNumber] = useState('');

        const cardNumberRef = useRef();
        const monthRef = useRef();
        const yearRef = useRef();
        const cvcRef = useRef();

        const {id} = useParams();

        const q = query(collection(db, "Posts"), where("id", "==", id));


        const [productName, setProductName] = useState("");
        const [productDescription, setProductDescription] = useState("");
        const [productPrice, setProductPrice] = useState("");
        const [isSold, setIsSold] = useState("");
        const [isDeleted, setIsDeleted] = useState("");
        const [acceptsOffers, setAcceptsOffers] = useState(false);
        const [currentBid, setCurrentBid] = useState(0);
        const [productImg, setProductImg] = useState("");
        const [expiryDate, setExpiryDate] = useState("");
        const [hasExpired, setHasExpired] = useState(false);
        const [startingPrice, setStartingPrice] = useState(false);
        const [bidders, setBidders] = useState("");
        const [bidIncrements, setBidIncrements] = useState(0);
        const [heighestBidder, setHeighestBidder] = useState(0);


        const price = acceptsOffers ? (hasExpired ? currentBid - (startingPrice * 0.05) : startingPrice * 0.05) : productPrice;
        const shippingFee = hasExpired ? 35 : 0;
        const amount = parseFloat(price + shippingFee);

        const [orderNumber, setOrderNumber] = useState(null)

        const navigate = useNavigate();

        const ordersCollection = collection(db, 'Orders');


        
        window.scrollTo({
                top: 0,
                left: 0,
                behavior: 'instant'
              });
        
        useEffect(() => {


                      auth.onAuthStateChanged(async function (user) {
                        // if (user != null) {


                const fetchData = () => {
                        try {
                                const unsubscribe = onSnapshot(q, (querySnapshot) => {
                                        querySnapshot.forEach((doc) => {
                                                const data = doc.data();
                                                
                                                if (!data.isDeleted || !data.isSold) {
                                                        const {
                                                                productName,
                                                                productPrice,
                    isSold,
                    productDescription,
                    acceptsOffers,
                    currentBid,
                    imgs,
                    expiryDate,
                    startingPrice,
                    bidders,
                    bidIncrements,
                    heighestBidder,
                    isDeleted
                } = data;
                
                  setProductName(productName);
                  setProductPrice(productPrice);
                  setIsSold(isSold);
                  setProductDescription(productDescription);
                  setAcceptsOffers(acceptsOffers);
                  setCurrentBid(currentBid);
                  setProductImg(imgs[0]);
                  setExpiryDate(expiryDate);
                  setStartingPrice(startingPrice);
                  setBidders(bidders);
                  setBidIncrements(bidIncrements);
                  setHeighestBidder(heighestBidder);
                  setIsDeleted(isDeleted);
                  
                  if (doc.data().expiryDate !== "") {
                          if (Date.now() / 1000 - doc.data().expiryDate.seconds < 0) {
                                  setHasExpired(false);
                                } else {
                                        setHasExpired(true);
                                }
                        }
                } else {
                        data.isSold ? setIsSold(true) : setIsSold(false);
                }
        });
});

            return () => {
                    // Unsubscribe from the snapshot listener when the component unmounts
                    unsubscribe();
                };
          } catch (error) {
            console.error("Error fetching data:", error);
        }
        };

        const getOrderCount = async () => {
                try {
                  const querySnapshot = await getDocs(ordersCollection);
                  return querySnapshot.size; // Returns the count of documents
                } catch (error) {
                  console.error('Error getting order count:', error);
                  return -1; // Return -1 to indicate an error
                }
              };
        
        const getOrderNumber = async () => {
                const count = await getOrderCount();

                console.log(count)
              
                if (count !== -1) {
                  const newOrderNumber = count + 1;
                  // Assuming orderNumber is a state variable, you can set it here
                  // setOrderNumber(newOrderNumber);
                  setOrderNumber(newOrderNumber)
                  console.log('New Order Number:', newOrderNumber);
                } else {
                  console.log('Failed to get order count. Handle the error accordingly.');
                }
              };
              


        
      
        // Call the fetchData function
        fetchData();
        getOrderNumber();
        setIsLoading(false)
// }
// else{
//         setIsLoading(false)
// }
})
      }, []);



      
    if(isLoading){
        return       <div style={{
          width: "100vw",
          height: "80vh",
          marginTop: "55px",
          display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
  
        }}>
          <Oval
                height={"50px"}
                width={"50px"}
                color={"dodgerblue"}
                wrapperClass=""
                visible={true}
                wrapperStyle={{display: "block"}}
                ariaLabel='oval-loading'
                secondaryColor="#ccc"
                strokeWidth={1}
                strokeWidthSecondary={1}
              />
        </div>
      }


      if(acceptsOffers && hasExpired && heighestBidder != auth.currentUser.uid ){
        return <h1 style={{marginTop:"60px"}}>this post has expired</h1>
      }

      if(isDeleted){
        return <h1 style={{marginTop: "60px"}}>the post was deleted</h1>
      }

      if(isSold && heighestBidder != auth.currentUser.uid){
        return <h1 style={{marginTop: "60px"}}>the post was sold</h1>
      }

      if(isSold && heighestBidder === auth.currentUser.uid){
        navigate(`/product/${id}`)
      }

//       if(auth.currentUser == null){
//         return <SignInOrSignUpScreen />
//       }




//       async function createOrder(){
//         const coll = collection(db, "Orders");
//         const snapshot = await getCountFromServer(coll);

//         var orderNumber = ++snapshot.data().count;

//         await setDoc(doc(db, "Orders", `order${orderNumber}`), {
//                 id: orderNumber,
//                 buyer: auth.currentUser.uid,
//                 amount: amount,
//                 isPaid: false
//         })
//       }
      
        const isApplePaySupported = () => {
          return typeof ApplePaySession === 'function';
        };

        // const [supportsApplePay, setSupportsApplePay] = useState(isApplePaySupported());

        const options = [
                { label : "Cards" , value: 'cards', image: cards },
                { label: 'Apple Pay', value: 'applePay', image: applePay, size: '80px' },
              ];

        const handleOptionChange = (value) => {
                setSelectedOption(value);
              };


              const handleInputChange = (e) => {
                // Remove non-numeric characters
                const newDigit = e.target.value.replace(/\D/g, '');
            
                // Limit the input to 16 digits
                if (newDigit.length > 16) {
                  return;
                }
            
                // Create a new card number with space after every 4 digits
                const newCardNumber = newDigit
                  .match(/.{1,4}/g)
                  ?.join(' ')
                  .trim();
            
                setCardNumber(newCardNumber || '');
                        if(e.target.value.length === 19){
                                monthRef.current.focus();
                              }
              };


              function formatAccountingArabic(number) {
                // Check if the input is a valid number
                if (typeof number !== 'number' || isNaN(number)) {
                  return 'Invalid input';
                }
              
                // Use toLocaleString to format the number with commas and two decimal places
                const formattedNumber = number.toLocaleString('en', {
                  minimumFractionDigits: number > 100 ? 0 : 2,
                  maximumFractionDigits: number > 100 ? 0 : 2,
                });
              
                // Add the Arabic currency symbol manually
                const arabicFormattedNumber = `${formattedNumber} ر.س`;
              
                return arabicFormattedNumber;
            }
            
        

        return (
                <div style={{display: "flex", flexDirection:  window.innerWidth > 600 ? "row" : "column",justifyContent: window.innerWidth > 600 ? "space-around" : "start",direction: "rtl", width: window.innerWidth > 600 ? "80%" : "95%", paddingTop: "24px", marginLeft:"auto", marginRight:"auto", height: window.innerWidth > 600 ? "calc(100vh - 55px)" : "100vh"}}>
                        <div style={{width: window.innerWidth > 600 ? "47%" : "100%"}}>
                                <div>

                        <h5>ملخص الطلب</h5>
                        <div style={{ height: "fit-content", border : "1px solid #00C6B7", borderRadius: "12px", padding: "8px"}}>
                                <h6 style={{marginRight: "8px"}}>{acceptsOffers && !hasExpired ? `عربون دخول مزاد لمنتج رقم #${id}` : `طلب رقم #${orderNumber}`}</h6>

                                <div style={{width: "95%", height: "fit-content", display: "flex"}}>
                                <img src={productImg} style={{width: "30%", height: window.innerWidth > 600 ? "150px": "100px", borderRadius: "12px", objectFit:"cover"}} />
                                <div style={{marginRight: "8px", position:"relative", width: "70%"}}>
                                    <p style={{marginBottom: "4px", fontWeight: "500"}}>{productName}</p>    
                                    <p style={{marginBottom: "0px", fontSize: "14px", color: "gray"}} id='product-description-div'>{productDescription}</p>    
                                <div style={{width:"100%",display:"flex", justifyContent:"flex-end"}}><p style={{marginBottom:"0px", marginRight:"auto"}}>{formatAccountingArabic(price)}</p></div>
                                </div>
                                </div>
                        </div>
                        
                        { hasExpired &&
                        <>
                        <h5 style={{fontWeight: "bold", color: "#444", marginTop: "16px" }}>العنوان</h5>
                        <div style={{width: "100%", height: "fit-content", backgroundColor: "#DBEDED", border: "1px solid #00C8B8", borderRadius: "12px", display: "flex", alignItems: "center", padding: "12px", position: "relative"}}>
                        <IoLocation size={24} color='#00C8B8' />
                        <div style={{marginRight: "8px"}}>
                            <p style={{fontSize: "16px", fontWeight: "bold", marginBottom: "0px"}}>الرياض</p>    
                            <p style={{marginBottom: "16px", color:"gray"}}>حي طويق, الرياض</p>
                        </div>
                        <div style={{justifyContent: "space-between", display: "flex", position:"absolute", left: 12 , top: 10, width: window.innerWidth > 600 ? "8%" : "13%"}}>
                        <MdModeEdit size={17} color='gray' />
                        <FaTrashCan size={17} color='gray'/>
                        </div>
                        </div>
                        </>
                        }
                        
                        </div>
                        
                        </div>
                        <div style={{width: window.innerWidth > 600 ? "47%" : "100%"}}>
                                <h5  style={{fontWeight: "bold", color: "#444", marginTop: window.innerWidth > 600 ? "0px": "16px" }}>طريقة الدفع</h5>

                                <div style={{ height: "fit-content",borderRadius: "12px" , width: "100%" ,backgroundColor: "#F4F4F4", padding: "16px 0px"}}>
                                {options.map((option) => (
        <div key={option.value} style={{ marginBottom: '10px', backgroundColor: 'white', borderRadius: '5px', width: '95%', marginRight: 'auto', marginLeft: 'auto' }}>
          {(isApplePaySupported() || option.label !== 'Apple Pay') && (
            <label
              htmlFor={option.value}
              style={{
                display: 'flex',
                justifyContent: 'start',
                alignItems: 'center',
                cursor: 'pointer',
              }}
            >
              <input
                type="radio"
                id={option.value}
                name="radioOptions"
                value={option.value}
                checked={selectedOption === option.value}
                onChange={() => handleOptionChange(option.value)}
                style={{ marginRight: '20px', paddingRight:"8px" ,height: '24px', width: '24px', accentColor: "#F00057" }}
              />
              <span
                style={{
                  backgroundColor: option.color,
                  padding: '5px 10px',
                  height: '100%',
                  borderRadius: '5px',
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <div style={{ width: '50%', height: '50px' }}>
                  <img
                    src={option.image}
                    alt={option.label}
                    style={{ width: option.size, height: '100%', objectFit: 'cover', borderRadius:"8px" }}
                  />
                </div>
              </span>
            </label>
          )}
        </div>
      ))}
      {selectedOption === "cards" && (
  <>
    <div style={{ position: 'relative', width: '85%', margin: 'auto', marginTop: '24px' }}>
      <input
        type="tel"
        autoFocus
        maxLength="19" // Allowing 19 characters to account for spaces
        placeholder='رقم البطاقة'
        value={cardNumber}
        onChange={handleInputChange}
        ref={cardNumberRef}
        style={{
          width: '100%',
          border: 'none',
          outline: 'none',
          height: '40px',
          borderRadius: '4px',
          direction: 'rtl',
          padding: '0px 8px',
          color: '#555',
          paddingRight: "48px",
          fontSize: "14px",
          fontFeatureSettings: 'tnum',
        }}
      />
      <CiCreditCard1
        size={30}
        color="#555"
        style={{ position: 'absolute', right: '8px', top: '50%', transform: 'translateY(-50%)' }}
      />
    </div>
    <div style={{display: "flex", marginTop: "8px", width: "85%", marginRight: "auto", marginLeft: "auto", alignItems : "center"}}>
      <input ref={monthRef} onChange={(e)=>{
        if(e.target.value.length === 2){
                yearRef.current.focus();
              }
      }} maxLength={2} type='tel' style={{width: "25%", height: "35px", direction: "rtl", textAlign: "center", borderLeft: "none", marginLeft: "-8px", borderRadius: "0px", borderTopRightRadius: "4px", borderBottomRightRadius: "4px", border: "none", outline: "none"}} placeholder='الشهر'/>
      <p style={{marginBottom: "0px", color: "#555"}}>/</p>
      <input onChange={(e)=>{
        if(e.target.value.length === 2){
                cvcRef.current.focus();
        }
      }} ref={yearRef} maxLength={2} type='tel' style={{width: "25%", height: "35px", borderRight: "none", borderRadius: "0px", textAlign: "center", borderTopLeftRadius: "4px", borderBottomLeftRadius: "4px", border: "none", outline: "none"}} placeholder='السنة'/>
      <input maxLength={4} ref={cvcRef} type='tel' style={{width: "47%", height: "35px", marginRight: "auto", textAlign: "center", borderRadius: "4px", border: "none", outline: "none"}} placeholder='CVC'/>
    </div>
  </>
)}

<Divider sx={{height: "2px", width: "80%", marginRight: "auto", marginLeft: "auto", bgcolor: "white", borderColor: "white" ,marginTop: "16px"}}/>
<div style={{ width: "80%",marginRight: "auto", marginLeft: "auto", marginTop: "24px", justifyContent: "space-between", display: "flex"}}>
<h6>المبلغ</h6>
<h6>{formatAccountingArabic(price)}</h6>
</div>

{ hasExpired &&

<div style={{ width: "80%",marginRight: "auto", marginLeft: "auto", marginTop: "8px", justifyContent: "space-between", display: "flex"}}>
<h6>الشحن</h6>
<h6>{formatAccountingArabic(35)}</h6>
</div>
}

<div style={{ width: "80%",marginRight: "auto", marginLeft: "auto", marginTop: "8px", justifyContent: "space-between", display: "flex"}}>
<h6>الإجمالي</h6>
<h6>{formatAccountingArabic(amount)}</h6>
</div>

<div style={{display:"flex", marginTop: "16px"}}>


        <button style={{marginRight: "auto", marginLeft: "auto", backgroundColor: "#F00057", color:"white", width: "75%"}} id='my-button'
onClick={async ()=>{

        const postRef = doc(db, "Posts", `post${id}`);
        const orderRef = doc(db, "Orders", `order${orderNumber}`);
        console.log(orderNumber)
        if(acceptsOffers){
                if(!hasExpired){

                        Object.assign(bidders, {
                          [`${auth.currentUser.uid}`]: parseFloat(currentBid) === 0 && Object.keys(bidders).length === 1 ? parseFloat(startingPrice) : 0,
                        });
            
                        await updateDoc(postRef, {
                          bidders: bidders,
                        }).then(async ()=>{
                          if(parseFloat(currentBid) === 0 && Object.keys(bidders).length === 1){
                            await updateDoc(postRef, {
                              currentBid: startingPrice,
                              lastBidDate: serverTimestamp(),
                              heighestBidder: auth.currentUser.uid
                            })
                          }
                        });
                        navigate(`/product/${id}`)

                }
                else{
                        await updateDoc(postRef, {
                                isSold: true,
                                soldDate: serverTimestamp(),
                              }).then(async ()=>{
                                await setDoc(orderRef, {
                                        soldDate: serverTimestamp(),
                                        productId : parseFloat(id),
                                        buyerId: auth.currentUser.uid,
                                        amount: amount
                                }).then(()=>{
                                    navigate(`/product/${id}`)
                                })
                              });
                }
        }
        else{
                await updateDoc(postRef, {
                        isSold: true,
                        soldDate: serverTimestamp(),
                        heighestBidder: auth.currentUser.uid
                      }).then(async ()=>{
                        await setDoc(orderRef, {
                                soldDate: serverTimestamp(),
                                productId : parseFloat(id),
                                buyerId: auth.currentUser.uid,
                                amount: amount
                                })
                      });
                      navigate(`/product/${id}`)
        }

                
}}
>الدفع</button>

</div>


                                </div>
                        </div>
                </div>
        );
}
 
export default Checkout;