import React , {useState, useEffect, useRef} from 'react'
import {collection ,getDoc,getCountFromServer ,doc, setDoc, Timestamp, serverTimestamp } from 'firebase/firestore';
import ImageGallery from "react-image-gallery";
import '../../node_modules/react-image-gallery/styles/css/image-gallery.css';
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { Oval } from 'react-loader-spinner'
import galleryPlaceholder from '../assets/galley-placeholder.jpg'
import SignInOrSignUpScreen from './SignInOrSignUpScreen';
import { db, auth, storage } from '../config/firebase'
import { IoMdClose } from "react-icons/io";
import { MdInfo } from "react-icons/md";


function AddPostScreen() {
    const [productName, setProductName] = useState("");
    const [productDescription, setProductDescription] = useState("");
    const [productPrice, setProductPrice] = useState("");
    const [productCondition, setProductCondition] = useState("");
    const [productCategory, setProductCategory] = useState("");
    const [productSubCategory, setProductSubCategory] = useState("");
    const [productExpiryDate, setProductExpiryDate] = useState("");
    const [productWeight, setProductWeight] = useState("");
    const [passedDateSelected, setPassedDateSelected] = useState(false);
    const [startingPrice, setStartingPrice] = useState(10);
    const [bidIncrements, setBidIncrements] = useState(10);
    const [images, setImages] = useState([]);
    const [gal, setGal] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [blobImgs, setBlobImgs] = useState([]);
    const [acceptsOffers, setAcceptsOffers] = useState(false);

    const [productCompany, setProductCompany] = useState("")
    // const [productMemorySize, setProductMemorySize] = useState("")
    // const [productScreenSize, setProductScreenSize] = useState("")

    const [productKind, setProductKind] = useState("");
    // const [productInkKind, setProductInkKind] = useState("");
    // const [productStorageSize, setProductStorageSize] = useState("");

    const [productImagesHasError, setProductImageHasError] = useState(false);
    const [productNameHasError, setProductNameHasError] = useState(false);
    const [productDescriptionHasError, setProductDescriptionHasError] = useState(false);
    const [productPriceHasError, setProductPriceHasError] = useState(false);
    const [productExpiryDateHasError, setProductExpiryDateHasError] = useState(false);
    const [productStartingPriceHasError, setProductStartingPriceHasError] = useState(false);
    const [productConditionHasError, setProductConditionHasError] = useState(false);
    const [productWeightHasError, setProductWeightHasError] = useState(false);
    const [productCategoryHasError, setProductCategoryHasError] = useState(false);
    const [bidIncrementHasError, setBidIncrementHasError] = useState(false);
    
    
    // const [productSubCategoryHasError, setProductSubCategoryHasError] = useState(false);
    // const [productCompanyHasError, setProductCompanyHasError] = useState(false);
    // const [productKindHasError, setProductKindHasError] = useState(false);
    // const [productMemorySizeHasError, setProductMemorySizeHasError] = useState(false);
    // const [productStorageSizeHasError, setProductStorageSizeHasError] = useState(false);
    // const [productScreenSizeHasError, setProductScreenSizeHasError] = useState(false);
    // const [productInkKindHasError, setProductInkKindHasError] = useState(false);

    const productNameRef = useRef();
    const productDescriptionRef = useRef();
    const productPriceRef = useRef();
    const productWeightRef = useRef();
    const startingPriceRef = useRef();
    const productConditionRef = useRef();
    const productCategoryRef = useRef();
    const productSubCategoryRef = useRef();
    const bidIncrementRef = useRef();

    const productCompanyRef = useRef();
    const productKindRef = useRef();
    // const productInkKindRef = useRef();
    const productStorageSizeRef = useRef();
    // const productMemorySizeRef = useRef();
    const productExpiryDateRef = useRef();
    const productImagesRef = useRef();
    const acceptsOffersRef = useRef();
    const productScreenSizeRef = useRef();

    const [selectedFiles, setSelectedFiles] = useState([]);

    useEffect(() => {
      auth.onAuthStateChanged(async function (user) {
        if (user != null) {
          if (images.length < 1) {
            setIsLoading(false);
            return;
          }

          if(productNameHasError){
            setIsLoading(false);
            return;
          }
    
          const temp = [];
          const tempGal = [];
    
          images.forEach((image) => {
            temp.push(URL.createObjectURL(image));
            tempGal.push({
              original: URL.createObjectURL(image),
              thumbnail: URL.createObjectURL(image),
              originalClass: 'image-gallery-class',
              bulletClass: 'bulletClass',
            });
          });
    
          setBlobImgs(temp);
          setGal(tempGal);
        } else {
          // Handle the case when user is null (optional, add your logic if needed)
        }
    
        setIsLoading(false);
      });
    }, [images]);

        
        if(isLoading){
          return <div style={{
            width: "100vw",
            height: "80vh",
            marginTop: "55px",
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}>
            <Oval
                  height={"50px"}
                  width={"50px"}
                  color={"dodgerblue"}
                  wrapperClass=""
                  visible={true}
                  wrapperStyle={{display: "block"}}
                  ariaLabel='oval-loading'
                  secondaryColor="#ccc"
                  strokeWidth={1}
                  strokeWidthSecondary={1}
                />
                </div>
        }

        if(auth.currentUser === null ){
          return <SignInOrSignUpScreen prompt={"الرجاء التسجيل او تسجيل الدخول لإضافة منتج"} />
         }

         const uploadImagesToStorageAndFirestore = async (imagesArray, newId) => {
          const urls = {};
          const uploadPromises = imagesArray.map(async (image, index) => {
            try {
              const storageRef = ref(storage, `Posts/post${newId}/${index + 1}`);
              await uploadBytes(storageRef, image);
              const downloadURL = await getDownloadURL(storageRef);
              urls[index] = downloadURL;
            } catch (error) {
              console.error(`Error uploading image ${index + 1}:`, error);
              throw error;
            }
          });
        
          try {
            await Promise.all(uploadPromises);
            return urls;
          } catch (error) {
            console.error('Error uploading images:', error);
            throw error;
          }
        };
        



    // async function getBase64(file) {
    //   return new Promise((resolve, reject) => {
    //     const reader = new FileReader()
    //     reader.readAsDataURL(file)
    //     reader.onload = () => {
    //       resolve(reader.result)
    //     }
    //     reader.onerror = reject
    //   })
    // }

  return ( 
    <>
        <div style={{ direction: "rtl", textAlign: window.innerWidth > 600 ? "center" : "center" , display:  window.innerWidth > 600 ? "flex" : "block", width:  window.innerWidth > 600 ? "80%" : "100%", marginLeft: "auto", marginRight: "auto", justifyContent: "start"}}>

<div style={{width:  window.innerWidth > 600 ? "0px"  : "100vw", height: "fit-content"}}>

          <ImageGallery 
            showBullets={true}
            showNav={false}
            showPlayButton={false}
            showFullscreenButton={false}
            isRTL={true}
            lazyLoad
            infinite={false}
            items={gal} />
  {
    gal.length > 0 ?
    <>
    <div style={{display: "flex", textAlign: "center", justifyContent: "center", marginBottom: "16px"}}>
  
      <button style={{display: "block", backgroundColor: "#00C6B7", border: "none" , outline: "none" , color: "white" ,borderRadius: "8px", margin: "8px", height: "fit-content", width: "fit-content", padding: "8px", paddingRight: "16px", paddingLeft: "16px"}} onClick={()=>{
        productImagesRef.current.click();
      }}>تغيير الصور</button>
    <button style={{display: "block",  border: "none" , outline: "none",  backgroundColor: "#F00057", color: "white",borderRadius: "8px", margin: "8px", height: "fit-content", width: "fit-content", padding: "8px", paddingRight: "16px", paddingLeft: "16px"}} onClick={()=>{
      setImages([]);
      setGal([])
    }}>ازالة الصور</button>
    </div>
    </>
    :""
  }
            </div>

<div style={{width:  window.innerWidth > 600 ? "500px" : "100%", height:  window.innerWidth > 600 ? "500px" : "fit-content"}}>

            {
              gal.length === 0 ? 
              <img 
              onClick={()=>{
                productImagesRef.current.click();
              }}
              id='gallery-placeholder'
              style={{width: window.innerWidth > 600 ? "500px" : "100%", height: window.innerWidth > 600 ? "500px":  "100%" , borderRadius:  window.innerWidth > 600 ? "12px" : "0px"}}
              src = {galleryPlaceholder}/>
              : ""
            }
            <input ref={productImagesRef} onChange={(value)=>{
              if(value.target.files.length > 10 || value.target.files.length < 3){
                value.preventDefault();
                setImages([]);
                setProductImageHasError(true);
                return;
              }
              setImages([...value.target.files]);
              setProductImageHasError(false);
            }} type='file' accept="image/*" multiple style={{display: "none", margin: "8px"}} placeholder='صور المنتج' />

            {images.length === 0 && 
            <p style={{color: productImagesHasError ? "red": "gray", marginRight: "14px", fontSize: "15px", marginTop: "0px"}}>يجب اختيار من 3 إلى 10 صور</p>
            
          }
            </div>

            <div style={{textAlign: "right", width: window.innerWidth > 600 ? "30%" : "100%"}}>


            <input ref={productNameRef} 
            style={{marginTop: "24px"}}
            onChange={(value)=> {
              setProductNameHasError(false);
              setProductName(value.target.value)}} id={productNameHasError ? 'input-field-error' : 'input-field'} placeholder='اسم المنتج' />
             <p style={{display: productName.length > 0 && productName.length < 10 || productNameHasError ? "block" : "none", color: "red", marginRight: "14px", fontSize: "15px", marginTop: "0px"}}>اسم المنتج يجب ان يتكون على الأقل من 10 أحرف</p>
            

            <textarea required ={false} ref={productDescriptionRef} id={productDescriptionHasError ? 'text-area-error' : 'text-area'} minLength={100} onChange={(value)=> {
              setProductDescriptionHasError(false);
              setProductDescription(value.target.value)}}  placeholder='وصف المنتج' />
              <p style={{display: productDescription.length > 0 && productDescription.length < 50 || productDescriptionHasError ? "block" : "none", marginBottom: "0px", color: "red", marginRight: "14px", fontSize: "15px", marginTop: "0px"}}>وصف المنتج يجب ان يتكون على الأقل من 50 حرف</p>
              
              {/* <div style={{display: "flex", backgroundColor: "#F0F0F0" , width: "70%", marginRight: "16px", marginBottom: "16px", marginTop: "4px", borderRadius: "6px", padding: "16px 8px"}}>
              <MdInfo size={20} color='#555' />
              <p style={{display: "block", color: "#555", marginRight: "4px" ,fontSize: "15px", marginBottom: "0px", width: "100%"}}>ادخل وصف المنتج و تأكد من كتابة أكبر عدد ممكن من الكلمات المفتاحية ليظهر المنتج في قوائم البحث.</p>
              </div> */}
            


            <div style={{display: "flex"}}>
            <input ref={productPriceRef} onChange={(value)=>{
              setProductPriceHasError(false);
              setProductPrice(value.target.value)}} type='tel' id={productPriceHasError ? 'price-input-field-error' : 'price-input-field'} placeholder='سعر المنتج' />
              <p style={{marginBottom: "auto", marginTop: "auto"}}>ر.س</p>
              </div>

              <div style={{display: acceptsOffers ? "flex" : "none", backgroundColor: "#F0F0F0" , width: "70%", marginRight: "16px", marginBottom: "16px", marginTop: "4px", borderRadius: "6px", padding: "16px 8px"}}>
              <MdInfo size={20} color='#555' />
            <p style={{display: acceptsOffers ? "block" : "none", color: "#555", marginRight: "4px", fontSize: "15px", marginBottom: "0px", width: "100%"}}>ضع 0 اذا كنت تريد السماح بالمزايدة على منتجك بدون سعر شراء فوري. هذا سيسمح بالمزايدة بدون سقف للسعر.</p>
              </div>
            <p style={{display: productPriceHasError && productPrice == ""  ? "block" : "none", marginBottom: "0px", color: "red", marginRight: "14px", fontSize: "15px", marginTop: "0px", marginBottom: "0px"}}>يجب تحديد سعر المنتج</p>
            <p style={{display: productPriceHasError && isNaN(parseFloat(productPrice))  ? "block" : "none", marginBottom: "0px", color: "red", marginRight: "14px", fontSize: "15px", marginTop: "0px", width: window.innerWidth > 600 ? "100%" : "80%",marginBottom: "16px"}}>يرجى ادخال قيمة صحيحة (مسموح بالارقام الانجليزية فقط 0-9)</p>


            <div style={{display: "flex",marginTop: "16px"}}>
            <input ref={productWeightRef} onChange={(value)=>{
              setProductWeightHasError(false);
              setProductWeight(value.target.value)}} type='tel' id={productWeightHasError ? 'price-input-field-error' : 'price-input-field'} placeholder='وزن المنتج' />
              <p style={{marginBottom: "auto", marginTop: "auto"}}>كجم</p>
              </div>
              {/* <div style={{display: "flex", backgroundColor: "#F0F0F0" , width: "70%", marginRight: "16px", marginBottom: "16px", marginTop: "4px", borderRadius: "6px", padding: "16px 8px"}}>
              <MdInfo size={20} color='#555' />
              <p style={{ color: "#555", marginRight: "4px", fontSize: "15px",marginBottom: "0px", width: "100%"}}>مطلوب لتسعيرة الشحن في حال كان المنتج يتطلب شحن</p>
              </div> */}
            <p style={{display: productPriceHasError && productPrice == ""  ? "block" : "none", marginBottom: "0px", color: "red", marginRight: "14px", fontSize: "15px", marginTop: "0px", marginBottom: "0px"}}>يجب تحديد وزن المنتج</p>
            <p style={{display: productWeightHasError && isNaN(parseFloat(productWeight))  ? "block" : "none", marginBottom: "0px", color: "red", marginRight: "14px", fontSize: "15px", marginTop: "0px", width: window.innerWidth > 600 ? "100%" : "80%", marginBottom: "16px"}}>يرجى ادخال قيمة صحيحة (مسموح بالارقام الانجليزية فقط 0-9)</p>





            <div style={{display: "flex", direction: "rtl", marginRight: "8px", marginTop: "16px", color: "#444"}}>
              <p style={{margin: "8px"}}>السماح بالمزايدات؟</p>
            
            <input ref={acceptsOffersRef} onChange={(value)=>{
              setAcceptsOffers(value.target.checked)}} style={{display: "block", transform: "scale(1.1)",marginTop: "auto", marginBottom: "auto", marginRight: "4px" , accentColor: "#00B6C7"}} type='checkbox' placeholder='test'/>
            
            </div>




          <div style={{display: acceptsOffers ? "block" : "none"}}>


            <input ref={productExpiryDateRef} id={productExpiryDateHasError ? 'input-field-error' : 'input-field'} onChange={
                (value)=>{
                  if(new Date(value.target.value) < new Date().getTime()){
                    setProductExpiryDateHasError(true);
                    setPassedDateSelected(true)
                  }else{
                    setPassedDateSelected(false)
                    setProductExpiryDateHasError(false);
                    setProductExpiryDate( Timestamp.fromDate(new Date(value.target.value )))}
                  }
                } type='datetime-local' />
                              <div style={{display: "flex", backgroundColor: "#F0F0F0" , width: "70%", marginRight: "16px", marginBottom: "16px", marginTop: "4px", borderRadius: "6px", padding: "8px 10px"}}>
              <MdInfo size={20} color='#555' />
            <p style={{display: acceptsOffers ? "block" : "none", color: "#555", marginRight: "4px", fontSize: "15px", width: "100%",marginBottom: "0px"}}>تاريخ انتهاء المزاد</p>
                </div>
            <p style={{display: productExpiryDateHasError && productExpiryDate == ""  ? "block" : "none", marginBottom: "0px", color: "red", marginRight: "14px", fontSize: "15px", marginTop: "0px", marginBottom: "0px"}}>يجب تحديد المدة الزمنية لانتهاء المزاد</p>
            <p style={{display: passedDateSelected ? "block" : "none", marginBottom: "0px", color: "red", marginRight: "14px", fontSize: "15px", marginTop: "0px", marginBottom: "16px"}}>يجب ان يكون تاريخ الانتهاء في المستقبل</p>





            <div style={{display: "flex"}}>




              <input ref={startingPriceRef} onChange={(value)=>{
                setProductStartingPriceHasError(false);
                setStartingPrice(value.target.value)}} type='tel' min={10} id={productStartingPriceHasError ? 'price-input-field-error' : 'price-input-field'} defaultValue="10" placeholder='مبلغ دخول المزاد'/>
                <p style={{marginBottom: "auto", marginTop: "auto"}}>ر.س</p>
            </div>
            <div style={{display: "flex", backgroundColor: "#F0F0F0" , width: "70%", marginRight: "16px", marginBottom: "16px", marginTop: "4px", borderRadius: "6px", padding: "8px 10px"}}>
              <MdInfo size={20} color='#555' />
            <p style={{display: acceptsOffers ? "block" : "none", color: "#555", marginRight: "4px", fontSize: "15px", width: "100%" , marginBottom: "0px"}}>المزاد يبدأ من هذا المبلغ. أقل مبلغ هو 10 ريال</p>
            </div>
            <p style={{display: acceptsOffers && productStartingPriceHasError && startingPrice < 10? "block" : "none", color: "red", marginRight: "14px", fontSize: "15px", marginTop: "0px", marginBottom: "16px"}}>أقل سعر للمزايدة يجب ألا يقل عن 10 ريال</p>
         
         



            <div style={{display: "flex"}}>
              <input ref={bidIncrementRef} onChange={(value)=>{
                setBidIncrementHasError(false);
                setBidIncrements(value.target.value)}} type='tel' min={10} id={bidIncrementHasError ? 'price-input-field-error' : 'price-input-field'} defaultValue="10" placeholder='مبلغ مضاعفات المزايدة'/>
                <p style={{marginBottom: "auto", marginTop: "auto"}}>ر.س</p>
            </div>
            <div style={{display: "flex", backgroundColor: "#F0F0F0" , width: "70%", marginRight: "16px", marginBottom: "16px", marginTop: "4px", borderRadius: "6px", padding: "8px 10px"}}>
              <MdInfo size={20} color='#555' />
            <p style={{display: acceptsOffers ? "block" : "none", color: "#555", marginRight: "4px", fontSize: "15px",width: "100%", marginBottom: "0px"}}>مضاعفات المزايدة تكون بهذا المبلغ</p>
            </div>
            <p style={{display: acceptsOffers && bidIncrementHasError && bidIncrementHasError < 10? "block" : "none", color: "red", marginRight: "14px", fontSize: "15px", marginTop: "0px", marginBottom: "16px"}}>أقل سعر للمزايدة يجب ألا يقل عن 10 ريال</p>
         
          </div>




           

           
            <div>


            <select ref={productCategoryRef} id={productCategoryHasError ? 'price-input-field-error' : 'price-input-field'} onChange={(value)=>{
              setProductCategoryHasError(false)
              setProductCategory(value.target.value)
            }}
            style={{backgroundColor: "#00C6B7", color: "white", borderRadius: "4px"}}
            >
                <option hidden>فئة المنتج</option>
                {/* <option style={{backgroundColor: "white"}} value="الكترونيات">الكترونيات</option> */}
                {/* <option style={{backgroundColor: "white"}} value="اثاث">اثاث</option> */}
                {/* <option style={{backgroundColor: "white"}} value="قطع غيار">قطع غيار</option> */}
                <option style={{backgroundColor: "white", textAlign: "right"}} value="ساعات">ساعات</option>
                <option style={{backgroundColor: "white", textAlign: "right"}} value="سبح">سبح</option>
                <option style={{backgroundColor: "white", textAlign: "right"}} value="اقلام">أقلام</option>
                <option style={{backgroundColor: "white", textAlign: "right"}} value="ابواك">أبواك</option>
            </select>

            <p style={{display: productCategoryHasError ? "block" : "none", color: "red", marginRight: "14px", fontSize: "15px", marginTop: "0px", marginBottom: "16px"}}>يجب تحديد فئة المنتج</p>




            <select ref={productConditionRef} id={productConditionHasError ? 'price-input-field-error' : 'price-input-field'} onChange={(value)=>{
              setProductConditionHasError(false);
              setProductCondition(value.target.value);
              
            }}
            style={{backgroundColor: "#00C6B7", color: "white", borderRadius: "4px"}}
            >
                <option hidden>حالة المنتج</option>
                <option style={{backgroundColor: "white"}} value="جديد">جديد</option>
                <option style={{backgroundColor: "white"}} value="مستعمل">مستعمل</option>
                <option style={{backgroundColor: "white"}} value="ممتازة">ممتازة</option>
                <option style={{backgroundColor: "white"}} value="جيدة جدا">جيدة جدا</option>
                <option style={{backgroundColor: "white"}} value="جيدة">جيدة</option>
                <option style={{backgroundColor: "white"}} value="مقبولة">مقبولة</option>
            </select>

            <p style={{display: productConditionHasError ? "block" : "none", color: "red", marginRight: "14px", fontSize: "15px", marginTop: "0px", marginBottom: "16px"}}>يجب تحديد حالة المنتج</p>





            
            {/* {
              productCategory == 'الكترونيات' ?
              <select ref={productSubCategoryRef} id={productConditionHasError ? 'price-input-field-error' : 'price-input-field'} onChange={(value)=>{
                setProductSubCategoryHasError(false);
                setProductSubCategory(value.target.value);
              }}>
                <option hidden>نوع المنتج</option>
                <option style={{backgroundColor: "white"}} value="جوال">جوال</option>
                <option style={{backgroundColor: "white"}} value="شاشة">شاشة</option>
                <option style={{backgroundColor: "white"}} value="سماعة">سماعة</option>
                <option style={{backgroundColor: "white"}} value="لابتوب">لابتوب</option>
                <option style={{backgroundColor: "white"}} value="جهاز لوحي">جهاز لوحي</option>
                <option style={{backgroundColor: "white"}} value="كاميرا">كاميرا</option>
                <option style={{backgroundColor: "white"}} value="طابعة">طابعة</option>
                <option value="مكيف">مكيف</option>
                <option value="جهاز العاب">جهاز العاب</option>
            </select>
            : ""
            } */}
            {/* {
              productCategory == 'اثاث' ?
              <select ref={productSubCategoryRef} id={productConditionHasError ? 'price-input-field-error' : 'price-input-field'} onChange={(value)=>{
                setProductSubCategoryHasError(false);
                setProductSubCategory(value.target.value);
              }}>
                <option hidden>نوع المنتج</option>
                <option value="كرسي">كرسي</option>
                <option value="كنب">كنب</option>
                <option value="طاولة">طاولة</option>
                <option value="رف">رف</option>
                <option value="دواليب و ادراج">دواليب و أدراج</option>
                <option value="لوحات فنية">لوحات فنية</option>
                <option value="أخرى">أخرى</option>
            </select>
            : ""
            } */}
            {/* {
              productCategory == 'قطع غيار' ?
              <select ref={productSubCategoryRef} id={productConditionHasError ? 'price-input-field-error' : 'price-input-field'} onChange={(value)=>{
                setProductSubCategoryHasError(false);
                setProductSubCategory(value.target.value);
              }}>
            <option value="قطع غيار سيارات">قطع غيار السيارات</option>
            </select>
            : ""
            } */}

            {/* {
              productCategory == 'الكترونيات' && productSubCategory == "جوال" ? 
              <>
              <select ref={productCompanyRef} id={productConditionHasError ? 'price-input-field-error' : 'price-input-field'} onChange={(value)=>{
                setProductCompanyHasError(false);
                setProductCompany(value.target.value);
              }}>
                <option style={{backgroundColor: "white"}} hidden>الشركة المصنعة</option>
                <option style={{backgroundColor: "white"}} value="ابل">ابل</option>
                <option style={{backgroundColor: "white"}} value="سامسونج">سامسونج</option>
                <option style={{backgroundColor: "white"}} value="هواوي">هواوي</option>
                <option style={{backgroundColor: "white"}} value="HTC">HTC</option>
                <option style={{backgroundColor: "white"}} value="أخرى">أخرى</option>
              </select>

             {
              productCategory == 'الكترونيات' && productSubCategory == "جوال" && productCompany == 'ابل' ? 
              <select ref={productKindRef} id={productConditionHasError ? 'price-input-field-error' : 'price-input-field'} onChange={(value)=>{
                
                setProductKind(value.target.value);
              }}>
                <option style={{backgroundColor: "white"}} hidden>نوع الجوال</option>
                <option style={{backgroundColor: "white"}} value="ايفون 15 برو ماكس">ايفون 15 برو ماكس</option>
                <option style={{backgroundColor: "white"}} value="ايفون 15 برو">ايفون 15 برو</option>
                <option style={{backgroundColor: "white"}} value="ايفون 15 بلس">ايفون 15 بلس</option>
                <option style={{backgroundColor: "white"}} value="ايفون 15">ايفون 15</option>
                <option style={{backgroundColor: "white"}} value="ايفون 14 برو ماكس">ايفون 14 برو ماكس</option>
                <option style={{backgroundColor: "white"}} value="ايفون 14 برو">ايفون 14 برو</option>
                <option style={{backgroundColor: "white"}} value="ايفون 14 بلس">ايفون 14 بلس</option>
                <option style={{backgroundColor: "white"}} value="ايفون 14">ايفون 14</option>
                <option style={{backgroundColor: "white"}} value="ايفون 13 برو ماكس">ايفون 13 برو ماكس</option>
                <option style={{backgroundColor: "white"}} value="ايفون 13 برو">ايفون 13 برو</option>
                <option style={{backgroundColor: "white"}} value="ايفون 13">ايفون 13</option>
                <option style={{backgroundColor: "white"}} value="ايفون 13 ميني">ايفون 13 ميني</option>
                <option style={{backgroundColor: "white"}} value="ايفون 12 برو ماكس">ايفون 12 برو ماكس</option>
                <option style={{backgroundColor: "white"}} value="ايفون 12 برو">ايفون 12 برو</option>
                <option style={{backgroundColor: "white"}} value="ايفون 12">ايفون 12</option>
                <option style={{backgroundColor: "white"}} value="ايفون 12 ميني">ايفون 12 ميني</option>
                <option style={{backgroundColor: "white"}} value="ايفون 11 برو ماكس">ايفون 11 برو ماكس</option>
                <option style={{backgroundColor: "white"}} value="ايفون 11 برو">ايفون 11 برو</option>
                <option style={{backgroundColor: "white"}} value="ايفون 11">ايفون 11</option>
                <option style={{backgroundColor: "white"}} value="ايفون XS Max">ايفون XS Max</option>
                <option style={{backgroundColor: "white"}} value="ايفون XS">ايفون XS</option>
                <option style={{backgroundColor: "white"}} value="ايفون XR">ايفون XR</option>
                <option style={{backgroundColor: "white"}} value="ايفون X">ايفون X</option>
                <option style={{backgroundColor: "white"}} value="ايفون 8 بلس">ايفون 8 بلس</option>
                <option style={{backgroundColor: "white"}} value="ايفون 8">ايفون 8</option>
                <option style={{backgroundColor: "white"}} value="ايفون 7 بلس">ايفون 7 بلس</option>
                <option style={{backgroundColor: "white"}} value="ايفون 7">ايفون 7</option>
                <option style={{backgroundColor: "white"}} value="ايفون 6s بلس">ايفون 6s بلس</option>
                <option style={{backgroundColor: "white"}} value="ايفون 6s">ايفون 6s</option>
                <option style={{backgroundColor: "white"}} value="ايفون 6 بلس">ايفون 6 بلس</option>
                <option style={{backgroundColor: "white"}} value="ايفون 6">ايفون 6</option>
              </select>            
              :""
            } */}
            {/* {
              productCategory == 'الكترونيات' && productSubCategory == "جوال" && productCompany == 'سامسونج' ? 
              <select ref={productCompanyRef} id={productConditionHasError ? 'price-input-field-error' : 'price-input-field'} onChange={(value)=>{
                setProductKindHasError(false);
                setProductKind(value.target.value);
              }}> */}
                {/* <option hidden>نوع الجوال</option> */}
                {/* S series */}
                {/* <option style={{direction: "ltr", backgroundColor: "white"}} value="Galaxy S23 Ultra">Galaxy S23 Ultra</option>
                <option style={{direction: "ltr", backgroundColor: "white"}} value="Galaxy S23+">Galaxy S23+</option>
                <option style={{direction: "ltr", backgroundColor: "white"}} value="Galaxy S23">Galaxy S23</option>
                <option style={{direction: "ltr", backgroundColor: "white"}} value="Galaxy S22 Ultra">Galaxy S22 Ultra</option>
                <option style={{direction: "ltr", backgroundColor: "white"}} value="Galaxy S22+">Galaxy S22+</option>
                <option style={{direction: "ltr", backgroundColor: "white"}} value="Galaxy S22">Galaxy S22</option>
                <option style={{direction: "ltr", backgroundColor: "white"}} value="Galaxy S21 FE">Galaxy S21 FE</option>
                <option style={{direction: "ltr", backgroundColor: "white"}} value="Galaxy S21 Ultra">Galaxy S21 Ultra</option>
                <option style={{direction: "ltr", backgroundColor: "white"}} value="Galaxy S21+">Galaxy S21+</option>
                <option style={{direction: "ltr", backgroundColor: "white"}} value="Galaxy S21">Galaxy S21</option>
                <option style={{direction: "ltr", backgroundColor: "white"}} value="Galaxy S20 FE">Galaxy S20 FE</option>
                <option style={{direction: "ltr", backgroundColor: "white"}} value="Galaxy S20 Ultra 5G">Galaxy S20 Ultra 5G</option>
                <option style={{direction: "ltr", backgroundColor: "white"}} value="Galaxy S20+">Galaxy S20+</option>
                <option style={{direction: "ltr", backgroundColor: "white"}} value="Galaxy S20">Galaxy S20</option>
                <option style={{direction: "ltr", backgroundColor: "white"}} value="Galaxy S10 Lite">Galaxy S10 Lite</option>
                <option style={{direction: "ltr", backgroundColor: "white"}} value="Galaxy S10 5G">Galaxy S10 5G</option>
                <option style={{direction: "ltr", backgroundColor: "white"}} value="Galaxy S10+">Galaxy S10+</option>
                <option style={{direction: "ltr", backgroundColor: "white"}} value="Galaxy S10e">Galaxy S10e</option>
                <option style={{direction: "ltr", backgroundColor: "white"}} value="Galaxy S10">Galaxy S10</option>
                <option style={{direction: "ltr", backgroundColor: "white"}} value="Galaxy S9+">Galaxy S9+</option>
                <option style={{direction: "ltr", backgroundColor: "white"}} value="Galaxy S9">Galaxy S9</option>
                <option style={{direction: "ltr", backgroundColor: "white"}} value="Galaxy S8+">Galaxy S8+</option>
                <option style={{direction: "ltr", backgroundColor: "white"}} value="Galaxy S7 Active">Galaxy S7 Active</option>
                <option style={{direction: "ltr", backgroundColor: "white"}} value="Galaxy S7 edge">Galaxy S7 edge</option>
                <option style={{direction: "ltr", backgroundColor: "white"}} value="Galaxy S7">Galaxy S7</option>
                <option style={{direction: "ltr", backgroundColor: "white"}} value="Galaxy S6 Edge+">Galaxy S6 Edge+</option>
                <option style={{direction: "ltr", backgroundColor: "white"}} value="Galaxy S6 Edge">Galaxy S6 Edge</option>
                <option style={{direction: "ltr", backgroundColor: "white"}} value="Galaxy S6">Galaxy S6</option> */}

                {/* Z and Fold */}
                {/* <option style={{direction: "ltr", backgroundColor: "white"}} value="Galaxy Z Flip 5">Galaxy Z Flip 5</option>
                <option style={{direction: "ltr", backgroundColor: "white"}} value="Galaxy Z Fold 5">Galaxy Z Fold 5</option>
                <option style={{direction: "ltr", backgroundColor: "white"}} value="Galaxy Z Flip 4">Galaxy Z Flip 4</option>
                <option style={{direction: "ltr", backgroundColor: "white"}} value="Galaxy Z Fold 4">Galaxy Z Fold 4</option>
                <option style={{direction: "ltr", backgroundColor: "white"}} value="Galaxy Z Flip 3">Galaxy Z Flip 3</option>
                <option style={{direction: "ltr", backgroundColor: "white"}} value="Galaxy Z Fold 3">Galaxy Z Fold 3</option>
                <option style={{direction: "ltr", backgroundColor: "white"}} value="Galaxy Z Fold">Galaxy Z Fold</option>
                <option style={{direction: "ltr", backgroundColor: "white"}} value="Galaxy Z Flip">Galaxy Z Flip</option>
                <option style={{direction: "ltr", backgroundColor: "white"}} value="Galaxy Fold">Galaxy Fold</option> */}

              {/* Note series */}
                {/* <option style={{direction: "ltr", backgroundColor: "white"}} value="Galaxy Note 20 Ultra">Galaxy Note 20 Ultra</option>
                <option style={{direction: "ltr", backgroundColor: "white"}} value="Galaxy Note 20">Galaxy Note 20</option>
                <option style={{direction: "ltr", backgroundColor: "white"}} value="Galaxy Note 10+">Galaxy Note 10+</option>
                <option style={{direction: "ltr", backgroundColor: "white"}} value="Galaxy Note 10">Galaxy Note 10</option>
                <option style={{direction: "ltr", backgroundColor: "white"}} value="Galaxy Note 9">Galaxy Note 9</option>
                <option style={{direction: "ltr", backgroundColor: "white"}} value="Galaxy Note 8">Galaxy Note 8</option>
                <option style={{direction: "ltr", backgroundColor: "white"}} value="Galaxy Note 7">Galaxy Note 7</option>
                <option style={{direction: "ltr", backgroundColor: "white"}} value="Galaxy Note 5">Galaxy Note 5</option> */}

                {/* M Series */}
                {/* <option style={{direction: "ltr"}} value="Galaxy M54 5G">Galaxy M54 5G</option>
                <option style={{direction: "ltr"}} value="Galaxy M34 5G">Galaxy M34 5G</option>
                <option style={{direction: "ltr"}} value="Galaxy M14 5G">Galaxy M14 5G</option>
                <option style={{direction: "ltr"}} value="Galaxy M04">Galaxy M04</option>
                <option style={{direction: "ltr"}} value="Galaxy M53 5G">Galaxy M53 5G</option>
                <option style={{direction: "ltr"}} value="Galaxy M33 5G">Galaxy M33 5G</option>
                <option style={{direction: "ltr"}} value="Galaxy M23">Galaxy M23</option>
                <option style={{direction: "ltr"}} value="Galaxy M13 5G">Galaxy M13 5G</option>
                <option style={{direction: "ltr"}} value="Galaxy M13">Galaxy M13</option>
                <option style={{direction: "ltr"}} value="Galaxy M62">Galaxy M62</option>
                <option style={{direction: "ltr"}} value="Galaxy M52 5G">Galaxy M52 5G</option>
                <option style={{direction: "ltr"}} value="Galaxy M42 5G">Galaxy M42 5G</option>
                <option style={{direction: "ltr"}} value="Galaxy M32 5G">Galaxy M32 5G</option>
                <option style={{direction: "ltr"}} value="Galaxy M32">Galaxy M32</option>
                <option style={{direction: "ltr"}} value="Galaxy M22">Galaxy M22</option>
                <option style={{direction: "ltr"}} value="Galaxy M12">Galaxy M12</option>
                <option style={{direction: "ltr"}} value="Galaxy M02s">Galaxy M02s</option>
                <option style={{direction: "ltr"}} value="Galaxy M02">Galaxy M02</option>
                <option style={{direction: "ltr"}} value="Galaxy M51">Galaxy M51</option>
                <option style={{direction: "ltr"}} value="Galaxy M31s">Galaxy M31s</option>
                <option style={{direction: "ltr"}} value="Galaxy M31">Galaxy M31</option>
                <option style={{direction: "ltr"}} value="Galaxy M21s">Galaxy M21s</option>
                <option style={{direction: "ltr"}} value="Galaxy M21">Galaxy M21</option>
                <option style={{direction: "ltr"}} value="Galaxy M11">Galaxy M11</option>
                <option style={{direction: "ltr"}} value="Galaxy M01s">Galaxy M01s</option>
                <option style={{direction: "ltr"}} value="Galaxy M01">Galaxy M01</option>
                <option style={{direction: "ltr"}} value="Galaxy M40">Galaxy M40</option>
                <option style={{direction: "ltr"}} value="Galaxy M30s">Galaxy M30s</option>
                <option style={{direction: "ltr"}} value="Galaxy M30">Galaxy M30</option>
                <option style={{direction: "ltr"}} value="Galaxy M20">Galaxy M20</option>
                <option style={{direction: "ltr"}} value="Galaxy M10s">Galaxy M10s</option>
                <option style={{direction: "ltr"}} value="Galaxy M10">Galaxy M10</option>

                {/* J series */}
                {/* <option style={{direction: "ltr"}} value="Galaxy J8">Galaxy J8</option>
                <option style={{direction: "ltr"}} value="Galaxy J7 (2018)">Galaxy J7 (2018)</option>
                <option style={{direction: "ltr"}} value="Galaxy J7 Duo">Galaxy J7 Duo</option>
                <option style={{direction: "ltr"}} value="Galaxy J7 Prime 2">Galaxy J7 Prime 2</option>
                <option style={{direction: "ltr"}} value="Galaxy J7+">Galaxy J7+</option>
                <option style={{direction: "ltr"}} value="Galaxy J7 Pro">Galaxy J7 Pro</option>
                <option style={{direction: "ltr"}} value="Galaxy J7 (2017)">Galaxy J7 (2017)</option>
                <option style={{direction: "ltr"}} value="Galaxy J7 Nxt">Galaxy J7 Nxt</option>
                <option style={{direction: "ltr"}} value="Galaxy J7 Max">Galaxy J7 Max</option>
                <option style={{direction: "ltr"}} value="Galaxy J7 Sky Pro">Galaxy J7 Sky Pro</option>
                <option style={{direction: "ltr"}} value="Galaxy J7 V">Galaxy J7 V</option>
                <option style={{direction: "ltr"}} value="Galaxy J7 Prime">Galaxy J7 Prime</option>
                <option style={{direction: "ltr"}} value="Galaxy J7 (2016)">Galaxy J7 (2016)</option>
                <option style={{direction: "ltr"}} value="Galaxy J7">Galaxy J7</option>
                <option style={{direction: "ltr"}} value="Galaxy J6+">Galaxy J6+</option>
                <option style={{direction: "ltr"}} value="Galaxy J6">Galaxy J6</option>
                <option style={{direction: "ltr"}} value="Galaxy J5 (2017)">Galaxy J5 (2017)</option>
                <option style={{direction: "ltr"}} value="Galaxy J5 Prime">Galaxy J5 Prime</option>
                <option style={{direction: "ltr"}} value="Galaxy J5 (2016)">Galaxy J5 (2016)</option>
                <option style={{direction: "ltr"}} value="Galaxy J5 (2015)">Galaxy J5 (2015)</option>
                <option style={{direction: "ltr"}} value="Galaxy J4 Core">Galaxy J4 Core</option>
                <option style={{direction: "ltr"}} value="Galaxy J4+">Galaxy J4+</option>
                <option style={{direction: "ltr"}} value="Galaxy J4">Galaxy J4</option>
                <option style={{direction: "ltr"}} value="Galaxy J3 (2018)">Galaxy J3 (2018)</option>
                <option style={{direction: "ltr"}} value="Galaxy J3 (2017)">Galaxy J3 (2017)</option>
                <option style={{direction: "ltr"}} value="Galaxy J3 Prime">Galaxy J3 Prime</option>
                <option style={{direction: "ltr"}} value="Galaxy J3 Pro">Galaxy J3 Pro</option>
                <option style={{direction: "ltr"}} value="Galaxy J3 (2016)">Galaxy J3 (2016)</option>
                <option style={{direction: "ltr"}} value="Galaxy J2 Core (2020)">Galaxy J2 Core (2020)</option>
                <option style={{direction: "ltr"}} value="Galaxy J2 Pure">Galaxy J2 Pure</option>
                <option style={{direction: "ltr"}} value="Galaxy J2 Core">Galaxy J2 Core</option>
                <option style={{direction: "ltr"}} value="Galaxy J2 Pro (2018)">Galaxy J2 Pro (2018)</option>
                <option style={{direction: "ltr"}} value="Galaxy J2 (2018)">Galaxy J2 (2018)</option>
                <option style={{direction: "ltr"}} value="Galaxy J2 (2017)">Galaxy J2 (2017)</option>
                <option style={{direction: "ltr"}} value="Galaxy J2 Prime">Galaxy J2 Prime</option>
                <option style={{direction: "ltr"}} value="Galaxy J2 Pro (2016)">Galaxy J2 Pro (2016)</option>
                <option style={{direction: "ltr"}} value="Galaxy J2 (2016)">Galaxy J2 (2016)</option>
                <option style={{direction: "ltr"}} value="Galaxy J2">Galaxy J2</option>
                <option style={{direction: "ltr"}} value="Galaxy J1 mini prime">Galaxy J1 mini prime</option>
                <option style={{direction: "ltr"}} value="Galaxy J1 mini">Galaxy J1 mini</option>
                <option style={{direction: "ltr"}} value="Galaxy J1 Nxt">Galaxy J1 Nxt</option>
                <option style={{direction: "ltr"}} value="Galaxy J1 (2016)">Galaxy J1 (2016)</option>
                <option style={{direction: "ltr"}} value="Galaxy J1 Ace Neo">Galaxy J1 Ace Neo</option>
                <option style={{direction: "ltr"}} value="Galaxy J1 Ace">Galaxy J1 Ace</option>
                <option style={{direction: "ltr"}} value="Galaxy J1 4G">Galaxy J1 4G</option>
                <option style={{direction: "ltr"}} value="Galaxy J1">Galaxy J1</option> */}

                {/* F series */}
                {/* <option style={{direction: "ltr"}} value="Galaxy F54 5G">Galaxy F54 5G</option>
                <option style={{direction: "ltr"}} value="Galaxy F34 5G">Galaxy F34 5G</option>
                <option style={{direction: "ltr"}} value="Galaxy F14 5G">Galaxy F14 5G</option>
                <option style={{direction: "ltr"}} value="Galaxy F04">Galaxy F04</option>
                <option style={{direction: "ltr"}} value="Galaxy F23 5G">Galaxy F23 5G</option>
                <option style={{direction: "ltr"}} value="Galaxy F13">Galaxy F13</option>
                <option style={{direction: "ltr"}} value="Galaxy F62">Galaxy F62</option>
                <option style={{direction: "ltr"}} value="Galaxy F52 5G">Galaxy F52 5G</option>
                <option style={{direction: "ltr"}} value="Galaxy F42 5G">Galaxy F42 5G</option>
                <option style={{direction: "ltr"}} value="Galaxy F22">Galaxy F22</option>
                <option style={{direction: "ltr"}} value="Galaxy F12">Galaxy F12</option>
                <option style={{direction: "ltr"}} value="Galaxy F02s">Galaxy F02s</option> */}

                {/* A series */}
                {/* here */}
                {/* <option style={{direction: "ltr", backgroundColor: "white"}} value="Galaxy A05s">Galaxy A05s</option>
                <option style={{direction: "ltr", backgroundColor: "white"}} value="Galaxy A05">Galaxy A05</option>
                <option style={{direction: "ltr", backgroundColor: "white"}} value="Galaxy A34 5G">Galaxy A34 5G</option>
                <option style={{direction: "ltr", backgroundColor: "white"}} value="Galaxy A24">Galaxy A24</option>
                <option style={{direction: "ltr", backgroundColor: "white"}} value="Galaxy A14 5G">Galaxy A14 5G</option>
                <option style={{direction: "ltr", backgroundColor: "white"}} value="Galaxy A14">Galaxy A14</option>
                <option style={{direction: "ltr", backgroundColor: "white"}} value="Galaxy A04s">Galaxy A04s</option>
                <option style={{direction: "ltr", backgroundColor: "white"}} value="Galaxy A04">Galaxy A04</option>
                <option style={{direction: "ltr", backgroundColor: "white"}} value="Galaxy A04e">Galaxy A04e</option>
                <option style={{direction: "ltr", backgroundColor: "white"}} value="Galaxy A23 5G">Galaxy A23 5G</option>
                <option style={{direction: "ltr", backgroundColor: "white"}} value="Galaxy A23">Galaxy A23</option>
                <option style={{direction: "ltr", backgroundColor: "white"}} value="Galaxy A13 5G">Galaxy A13 5G</option>
                <option style={{direction: "ltr", backgroundColor: "white"}} value="Galaxy A13">Galaxy A13</option>
                <option style={{direction: "ltr", backgroundColor: "white"}} value="Galaxy A03s">Galaxy A03s</option>
                <option style={{direction: "ltr", backgroundColor: "white"}} value="Galaxy A03">Galaxy A03</option>
                <option style={{direction: "ltr", backgroundColor: "white"}} value="Galaxy A32">Galaxy A32</option>
                <option style={{direction: "ltr", backgroundColor: "white"}} value="Galaxy A22 5G">Galaxy A22 5G</option>
                <option style={{direction: "ltr", backgroundColor: "white"}} value="Galaxy A22">Galaxy A22</option>
                <option style={{direction: "ltr", backgroundColor: "white"}} value="Galaxy A12">Galaxy A12</option>
                <option style={{direction: "ltr", backgroundColor: "white"}} value="Galaxy A02s">Galaxy A02s</option>
                <option style={{direction: "ltr", backgroundColor: "white"}} value="Galaxy A02">Galaxy A02</option>
                <option style={{direction: "ltr", backgroundColor: "white"}} value="Galaxy A31">Galaxy A31</option>
                <option style={{direction: "ltr", backgroundColor: "white"}} value="Galaxy A21s">Galaxy A21s</option>
                <option style={{direction: "ltr", backgroundColor: "white"}} value="Galaxy A21">Galaxy A21</option>
                <option style={{direction: "ltr", backgroundColor: "white"}} value="Galaxy A11">Galaxy A11</option>
                <option style={{direction: "ltr", backgroundColor: "white"}} value="Galaxy A01">Galaxy A01</option>
                <option style={{direction: "ltr", backgroundColor: "white"}} value="Galaxy A20">Galaxy A20</option>
                <option style={{direction: "ltr", backgroundColor: "white"}} value="Galaxy A20e">Galaxy A20e</option>
                <option style={{direction: "ltr", backgroundColor: "white"}} value="Galaxy A10s">Galaxy A10s</option>
                <option style={{direction: "ltr", backgroundColor: "white"}} value="Galaxy A10">Galaxy A10</option>
                <option style={{direction: "ltr", backgroundColor: "white"}} value="Galaxy A10e">Galaxy A10e</option>
                <option style={{direction: "ltr", backgroundColor: "white"}} value="Galaxy A8* (2018)">Galaxy A8* (2018)</option>
                <option style={{direction: "ltr", backgroundColor: "white"}} value="Galaxy A8+ (2018)">Galaxy A8+ (2018)</option>
                <option style={{direction: "ltr", backgroundColor: "white"}} value="Galaxy A8 (2018)">Galaxy A8 (2018)</option>
                <option style={{direction: "ltr", backgroundColor: "white"}} value="Galaxy A7 (2018)">Galaxy A7 (2018)</option>
                <option style={{direction: "ltr", backgroundColor: "white"}} value="Galaxy A6s (2018)">Galaxy A6s (2018)</option>
                <option style={{direction: "ltr", backgroundColor: "white"}} value="Galaxy A6+ (2018)">Galaxy A6+ (2018)</option>
                <option style={{direction: "ltr", backgroundColor: "white"}} value="Galaxy A6 (2018)">Galaxy A6 (2018)</option>
                <option style={{direction: "ltr", backgroundColor: "white"}} value="Galaxy A3 (2017)">Galaxy A3 (2017)</option>
                <option style={{direction: "ltr", backgroundColor: "white"}} value="Galaxy A5 (2017)">Galaxy A5 (2017)</option>
                <option style={{direction: "ltr", backgroundColor: "white"}} value="Galaxy A7 (2017)">Galaxy A7 (2017)</option>
                <option style={{direction: "ltr", backgroundColor: "white"}} value="Galaxy A5 (2016)">Galaxy A5 (2016)</option>
                <option style={{direction: "ltr", backgroundColor: "white"}} value="Galaxy A6 (2016)">Galaxy A6 (2016)</option>
                <option style={{direction: "ltr", backgroundColor: "white"}} value="Galaxy A7 (2016)">Galaxy A7 (2016)</option>
                <option style={{direction: "ltr", backgroundColor: "white"}} value="Galaxy A8 (2016)">Galaxy A8 (2016)</option>
                <option style={{direction: "ltr", backgroundColor: "white"}} value="Galaxy A9 (2016)">Galaxy A9 (2016)</option>
          </select>            
              :""
            }
              <select ref={productStorageSizeRef} id={productConditionHasError ? 'price-input-field-error' : 'price-input-field'} onChange={(value)=>{
                setProductStorageSizeHasError(false)
                setProductStorageSize(value.target.value);
              }}>
                <option hidden>سعة التخزين</option>
                <option style={{backgroundColor: "white"}} value="64 جيجا">64 جيجا</option>
                <option style={{backgroundColor: "white"}} value="128 جيجا">128 جيجا</option>
                <option style={{backgroundColor: "white"}} value="256 جيجا">256 جيجا</option>
                <option style={{backgroundColor: "white"}} value="512 جيجا">512 جيجا</option>
                <option style={{backgroundColor: "white"}} value="1 تيرا">1 تيرا</option>
            </select>
                </>
            : ""
            }
            {
              productCategory == 'الكترونيات' && productSubCategory == "شاشة" ? 
              <>
              <select ref={productCompanyRef} id={productConditionHasError ? 'price-input-field-error' : 'price-input-field'} onChange={(value)=>{
                setProductCompanyHasError(false)
                setProductCompany(value.target.value);
              }}>
                <option style={{backgroundColor: "white"}} hidden>الشركة المصنعة</option>
                <option style={{backgroundColor: "white"}} value="سامسونج">سامسونج</option>
                <option style={{backgroundColor: "white"}} value="سوني">سوني</option>
                <option style={{backgroundColor: "white"}} value="TCL">TCL</option>
                <option style={{backgroundColor: "white"}} value="دانسات">دانسات</option>
                <option style={{backgroundColor: "white"}} value="باناسونيك">باناسونيك</option>
                <option style={{backgroundColor: "white"}} value="ال جي">ال جي</option>
                <option style={{backgroundColor: "white"}} value="اسوس">اسوس</option>
                <option style={{backgroundColor: "white"}} value="BenQ">BenQ</option>
                <option style={{backgroundColor: "white"}} value="أخرى">أخرى</option>
            </select>
              <select ref={productScreenSizeRef} id={productConditionHasError ? 'price-input-field-error' : 'price-input-field'} onChange={(value)=>{
                setProductScreenSizeHasError(false)
                setProductScreenSize(value.target.value);
              }}>
                <option hidden>حجم الشاشة</option>
                <option style={{backgroundColor: "white"}} value="16 بوصة">16 بوصة</option>
                <option style={{backgroundColor: "white"}} value="18 بوصة">18 بوصة</option>
                <option style={{backgroundColor: "white"}} value="20 بوصة">20 بوصة</option>
                <option style={{backgroundColor: "white"}} value="22 بوصة">22 بوصة</option>
                <option style={{backgroundColor: "white"}} value="24 بوصة">24 بوصة</option>
                <option style={{backgroundColor: "white"}} value="26 بوصة">26 بوصة</option>
                <option style={{backgroundColor: "white"}} value="28 بوصة">28 بوصة</option>
                <option style={{backgroundColor: "white"}} value="30 بوصة">30 بوصة</option>
                <option style={{backgroundColor: "white"}} value="32 بوصة">32 بوصة</option>
                <option style={{backgroundColor: "white"}} value="34 بوصة">34 بوصة</option>
                <option style={{backgroundColor: "white"}} value="36 بوصة">36 بوصة</option>
                <option style={{backgroundColor: "white"}} value="38 بوصة">38 بوصة</option>
                <option style={{backgroundColor: "white"}} value="40 بوصة">40 بوصة</option>
                <option style={{backgroundColor: "white"}} value="45 بوصة">45 بوصة</option>
                <option style={{backgroundColor: "white"}} value="50 بوصة">50 بوصة</option>
                <option style={{backgroundColor: "white"}} value="55 بوصة">55 بوصة</option>
                <option style={{backgroundColor: "white"}} value="60 بوصة">60 بوصة</option>
                <option style={{backgroundColor: "white"}} value="65 بوصة">65 بوصة</option>
                <option style={{backgroundColor: "white"}} value="70 بوصة">70 بوصة</option>
                <option style={{backgroundColor: "white"}} value="75 بوصة">75 بوصة</option>
                <option style={{backgroundColor: "white"}} value="80 بوصة">80 بوصة</option>
                <option style={{backgroundColor: "white"}} value="85 بوصة">85 بوصة</option>
                <option style={{backgroundColor: "white"}} value="100 بوصة">100 بوصة</option>
            </select>
                </>
            : ""
            } */}
            
           
            {/* {
              productCategory == 'الكترونيات' && productSubCategory == "سماعة" ? 
              <select ref={productCompanyRef} style={{display: "block"}} onChange={(value)=>{
                setProductCompany(value.target.value);
              }}>
                <option hidden>النوع</option>
                <option value="سامسونج">سماعات ابل</option>
                <option value="سوني">سوني</option>
                <option value="TCL">TCL</option>
                <option value="دانسات">دانسات</option>
                <option value="باناسونيك">باناسونيك</option>
                <option value="ال جي">ال جي</option>
                <option value="اسوس">اسوس</option>
                <option value="BenQ">BenQ</option>
            </select>
            : ""
            } */}

              {/* {
              productCategory == 'الكترونيات' && productSubCategory == "لابتوب" ? 
              <>
              <select ref={productCompanyRef} id={productConditionHasError ? 'price-input-field-error' : 'price-input-field'} onChange={(value)=>{
                setProductCompanyHasError(false)
                setProductCompany(value.target.value);
              }}>
                <option hidden>الشركة المصنعة</option>
                <option style={{backgroundColor: "white"}} value="ابل">ابل</option>
                <option style={{backgroundColor: "white"}} value="اتش بي">اتش بي</option>
                <option style={{backgroundColor: "white"}} value="ثينك باد">ثينك باد</option>
                <option style={{backgroundColor: "white"}} value="مايكروسوفت">مايكروسوفت</option>
                <option style={{backgroundColor: "white"}} value="ديل">ديل</option>
                <option style={{backgroundColor: "white"}} value="توشيبا">توشيبا</option>
                <option style={{backgroundColor: "white"}} value="هواوي">هواوي</option>
                <option style={{backgroundColor: "white"}} value="اسوس">اسوس</option>
                <option style={{backgroundColor: "white"}} value="لينوفو">لينوفو</option>
                <option style={{backgroundColor: "white"}} value="أخرى">أخرى</option>
            </select>
              <select ref={productStorageSizeRef} style={{display: "block", border: productMemorySizeHasError ? "1.5px solid red" : "1px solid #ccc", borderRadius: "8px", margin: "8px", height: "35px", width: "fit-content", paddingRight: "8px", backgroundColor: productMemorySizeHasError ? "#fff0f0" : "white" }} onChange={(value)=>{
                setProductMemorySizeHasError(false)
                setProductMemorySize(value.target.value);
              }}>
                <option hidden>الحجم</option>
                <option style={{backgroundColor: "white"}} value="4 رام">4 رام</option>
                <option style={{backgroundColor: "white"}} value="8 رام">8 رام</option>
                <option style={{backgroundColor: "white"}} value="16 رام">16 رام</option>
                <option style={{backgroundColor: "white"}} value="32 رام">32 رام</option>
            </select>
            <select ref={productScreenSizeRef} style={{display: "block", border: productScreenSizeHasError ? "1.5px solid red" : "1px solid #ccc", borderRadius: "8px", margin: "8px", height: "35px", width: "fit-content", paddingRight: "8px",backgroundColor: productScreenSizeHasError ? "#fff0f0" : "white" }} onChange={(value)=>{
                setProductScreenSizeHasError(false)
                setProductScreenSize(value.target.value);
              }}>
                <option hidden>حجم الشاشة</option>
                <option style={{backgroundColor: "white"}} value="16 بوصة">11 بوصة</option>
                <option style={{backgroundColor: "white"}} value="18 بوصة">12 بوصة</option>
                <option style={{backgroundColor: "white"}} value="20 بوصة">13 بوصة</option>
                <option style={{backgroundColor: "white"}} value="22 بوصة">14 بوصة</option>
                <option style={{backgroundColor: "white"}} value="24 بوصة">15 بوصة</option>
                <option style={{backgroundColor: "white"}} value="26 بوصة">16 بوصة</option>
                <option style={{backgroundColor: "white"}} value="28 بوصة">17 بوصة</option>
            </select>
                </>
            : ""
            }
              {
              productCategory == 'الكترونيات' && productSubCategory == "جهاز لوحي" ? 
              <>
              <select ref={productCompanyRef} id={productConditionHasError ? 'price-input-field-error' : 'price-input-field'} onChange={(value)=>{
                setProductCompanyHasError(false);
                setProductCompany(value.target.value);
              }}>
                <option hidden>الشركة المصنعة</option>
                <option value="ابل">ابل</option>
                <option value="سامسونج">سامسونج</option>
                <option value="هواوي">هواوي</option>
                <option value="شاومي">شاومي</option>
                <option value="اونور">اونور</option>
                <option value="أخرى">أخرى</option>
            </select>
              <select ref={productMemorySizeRef} style={{display: "block", border: productMemorySizeHasError ? "1.5px solid red" : "1px solid #ccc", borderRadius: "8px", margin: "8px", height: "35px", width: "fit-content", paddingRight: "8px", backgroundColor: productMemorySizeHasError ? "#fff0f0" : "white"}} onChange={(value)=>{
                setProductMemorySizeHasError(false)
                setProductMemorySize(value.target.value);
              }}>
                <option style={{backgroundColor: "white"}} hidden>الحجم</option>
                <option style={{backgroundColor: "white"}} value="4 رام">4 رام</option>
                <option style={{backgroundColor: "white"}} value="8 رام">8 رام</option>
                <option style={{backgroundColor: "white"}} value="16 رام">16 رام</option>
                <option style={{backgroundColor: "white"}} value="32 رام">32 رام</option>
            </select>
                </>
            : ""
            }
              {
              productCategory == 'الكترونيات' && productSubCategory == "كاميرا" ? 
              <>
              <select ref={productCompanyRef} id={productConditionHasError ? 'price-input-field-error' : 'price-input-field'} onChange={(value)=>{
                setProductCompanyHasError(false);
                setProductCompany(value.target.value);
              }}>
                <option hidden>الشركة المصنعة</option>
                <option value="كانون">كانون</option>
                <option value="سوني">سوني</option>
                <option value="فوجي">فوجي</option>
                <option value="أخرى">أخرى</option>
            </select>
                </>
            : ""
            }
              {
              productCategory == 'الكترونيات' && productSubCategory == "طابعة" ? 
              <>
              <select ref={productCompanyRef} id={productConditionHasError ? 'price-input-field-error' : 'price-input-field'} onChange={(value)=>{
                setProductCompanyHasError(false);
                setProductCompany(value.target.value);
              }}>
                <option hidden>الشركة المصنعة</option>
                <option value="اتش بي">اتش بي</option>
                <option value="كانون">كانون</option>
                <option value="ايبسون">ايبسون</option>
                <option value="أخرى">أخرى</option>
            </select>
              <select ref={productInkKindRef} style={{display: "block", border: productInkKindHasError ? "1.5px solid red" : "1px solid #ccc", borderRadius: "8px", margin: "8px", height: "35px", width: "fit-content", paddingRight: "8px",backgroundColor: productInkKindHasError ? "#fff0f0" : "white" }} onChange={(value)=>{
                setProductInkKindHasError(false);
                setProductInkKind(value.target.value);
              }}>
                <option hidden>نوع الحبر</option>
                <option value="ليزر">ليزر</option>
                <option value="سائل">سائل</option>
                <option value="حرارية">حرارية</option>
            </select>
                </>
            : ""
            }
              {
              productCategory == 'الكترونيات' && productSubCategory == "جهاز العاب" ? 
              <>
              <select ref={productCompanyRef} id={productConditionHasError ? 'price-input-field-error' : 'price-input-field'} onChange={(value)=>{
                setProductCompanyHasError(false);
                setProductCompany(value.target.value);
              }}>
                <option hidden>الشركة المصنعة</option>
                <option value="بلايستيشن">بلايستيشن</option>
                <option value="مايكروسوفت">مايكروسوفت</option>
            </select>
            {
               productCategory == 'الكترونيات' && productSubCategory == "جهاز العاب"  && productCompany == 'بلايستيشن'?
              <select ref={productKindRef} id={productConditionHasError ? 'price-input-field-error' : 'price-input-field'} onChange={(value)=>{
                setProductKindHasError(false);
                setProductKind(value.target.value);
              }}>
                <option hidden>نوع الجهاز</option>
                <option style={{backgroundColor: "white"}} value="بلايستيشن 1">بلايستيشن 1</option>
                <option style={{backgroundColor: "white"}} value="بلايستيشن 2">بلايستيشن 2</option>
                <option style={{backgroundColor: "white"}} value="بلايستيشن 3">بلايستيشن 3</option>
                <option style={{backgroundColor: "white"}} value="بلايستيشن 4">بلايستيشن 4</option>
                <option style={{backgroundColor: "white"}} value="بلايستيشن 5">بلايستيشن 5</option>
            </select>
            : ""
            }
            {
              productCategory == 'الكترونيات' && productSubCategory == "جهاز العاب"  && productCompany == 'مايكروسوفت'?
                <select ref={productKindRef} id={productConditionHasError ? 'price-input-field-error' : 'price-input-field'} onChange={(value)=>{
                  setProductKindHasError(false);
                  setProductKind(value.target.value);
                }}>
                  <option hidden>نوع الجهاز</option>
                  <option style={{backgroundColor: "white"}} value="اكسبوكس 360">اكسبوكس 360</option>
                  <option style={{backgroundColor: "white"}} value="اكسبوكس 1">اكسبوكس 1</option>
              </select>
              : ""
            }
                </>
            : ""
            }
            {
              productCategory == 'اثاث' && productSubCategory == "كرسي" ? 
              <select ref={productKindRef} id={productConditionHasError ? 'price-input-field-error' : 'price-input-field'} onChange={(value)=>{
                setProductKindHasError(false);
                setProductKind(value.target.value);
              }}>
                <option hidden>النوع</option>
                <option style={{backgroundColor: "white"}} value="منزلي">منزلي</option>
                <option style={{backgroundColor: "white"}} value="مكتبي">مكتبي</option>
                <option style={{backgroundColor: "white"}} value="مدرسي">مدرسي</option>
                <option style={{backgroundColor: "white"}} value="خارجي">خارجي</option>
            </select> 
            : ""
            } 
            { 
              productCategory == 'اثاث' && productSubCategory == "كنب" ? 
              <select ref={productKindRef} id={productConditionHasError ? 'price-input-field-error' : 'price-input-field'} onChange={(value)=>{
                setProductKindHasError(false);
                setProductKind(value.target.value);
              }}>
                <option hidden>النوع</option>
                <option style={{backgroundColor: "white"}} value="منزلي">منزلي</option>
                <option style={{backgroundColor: "white"}} value="مكتبي">مكتبي</option>
                <option style={{backgroundColor: "white"}} value="خارجي">خارجي</option>
                <option style={{backgroundColor: "white"}} value="زاوية">زاوية</option>
            </select> 
            : ""
            }
            {
              productCategory == 'اثاث' && productSubCategory == "طاولة" ? 
              <select ref={productKindRef} id={productConditionHasError ? 'price-input-field-error' : 'price-input-field'} onChange={(value)=>{
                setProductKindHasError(false);
                setProductKind(value.target.value);
              }}>
                <option hidden>النوع</option>
                <option style={{backgroundColor: "white"}} value="منزلية">منزلية</option>
                <option style={{backgroundColor: "white"}} value="مكتب">مكتب</option>
            </select>
            : ""
            } */}
              </div>
              <div style={{display:  acceptsOffers &&  parseFloat(productPrice) > 0 && (parseInt(startingPrice) >= parseFloat(productPrice))  ? "block" : "none", textAlign : "right", marginRight: "16px", marginTop: "8px", color: "red"}}>
              <p>مبلغ دخول المزاد يجب ان يكون اقل من سعر الشراء الفوري</p>
            </div>

            <div style={{width: "100%", marginTop: "24px" ,alignItems: "center", justifyContent: "center", textAlign:"center"}}>

              <button id='my-button-2' style={{width:"60%",backgroundColor: "#00C6B7"}} onClick={async ()=>{

if(!acceptsOffers){
  if(images.length < 3 || 
    images.length > 10 ||
    productName.length < 10 || 
    productDescription.length < 50 || 
    typeof parseFloat(productPrice) !== 'number' || isNaN(parseFloat(productPrice)) ||
    productCondition == "" || 
    productCategory == "" ||
    productWeight == "" ||
    typeof parseFloat(productWeight) !== 'number' || isNaN(parseFloat(productWeight)) ||
    parseFloat(productWeight) == 0
    ){
      if(images.length < 3){
        setProductImageHasError(true);
      }
      if(images.length > 10){
        setProductImageHasError(true);
      }
      if(productName.length < 10){
        setProductNameHasError(true)
      }
      if(productDescription.length < 50){
        setProductDescriptionHasError(true)
      }
      if(productPrice == ""){
        setProductPriceHasError(true)
      }
      if(typeof parseFloat(productPrice) !== 'number'){
        setProductPriceHasError(true)
      }
      if(typeof parseFloat(productWeight) !== 'number'){
        setProductWeightHasError(true)
      }
      if(isNaN(parseFloat(productPrice))){
        setProductPriceHasError(true)
      }
      if(isNaN(parseFloat(productWeight))){
        setProductWeightHasError(true)
      }
      if(productCondition == ""){
        setProductConditionHasError(true)
      }
      if(productWeight == ""){
        setProductWeightHasError(true)
      }
      if(productCategory == ""){
        setProductCategoryHasError(true)
      }

      if(parseFloat(productWeight) == 0){
        setProductWeightHasError(true)
      }


      return;
    }
}
else{
  if(images.length < 3 || 
    productName.length < 10 || 
    productDescription.length < 50 || 
    typeof parseFloat(productPrice) !== 'number' || isNaN(parseFloat(productPrice)) ||
    productCondition == "" || 
    productCategory == "" ||
    productExpiryDate == "" ||
    startingPrice == "" ||
    parseFloat(startingPrice) < 10 ||
    typeof parseFloat(startingPrice) !== 'number' || isNaN(parseFloat(startingPrice)) ||
    ((parseFloat(productPrice) > 0) && (parseInt(startingPrice) >= parseFloat(productPrice))) ||
    passedDateSelected ||
    parseFloat(bidIncrements) < 10 || 
    productWeight == "" ||
    typeof parseFloat(productWeight) !== 'number' || isNaN(parseFloat(productWeight)) ||
    parseFloat(productWeight) == 0
    ){
      if(images.length < 3){
        setProductImageHasError(true);
      }
      if(productName.length < 10){
        setProductNameHasError(true)
      }
      if(productDescription.length < 50){
        setProductDescriptionHasError(true)
      }
      if(productPrice == ""){
        setProductPriceHasError(true)
      }
      if(typeof parseFloat(productPrice) !== 'number'){
        setProductPriceHasError(true)
      }
      if(typeof parseFloat(productWeight) !== 'number'){
        setProductWeightHasError(true)
      }
      if(isNaN(parseFloat(productPrice))){
        setProductPriceHasError(true)
      }
      if(isNaN(parseFloat(productWeight))){
        setProductWeightHasError(true)
      }
      if(productCondition == ""){
        setProductConditionHasError(true)
      }
      if(productCategory == ""){
        setProductCategoryHasError(true)
      }
      if(productExpiryDate == ""){
        setProductExpiryDateHasError(true)
      }
      if(startingPrice == "")
        setProductStartingPriceHasError(true)
      if(startingPrice < 10)
        setProductStartingPriceHasError(true)
      if(typeof parseInt(startingPrice) !== 'number' || isNaN(parseInt(startingPrice)))
      setProductStartingPriceHasError(true)
      if((parseFloat(productPrice) > 0) && parseFloat(startingPrice) >= parseFloat(productPrice)){
        setProductPriceHasError(true)
        setProductStartingPriceHasError(true)
      }
      if(passedDateSelected)
      setProductExpiryDateHasError(true)
      if(parseFloat(bidIncrements) < 10){
        setBidIncrementHasError(true)
      }
      if(isNaN(parseFloat(bidIncrements))){
        setBidIncrementHasError(true)
      }
      if(productWeight == ""){
        setProductWeightHasError(true)
      }
      if(parseFloat(productWeight) == 0){
        setProductWeightHasError(true)
      }
      return;
    }
}

  setIsLoading(true); 
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: 'instant'
  });
  const keywords = [];
  const productCon = productCondition.trim();
  keywords.push(productCon)

  const companyStr = productCompany.trim().split(" ");
  const productKindStr = productKind.trim().split(" ");
  const descriptionStr = productDescription.trim().split(/\s+/);
  //#region 
  // const subCategoryStr = productSubCategory.trim().split(" ");
  
  // for(var i = 0 ; i < subCategoryStr.length; i ++){
  //   if(!keywords.includes(subCategoryStr[i])){
  //     keywords.push(subCategoryStr[i].trim())
  //   }
  // }
  // for(var i = 0 ; i < companyStr.length; i ++){
  //   if(!keywords.includes(companyStr[i])){
  //     keywords.push(companyStr[i].trim())
  //   }
  // }
  // for(var i = 0 ; i < productKindStr.length; i ++){
  //   if(!keywords.includes(productKindStr[i])){
  //     keywords.push(productKindStr[i].trim())
  //   }
  // }
//#endregion
  for(var i = 0 ; i < descriptionStr.length; i ++){
    if(!descriptionStr[i].includes("\n") && !keywords.includes(descriptionStr[i])){
      keywords.push(descriptionStr[i].trim())
    }
  }

  //#region 
  // if(productCategory == 'الكترونيات'){
  //   if(productSubCategory == "جوال"){
  //     keywords.push("قيقا".trim())
  //     const productStorageSizeStr = productStorageSize.split(" ");
  //     for(var i = 0 ; i < productStorageSizeStr.length; i ++){
  //       keywords.push(productStorageSizeStr[i].trim())
  //     }
  //     if(productCompany == 'سامسونج'){
  //       keywords.push("سامسونغ")
  //       keywords.push("سامسونق")
  //       keywords.push("جلكسي")
  //       keywords.push("جالكسي")
  //       if(productKind == 'Galaxy S23 Ultra'){
  //         keywords.push("s")
  //         keywords.push("23")
  //         keywords.push("اس")
  //         keywords.push("إس")
  //         keywords.push("٢٣")
  //         keywords.push("الترا")
  //         keywords.push("ألترا")
  //       }
  //       else if(productKind == 'Galaxy S23+'){
  //         keywords.push("s")
  //         keywords.push("23")
  //         keywords.push("اس")
  //         keywords.push("إس")
  //         keywords.push("٢٣")
  //         keywords.push("بلس")
  //         keywords.push("+")
  //       }
  //       else if(productKind == 'Galaxy S23'){
  //         keywords.push("s")
  //         keywords.push("23")
  //         keywords.push("اس")
  //         keywords.push("إس")
  //         keywords.push("٢٣")
  //       }
  //       else if(productKind == 'Galaxy S22 Ultra'){
  //         keywords.push("s")
  //         keywords.push("22")
  //         keywords.push("اس")
  //         keywords.push("إس")
  //         keywords.push("٢٢")
  //         keywords.push("الترا")
  //         keywords.push("ألترا")
  //       }
  //       else if(productKind == 'Galaxy S22+'){
  //         keywords.push("s")
  //         keywords.push("22")
  //         keywords.push("اس")
  //         keywords.push("إس")
  //         keywords.push("٢٢")
  //         keywords.push("بلس")
  //         keywords.push("+")
  //       }
  //       else if(productKind == 'Galaxy S22'){
  //         keywords.push("s")
  //         keywords.push("22")
  //         keywords.push("اس")
  //         keywords.push("إس")
  //         keywords.push("٢٢")
  //       }
  //       else if(productKind == 'Galaxy S21 FE'){
  //         keywords.push("s")
  //         keywords.push("21")
  //         keywords.push("اف")
  //         keywords.push("إف")
  //         keywords.push("اي")
  //         keywords.push("إي")
  //         keywords.push("٢١")
  //       }
  //       else if(productKind == 'Galaxy S21 Ultra'){
  //         keywords.push("s")
  //         keywords.push("21")
  //         keywords.push("اس")
  //         keywords.push("إس")
  //         keywords.push("٢١")
  //         keywords.push("الترا")
  //         keywords.push("ألترا")
  //       }
  //       else if(productKind == 'Galaxy S21+'){
  //         keywords.push("s")
  //         keywords.push("21")
  //         keywords.push("اس")
  //         keywords.push("إس")
  //         keywords.push("٢١")
  //         keywords.push("بلس")
  //         keywords.push("+")
  //       }
  //       else if(productKind == 'Galaxy S21'){
  //         keywords.push("s")
  //         keywords.push("21")
  //         keywords.push("اس")
  //         keywords.push("إس")
  //         keywords.push("٢٢")
  //       }
  //       else if(productKind == 'Galaxy S20 FE'){
  //         keywords.push("s")
  //         keywords.push("20")
  //         keywords.push("اف")
  //         keywords.push("إف")
  //         keywords.push("إي")
  //         keywords.push("اي")
  //         keywords.push("٢٠")
  //       }
  //       else if(productKind == 'Galaxy S20 Ultra 5G'){
  //         keywords.push("s")
  //         keywords.push("20")
  //         keywords.push("اس")
  //         keywords.push("إس")
  //         keywords.push("الترا")
  //         keywords.push("ألترا")
  //         keywords.push("فايف")
  //         keywords.push("جي")
  //         keywords.push("٢٠")
  //       }
  //       else if(productKind == 'Galaxy S20+'){
  //         keywords.push("s")
  //         keywords.push("20")
  //         keywords.push("اس")
  //         keywords.push("إس")
  //         keywords.push("بلس")
  //         keywords.push("+")
  //         keywords.push("٢٠")
  //       }
  //       else if(productKind == 'Galaxy S20'){
  //         keywords.push("s")
  //         keywords.push("20")
  //         keywords.push("اس")
  //         keywords.push("إس")
  //         keywords.push("٢٠")
  //       }
  //       else if(productKind == 'Galaxy S10 Lite'){
  //         keywords.push("s")
  //         keywords.push("10")
  //         keywords.push("اس")
  //         keywords.push("إس")
  //         keywords.push("١٠")
  //         keywords.push("لايت")
  //       }
  //       else if(productKind == 'Galaxy S10 5G'){
  //         keywords.push("s")
  //         keywords.push("10")
  //         keywords.push("اس")
  //         keywords.push("إس")
  //         keywords.push("١٠")
  //         keywords.push("فايف")
  //         keywords.push("جي")
  //       }
  //       else if(productKind == 'Galaxy S10+'){
  //         keywords.push("s")
  //         keywords.push("10")
  //         keywords.push("اس")
  //         keywords.push("إس")
  //         keywords.push("١٠")
  //         keywords.push("بلس")
  //         keywords.push("+")
  //       }
  //       else if(productKind == 'Galaxy S10e'){
  //         keywords.push("s")
  //         keywords.push("10")
  //         keywords.push("اس")
  //         keywords.push("إس")
  //         keywords.push("١٠")
  //         keywords.push("بلس")
  //         keywords.push("+")
  //       }
  //       else if(productKind == 'Galaxy S10'){
  //         keywords.push("s")
  //         keywords.push("10")
  //         keywords.push("اس")
  //         keywords.push("إس")
  //         keywords.push("١٠")
  //       }
  //       else if(productKind == 'Galaxy S9+'){
  //         keywords.push("s")
  //         keywords.push("9")
  //         keywords.push("اس")
  //         keywords.push("إس")
  //         keywords.push("٩")
  //         keywords.push("بلس")
  //         keywords.push("+")
  //       }
  //       else if(productKind == 'Galaxy S9'){
  //         keywords.push("s")
  //         keywords.push("9")
  //         keywords.push("اس")
  //         keywords.push("إس")
  //         keywords.push("٩")
  //       }
  //       else if(productKind == 'Galaxy S8+'){
  //         keywords.push("s")
  //         keywords.push("8")
  //         keywords.push("اس")
  //         keywords.push("إس")
  //         keywords.push("٨")
  //         keywords.push("بلس")
  //         keywords.push("+")
  //       }
  //       else if(productKind == 'Galaxy S7 Active'){
  //         keywords.push("s")
  //         keywords.push("7")
  //         keywords.push("اس")
  //         keywords.push("إس")
  //         keywords.push("٧")
  //         keywords.push("اكتف")
  //         keywords.push("اكتيف")
  //         keywords.push("active")
  //       }
  //       else if(productKind == 'Galaxy S7 edge'){
  //         keywords.push("s")
  //         keywords.push("7")
  //         keywords.push("اس")
  //         keywords.push("إس")
  //         keywords.push("٧")
  //         keywords.push("ادج")
  //         keywords.push("ايدج")
  //         keywords.push("edge")
  //       }
  //       else if(productKind == 'Galaxy S7'){
  //         keywords.push("s")
  //         keywords.push("7")
  //         keywords.push("اس")
  //         keywords.push("إس")
  //         keywords.push("٧")
  //       }
  //       else if(productKind == 'Galaxy S6 Edge+'){
  //         keywords.push("s")
  //         keywords.push("6")
  //         keywords.push("اس")
  //         keywords.push("إس")
  //         keywords.push("٦")
  //         keywords.push("ادج")
  //         keywords.push("ايدج")
  //         keywords.push("edge")
  //         keywords.push("بلس")
  //         keywords.push("+")
  //       }
  //       else if(productKind == 'Galaxy S6 Edge'){
  //         keywords.push("s")
  //         keywords.push("6")
  //         keywords.push("اس")
  //         keywords.push("إس")
  //         keywords.push("٦")
  //         keywords.push("ادج")
  //         keywords.push("ايدج")
  //         keywords.push("edge")
  //       }
  //       else if(productKind == 'Galaxy S6 Edge'){
  //         keywords.push("s")
  //         keywords.push("6")
  //         keywords.push("اس")
  //         keywords.push("إس")
  //         keywords.push("٦")
  //       }
  //       else if(productKind == 'Galaxy Z Flip 5'){
  //         keywords.push("z")
  //         keywords.push("5")
  //         keywords.push("فلب")
  //         keywords.push("فليب")
  //         keywords.push("زي")
  //         keywords.push("زيد")
  //         keywords.push("زد")
  //         keywords.push("٥")
  //       }
  //       else if(productKind == 'Galaxy Z Fold 5'){
  //         keywords.push("z")
  //         keywords.push("5")
  //         keywords.push("فولد")
  //         keywords.push("زي")
  //         keywords.push("زيد")
  //         keywords.push("٥")
  //         keywords.push("زد")
  //       }
  //       else if(productKind == 'Galaxy Z Flip 4'){
  //         keywords.push("z")
  //         keywords.push("4")
  //         keywords.push("فلب")
  //         keywords.push("فليب")
  //         keywords.push("زي")
  //         keywords.push("زيد")
  //         keywords.push("٤")
  //         keywords.push("زد")
  //       }
  //       else if(productKind == 'Galaxy Z Fold 4'){
  //         keywords.push("z")
  //         keywords.push("4")
  //         keywords.push("فولد")
  //         keywords.push("زي")
  //         keywords.push("زيد")
  //         keywords.push("٤")
  //         keywords.push("زد")
  //       }
  //       else if(productKind == 'Galaxy Z Flip 3'){
  //         keywords.push("z")
  //         keywords.push("3")
  //         keywords.push("فلب")
  //         keywords.push("فليب")
  //         keywords.push("زي")
  //         keywords.push("زيد")
  //         keywords.push("٣")
  //         keywords.push("زد")
  //       }
  //       else if(productKind == 'Galaxy Z Fold 3'){
  //         keywords.push("z")
  //         keywords.push("3")
  //         keywords.push("فولد")
  //         keywords.push("زي")
  //         keywords.push("زيد")
  //         keywords.push("٣")
  //         keywords.push("زد")
  //       }
  //       else if(productKind == 'Galaxy Z Flip'){
  //         keywords.push("z")
  //         keywords.push("فلب")
  //         keywords.push("فليب")
  //         keywords.push("زي")
  //         keywords.push("زيد")
  //         keywords.push("زد")
  //       }
  //       else if(productKind == 'Galaxy Z Fold'){
  //         keywords.push("z")
  //         keywords.push("فولد")
  //         keywords.push("زي")
  //         keywords.push("زيد")
  //         keywords.push("زد")
  //       }
  //       else if(productKind == 'Galaxy Fold'){
  //         keywords.push("فولد")
  //       }
  //       else if(productKind == 'Galaxy Note 20 Ultra'){
  //         keywords.push("note")
  //         keywords.push("20")
  //         keywords.push("نوت")
  //         keywords.push("٢٠")
  //         keywords.push("الترا")
  //         keywords.push("ألترا")
  //       }
  //       else if(productKind == 'Galaxy Note 20'){
  //         keywords.push("note")
  //         keywords.push("20")
  //         keywords.push("نوت")
  //         keywords.push("٢٠")
  //       }
  //       else if(productKind == 'Galaxy Note 10+'){
  //         keywords.push("note")
  //         keywords.push("10")
  //         keywords.push("نوت")
  //         keywords.push("١٠")
  //         keywords.push("بلس")
  //         keywords.push("+")
  //       }
  //       else if(productKind == 'Galaxy Note 10'){
  //         keywords.push("note")
  //         keywords.push("10")
  //         keywords.push("نوت")
  //         keywords.push("١٠")
  //       }
  //       else if(productKind == 'Galaxy Note 9'){
  //         keywords.push("note")
  //         keywords.push("9")
  //         keywords.push("نوت")
  //         keywords.push("٩")
  //       }
  //       else if(productKind == 'Galaxy Note 8'){
  //         keywords.push("note")
  //         keywords.push("8")
  //         keywords.push("نوت")
  //         keywords.push("٨")
  //       }
  //       else if(productKind == 'Galaxy Note 7'){
  //         keywords.push("note")
  //         keywords.push("7")
  //         keywords.push("نوت")
  //         keywords.push("٧")
  //       }
  //       else if(productKind == 'Galaxy Note 5'){
  //         keywords.push("note")
  //         keywords.push("5")
  //         keywords.push("نوت")
  //         keywords.push("٥")
  //       }
  //       else if(productKind == 'Galaxy A6+ (2018)'){
  //         keywords.push("A")
  //         keywords.push("6")
  //         keywords.push("بلس")
  //         keywords.push("٦")
  //       }
  //     }
  //     else if(productCompany == 'ابل'){
  //       if(productKind.includes("15")){keywords.push("١٥")}
  //       else if(productKind.includes("14")){keywords.push("١٤")}
  //       else if(productKind.includes("13")){keywords.push("١٣")}
  //       else if(productKind.includes("12")){keywords.push("١٢")}
  //       else if(productKind.includes("11")){keywords.push("١١")}
  //       else if(productKind == 'ايفون XS Max'){
  //         keywords.push("اكس اس ماكس")
  //         keywords.push("إكس اس ماكس")
  //         keywords.push("إكس إس ماكس")
  //         keywords.push("اكس إس ماكس")
  //       }
  //       else if(productKind == 'ايفون XS'){
  //         keywords.push("اكس اس")
  //         keywords.push("إكس اس")
  //         keywords.push("إكس إس")
  //         keywords.push("اكس إس")
  //       }
  //       else if(productKind == 'ايفون XR'){
  //         keywords.push("اكس ار")
  //         keywords.push("إكس ار")
  //         keywords.push("إكس أر")
  //       }
  //       else if(productKind == 'ايفون X'){
  //         keywords.push("اكس")
  //         keywords.push("إكس")
  //       }
  //       else if(productKind.includes("8")){keywords.push("٨")}
  //       else if(productKind.includes("7")){keywords.push("٧")}
  //       else if(productKind.includes("6")){keywords.push("٦")}
  //     }
  //   }
  //   else if(productSubCategory == 'شاشة'){
  //       keywords.push('شاشة')
  //       keywords.push('شاشه')
  //       keywords.push('تلفزيون')
  //       keywords.push('انش')
  //       const productScreenSizeStr = productScreenSize.split(" ");
  //       for(var i = 0 ; i < productScreenSizeStr.length; i ++){
  //         keywords.push(productScreenSizeStr[i])
  //       }
  //       if(productCompany == 'سامسونج'){
  //         keywords.push("سامسونغ")
  //         keywords.push("سامسونق")
  //       }
  //       else if(productCompany == 'TCL'){
  //         keywords.push("تي سي ال")
  //         keywords.push("تي")
  //         keywords.push("سي")
  //         keywords.push("ال")
  //       }
  //       else if(productCompany == 'دانسات'){
  //         keywords.push("danast")
  //       }
  //       else if(productCompany == 'باناسونيك'){
  //         keywords.push("panasonic")
  //       }
  //       else if(productCompany == 'ال جي'){
  //         keywords.push("إل جي")
  //         keywords.push("إل")
  //         keywords.push("جي")
  //         keywords.push("ال")
  //         keywords.push("LG")
  //       }
  //       else if(productCompany == 'اسوس'){
  //         keywords.push("ايسوس")
  //         keywords.push("asus")
  //       }
  //       else if(productCompany == 'BenQ'){
  //         keywords.push("بن كيو")
  //         keywords.push("بين كيو")
  //         keywords.push("بن")
  //         keywords.push("بين")
  //         keywords.push("كيو")
  //         keywords.push("benq")
  //         keywords.push("ben")
  //         keywords.push("q")
  //       }

  //       // screen size
  //       if(productScreenSize == '16 بوصة'){
  //         keywords.push('١٦')
  //       }
  //       else if(productScreenSize == '18 بوصة'){
  //         keywords.push('١٨')
  //       }
  //       else if(productScreenSize == '20 بوصة'){
  //         keywords.push('٢٠')
  //       }
  //       else if(productScreenSize == '22 بوصة'){
  //         keywords.push('٢٢')
  //       }
  //       else if(productScreenSize == '24 بوصة'){
  //         keywords.push('٢٤')
  //       }
  //       else if(productScreenSize == '26 بوصة'){
  //         keywords.push('٢٦')
  //       }
  //       else if(productScreenSize == '28 بوصة'){
  //         keywords.push('٢٨')
  //       }
  //       else if(productScreenSize == '30 بوصة'){
  //         keywords.push('٣٠')
  //       }
  //       else if(productScreenSize == '32 بوصة'){
  //         keywords.push('٣٢')
  //       }
  //       else if(productScreenSize == '34 بوصة'){
  //         keywords.push('٣٤')
  //       }
  //       else if(productScreenSize == '36 بوصة'){
  //         keywords.push('٣٦')
  //       }
  //       else if(productScreenSize == '38 بوصة'){
  //         keywords.push('٣٨')
  //       }
  //       else if(productScreenSize == '40 بوصة'){
  //         keywords.push('٤٠')
  //       }
  //       else if(productScreenSize == '45 بوصة'){
  //         keywords.push('٤٥')
  //       }
  //       else if(productScreenSize == '50 بوصة'){
  //         keywords.push('٥٠')
  //       }
  //       else if(productScreenSize == '55 بوصة'){
  //         keywords.push('٥٥')
  //       }
  //       else if(productScreenSize == '60 بوصة'){
  //         keywords.push('٦٠')
  //       }
  //       else if(productScreenSize == '65 بوصة'){
  //         keywords.push('٦٥')
  //       }
  //       else if(productScreenSize == '70 بوصة'){
  //         keywords.push('٧٠')
  //       }
  //       else if(productScreenSize == '75 بوصة'){
  //         keywords.push('٧٥')
  //       }
  //       else if(productScreenSize == '80 بوصة'){
  //         keywords.push('٨٠')
  //       }
  //       else if(productScreenSize == '85 بوصة'){
  //         keywords.push('٨٥')
  //       }
  //       else if(productScreenSize == '100 بوصة'){
  //         keywords.push('١٠٠')
  //       }
  //   }
  //   else if(productSubCategory == 'لابتوب'){
  //     keywords.push("ram")
  //     const productMemorySizeStr = productMemorySize.split(" ");
  //     for(var i = 0 ; i < productMemorySizeStr.length; i ++){
  //       keywords.push(productMemorySizeStr[i])
  //     }

  //     if(productCompany == 'ابل'){
  //       keywords.push("ماك")
  //       keywords.push("ماك بوك")
  //     }
  //     else if(productCompany == 'اتش بي'){
  //       keywords.push("hp")
  //       keywords.push("اتش")
  //       keywords.push("بي")
  //     }
  //     else if(productCompany == 'ثينك باد'){
  //       keywords.push("thinkpad")
  //     }
  //     else if(productCompany == 'مايكروسوفت'){
  //       keywords.push("microsoft")
  //     }
  //     else if(productCompany == 'ديل'){
  //       keywords.push("dell")
  //     }
  //     else if(productCompany == 'توشيبا'){
  //       keywords.push("toshiba")
  //     }
  //     else if(productCompany == 'اسوس'){
  //       keywords.push("asus")
  //     }
  //     else if(productCompany == 'لينوفو'){
  //       keywords.push("lenovo")
  //     }

  //     if(productMemorySize == '4 رام'){
  //       keywords.push('٤')
  //     }
  //     else if(productMemorySize == '8 رام'){
  //       keywords.push('٨')
  //     }
  //     else if(productMemorySize == '16 رام'){
  //       keywords.push('١٦')
  //     }
  //     else if(productMemorySize == '32 رام'){
  //       keywords.push('٣٢')
  //     }
  //   }
  //   else if(productSubCategory == 'جهاز لوحي'){
  //     const productMemorySizeStr = productMemorySize.split(" ");
  //     for(var i = 0 ; i < productMemorySizeStr.length; i ++){
  //       keywords.push(productMemorySizeStr[i])
  //     }

  //     if(productCompany == 'ابل'){
  //       keywords.push("ايباد")
  //     }

  //     if(productMemorySize == '4 رام'){
  //       keywords.push('٤')
  //     }
  //     else if(productMemorySize == '8 رام'){
  //       keywords.push('٨')
  //     }
  //     else if(productMemorySize == '16 رام'){
  //       keywords.push('١٦')
  //     }
  //     else if(productMemorySize == '32 رام'){
  //       keywords.push('٣٢')
  //     }
  //   }
  //   else if(productSubCategory == 'طابعة'){
  //     keywords.push("طابعه")
  //     keywords.push("حبر")
  //     const productInkKindStr = productInkKind.split(" ");
  //     for(var i = 0 ; i < productInkKindStr.length; i ++){
  //       keywords.push(productInkKindStr[i])
  //     }

  //     if(productCompany == 'اتش بي'){
  //       keywords.push("hp")
  //     }
  //     else if(productCompany == 'كانون'){
  //       keywords.push("canon")
  //     }
  //     else if(productCompany == 'ايبسون'){
  //       keywords.push("epson")
  //     }
  //   }
  //   else if(productSubCategory == 'سماعة'){
  //     keywords.push('سماعه')
  //     keywords.push('سماعات')
  //     keywords.push('ستيريو')
  //     keywords.push('استريو')
  //     keywords.push('سبيكر')
  //     keywords.push('اسبيكر')
  //     keywords.push('سبيكرات')
  //   }
  //   else if(productSubCategory == 'جهاز العاب'){
  //     if(productCompany == 'بلايستيشن'){
  //       keywords.push("سوني")
  //       if(productKind == 'بلايستيشن 1'){
  //         keywords.push("١")
  //       }
  //       else if(productKind == 'بلايستيشن 2'){
  //         keywords.push("٢")
          
  //       }
  //       else if(productKind == 'بلايستيشن 3'){
  //         keywords.push("٣")
          
  //       }
  //       else if(productKind == 'بلايستيشن 4'){
  //         keywords.push("٤")
          
  //       }
  //       else if(productKind == 'بلايستيشن 5'){
  //         keywords.push("٥")
  //       }
  //     }
  //     else if(productCompany == 'مايكروسوفت'){
  //       keywords.push("اكس بوكس")

  //       if(productKind == 'اكسبوكس 360'){
  //         keywords.push("٣٦٠")
  //       }
  //       else if (productKind == 'اكسبوكس 1'){
  //         keywords.push("١")
  //       }
  //     }
  //   }
  // }

  // else if(productSubCategory == 'جهاز العاب'){
  //   keywords.push('جهاز العاب')
  //   keywords.push('سوني')
  //   keywords.push('سوني 1')
  //   keywords.push('سوني ون')
  //   keywords.push('سوني 2')
  //   keywords.push('سوني تو')
  //   keywords.push('سوني 3')
  //   keywords.push('سوني ثري')
  //   keywords.push('سوني 4')
  //   keywords.push('سوني فور')
  //   keywords.push('سوني 5')
  //   keywords.push('سوني فايف')
  //   keywords.push('بلايستيشن')
  //   keywords.push('بلايستيشن 1')
  //   keywords.push('بلايستيشن ون')
  //   keywords.push('بلايستيشن 2')
  //   keywords.push('بلايستيشن تو')
  //   keywords.push('بلايستيشن 3')
  //   keywords.push('بلايستيشن ثري')
  //   keywords.push('بلايستيشن 4')
  //   keywords.push('بلايستيشن فور')
  //   keywords.push('بلايستيشن 5')
  //   keywords.push('بلايستيشن فايف')
  //   keywords.push('اكسبوكس')
  //   keywords.push('اكسبوكس 360')
  //   keywords.push('اكسبوكس 1')
  //   keywords.push('اكسبوكس ون')
  // }
  
  // else if(productSubCategory == 'اثاث'){
  //   keywords.push('اثاث')
  //   keywords.push('أثاث')
  //   keywords.push('منزلي')
  //   keywords.push('مكتبي')
  //   keywords.push('مدرسي')
  //   keywords.push('خارجي')
  // }
  // else if(productSubCategory == 'كرسي'){
  //   keywords.push('كرسي')
  //   keywords.push('كراسي')
  //   keywords.push('منزلي')
  //   keywords.push('مكتبي')
  //   keywords.push('مدرسي')
  //   keywords.push('خارجي')
  //   keywords.push('قيمنق')
  //   keywords.push('العاب')
  // }
  // else if(productSubCategory == 'كنب'){
  //   keywords.push('كنب')
  //   keywords.push('كنبة')
  //   keywords.push('كنبه')
  //   keywords.push('كنبات')
  //   keywords.push('منزلي')
  //   keywords.push('مكتبي')
  //   keywords.push('مدرسي')
  //   keywords.push('خارجي')
  //   keywords.push('ثنائي')
  //   keywords.push('ثلاثي')
  //   keywords.push('رباعي')
  //   keywords.push('زاوية')
  // }
  // else if(productSubCategory == 'طاولة'){
  //   keywords.push('طاولة')
  //   keywords.push('طاوله')
  //   keywords.push('طاولات')
  //   keywords.push('مكتب')
  //   keywords.push('منزلي')
  //   keywords.push('منزلية')
  //   keywords.push('مكتبي')
  //   keywords.push('مكتبية')
  //   keywords.push('مدرسي')
  //   keywords.push('مدرسية')
  //   keywords.push('خارجي')
  //   keywords.push('خارجية')
  //   keywords.push('زاوية')
  //   keywords.push('خشب')
  //   keywords.push('خشبية')
  //   keywords.push('خشبيه')
  // }
  // else if(productSubCategory == 'ارفف'){
  //   keywords.push('ارفف')
  //   keywords.push('رف')
  //   keywords.push('منزلي')
  //   keywords.push('منزلية')
  //   keywords.push('مكتبي')
  //   keywords.push('مكتبية')
  //   keywords.push('مدرسي')
  //   keywords.push('مدرسية')
  //   keywords.push('خارجي')
  //   keywords.push('خارجية')
  //   keywords.push('خشب')
  //   keywords.push('خشبية')
  //   keywords.push('خشبيه')
  // }
  // else if(productSubCategory == 'دواليب و ادراج'){
  //   keywords.push('دولاب')
  //   keywords.push('دواليب')
  //   keywords.push('ادراج')
  //   keywords.push('درج')
  //   keywords.push('منزلية')
  //   keywords.push('منزليه')
  //   keywords.push('منزلي')
  //   keywords.push('مكتبي')
  //   keywords.push('مكتبية')
  //   keywords.push('مكتبيه')
  //   keywords.push('مدرسي')
  //   keywords.push('مدرسية')
  //   keywords.push('مدرسيه')
  //   keywords.push('خارجي')
  //   keywords.push('خارجية')
  //   keywords.push('خارجيه')
  //   keywords.push('خشب')
  //   keywords.push('خشبية')
  //   keywords.push('خشبيه')
  // }
  // else if(productSubCategory == 'لوحات فنية'){
  //   keywords.push('لوحة')
  //   keywords.push('لوحات')
  //   keywords.push('فنية')
  //   keywords.push('فنيه')
  //   keywords.push('جدارية')
  //   keywords.push('جداريه')
  //   keywords.push('جداريات')
  //   keywords.push('اثاث')
  //   keywords.push('منزلي')
  //   keywords.push('منزلية')
  //   keywords.push('منزليه')
  //   keywords.push('مكتبي')
  //   keywords.push('مكتبية')
  //   keywords.push('مكتبيه')
  //   keywords.push('داخلية')
  //   keywords.push('داخليه')
  // }
  // else if(productSubCategory == 'قطع غيار سيارات'){
  //   keywords.push('قطع غيار سيارات')
  //   keywords.push('قطع غيار')
  //   keywords.push('سيارة')
  //   keywords.push('سياره')
  // }
  // else if(productSubCategory == 'الات موسيقية'){
  //   keywords.push('الات موسيقية')
  //   keywords.push('الات موسيقيه')
  //   keywords.push('موسيقية')
  //   keywords.push('الة')
  //   keywords.push('اله')
  // }
  // #endregion
  if(productCategory == "ساعات"){
    if(!keywords.includes("ساعة")){
      keywords.push("ساعة")
    }
    if(!keywords.includes("ساعات")){
      keywords.push("ساعات")
    }
  }
  else if(productCategory == "سبح"){
    if(!keywords.includes("سبحة")){
      keywords.push("مسبحة")
    }
    if(!keywords.includes("مسباح")){
      keywords.push("مسباح")
    }
  }
  else if(productCategory == "اقلام"){
    if(!keywords.includes("قلم")){
      keywords.push("قلم")
    }
    if(!keywords.includes("أقلام")){
      keywords.push("أقلام")
    }
  }
  else if(productCategory == "ابواك"){
    if(!keywords.includes("بوك")){
      keywords.push("بوك")
    }
    if(!keywords.includes("محفظة")){
      keywords.push("محفظة")
    }
  }

  const coll = collection(db, "Posts");
  const snapshot = await getCountFromServer(coll);
  const docRef = doc(db, `Users/${auth.currentUser.uid}`)
  const docSnap = await getDoc(docRef)
  var newId = ++snapshot.data().count;

  keywords.push(docSnap.data()['city'])
  keywords.push(newId.toString())

  const d = [];

  // #region
  // if(productSubCategory == 'جوال'){
  //   d.push({
  //     productName: productName,
  //     productDescription: productDescription,
  //     category: productCategory,
  //     subCategory: productSubCategory,
  //     productPrice: parseFloat(productPrice),
  //     condition: productCondition,
  //     imgs: urls,
  //     expiryDate: productExpiryDate,
  //     id: newId.toString(),
  //     numBidders: 0,
  //     seller: {
  //       sellerImg: docSnap.data()['profileImgUrl'],
  //       sellerRating: parseFloat(docSnap.data()['rating']), 
  //       sellerName: docSnap.data()['fullName'],
  //       sellerId: auth.currentUser.uid,
  //       sellerNumRaters: docSnap.data()['numRaters']
  //     },
  //     createdAt: serverTimestamp(),
  //     currentBid: 0,
  //     acceptsOffers: acceptsOffers,
  //     startingPrice: parseInt(startingPrice),
  //     productCompany: productCompany,
  //     heighestBidder: "",
  //     productKind: productKind,
  //     productStorageSize: productStorageSize,
  //     keywords: keywords,
  //     isDeleted: false, 
  //     deletedDate : "",
  //     isUpdated: false,
  //     lastUpdated: "",
  //     isSold: false,
  //     startingPrice: startingPrice,
  //     bidders: {},
  //     isReceived: false,
  //     receivedDate: "",
  //     soldDate: ""
  //   })
  // }

  // else if(productSubCategory == 'شاشة'){
  //   d.push(
  //     {
  //       productName: productName,
  //       productDescription: productDescription,
  //       category: productCategory,
  //       subCategory: productSubCategory,
  //       productPrice: parseFloat(productPrice),
  //       condition: productCondition,
  //       imgs: urls,
  //       expiryDate: productExpiryDate,
  //       id: newId.toString(),
  //       numBidders: 0,
  //       seller: {
  //         sellerImg: docSnap.data()['profileImgUrl'],
  //         sellerRating: parseFloat(docSnap.data()['rating']), 
  //         sellerName: docSnap.data()['fullName'],
  //         sellerId: auth.currentUser.uid,
  //         sellerNumRaters: docSnap.data()['numRaters']
  //       },
  //       createdAt: serverTimestamp(),
  //       currentBid: 0,
  //       acceptsOffers: acceptsOffers,
  //       startingPrice: parseInt(startingPrice),
  //       productCompany: productCompany,
  //       productKind: productKind,
  //       heighestBidder: "",
  //       productScreenSize: productScreenSize,
  //       keywords: keywords,
  //       isDeleted: false, 
  //       isUpdated: false,
  //       isSold: false,
  //       bidders: {},
  //       isReceived: false,
  //       receivedDate: "",
  //       soldDate: ""
  //     }
  //   )
  // }





  // else if(productSubCategory == 'لابتوب'){
  //   d.push(
  //     {
  //       productName: productName,
  //       productDescription: productDescription,
  //       category: productCategory,
  //       subCategory: productSubCategory,
  //       productPrice: parseFloat(productPrice),
  //       condition: productCondition,
  //       imgs: urls,
  //       expiryDate: productExpiryDate,
  //       id: newId.toString(),
  //       numBidders: 0,
  //       seller: {
  //         sellerImg: docSnap.data()['profileImgUrl'],
  //         sellerRating: parseFloat(docSnap.data()['rating']), 
  //         sellerName: docSnap.data()['fullName'],
  //         sellerCity: docSnap.data()['city'] != "" ? docSnap.data()['city'] : "", 
  //         sellerId: auth.currentUser.uid
  //       },
  //       createdAt: serverTimestamp(),
  //       currentBid: 0,
  //       acceptsOffers: acceptsOffers,
  //       startingPrice: parseInt(startingPrice),
  //       productCompany: productCompany,
  //       productKind: productKind,
  //       productScreenSize: productScreenSize,
  //       productMemorySize: productMemorySize,
  //       keywords: keywords,
  //       isDeleted: false, 
  //       isUpdated: false,
  //       bidders: {},
  //       isSold: false,
  //       isReceived: false,
  //       receivedDate: "",
  //       soldDate: ""

  //     }
  //   )
  // }
  // else if(productSubCategory == 'جهاز لوحي'){
  //   d.push(
  //     {
  //       productName: productName,
  //       productDescription: productDescription,
  //       category: productCategory,
  //       subCategory: productSubCategory,
  //       productPrice: parseFloat(productPrice),
  //       condition: productCondition,
  //       imgs: urls,
  //       expiryDate: productExpiryDate,
  //       id: newId.toString(),
  //       numBidders: 0,
  //       seller: {
  //         sellerImg: docSnap.data()['profileImgUrl'],
  //         sellerRating: parseFloat(docSnap.data()['rating']), 
  //         sellerName: docSnap.data()['fullName'],
  //         sellerCity: docSnap.data()['city'] != "" ? docSnap.data()['city'] : "",
  //         sellerId: auth.currentUser.uid
  //       },
  //       createdAt: serverTimestamp(),
  //       currentBid: 0,
  //       acceptsOffers: acceptsOffers,
  //       startingPrice: parseInt(startingPrice),
  //       productCompany: productCompany,
  //       productKind: productKind,
  //       productMemorySize: productMemorySize,
  //       keywords: keywords,
  //       isDeleted: false, 
  //       isUpdated: false,
  //       bidders: {},
  //       isSold: false,
  //       isReceived: false,
  //       receivedDate: "",
  //       soldDate: ""
  //     }
  //   )
  // }
  // else if(productSubCategory == 'طابعة'){
  //   d.push(
  //      {
  //       productName: productName,
  //       productDescription: productDescription,
  //       category: productCategory,
  //       subCategory: productSubCategory,
  //       productPrice: parseFloat(productPrice),
  //       condition: productCondition,
  //       imgs: urls,
  //       expiryDate: productExpiryDate,
  //       id: newId.toString(),
  //       numBidders: 0,
  //       seller: {
  //         sellerImg: docSnap.data()['profileImgUrl'],
  //         sellerRating: parseFloat(docSnap.data()['rating']), 
  //         sellerName: docSnap.data()['fullName'],
  //         sellerNumRaters: docSnap.data()['numRaters'],
  //         sellerId: auth.currentUser.uid
  //       },
  //       createdAt: serverTimestamp(),
  //       currentBid: 0,
  //       acceptsOffers: acceptsOffers,
  //       startingPrice: parseInt(startingPrice),
  //       productCompany: productCompany,
  //       productKind: productKind,
  //       productInkKind: productInkKind,
  //       keywords: keywords,
  //       isDeleted: false, 
  //       isUpdated: false,
  //       bidders: {},
  //       isSold: false,
  //       isReceived: false,
  //       receivedDate: "",
  //       soldDate: ""
  //     }
  //   )
  // }
//#endregion
  // else{
    // await uploadImagesToStorageAndFirestore(images, newId)
  d.push(
        {
          productName: productName,
          productDescription: productDescription,
          category: productCategory,
          subCategory: productSubCategory,
          productPrice: parseFloat(productPrice),
          condition: productCondition,
          expiryDate: productExpiryDate,
          id: newId.toString(),
          numBidders: 0,
          seller: {
            sellerImg: docSnap.data()['profileImgUrl'],
            sellerRating: parseFloat(docSnap.data()['rating']), 
            sellerName: docSnap.data()['fullName'],
            sellerNumRaters: docSnap.data()['numRaters'],
            sellerId: docSnap.data()['id'],
            sellerCity: docSnap.data()['city'],
            sellerEmail: docSnap.data()['email'],
          },
          createdAt: serverTimestamp(),
          currentBid: 0,
          acceptsOffers: acceptsOffers,
          startingPrice: parseInt(startingPrice),
          productCompany: productCompany,
          productKind: productKind,
          keywords: keywords,
          isDeleted: false, 
          isUpdated: false,
          bidders: {},
          isSold: false,
          isReceived: false,
          receivedDate: "",
          soldDate: "",
          views: 0,
          productWeight: parseFloat(productWeight),
          imgs: await uploadImagesToStorageAndFirestore(images, newId),
          isRejected: false,
          lastBidDate: "",
          updatedDate: "",
          bidIncrements: parseInt(bidIncrements),
          numBids: 0
        }
      )
  // }

  // await uploadImagesToStorageAndFirestore(images, newId).then(async ()=>{
    // uploadImagesToStorageAndFirestore(images, newId)
    await setDoc(doc(db, "Posts", `post${newId.toString()}`), d[0]).then(()=>{
      setProductName("")
      setProductDescription("")
      setProductPrice("")
      setProductCondition("")
      setProductCategory("")
      setProductSubCategory("")
      setProductExpiryDate("")
      setProductCompany("")
      setProductKind("")
      // setProductInkKind("")
      setImages([])
      setGal([])
      setBlobImgs([])
      setAcceptsOffers(false)
    })
  // })

      //#region 
  // await setDoc(doc(db, "Posts", `post${newId.toString()}`), d[0]).then(()=>{
  //   uploadImagesToStorageAndFirestore(images, newId)
  //   .then((downloadURLs) => {
  //     // Handle success

  //     console.log('Images uploaded successfully:', downloadURLs);
  //   })
  //   .catch((error) => {
  //     // Handle error
  //     console.error('Error uploading images:', error);
  //   });
  // });

  // ####################################################################

  // blobs

  //   const docData = {
  //   productName: productName,
  //   productDescription: productDescription,
  //   category: productCategory,
  //   subCategory: productSubCategory,
  //   productPrice: productPrice,
  //   productCondition: productCondition,
  //   imgs: blobImgs,
  //   expiryDate: productExpiryDate,
  //   id: "testPost",
  //   currentBid: "0"
  // };
  // setDoc(doc(db, "Posts", "testPost2"), docData);



  // ############################################################

  // const base64urls =[];
  // for(var i = 0 ; i < images.length ; i++){
  //   await getBase64(images[i]) // `file` your img file
  //   .then(res => {
  //     base64urls.push(res);
  //     console.log(res)
  //     console.log(res.length)
  //   }) // `res` base64 of img file
  //   .catch(err => console.log(err))
  // }

  
  //   const docData = {
  //   productName: productName,
  //   productDescription: productDescription,
  //   category: productCategory,
  //   subCategory: productSubCategory,
  //   productPrice: productPrice,
  //   productCondition: productCondition,
  //   imgs: base64urls,
  //   expiryDate: productExpiryDate,
  //   id: "testPost",
  //   currentBid: "0"
  // };
  // await setDoc(doc(db, "Posts", "testPost2"), docData);
//#endregion
  setIsLoading(false);
}}> اضافة المنتج </button>
</div>
            </div>

        </div>
    </>
   );
  }

export default AddPostScreen;