import React , {useState, useEffect, useRef} from 'react'
import Category from '../components/Category';
import ElectronicsImg from '../assets/electronics.avif';
import FurnitureImg from '../assets/furniture.jpg';
import autoParts from '../assets/auto-parts.jpg';
import musicInstruments from '../assets/music-instruments.jpeg';
import CustomNavbar from '../components/CustomNavbar';
import { initializeApp } from 'firebase/app';
import { getFirestore, collection, getDocs ,onSnapshot, serverTimestamp ,query, where,getDoc, updateDoc, limit,getCountFromServer ,doc, setDoc, Timestamp, FieldValue } from 'firebase/firestore';
import Product from '../components/Product';
import Button from '@mui/material/Button';
import { Select, MenuItem } from '@mui/material';
import { Dropdown, DropdownMenu, DropdownToggle } from 'react-bootstrap';
// Follow this pattern to import other Firebase services
// import { } from 'firebase/<service>';
import { Divider } from '@mui/material';
import { PiGavelFill } from "react-icons/pi";
import { MdOutlineWatchLater } from "react-icons/md";
import { AiOutlineHeart } from "react-icons/ai";
import { IoIosWarning } from "react-icons/io";
import {useNavigate, useLocation, useSearchParams, useParams, Link} from "react-router-dom";
import '../../node_modules/react-image-gallery/styles/css/image-gallery.css';
import { AiFillStar } from "react-icons/ai";
import { ref, uploadBytesResumable, getDownloadURL, listAll} from "firebase/storage";
import { MdVerifiedUser } from "react-icons/md";
import { FaLocationDot } from "react-icons/fa6";
import Comment from '../components/Comment';
import { HiOutlinePencil } from "react-icons/hi";
import { Oval } from 'react-loader-spinner'
import { FaChevronDown } from "react-icons/fa";
import SignInOrSignUpScreen from './SignInOrSignUpScreen';
import { db, auth, storage } from '../config/firebase'
import galleryPlaceholder from '../assets/galley-placeholder.jpg'

function MyAccountScreen() {
    const [Listing, setListing] = useState([]);

    const [isLoading, setLoading] = useState(true);
    const [email, setEmail] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [fullName, setFullName] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [originalImg, setOriginalImg] = useState("");
    const [balance, setBalance] = useState();
    const [feeBalance, setFeeBalance] = useState();
    const [blobImg , setBlobImg] = useState("");
    const [city, setCity] = useState("")
    const [isVerified, setIsVerified] = useState(false);
    const [userRating, setUserRating] = useState(0);
    const [numRaters, setNumRaters] = useState(0);
    const [comment, setComment] = useState("");
    const [newEmail, setNewEmail] = useState("")
    const [iban, setIban] = useState("")
    const [newIban, setNewIban] = useState("")
    const [userId, setUserId] = useState("")
    const [comments, setComments] = useState([]);
    const [commentsLoading, setCommentsLoading] = useState(true);
    const [bank, setBank] = useState(true);
    const [newBank, setNewBank] = useState(true);

    const firstNameRef = useRef();
    const lastNameRef = useRef();
    const fullNameRef = useRef();
    const cityRef = useRef();
    const commentRef = useRef();
    const emailRef =useRef();
    const ibanRef =useRef();
    const bankRef =useRef();
    
    const navigate = useNavigate();
    
    const newProfilePictureRef = useRef();
    const [profilePictureUpdated, setProfilePictureUpdated] = useState(false);
    const [newUrl, setNewUrl] = useState("");
    const [phoneNumberUpdated, setPhoneNumberUpdated] = useState(false);

    
  const fetchComments = async () => {
    try {
      setCommentsLoading(true)
      const postRef = doc(db, 'Users', auth.currentUser.uid);
      const postDoc = await getDoc(postRef);

      
      if (postDoc.exists()) {
        const postData = postDoc.data();
        const commentsData = postData.commentsOnTheUser || [];
        
        // Fetch user data for each comment
        const commentsWithUserData = await Promise.all(
          commentsData.map(async (comment) => {
            const userRef = doc(db, 'Users', comment.userId);
            const userDoc = await getDoc(userRef);
            const userData = userDoc.exists() ? userDoc.data() : null;

            console.log(userData)
            return {
              ...comment,
              user: userData,
            };
          })
        );

        setComments(commentsWithUserData);
      } else {
        console.error('Post not found');
      }
    } catch (error) {
      console.error('Error fetching comments:', error);
    }
    setCommentsLoading(false)
  };



  useEffect(() => {

    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'instant'
    });


    const unsubscribe = auth.onAuthStateChanged(async function (user) {
      if (user != null) {
        try {
          // const imageRef = ref(storage, `Users/${auth.currentUser.uid}/Profile Picture`); // Adjust the path based on your storage structure

          // getDownloadURL(imageRef)
          //   .then((url) => {
          //     setOriginalImg(url);
          //   })
          //   .catch((error) => {
          //     console.error('Error fetching image:', error);
          //   });


          const docRef = doc(db, 'Users', user.uid);
  
          // Use onSnapshot to listen for changes in the user document
          const unsubscribeUser = onSnapshot(docRef, (docu) => {
            if (docu.exists()) {
              const userData = docu.data();
              setEmail(userData.email);
              setFirstName(userData.firstName);
              setLastName(userData.lastName);
              setFullName(userData.fullName);
              setPhoneNumber(userData.phoneNumber);
              setOriginalImg(userData.profileImgUrl);
              setBalance(userData.balance);
              setCity(userData.city);
              setUserId(userData.id)
              setFeeBalance(userData.feeBalance);
              setIsVerified(userData.isVerified);
              setIban(userData.iban)
  
              // Fetch user's comments
              fetchComments(user.uid);
  
              // Set loading to false here, after the data is fetched
              setLoading(false);
            } else {
              console.error('User data not found');
              // Set loading to false in case of an error
              setLoading(false);
            }
          });
          // Use onSnapshot to listen for changes in the Posts collection
          const unsubscribePosts = onSnapshot(
            query(collection(db, 'Posts'), where('seller.sellerId', '==', userId)),
            (querySnapshot) => {
              if (!querySnapshot.empty) {
                const posts = [];
                querySnapshot.forEach((doc) => {
                  if (!doc.data()['isDeleted']) {
                    posts.push(doc.data());
                  }
                });
  
                setListing(posts.sort((a, b) => b.createdAt - a.createdAt));
              } else {
                setListing([]);
              }
            },
            (err) => {
              console.error('Error fetching posts:', err);
              // Set loading to false in case of an error
              setLoading(false);
            }
          );
  
          // Return a cleanup function to unsubscribe when the component unmounts
          return () => {
            unsubscribeUser();
            unsubscribePosts();
          };
        } catch (error) {
          console.error('Error fetching user data:', error);
          // Set loading to false in case of an error
          setLoading(false);
        }
      } else {
        // Set loading to false if the user is null
        setLoading(false);
      }
    });
  }, [userId, originalImg]);

    
      function formatAccountingArabic(number) {
        // Check if the input is a valid number
        if (typeof number !== 'number' || isNaN(number)) {
          return 'Invalid input';
        }
      
        // Use toLocaleString to format the number with commas and two decimal places
        const formattedNumber = number.toLocaleString('en', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });
      
        // Add the Arabic currency symbol manually
        const arabicFormattedNumber = `${formattedNumber} ر.س`;
      
        return arabicFormattedNumber;
    }
      
    if(isLoading){
      return <div style={{
        width: "100vw",
        height: "80vh",
        marginTop: "55px",
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        }}>
          <Oval
                height={"50px"}
                width={"50px"}
                color={"dodgerblue"}
                wrapperClass=""
                visible={true}
                wrapperStyle={{display: "block"}}
                ariaLabel='oval-loading'
                secondaryColor="#ccc"
                strokeWidth={1}
                strokeWidthSecondary={1}
              />
        </div>
      }

    if(auth.currentUser === null ){
      return <SignInOrSignUpScreen prompt={"الرجاء التسجيل او تسجيل الدخول لرؤية هذه الصفحة"} />
     }

  return ( 
    
    <>
    <input ref={newProfilePictureRef} onChange={(value)=>{
      setBlobImg(URL.createObjectURL(value.target.files[0]))
      setProfilePictureUpdated(true);
                }} type='file' accept='image/*' style={{display: "none", margin: "8px"}}/>
    <div style={{
      marginTop: "10px",
      marginBottom: "65px",
      }}
      >


<div style={{ display: "flex", direction: "rtl", width: "65%", marginLeft: "auto", marginRight: "auto", textAlign: "center", justifyContent: "center",}}>
  <div>
    <div onClick={()=>{
                    newProfilePictureRef.current.click();
                }} style={{
                  position: "relative",
                  width: "fit-content",
                  outline: "none",
                  cursor: "pointer", 
                  marginRight: "auto",
                  marginLeft: "auto",
                  border:"none"
                  }}>
               
               
               
                <img className='skeleton' style={{width: "180px", opacity: 1 ,height: "180px", borderRadius: "1000px", objectFit: "cover"}} src={blobImg == "" ? originalImg : blobImg} />
               
               
                {/* <HiOutlinePencil style={{width: "20px", height: "20px", bottom: 12, right: 32, position: "absolute", color: "#ccc", display: "block"}}/> */}
                </div>
    <div style={{ display: "flex", flexDirection: "column", alignItems: "center", marginTop: "8px",width: "100%", position: "relative" }}>
                    { 
                    blobImg != "" &&
                    <button id='my-button-2' style={{marginBottom: "8px"}} onClick={()=>{
                      setBlobImg("")
            newProfilePictureRef.current.value = ""; 
            setProfilePictureUpdated(false);
            setBlobImg("")}}>ازالة الصورة</button>}
      <div style={{ 
        display: "flex", 
        alignItems: "center",
        position:"relative",
        justifyContent:"center"
        }}>
          <input 
          onChange={(e)=>{
              setFullName(e.target.value)
          }}
          ref={fullNameRef} 
          style={{
            borderRadius: "8px", 
            border: "none", 
            direction: "rtl", 
            height: "34px", 
            paddingRight: "8px", 
            border: "1px solid #f0f0f0", 
            width: "90%", 
            outline: "none"
            }} type='text' defaultValue={fullName}/>
          {isVerified && <MdVerifiedUser size={25}  style={{position: "relative"}} color='dodgerblue'/>}
      </div>
      <div style={{display: "flex", marginTop:"8px"}}>
        <AiFillStar size={20} style={{marginTop: "3px"}} color='gold' />
<p style={{marginBottom: "0px"}}>{userRating}</p>
<p style={{marginBottom: "0px", marginRight: "4px"}}>({numRaters})</p>
      </div>
      

        <div style={{
          direction: "rtl", 
          display: "flex", 
          justifyContent: "center", 
          alignItems: "center",
          width: "100%", 
          marginTop:"8px", 
          position:"relative"
          }}>
<FaLocationDot size={16} color='red' style={{right: "8px", top:"8px", position: "absolute"}}/>
<FaChevronDown size={12} color='black' style={{left: "8px", top:"13px", position: "absolute"}}/>

<select
  ref={cityRef}
  style={{
    display: "block",
    width: "100%",
    borderRadius: "8px",
    backgroundColor: "white",
    border: "1px solid #f0f0f0",
    direction: "rtl",
    height: "34px",
    color: "black",
    paddingRight: "28px",
    marginBottom: "16px",
    WebkitAppearance: "initial",
    outline: "none"
  }}
  defaultValue={city == "" ? "" : city}
  onChange={(value) => {
    setCity(value.target.value);
  }}
>
  <option hidden value="" >المدينة</option>
  <option value="الرياض">الرياض</option>
  <option value="جدة">جدة</option>
  <option value="الدمام">الدمام</option>
  <option value="الخبر">الخبر</option>
  <option value="الظهران">الظهران</option>
</select>

      </div>

<div style={{display: "flex", width: "100%", position: "relative"}}>
<input 
placeholder='SA0000000000000000000000'
        onChange={(e)=>{
            setNewIban(e.target.value)
        }}
         ref={ibanRef} style={{borderRadius: "8px", border: "none", direction: "ltr", height: "40px", paddingRight: "8px", paddingLeft: "8px" ,border: "1px solid #f0f0f0", width: "100%", marginLeft:"4px", outline: "none"}} type='text' defaultValue={iban }/>
         
<select
  ref={bankRef}
  style={{
    display: "block",
    width: "100%",
    borderRadius: "8px",
    backgroundColor: "white",
    border: "1px solid #f0f0f0",
    direction: "rtl",
    height: "40px",
    color: "black",
    paddingRight: "8px",
    paddingLeft: "24px",
    WebkitAppearance: "initial",
    outline: "none"
  }}
  defaultValue={bank == "" ? "" : bank}
  onChange={(value) => {
    setBank(value.target.value);
  }}
>
  <option hidden value="" >البنك</option>
  <option value="الأهلي" >الأهلي</option>
  <option value="بنك ساب">بنك ساب</option>
  <option value="مصرف الإنماء">مصرف الإنماء</option>
  <option value="البنك السعودي الفرنسي">البنك السعودي الفرنسي</option>
  <option value="بنك الرياض">بنك الرياض</option>
  <option value="مصرف الراجحي">مصرف الراجحي</option>
  <option value="البنك العربي الوطني">البنك العربي الوطني</option>
  <option value="بنك البلاد">بنك البلاد</option>
  <option value="بنك الجزيرة">بنك الجزيرة</option>
  <option value="البنك السعودي للاستثمار">البنك السعودي للاستثمار</option>
</select>
<FaChevronDown size={12} color='black' style={{left: "8px", top:"13px", position: "absolute"}}/>
</div>
         <p style={{color:"GrayText", fontSize:"13px"}}>قم بإضافة الأيبان لنقوم بتحويل الرصيد لحسابك البنكي</p>


<input 
placeholder='البريد الالكتروني'
        onChange={(e)=>{
            setNewEmail(e.target.value)
        }}
         ref={emailRef} style={{borderRadius: "8px", border: "none", direction: "rtl", height: "40px", paddingRight: "8px", border: "1px solid #f0f0f0", width: "100%", marginLeft:"4px", outline: "none"}} type='email' defaultValue={newEmail != "" ? newEmail : email }/>
         <p style={{color:"GrayText", fontSize:"13px"}}>قم بإضافة بريدك الإلكتروني لتصلك إشعارات مثل اذا قام شخص بإضافة تعليق على منتجك</p>
      <div style={{marginTop: "8px", direction:"rtl", display:"flex", marginBottom: "8px"}}>
                    <p style={{marginBottom: "0px", fontSize:"16px"}}>رصيدي:</p>
                    <p style={{marginRight:"4px", marginBottom: "0px", fontWeight:"bold", fontSize: "16px", marginTop:"1px"}}>{formatAccountingArabic(balance)}</p>
                    {/* <p style={{marginRight:"4px", marginBottom: "0px"}}>ر.س</p> */}
      </div>
      {/* <div style={{marginTop: "8px", direction:"rtl", display:"flex", marginBottom: "8px"}}>
                    <p style={{marginBottom: "0px"}}>رصيد العربونات:</p>
                    <p style={{marginRight:"4px", marginBottom: "0px", fontWeight:"bold", fontSize: "18px"}}>{formatAccountingArabic(feeBalance)}</p>
                     <p style={{marginRight:"4px", marginBottom: "0px"}}>ر.س</p> 
      </div> */}
      <div style={{direction:"rtl", display:"flex" }}>
      <p style={{marginBottom: "0px", fontSize:"16px"}}>رقم الجوال:</p>
      {/* show as input later for updating the phone number */}
     <p style={{marginRight:"4px", marginBottom: "0px", fontSize: "16px", direction: "ltr", marginTop:"1px"}}>{phoneNumber}</p>
      </div>


    </div>          
  </div>
</div>

<div style={{ 
  width:"100%", 
  justifyContent:"center", 
  alignItems:"center", 
  textAlign:"center", 
  marginTop:"24px", 
  display:"flex", 
  flexDirection:"column"
  }}>
<button
  id='my-button'
  style={{
    width:"200px",
    height:"35px",
    borderRadius:"6px"
  }}
  onClick={async () => {
    try {
      setLoading(true);
      setBlobImg("")

      const washingtonRef = doc(db, "Users", auth.currentUser.uid);

      // Update profile picture if changed
      if (profilePictureUpdated) {
        const desertRef = ref(storage, `Users/${auth.currentUser.uid}/Profile Picture`);
        await uploadBytesResumable(desertRef, newProfilePictureRef.current.files[0]);
        const url = await getDownloadURL(desertRef);
        await updateDoc(washingtonRef, {
          profileImgUrl: url,
        });
        setNewUrl(url);
        setOriginalImg(url);
      }

      // Update user information
      await updateDoc(washingtonRef, {
        fullName: fullName,
        city: city,
        email: newEmail !== "" ? newEmail : email,
      });

      // Update name on posts
      const q = query(collection(db, "Posts"), where("seller.sellerId", "==", userId));
      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        await Promise.all(
          querySnapshot.docs.map(async (docu) => {
            const postRef = doc(db, "Posts", docu.id);
            await updateDoc(postRef, {
              'seller.sellerName': fullName,
              'seller.sellerImg': newUrl !== "" ? newUrl : originalImg,
              'seller.sellerCity': city,
              'seller.sellerEmail': newEmail !== "" ? newEmail : email,
            });
          })
        );
      }
      setLoading(false);
    } catch (error) {
      console.error("Error updating information:", error);
      // Set loading to false in case of an error
      setLoading(false);
    }
  }}
>
  تحديث المعلومات
</button>

</div>
        
<div id='product-details-comment-section'

style={{marginTop: "32px"}}
>
{
     ! commentsLoading ?
    comments.length > 0 ? (
      <>
        <h3 style={{ marginBottom: "16px" }}>التعليقات</h3>
        {comments.map((comment) => (
          <Comment
            key={comment.timestamp} // Make sure to add a unique key
            comment={comment.comment}
            userRating={comment.user.rating}
            numRaters={comment.user.numRaters}
            userImg={comment.user.profileImgUrl}
            commentId={comment.id}
            isUpdated={comment.isUpdated}
            userName={comment.user.fullName}
            timestamp={comment.timestamp}
            userId={comment.userId}
            link={`/user/${comment.user.id}`}
          />
        ))}
      </>
    ) : (
      <p style={{fontSize: "18px", marginRight: "8px"}}>لا توجد تعليقات حتى الآن</p>
    )
    : 
    <div style={{
      marginTop: "55px",
      display: 'flex',
      width: "fit-content",
      marginRight: "8px",
  alignItems: 'flex-start',
  justifyContent: 'flex-start',

    }}>
      <Oval
            height={"30px"}
            width={"30px"}
            color={"dodgerblue"}
            wrapperClass=""
            visible={true}
            wrapperStyle={{display: "block"}}
            ariaLabel='oval-loading'
            secondaryColor="#ccc"
            strokeWidth={1}
            strokeWidthSecondary={1}
          />
    </div>
    }


</div>

<div id='products-screen-div' style={{alignItems: "flex-start", marginRight: "18px", position: "relative", height: "fit-content"}}>
  <Divider
    style={{ borderBottomWidth: 1, width: "100%" }}
    color="#ccc"
  />

{Listing.length > 0 ? (
  <>

<div style={{width:"100%"}}>
<p style={{fontSize: "18px", marginRight: "16px", marginTop:"16px", textAlign:"right", fontWeight:"600"}}>منتجاتي</p>
  
  </div>    
<div style={{display:"grid", gap: "4px", width:"100%", height: "fit-content", gridTemplateColumns: window.innerWidth > 600 ? "repeat(auto-fill, minmax(30%, 1fr))" : "repeat(auto-fill, minmax(40%, 1fr))", alignItems: "center", textAlign:"center"}}>
  {Listing.map((item) => (
    <div key={item.id}>
      {/* Your Product component goes here */}
      <Product
        postId={item.id}
        expiryDate={`${item.expiryDate.seconds}`}
        link={`/product/${item.id}`}
        numBidders={Object.keys(item.bidders).length}
        currentBid={item.currentBid}
        imgs={item.imgs[0]}
        condition={item.condition}
        acceptsOffers={item.acceptsOffers}
        productPrice={item.productPrice}
        productDescription={item.productDescription}
        productSeller={item.seller}
        sellerId={item.seller['id']}
        startingPrice={item.startingPrice}
        sellerCity={item.seller}
        productName={item.productName}
      />
    </div>
  ))}
</div>
  </>
) : (
  <p style={{marginBottom: "85px"}}>عندما تضيف منتجات ستظهر هنا، حيث يمكنك تعديلها</p>
)}


        </div>
    </div>
    <div style={{
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      width: "100%"
    }}>
    <button
             id='my-button' 
             style={{
              backgroundColor:"#ff4942", 
              marginTop:"12px",     
             width:"200px",
             height:"35px",
             marginBottom:"16px",
             borderRadius:"6px"}}
            onClick={()=>{
                navigate("/")
                auth.signOut();
            }}
        >تسجيل خروج</button>
    </div>
    </>
    // <div
    // style={{
    //     marginTop: "55px",
    //     marginBottom: "100px"
    // }}
    // >

    //     <img style={{width: "200px", height: "200px", borderRadius: "1000px", objectFit: "cover"}} src={ blobImg == "" ? originalImg : blobImg} />
    //     <h4>{fullName}</h4>
    //     <input 
    //     onChange={()=>{
    //         setFirstName(firstNameRef.current.value)
    //     }}
    //      ref={firstNameRef} style={{display: "block"}} type='text' defaultValue={firstName}/>
    //     <input 
    //     onChange={()=>{
    //         setLastName(lastNameRef.current.value)
    //     }}
    //     ref={lastNameRef} style={{display: "block"}} type='text' defaultValue={lastName}/>
    //        <label>
    //         تغيير صورة العرض
    //         <input ref={newProfilePictureRef} onChange={(value)=>{
    //           setBlobImg(URL.createObjectURL(value.target.files[0]))
    //           setProfilePictureUpdated(true);
    //         }} type='file' accept='image/*' style={{display: "block"}} placeholder='صورة العرض' />
    //        </label>
    //     <button style={{display:"block"}} onClick={()=>{
    //         newProfilePictureRef.current.value = ""; 
    //         setProfilePictureUpdated(false);
    //         setBlobImg("")}}>ازالة الصورة</button>

    //     <p>رصيدي {balance}</p>
    //     <p>رقم الجوال {phoneNumber}</p>

    //     <Link to='/my_posts'>منشوراتي</Link>

    //     <br/>

    //     <div style={{
    //                 display: "flex",
    //                 justifyContent: "center",
    //                 marginTop: "4px"
    //             }}>
    //                 <select ref={cityRef} defaultValue={city != "" ? city : ""} style={{display: "block", width: "247px",borderRadius: "8px", border: "none", direction: "rtl", height: "40px", paddingRight: "8px", border: "1px solid #f0f0f0", marginBottom: "16px"}} onChange={(value)=>{
    //                   setCity(value.target.value);
    //                 }}>
    //                     <option hidden>المدينة</option>
    //                     <option style={{backgroundColor: "white"}} value="الرياض">الرياض</option>
    //                     <option style={{backgroundColor: "white"}} value="جدة">جدة</option>
    //                     <option style={{backgroundColor: "white"}} value="الدمام">الدمام</option>
    //                     <option style={{backgroundColor: "white"}} value="الخبر">الخبر</option>
        
    //                 </select>

    //             </div>

    //     <button
    //     onClick={async ()=>{

    //         // error checking

    //         const washingtonRef = doc(db, "Users", auth.currentUser.uid);
    //         setLoading(true);


            
    //         // if the pp is changed 
    //         if(profilePictureUpdated){
    //             const desertRef = ref(storage, `Users/${auth.currentUser.uid}`);
    //             // deleteObject(desertRef).then(() => {
    //             // }).catch((error) => {
    //             //     // Uh-oh, an error occurred!
    //             // });
    //             await uploadBytesResumable(desertRef, newProfilePictureRef.current.files[0]).then(async ()=>{
    //                 await getDownloadURL(desertRef).then(async (url)=>{
    //                     await updateDoc(washingtonRef, {
    //                         profileImgUrl: url
    //                     });
    //                     setNewUrl(url);
    //                 })
    //             })
    //         }
             
    //         await updateDoc(washingtonRef, {
    //             firstName: firstName,
    //             lastName: lastName,
    //             fullName: `${firstName} ${lastName}`
    //         });
            
    //         // update name on posts
    //         const q = query(collection(db, "Posts"), where("seller.sellerId", "==", auth.currentUser.uid));
    //         const querySnapshot = await getDocs(q);
    //         if(!querySnapshot.empty){
    //             querySnapshot.forEach(async (docu) => {
    //                 const postRef = doc(db, "Posts", docu.id);
    //                 await updateDoc(postRef, {
    //                 'seller.sellerName': `${firstName} ${lastName}`,
    //                 'seller.sellerImg': newUrl != "" ? newUrl : originalImg
    //                 });
    //             });
    //         }
    //         else{
    //             console.log("no posts yet")
    //         }
            
    //         setLoading(false);
    //     }}  
    //     >تحديث المعلومات</button>
    //     <button
    //     onClick={()=>{
    //         navigate("/")
    //         auth.signOut();
    //     }}
    //     >تسجيل خروج</button>

    // </div>
   );
  }

export default MyAccountScreen;