import React , {useState, useEffect, useRef} from 'react'
import { initializeApp } from 'firebase/app';
import { getFirestore, collection, onSnapshot, query, where} from 'firebase/firestore';
import Product from '../components/Product';
import { Divider } from '@mui/material';
import {useNavigate, useSearchParams} from "react-router-dom";
import { Oval } from 'react-loader-spinner'
import SignInOrSignUpScreen from './SignInOrSignUpScreen';
import {db, auth} from '../config/firebase'

function MyBidsScreen() {

    const [Listing, setListing] = useState([]);
    const [myBids, setMyBids] = useState([]);
    const [myPurchases, setMyPurchases] = useState([]);
    const [filteredCategory, setFilteredCategory] = useState("");
    const [qu, setQu] = useState(query(collection(db, "Posts"), where("isDeleted", "==", false)));
    const [lastDoc, setLastDoc] = useState("");
    const listInnerRef = useRef();
    const [hasMore, setHasMore] = useState(false);
    const [isLoading, setLoading] = useState(true);
    const [displayMenu, setDisplayMenu] = useState(false);
    // const que = query(ref(database, 'posts', orderByChild("postId"), equalTo("3")));

    const [conditionSelected, setConditionSelected] = useState("");
    const [orderSelected, setOrderSelected] = useState("");
    const [orderSelectedName, setOrderSelectedName] = useState("");
    const [orderSelectedFun, setOrderSelectedFun] = useState("");
    const [filterSelected, setFilterSelected] = useState("");
    const [productKindSelected, setProductKindSelected] = useState("");
    const [postKind, setPostKind] = useState("");
    
    
    const [lastKey, setLastKey] = useState("");
    const [lastValue, setLastValue] = useState("");
    
    const [ searchParams, setSearchParams ] = useSearchParams();

    const [sortedNewestToOldest, setSortedNewestToOldest] = useState(false);
    const [sortedOldestToNewest, setSortedOldestToNewest] = useState(false);
    const [sortedByProductPriceHeighestToLowest, setSortedByProductPriceHeighestToLowest] = useState(false);
    const [sortedByProductPriceLowestToHeighest, setSortedByProductPriceLowestToHeighest] = useState(false);



    const [myBidsSelected, setMyBidsSelected] = useState(true);
    const [myPurchasesSelected, setMyPurchasesSelected] = useState(false);


    const [hasOrderSelected, setHasOrderSelected] = useState(false);
    const [orderBySelected, setOrderByName] = useState("");

    const res = searchParams.get("search")

    const navigate = useNavigate();
    

    // let numProducts;

    
    
    const [numProducts, setNumProducts] = useState("");
    
    
    
    useEffect(() => {
      const unsubscribe = auth.onAuthStateChanged((user) => {
        if(user != null){

          try {
            onSnapshot(qu, (querySnapshot) => {
              if (!querySnapshot.empty) {
                const arr = [];
                querySnapshot.forEach((doc) => {
                  if(myBidsSelected){
                    if (Object.keys(doc.data()['bidders']).includes(auth.currentUser.uid)) {
                      if(Date.now() / 1000 - doc.data().expiryDate.seconds > 0 ){
                        // if(doc.data()['heighestBidder'] == auth.currentUser.uid){
                          if(!doc.data()['isSold'])
                          arr.push(doc.data());
                        // }
                      }
                      else{
                        arr.push(doc.data());
                      }
                    // if(Date.now() / 1000 - doc.data().expiryDate.seconds < 0 && doc.data()['heighestBidder'] == auth.currentUser.uid){
                    // }
                    }
                    setMyBids(arr.sort((a, b) => b.createdAt - a.createdAt));
                    setListing(arr.sort((a, b) => b.createdAt - a.createdAt))
                  }
                  else{
                    if (doc.data()['isSold'] && doc.data()['heighestBidder'] === auth.currentUser.uid) {
                      arr.push(doc.data());
                    }
                    setMyPurchases(arr.sort((a, b) => b.createdAt - a.createdAt));
                    setListing(arr.sort((a, b) => b.createdAt - a.createdAt))
                  }
                });

        
                setLoading(false);
              } else {
                setNumProducts("لم يتم العثور على منتجات");
                setListing([]);
                setLoading(false); // Ensure setLoading(false) is called when no data is present
              }
            });
          } catch (error) {
            console.error('Error fetching user data:', error);
            // Set loading to false in case of an error
            setLoading(false);
          }
        }
        else{
          setLoading(false);
        }
      });
    
      return () => {
        // Unsubscribe when the component unmounts
        unsubscribe();
      };
    }, [myBidsSelected]);
    
    
    if(isLoading){
      return       <div style={{
        width: "100vw",
        height: "80vh",
        marginTop: "55px",
        display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

      }}>
        <Oval
              height={"50px"}
              width={"50px"}
              color={"dodgerblue"}
              wrapperClass=""
              visible={true}
              wrapperStyle={{display: "block"}}
              ariaLabel='oval-loading'
              secondaryColor="#ccc"
              strokeWidth={1}
              strokeWidthSecondary={1}
            />
      </div>
    }


    if(auth.currentUser === null ){
      return <SignInOrSignUpScreen prompt={"الرجاء التسجيل او تسجيل الدخول لرؤية هذه الصفحة"} />
     }
    
  return ( 
    <div style={{marginTop:"55px", marginBottom: "55px", height: "100vh", width: "100vw", paddingTop: "45px", direction: "rtl"}}>
        <div style={{width: "100%", display: "flex", justifyContent: "space-evenly"}}>
          <div style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: 'center', padding: "4px"}}
          onClick={()=>{
            setMyBidsSelected(true)
            setMyPurchasesSelected(false)
          }}
          >
            <p
            style={{
              color: myBidsSelected ? "dodgerblue" : "black",
              marginBottom: "4px",
              fontSize: "17px"
            }}
            >مزايداتي</p>
            <div style={{height: "2px", width: "50%", backgroundColor: "dodgerblue", display: myBidsSelected ? "block" : "none"}}></div>
          </div>
          <div style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: 'center', padding: "4px"}}
          onClick={()=>{
            setMyPurchasesSelected(true)
            setMyBidsSelected(false)
          }}
          >
            <p
            style={{
              color: myPurchasesSelected ? "dodgerblue" : "black",
              marginBottom: "4px",
              fontSize: "17px"
            }}
            >مشترياتي</p>
            <div style={{height: "2px", width: "50%", backgroundColor: "dodgerblue", display: myPurchasesSelected ? "block" : "none"}}></div>
          </div>
        </div>
        {
          myBidsSelected && myBids == 0 &&
          <div style={{
            display:"flex",
            justifyContent: "center",
            marginTop:"128px"
          }}>
          <p style={{
            color: "gray"
          }}>
            انت لم تقم بأي مزايدة بعد
          </p>
          </div>
        }
        {
          myPurchasesSelected && myPurchases == 0 &&
          <div style={{
            display:"flex",
            justifyContent: "center",
            marginTop:"128px"
          }}>
          <p style={{
            color: "gray"
          }}>
            انت لم تقم بشراء أي منتج بعد
          </p>
          </div>
        }
            {
              isLoading ?
              <div style={{
                display: "flex",
                justifyContent: "center",
                textAlign: "center",
              }}>
              <Oval
              height={"50px"}
              width={"50px"}
              color={"dodgerblue"}
              wrapperClass=""
              visible={true}
              wrapperStyle={{display: "block"}}
              ariaLabel='oval-loading'
              secondaryColor="#ccc"
              strokeWidth={1}
              strokeWidthSecondary={1}
            />
              </div>
              : 
              <div style={{width: "100%", marginTop: "16px"}}>
          <div style={{ width: "95%", marginRight: "auto", marginLeft:"auto"}}>
          {
          Listing.length > 0 &&
          Listing.map((item) => (
            <div 
            key={item.id}
            >
          <Divider
          style={{ borderBottomWidth: 1, width: "100%"}}
          color='#ccc'
          />
          
          <div>
<Product 
          postId={item.id}
          expiryDate={`${item.expiryDate.seconds}`}
          link={`/product/${item.id}`}
          numBidders={Object.keys(item.bidders).length}
          currentBid={item.currentBid}
          imgs={item.imgs[0]}
          condition={item.condition}
          acceptsOffers={item.acceptsOffers}
          productPrice={item.productPrice}
          productDescription={item.productDescription}
          productSeller={item.seller}
          startingPrice={item.startingPrice}
          isSold={item.isSold}
          isBidder={Object.keys(item.bidders).includes(auth.currentUser.uid)}
          heighestBidder= {item.heighestBidder === auth.currentUser.uid}
          productName={item.productName}/>
  </div>
          </div>
          ))}
          </div>
        </div>
            }
      </div>
//     <>
//     <div id='products-screen-div'
//       >
//         <h2 style={{marginRight: "8px"}}>مزايداتي</h2>
//         {
//           Listing.length > 0 &&
//           Listing.map((item) => (
//             <div 
//             key={item.id}
//             >
//           <Divider
//           style={{ borderBottomWidth: 1, width: "100%"}}
//           color='#ccc'
//           />
          
// <div style={{padding: "1px 4px", backgroundColor: item.heighestBidder == auth.currentUser. uid ? "green" : "orange"}}>
// <Product 
//           postId={item.id}
//           expiryDate={`${item.expiryDate.seconds}`}
//           link={`/product/${item.id}`}
//           numBidders={item.numBidders}
//           currentBid={item.currentBid}
//           imgs={item.imgs[0]}
//           condition={item.condition}
//           acceptsOffers={item.acceptsOffers}
//           productPrice={item.productPrice}
//           productDescription={item.productDescription}
//           productSeller={item.seller}
//           startingPrice={item.startingPrice}
//           productName={item.productName}/>
//   </div>
//           </div>
//           ))}
//     </div>
//     </>
   );
  }

export default MyBidsScreen;