import React, { useEffect, useState } from 'react'
import e from '../assets/electronics.avif';
import f from '../assets/furniture.jpg';
import { MdOutlineWatchLater } from "react-icons/md";
import { PiGavelFill } from "react-icons/pi";
import { AiFillStar } from "react-icons/ai";
import { Divider } from '@mui/material';
import { initializeApp } from 'firebase/app';
import {Link , useParams, useNavigate, createSearchParams, useSearchParams} from 'react-router-dom';
import Countdown from 'react-countdown';
import { getStorage, ref, listAll, getDownloadURL,  } from "firebase/storage";
import { getFirestore, collection, getDocs, onSnapshot, where, Timestamp, Firestore, limit, doc, query, getDoc, updateDoc } from 'firebase/firestore';
import placeholder from '../assets/no-image.jpg';
import { formatDistanceToNow } from 'date-fns';
import arLocale from 'date-fns/locale/ar-SA';
import { BsThreeDotsVertical } from "react-icons/bs";
import { db, auth, storage } from '../config/firebase'
import { CiEdit } from "react-icons/ci";
import { FaRegTrashCan } from "react-icons/fa6";

function Comment({userName, isUpdated, userImg, commentRef ,userRating, numRaters, link, comment, timestamp, userId, commentId, postId}) {
    
    const date = timestamp != null && timestamp instanceof Timestamp ? timestamp.toDate() : timestamp;
    const timeAgo = timestamp != null ? formatDistanceToNow(date, { addSuffix: true , locale: arLocale}) : "";

    const [isOpen, setIsOpen] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const [newComment, setNewComment] = useState("");
    const [myuserId, setMyUserId] = useState("")

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
      };
    

      useEffect(()=>{
        const getUserId = async () => {
          if(auth.currentUser !== null){
            const userDocRef = doc(db, 'Users', auth.currentUser.uid);
            const userDocSnapshot = await getDoc(userDocRef);
            setMyUserId(userDocSnapshot.data().id)
          }
        }
        getUserId();
      }, [])
      
      const updateComment = async (postId, commentId, updatedCommentText) => {
        try {
          if (!commentId) {
            console.error('CommentId is empty or undefined');
            return;
          }
      
          const postRef = doc(db, 'Posts', postId);
          const commentsRef = collection(postRef, 'comments');
      
          // Get the specific comment document to update
          const commentDocRef = doc(commentsRef, commentId);
          const commentDoc = await getDoc(commentDocRef);
      
          if (commentDoc.exists()) {
            // Update the comment with the new text and set isUpdated to true
            await updateDoc(commentDocRef, {
              comment: updatedCommentText,
              isUpdated: true,
            });
      
            // Optionally, you may want to refetch comments after updating a comment
            // Refetch comments after updating a comment
          } else {
            console.error('Comment not found');
          }
        } catch (error) {
          console.error('Error updating comment:', error);
        }
      };
      
      

      const deleteComment = async (postId, commentId) => {
        try {
          const postRef = doc(db, 'Posts', postId);
          const commentsRef = collection(postRef, 'comments');
      
          // Get the specific comment document to update
          const commentDoc = await getDoc(doc(commentsRef, commentId));
      
          if (commentDoc.exists()) {
            // Update the comment with the new text and set isUpdated to true
            await updateDoc(doc(commentsRef, commentId), {
              isDeleted: true,
            });
            console.log("comment deleted")
            // Refetch comments after updating a comment
          } else {
            console.error('Comment not found');
          }
        } catch (error) {
          console.error('Error updating comment:', error);
        }
      };


    return (
        <>
        <div style={{ width:"100%", borderRadius: "24px" ,wordBreak: "break-word", marginTop: "8px", position: "relative"}}>
            <Link to={link} style={{display: "contents", color: "black", textDecoration: "none"}}>
            <div style={{display: "flex", width: "fit-content", paddingLeft: "32px"}}>
                <img src={userImg} width={50} height={50} style={{borderRadius: "1000px", objectFit: "cover"}}/>
                <div>
                    
                    <p style={{marginRight: "8px", marginBottom: "0px", width:"fit-content", whiteSpace:"nowrap"}}>{userName}</p>
                    <p style={{marginRight: "8px", marginBottom: "0px", width:"fit-content", whiteSpace:"nowrap", fontSize: "12px", color: "GrayText", direction: "rtl"}}>{timeAgo}{isUpdated && <CiEdit style={{marginRight: "4px"}}/>}</p>
                    
                    {/* <div style={{display: "flex"}}>
                    <AiFillStar color='gold' style={{marginTop:"3px", marginRight:"4px"}}/>
                         <p style={{marginBottom:"0px", marginRight:"2px"}}>{userRating}</p>
                         <p style={{marginBottom:"0px"   , marginRight:"2px"}}>({numRaters})</p>
                    </div> */}
                </div>
            </div>
            </Link>
            {
              auth.currentUser !== null && userId == myuserId && commentRef == "Post" &&
              <div style={{
                  position: "absolute",
                  padding: "0px 4px",
                  left: 0,
                  top: 0,
                  justifyContent: "center",
                  alignItems: "center",
                  
              }}
              onClick={toggleDropdown}
              >
                  <BsThreeDotsVertical />
              </div>
        }

        {isOpen && (
        <div style={{
            width: "fit-content",
            padding: "8px 16px",
            position: "absolute",
            backgroundColor: "white",
            left: 42,
            top: 0,
            direction: "rtl",
            borderRadius: "8px",
            boxShadow: "0 0 2px rgba(0,0,0,0.2)"
        }}>
          <p onClick={()=>{setEditMode(true); setIsOpen(false)}} style={{padding: "4px"}}><CiEdit size={18} style={{marginLeft: "4px"}} /> تعديل التعليق</p>
          <p onClick={()=>{deleteComment(postId, commentId)}} style={{color: "red", padding: "4px"}}><FaRegTrashCan size={14} style={{marginLeft: "8px"}} />حذف التعليق</p>
        </div>
      )}
        </div>
            <div style={{direction: "rtl", wordBreak: "break-word", marginLeft:"16px", width:"80%"}}>
                {
                    editMode ? 
                    <>
                    <textarea defaultValue={comment} id='input-comment' onChange={(e)=>{
                        setNewComment(e.target.value)
                    }}>
                    </textarea>
                    <button onClick={()=>{
                        if(newComment == "") return;
                        updateComment(postId, commentId, newComment)
                        setEditMode(false)
                    }} id='my-button' style={{width: "fit-content", padding: "8px", height: "40px", borderRadius : "8px", marginTop : "8px"}}>تعديل التعليق</button>
                    <button onClick={()=>{setNewComment(""); setEditMode(false);}} id='my-button' style={{width: "fit-content", padding: "8px", backgroundColor: "transparent", color: "red", marginRight: "2px", fontSize:"14px"}}>الغاء التعديل</button>
                    </>
                    : <p style={{marginRight: "55px", marginTop: "8px", marginBottom: "32px", width:"100%" }}>{newComment == "" ? comment : newComment}</p>
                }
            </div>
        </>
    );
}
 
export default Comment;