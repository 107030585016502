import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { GoHomeFill, GoReport } from "react-icons/go";
import { BsListCheck, BsFillPersonFill } from "react-icons/bs";
import { BsPersonCircle } from "react-icons/bs";
import { BiSupport } from "react-icons/bi";
import { FiSearch } from "react-icons/fi";
import { GiHamburgerMenu } from "react-icons/gi";
import { AiOutlineClose } from "react-icons/ai";
import { IoMdAddCircleOutline, IoMdSettings } from "react-icons/io";
import Dropdown from 'react-bootstrap/Dropdown';
import image from '../assets/logo.jpg';
import { TextField, Button, Divider } from "@mui/material"; 
import { Link, NavLink } from 'react-router-dom';
import { DropdownDivider } from 'react-bootstrap';
import Drawer from '@mui/material/Drawer';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import React, {useState, useRef} from 'react';
import ReactDom from 'react-dom'; 
import Category from './Category';
import HomeScreen from '../screens/ProductsScreen';
import { RxHamburgerMenu } from "react-icons/rx";
import { useSearchParams , useNavigate} from 'react-router-dom';
import furnitureImg from '../assets/furniture.jpg'
import logo2 from '../assets/logo2.png'
import { BiSolidWatch } from "react-icons/bi";
import SebhahIcon from '../assets/sebhah.svg'
import { BsFillPenFill } from "react-icons/bs";
import { BiSolidWalletAlt } from "react-icons/bi";
import { LiaPenAltSolid } from "react-icons/lia";
import { IoWalletOutline } from "react-icons/io5";
import { PiWatch } from "react-icons/pi";
import { BsThreeDotsVertical } from "react-icons/bs";
import { FaRegHeart } from "react-icons/fa";
import { MdOutlinePerson } from "react-icons/md";
import { FaPlus } from "react-icons/fa";
import { BsPerson } from "react-icons/bs";
import { BiChevronDown } from "react-icons/bi";



function CustomNavbar() {
 const [openDrawer, setOpenDrawer] = useState(false);
 const [openSearch, setOpenSearch] = useState(false);
 const [searchParams, setSearchParams] = useSearchParams();
 const navigate = useNavigate();

 const searchRef = useRef();
 const desktopSearchRef = useRef();
 const [searchTerm, setSearchTerm] = useState("");

 const formRef = useRef();


 const [isDropdownVisible, setDropdownVisible] = useState(false);

 const handleMouseEnter = () => {
   setDropdownVisible(true);
 };

 const handleMouseLeave = () => {
   setDropdownVisible(false);
 };




 function handleKeyDown(event) {
  if (event.key === 'Enter') {
    setSearchParams(`?${searchParams}`);
    if(searchRef.current.value.trim().length == 0){
      navigate("/");
      searchRef.current.value = "";
    }
    else{
      navigate(`?${searchParams}`)
    }
 }
}


  return (
    <>

    <div className='desktop-navbar'>
     
    <div style={{ width: "40%", height: "100%", display: "flex", alignItems: "center", position: "relative" }}>
  <a href='/'><img style={{ height: "55px", marginRight: "0px" }} src={logo2} /></a>
  <a style={{
     textDecoration: "none",
     color: "black",
     marginBottom: "0px",
     fontSize: "15px",
     fontWeight: "bold",
     display: "inline-block",
     height: "100%",
     marginRight: "22px",
     display: "flex",
     alignItems: "center",  // Center the content vertically
     padding: '0 10px',  // Add padding for better appearance
   }} href='/'>الرئيسية</a>
  <a
    onMouseEnter={handleMouseEnter}
    onMouseLeave={handleMouseLeave}
    style={{
      textDecoration: "none",
      color: "black",
      marginBottom: "0px",
      fontSize: "15px",
      fontWeight: "bold",
      display: "inline-block",
      height: "100%",
      marginRight: "22px",
      display: "flex",
      alignItems: "center",  // Center the content vertically
      padding: '0 10px',  // Add padding for better appearance
    }}
    className='navbar-option'
  >
    التصنيفات <BiChevronDown className='chevron' style={{marginRight:"2px"}} />
  </a>
  <a style={{ textDecoration: "none", color: "black", fontSize: "15px", fontWeight: "bold", display: "block", marginRight: "22px" }} href='contact_us'>تواصل معنا</a>
  {isDropdownVisible && (
    <div
      style={{
        position: 'absolute',
        top: '90%',
        right: "150px",
        backgroundColor: 'white',
        boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.1)',
        borderRadius: '4px',
        zIndex: 1,
      }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div style={{display:"flex", flexDirection: "column", textAlign:"right", direction: "rtl", }}>
  <a onClick={()=>{
     searchParams.set('category', 'ساعات');
     navigate(`/products?${searchParams.toString()}`)
  }} className='desktop-dropdown-item' style={{direction: "rtl", marginBottom: "8px", cursor: "pointer", width:"100%", marginLeft:"64px", padding: "8px 4px 8px 16px", borderTopLeftRadius: "4px", borderTopRightRadius :"4px"}}>
    <PiWatch size={20} style={{ marginLeft:"4px" }} />
    ساعات
    </a>
  <a onClick={()=>{
     searchParams.set('category', 'سبح');
     navigate(`/products?${searchParams.toString()}`)
  }} className='desktop-dropdown-item' style={{direction: "rtl", marginBottom: "8px", cursor: "pointer", width:"100%", marginLeft:"32px", padding: "8px 4px 8px 16px", borderTopLeftRadius: "4px", borderTopRightRadius :"4px"}}>
    <img src={SebhahIcon} alt="" width="20" height="20" style={{marginLeft:"4px"}} />

    سبح
    </a>
  <a onClick={()=>{
     searchParams.set('category', 'اقلام');
     navigate(`/products?${searchParams.toString()}`)
  }} className='desktop-dropdown-item' style={{direction: "rtl", marginBottom: "8px", cursor: "pointer", width:"100%", marginLeft:"32px", padding: "8px 4px 8px 16px", borderTopLeftRadius: "4px", borderTopRightRadius :"4px"}}>
      <LiaPenAltSolid size={20} style={{ marginLeft:"4px" }} />

    أقلام
    </a>
  <a onClick={()=>{
     searchParams.set('category', 'ابواك');
     navigate(`/products?${searchParams.toString()}`)
  }} className='desktop-dropdown-item' style={{direction: "rtl", cursor: "pointer", width:"100%", marginLeft:"32px", padding: "8px 4px 8px 16px", borderBottomLeftRadius: "4px", borderBottomRightRadius :"4px"}}>
    <IoWalletOutline size={20} style={{ marginLeft:"4px" }} />

    أبواك
    </a>

      </div>
    </div>
   )}
</div>


     <div style={{
      width: "40%",
      height: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      position: "relative",
      alignItems: "center"
    }}>
      <div style={{ position: "absolute", left: "19%", top: "47%", transform: "translateY(-50%)" }}>
        <FiSearch style={{ color: "#ccc", fontSize: "18px" , transform: 'rotate(90deg)'}}/>
      </div>
      <input
        style={{
          backgroundColor: "#fcfcfc",
          outline: "none",
          border: "1px solid #ededed",
          borderRadius: "4px",
          height: "30px",
          fontSize: "13px",
          width: "40%",
          paddingRight:"8px",
          marginRight: "25%",
          paddingLeft: "27px" // Padding left to accommodate the icon
        }}
        ref={desktopSearchRef}
        onChange={(e) => {
          searchParams.delete("category")
          searchParams.delete("subCategory")
          searchParams.set('search', e.target.value.trim());
          // setSearchTerm(e.target.value)
          // setSearchParams(`?${searchParams}`);
        }}
        onKeyDown={
          (e) => {
            if (e.key === 'Enter') {
              searchParams.delete("category")
              searchParams.delete("subCategory")
              setSearchParams(`?${searchParams}`);
              if (desktopSearchRef.current.value.trim().length === 0) {
                // navigate("/");
                desktopSearchRef.current.value = "";
              } else {
                navigate(`/products?${searchParams}`)
                desktopSearchRef.current.value = "";
              }
            }
          }
        }
        placeholder='البحث عن منتج'
      />
    </div>
    <a style={{textDecoration: "none", color: "black", marginTop: "0px", position: "relative", left: 0, top: "-14%"}} href='/my_account'><BsPerson style={{position: "absolute", left: "24%"}} size={22}/></a>
    </div>







    <nav id="Cnavbar-phone">
  <div id='phone-navbar-logo-div'
  >
    <RxHamburgerMenu
      id='phone-navbar-hamburger-icon'
      size={24}
      onClick={() => {
        setOpenDrawer(true)
      }}
    />

    <a href="/">
      <img id="logo-phone" src={logo2} alt='' />
    </a>


    <div style={{
      width: 'fit-content',
      textAlign: "center",
      height: '100%',
      position: "relative",
      alignItems: "center",
      justifyContent: "center"
    }}>
      <input
        type="search"
        ref={searchRef}
        onChange={(e) => {
          searchParams.delete("category")
          searchParams.delete("subCategory")
          searchParams.set('search', e.target.value.trim());
          // setSearchTerm(e.target.value)
          // setSearchParams(`?${searchParams}`);
        }}
        placeholder="البحث عن منتج"
        id='phone-navbar-search-input'
        style={{backgroundColor:"white", touchAction:"unset"}}
        
        onKeyDown={
          (e) => {
            if (e.key === 'Enter') {
              searchParams.delete("category")
              searchParams.delete("subCategory")
              setSearchParams(`?${searchParams}`);
              if (searchRef.current.value.trim().length === 0) {
                // navigate("/");
                searchRef.current.value = "";
              } else {
                navigate(`/products?${searchParams}`)
              }
            }
          }
        }
        // enterKeyHint="go"
      />

      <div style={{ position: 'absolute', top: '45%', left: '32px', transform: 'translateY(-50%)', color: "#ccc" }}>
        <FiSearch size={17} style={{transform: 'rotate(90deg)'}} />
      </div>
    </div>

    <div style={{
    height: "100%",
    width: "15%",
    marginLeft: "8px",
    display: "flex",
    // justifyContent: "space-between",
    justifyContent: "center",
    alignItems: "center",
    }}>

       {/* <Link to="/my_account" color='black' style={{color: "black"}}>
        <MdOutlinePerson size={22}/>
      </Link> */}
       <Link to="/contact_us" color='#444' style={{color: "black"}}>
        <BiSupport size={22}/>
      </Link>
        
     </div>
  </div>
</nav>

     
    <nav className="bottom-navbar">

      <NavLink
        to="/"
        className={({ isActive }) =>
          isActive ? 'Cnav-link-active' : 'Cnav-link-inActive'
        }
      >
      <GoHomeFill id="navbar-icons" size={19}/>
              <span id="Cnav-link-active">الرئيسية</span>
      </NavLink>

      <NavLink
        to="/my_bids"
        className={({ isActive }) =>
          isActive ? 'Cnav-link-active' : 'Cnav-link-inActive'
        }
      >     
      <BsListCheck id="navbar-icons" size={19}/>
        <span id="Cnav-link-active">مزايداتي</span>
      </NavLink>


      <NavLink
        to="/add_post"
        className={({ isActive }) =>
          isActive ? 'Cnav-link-active' : 'Cnav-link-inActive'
        }
      >
      <FaPlus id="navbar-icons" style={{}} size={22}/>
              <span id="Cnav-link-active">إضافة منتج</span>
      </NavLink>
      <NavLink
        to="/my_account"
        className={({ isActive }) =>
          isActive ? 'Cnav-link-active' : 'Cnav-link-inActive'
        }
      >
      <BsFillPersonFill id="navbar-icons" size={19}/>
              <span id="Cnav-link-active">حسابي</span>
      </NavLink>
      <NavLink
        to="/more"
        className={({ isActive }) =>
          isActive ? 'Cnav-link-active' : 'Cnav-link-inActive'
        }
      >
      <BsThreeDotsVertical id="navbar-icons" size={19}/>
              <span id="Cnav-link-active">المزيد</span>
      </NavLink>

      <a href="/">
        <img id="logo" src = {logo2} alt=''/>
      </a>

      {/* <Dropdown id="dropdown" 
      >
      <Dropdown.Toggle id="dropdown-toggle">
          التصنيفات 
      </Dropdown.Toggle>

      <Dropdown.Menu id="dropdown-menu">
        <a id='category-header'>الكترونيات</a>
        <Dropdown.Item href="/phones">جوالات</Dropdown.Item>
        <Dropdown.Item href="/screens">شاشات</Dropdown.Item>
        <Dropdown.Item href="/speakers">سماعات</Dropdown.Item>
        <Dropdown.Item href="/tablets">اجهزة لوحية</Dropdown.Item>
        <Dropdown.Item href="/cameras">كاميرات</Dropdown.Item>
        <Dropdown.Item href="/printers">طابعات</Dropdown.Item>
        <Dropdown.Item href="/fridgerators">ثلاجات</Dropdown.Item>
        <Dropdown.Item href="/air-conditioners">مكيفات</Dropdown.Item>
        <Dropdown.Item href="/pc-parts">قطع كمبيوتر</Dropdown.Item>
        <DropdownDivider/>
        <a id='category-header'>أثاث</a>
        <Dropdown.Item href="/chairs">كراسي</Dropdown.Item>
        <Dropdown.Item href="/sofas">كنب</Dropdown.Item>
        <Dropdown.Item href="/tables">طاولات</Dropdown.Item>
        <Dropdown.Item href="/shelves">أرفف</Dropdown.Item>
        <Dropdown.Item href="/drawers-and-wardrobes">دواليب و أدراج</Dropdown.Item>
        <Dropdown.Item href="/paintings">لوحات</Dropdown.Item>
        <DropdownDivider/>
        <a id='category-header'>قطع غيار</a>
        <Dropdown.Item href="/auto-parts">قطع غيار سيارات</Dropdown.Item>
        <DropdownDivider/>
        <Dropdown.Item href="/music-instruments">الات موسيقية</Dropdown.Item>

      </Dropdown.Menu>
    </Dropdown> */}

{/* 
<div id='navbar-search-input-div'>
  <input
    ref={searchRef}
    onChange={(e) => {
      searchParams.set('search', e.target.value.trim());
    }}
    onKeyDown={(e) => {
      if (e.key === 'Enter') {
        searchParams.delete("category")
        searchParams.delete("subCategory")
        setSearchParams(`?${searchParams}`);
        if (searchRef.current.value.trim().length === 0) {
          searchRef.current.value = "";
        } else {
          navigate(`/products?${searchParams}`)
        }
      }
    }}
    placeholder='ابحث هنا'
    id='navbar-search-input'
  />

  <div>
    <FiSearch id='navbar-search-icon' size={20} />
  </div>
</div>


    <Button 
    onClick={()=>{
      searchParams.delete("category")
      searchParams.delete("subCategory")
        setSearchParams(`?${searchParams}`);
        if (searchRef.current.value.trim().length === 0) {
          // navigate("/");
          searchRef.current.value = "";
        } else {
          navigate(`/products?${searchParams}`)
        }
    }}
    id="search-button" style={{
      width: "90px",
      height: "40px",
      fontSize: "16px",
      marginRight: "8px",
      borderRadius: "4px"
    }} ><span>البحث</span></Button>  

  <div 
  id="left-icons-div"
  >

      <Link
    to="/my_bids"
  >
<BsListCheck className="floating-icons" size={24}/>
  </Link>



      <Link
    to="/add_post"
  >
  <FaPlus 
  className="floating-icons" size={24}/>
  </Link>


      <Link
    to="/my_account"
  >
  <BsPersonCircle 
  className="floating-icons" size={24}/>
  </Link>

<Link to="contact_us">

  <BiSupport 
  className="floating-icons" size={24}/>
  </Link>
  </div>
     */}

    </nav>
    

{/*     
    <nav id="Cnavbar-phone">
  <div id='phone-navbar-logo-div'
  >
    <RxHamburgerMenu
      id='phone-navbar-hamburger-icon'
      size={24}
      onClick={() => {
        setOpenDrawer(true)
      }}
    />

    <a href="/">
      <img id="logo-phone" src={logo2} alt='' />
    </a>


    <div style={{
      width: 'fit-content',
      textAlign: "center",
      height: '100%',
      position: "relative",
      alignItems: "center",
      justifyContent: "center"
    }}>
      <input
        type="search"
        ref={searchRef}
        onChange={(e) => {
          searchParams.delete("category")
          searchParams.delete("subCategory")
          searchParams.set('search', e.target.value.trim());
          // setSearchTerm(e.target.value)
          // setSearchParams(`?${searchParams}`);
        }}
        placeholder="البحث"
        id='phone-navbar-search-input'
        style={{backgroundColor:"white"}}
        
        onKeyDown={
          (e) => {
            if (e.key === 'Enter') {
              searchParams.delete("category")
              searchParams.delete("subCategory")
              setSearchParams(`?${searchParams}`);
              if (searchRef.current.value.trim().length === 0) {
                // navigate("/");
                searchRef.current.value = "";
              } else {
                navigate(`/products?${searchParams}`)
              }
            }
          }
        }
        // enterKeyHint="go"
      />

      <div style={{ position: 'absolute', top: '45%', right: '32px', transform: 'translateY(-50%)', color: "#ccc" }}>
        <FiSearch size={17} />
      </div>
    </div>

    <div style={{
    height: "100%",
    width: "15%",
    marginLeft: "8px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    }}>

      {/* <Link to="/my_account" color='black' style={{color: "black"}}>
        <MdOutlinePerson size={24}/>
      </Link> */}
      {/* <Link to="/contact_us" color='black' style={{color: "black"}}>
        <BiSupport size={24}/>
      </Link> */}
        
    {/* </div>
  </div>
</nav>
 */} 



{/* <Drawer 
      anchor='right' open={openSearch} onClose={()=>{
        setOpenSearch(false);
      }}>
        <Box
        width={"80vw"}
        > 
        <h2>home</h2>

        </Box>
      </Drawer> */}

      <Drawer 
      
      anchor='right' open={openDrawer} onClose={()=>{
        setOpenDrawer(false);
      }}>
        <Box
        width={"80vw"}
        >
          <div style={{
            direction: "rtl",
            height: "100vh",
            width: "100%", 
            paddingRight: "16px"


            }}>
<div
  style={{
    height: "10%", // Set the height to 100% for the entire height of the parent container
    display: "flex", // Use flex display
    alignItems: "center"
  }}
>
  <img src = {logo2} style={{height: "100%", window: "50px", height: "50px"}} alt=''/>
  <p style={{marginBottom: "0px", marginRight: "4px", fontSize: "20"}}>من إلى</p>
</div>

            <Divider 
            style={{height:"4px"}}
            />
            <br/>
            <div style={{display: "flex", flexDirection:"column"}}>

              <p style={{marginBottom:"18px", fontSize: "17px"}}>التصفح السريع</p>


              <div style={{
              display: "flex",
              alignItems: "center", // Center items vertically
              padding: "8px 0px 8px 16px",
              cursor: "pointer",
              width: "fit-content",
              }}
              onClick={
                () => {
                    searchParams.set('category', 'ساعات');
                    navigate(`/products?${searchParams.toString()}`)
                    setOpenDrawer(false);
            }}
              >
  <PiWatch size={20} style={{ size: "24px" }} />
  <p
    style={{
      textDecoration: "none",
      color: "black",
      fontSize: "16px",
      marginBottom: "0px",
      marginRight: "7px"
    }}
  >
    ساعات
  </p>
</div>


<div style={{
  display: "flex",
  alignItems: "center", // Center items vertically
  padding: "8px 0px 8px 16px",
  cursor: "pointer",
  width: "fit-content",
}}
onClick={
  () => {
      searchParams.set('category', 'سبح');
      navigate(`/products?${searchParams.toString()}`)
      setOpenDrawer(false);
}}
>
<img src={SebhahIcon} alt="" width="20" height="20" />
  <p
    style={{
      textDecoration: "none",
      color: "black",
      fontSize: "16px",
      marginBottom: "0px",
      marginRight: "8px"
    }}
  >
    سبح
  </p>
</div>

<div style={{
  display: "flex",
  alignItems: "center", // Center items vertically
  padding: "8px 0px 8px 16px",
  cursor: "pointer",
  width: "fit-content",
}}
onClick={
  () => {
      searchParams.set('category', 'اقلام');
      navigate(`/products?${searchParams.toString()}`)
      setOpenDrawer(false);
}}
>
  <LiaPenAltSolid size={20} style={{ size: "24px" }} />
  <p
    style={{
      textDecoration: "none",
      color: "black",
      fontSize: "16px",
      marginBottom: "0px",
      marginRight: "8px"
    }}
  >
    أقلام
  </p>
</div>


<div style={{
  display: "flex",
  alignItems: "center", // Center items vertically
  padding: "8px 0px 8px 16px",
  cursor: "pointer",
  width: "fit-content",
}}
onClick={
  () => {
      searchParams.set('category', 'ابواك');
      navigate(`/products?${searchParams.toString()}`)
      setOpenDrawer(false);
}}
>
  <IoWalletOutline size={20} style={{ size: "24px" }} />
  <p
    style={{
      textDecoration: "none",
      color: "black",
      fontSize: "16px",
      marginBottom: "0px",
      marginRight: "8px"
    }}
  >
    أبواك
  </p>
</div>



<Divider 
            style={{height:"4px", marginTop: "16px"}}
            />
                <div style={{display: 'block'}}>

                {/* <a 
                href="/electronics/phones"
                id='drawer-option'
                >جوالات</a>
                <a 
                href="/electronics/screens"
                id='drawer-option'
                >شاشات</a>
                <a 
                href="/electronics/speakers"
                id='drawer-option'
                >سماعات</a>
                <a 
                href="/electronics/tablets"
                id='drawer-option'
                >اجهزة لوحية</a>
                <a 
                href="/electronics/cameras"
                id='drawer-option'
                >كاميرات</a>
                <a 
                href="/electronics/printers"
                id='drawer-option'
                >طابعات</a>
                <a 
                href="/electronics/fridgerators"
                id='drawer-option'
                >ثلاجات</a>
                <a 
                href="/electronics/air-conditioners"
                id='drawer-option'
                >مكيفات</a>
                <a 
                href="/electronics/pc-parts"
                id='drawer-option'
                >قطع كمبيوتر</a> */}
                {/* </div> */}
                {/* <Divider 
            style={{height:"4px"}}
            />
            <a 
              href='/furniture'
              style={{
                textDecoration: "none",
                color: "black",
                fontSize: "1.8rem",
                padding:"8px 16px", display: "block"}}>أثاث</a>
                
                <div style={{display: 'block'}}>

                <a 
                href="/furniture/chairs"
                id='drawer-option'
                >كراسي</a>
                <a 
                href="/furniture/sofas"
                id='drawer-option'
                >كنب</a>
                <a 
                href="/furniture/tables"
                id='drawer-option'
                >طاولات</a>
                <a 
                href="/furniture/shelves"
                id='drawer-option'
                >أرفف</a>
                <a 
                href="/furniture/drawers-and-wardrobes"
                id='drawer-option'
                >دواليب و أدراج</a>
                <a 
                href="/furniture/paintings"
                id='drawer-option'
                >لوحات</a>
                </div>
                <Divider 
            style={{height:"4px"}}
            />
            <a 
              href='/car-parts'
              style={{
                textDecoration: "none",
                color: "black",
                fontSize: "1.8rem",
                padding:"8px 16px", display: "block"}}>قطع غيار</a>
                
                <div style={{display: 'block'}}>

                <a 
                href='car-parts'
                id='drawer-option'
                >قطع غيار سيارات</a>
                </div> */}
                {/* <Divider 
            style={{height:"4px"}}
            />

                
                <div style={{display: 'block'}}>

                <a 
                id='drawer-option'
                href='music-instruments'
                style={{padding: "0px 16px 16px 32px", fontSize: "1.5rem", display: 'block'}}>الات موسيقية</a>
                </div> */}
             </div>
              </div> 
</div>
         </Box>
       </Drawer> 
    {/* </nav> */}
 </>
  );
}

export default CustomNavbar;