import React from 'react'

function AboutUsScreen () {
    window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'instant'
      });
        return (
            <div id='new-screens-div'>
                <h4>من نحن</h4>

                <p>منصة بيع المنتجات المستعملة هي مكان يجمع بين البائعين والمشترين لتبادل وبيع المنتجات المستعملة بطريقة آمنة وموثوقة. نحن نسعى لتوفير بيئة تجارية عبر الإنترنت تعزز الشفافية وتؤمن بحقوق الجميع.</p>

                <h4>رؤيتنا</h4>

                <p>نسعى لتوفير منصة إلكترونية تمكّن الأفراد من بيع وشراء المنتجات المستعملة بكل سهولة وثقة. نهدف إلى توفير تجربة تسوق ممتعة وآمنة لكل من البائعين والمشترين على حد سواء.</p>

                <h4>قيمنا</h4>

                <ul style={{marginRight: "24px"}}>
                    <li><strong>الشفافية:</strong> نؤمن بأهمية توفير معلومات صحيحة وواضحة حول المنتجات المُعروضة للبيع.</li>
                    <li><strong>الأمان والخصوصية:</strong> نلتزم بحماية بيانات المستخدمين وضمان سلامة معاملاتهم على المنصة.</li>
                    <li><strong>التواصل:</strong> نشجع على التواصل الفعّال بين البائعين والمشترين لضمان رضا الجميع.</li>
                    <li><strong>الجودة:</strong> نسعى لتوفير منصة تتسم بالجودة والموثوقية في تقديم الخدمات والدعم.</li>
                </ul>
            </div>
        );
}
 
export default AboutUsScreen;